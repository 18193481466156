/*
#***********************************************
#
#      Filename: src/api/qrcode/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 二维码设置相关接口
#        Create: 2021-11-16 09:20:54
# Last Modified: 2021-11-26 14:08:33
#***********************************************
*/
import request from '@/utils/request'

// 创建二维码
export function add(data) {
  return request({
    url: '/parkingQrcode/add',
    method: 'post',
    params: data
  })
}

// 批量删除
export function batchDelete(data) {
  return request({
    url: '/parkingQrcode/batchdel',
    method: 'post',
    params: data
  })
}

// 单个删除
export function deleteItem(data) {
  return request({
    url: '/parkingQrcode/delete',
    method: 'post',
    params: data
  })
}

// 下载二维码
export function download(data) {
  return request({
    url: '/parkingQrcode/download',
    method: 'post',
    params: data
  })
}

// 生成二维码
export function generate(data) {
  return request({
    url: '/parkingQrcode/generate',
    method: 'post',
    params: data
  })
}

// 详细信息
export function info(id, data) {
  return request({
    url: '/parkingQrcode/info/' + id,
    method: 'get',
    params: data
  })
}

// 数据列表
export function list(data) {
  return request({
    url: '/parkingQrcode/list',
    method: 'get',
    params: data
  })
}

// 子二维码列表
export function subList(data) {
  return request({
    url: '/parkingQrcode/sub/list',
    method: 'get',
    params: data
  })
}

// 更新数据
export function update(data) {
  return request({
    url: '/parkingQrcode/update',
    method: 'post',
    params: data
  })
}
