/*
#***********************************************
#
#      Filename: src/api/whiteList/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 白名单列表
#        Create: 2021-11-19 09:22:40
# Last Modified: 2021-11-26 14:11:04
#***********************************************
*/
import request from '@/utils/request'

// 添加
export function add(data) {
  return request({
    url: '/whiteList/add',
    method: 'post',
    params: data
  })
}

// 批量删除
export function batchDelete(data) {
  return request({
    url: '/whiteList/batchdel',
    method: 'post',
    params: data
  })
}

// 删除
export function deleteItem(id, data) {
  return request({
    url: '/whiteList/delete/' + id,
    method: 'post',
    params: data
  })
}

// 导出
export function exportRecord(data) {
  return request({
    url: '/whiteList/export',
    method: 'get',
    params: data
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/whiteList/info/' + id,
    method: 'get'
  })
}

// 详细信息
export function list(data) {
  return request({
    url: '/whiteList/list',
    method: 'get',
    params: data
  })
}

// 更新数据
export function update(data) {
  return request({
    url: '/whiteList/update',
    method: 'post',
    params: data
  })
}

// 白名单导入
export function upload(data) {
  return request({
    url: '/whiteList/uploadExcel',
    method: 'post'
  })
}
