/*
#***********************************************
#
#      Filename: src/api/exceptionOrder/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 财务报表相关api
#        Create: 2021-11-16 23:23:36
# Last Modified: 2021-11-26 13:59:59
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 导出数据
export function exportRecord(data) {
  return request({
    url: '/report/finance/export',
    method: 'get',
    params: data
  })
}

// 列表
export function list(data) {
  return request({
    url: '/report/finance/list',
    method: 'get',
    params: data
  })
}

// 租金缴纳统计报表
export function rentFeeReport(id, data) {
  const formData = qs.stringify(data)
  return request({
    url: '/housing/shop/rent/dailyReport/list/' + id,
    method: 'get',
    params: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 物业费缴纳统计报表
export function propertyFeeReport(id, data) {
  const formData = qs.stringify(data)
  return request({
    url: '/property/fee/dailyReport/list/' + id,
    method: 'get',
    params: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 租金缴纳明细列表
export function rentFeeList(id, data) {
  const formData = qs.stringify(data)
  return request({
    url: '/housing/shop/rent/charge/list/' + id,
    method: 'get',
    params: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 物业费缴纳明细列表
export function propertyFeeList(id, data) {
  const formData = qs.stringify(data)
  return request({
    url: '/property/fee/charge/list/' + id,
    method: 'get',
    params: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
