/*
#***********************************************
#
#      Filename: src/api/autoShift/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 自动换班管理相关api接口
#        Create: 2021-11-10 15:27:39
# Last Modified: 2021-11-27 21:16:36
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 添加换班
export function add(data) {
  const query = qs.stringify(data)
  return request({
    url: '/basic/changeWork/add',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 批量删除
export function batchDelete(data) {
  return request({
    url: '/basic/changeWork/batchDel',
    method: 'post',
    data: data
  })
}

// 删除数据
export function deleteItem(id) {
  return request({
    url: '/basic/changeWork/delete/' + id,
    method: 'post'
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/basic/changeWork/info' + id,
    method: 'get'
  })
}

// 数据列表
export function list(data) {
  return request({
    url: '/basic/changeWork/list',
    method: 'get',
    params: data
  })
}

// 信息更新
export function update(data) {
  const query = qs.stringify(data)
  return request({
    url: '/basic/changeWork/update',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 查询停车场出口对应IP
export function getIP(id) {
  return request({
    url: '/basic/changeWork/sentryList/' + id,
    method: 'get'
  })
}

// 停车场子账号列表
export function subaccountList(id) {
  return request({
    url: '/basic/changeWork/subaccountList' + id,
    method: 'get'
  })
}
