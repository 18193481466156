/**
 *
 * @param {Array} arr
 * @param {Array} compareArr
 * @param {String} attri        arr中的属性
 * @param {String} attriCompare compareArr中的属性
 * @param {String} newAttri      给arr中添加的属性
 * @returns {Array}
 */
function repetition(arr, compareArr, attri, attriCompare, newAttri) {
  const newArr = []
  if (arr && compareArr) {
    arr.forEach((element) => {
      const res = compareArr.find((it) => it[attriCompare] === element[attri])
      if (res) {
        const uData = res.createSaInfo
        console.log(uData)
        element[newAttri] = uData[newAttri]
        newArr.push(element)
      }
      return newArr
    })
  }
}

export { repetition }
