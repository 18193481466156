/*
#***********************************************
#
#      Filename: gz-report-front/src/api/order/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 车辆进出记录
#        Create: 2021-11-18 15:50:03
# Last Modified: 2021-11-26 14:03:28
#***********************************************
*/
import request from '@/utils/request'

// 导出
export function exportRecord(data) {
  return request({
    url: '/order/export',
    method: 'get',
    params: data
  })
}

// 数据列表
export function list(data) {
  return request({
    url: '/order/list',
    method: 'get',
    params: data
  })
}

// 支付记录
export function payLog(data) {
  return request({
    url: '/order/pay/log',
    method: 'get',
    params: data
  })
}
