<!--
#***********************************************
#
#      Filename: src/views/Setting/Merchant.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 商户设置
#        Create: 2021-10-28 17:03:52
# Last Modified: 2021-11-15 10:48:47
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      @filter-change="filterChange"
      @add="handleAdd"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-yellow" @click="edit(scope.row, '编辑角色')">编辑</div>
            <div class="gz-text-btn">权限配置</div>
            <div class="gz-text-btn gz-text-btn-red" @click="handleDelete(scope.row.id, '删除账号', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog :title="title" :visible.sync="open" append-to-body width="868px" class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="formData" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="商户名称:" prop="pids">
              <el-input v-model="formData.username" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式:" prop="pids">
              <el-input v-model="formData.username" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="地址:" prop="address">
              <el-input v-model="actionDataItem.remark" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="经营范围:" prop="address">
              <el-input v-model="actionDataItem.remark" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="折扣:" prop="address">
              <el-input v-model="actionDataItem.remark" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年免金:" prop="address">
              <el-input v-model="actionDataItem.remark" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { addSubAccount, batchDelete, info, list, resetPwd, update } from '@/api/person' // eslint-disable-line
  import { list as roleList } from '@/api/role'

  export default {
    name: 'PersonAccount',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          pids: '',
          username: '',
          name: '',
          roleName: '',
          phone: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          pids: [{ required: true, message: '所属停车场不能为空', trigger: 'blur' }],
          username: [{ required: true, message: '账号名称不能为空', trigger: 'blur' }],
          roleName: [{ required: true, message: '角色不能为空', trigger: 'blur' }],
          phone: [{ required: true, message: '手机号码不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '姓名/账号名称/电话号码'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            more: [
              {
                label: '批量启用',
                emitEvent: 'batch-active'
              },
              {
                label: '批量停用',
                emitEvent: 'batch-inActive'
              },
              {
                label: '批量删除',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateRange',
                name: 'dateRange'
              },
              {
                type: 'checkbox',
                name: 'isSync',
                label: '是否同步'
              },
              {
                type: 'select',
                name: 'status',
                placeholder: '请选择广告状态',
                options: [
                  { value: 1, label: '待审核' },
                  { value: 2, label: '待支付' },
                  { value: 3, label: '已上线' },
                  { value: 4, label: '已结束' }
                ]
              },
              {
                type: 'select',
                name: 'isChecked',
                placeholder: '请选择审核状态',
                options: [
                  { value: 0, label: '待审核' },
                  { value: 1, label: '通过' },
                  { value: 2, label: '拒绝' }
                ]
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '商户名称',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'name'
          },
          {
            label: '联系方式',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'username'
          },
          {
            label: '地址',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'username'
          },
          {
            label: '经营范围',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'username'
          },
          {
            label: '折扣',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'username'
          },
          {
            label: '年免金',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'username'
          },
          {
            label: '添加时间',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'username'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'roleName'
          }
        ],

        /**
         * 开发时用的数据
         */
        dataList: [],

        /**
         * 是否显示dialog
         */
        open: false,

        /**
         * 角色列表信息
         */
        roleList: [
          { value: '1', label: '角色1' },
          { value: '2', label: '角色2' }
        ]
      }
    },

    mounted() {
      this.getRoleList()
      this.getList()
    },

    methods: {
      /**
       * 获取角色列表
       */
      getRoleList() {
        if (this.pid) {
          const queryData = {}
          queryData.PId = this.pid
          roleList(queryData)
            .then((res) => {
              const dataList = []
              res.data.forEach((item) => {
                const roleItem = {}
                roleItem.value = item.id
                roleItem.label = item.name
                dataList.push(roleItem)
              })
              this.roleList = dataList
            })
            .catch((err) => {
              console.error('角色列表查询出错', err)
            })
        }
      },

      /**
       * 获取列表数据
       */
      getList() {
        this.loading = true

        if (this.pid) {
          // 组装查询条件
          const queryData = this.createBaseQueryData()
          queryData.PId = this.pid
          queryData.Uid = this.uid
          this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)

          list(queryData)
            .then((res) => {
              this.dataList = res.data
              this.total = res.total
              this.loading = false
            })
            .catch((err) => {
              console.error('子账号列表查询出错', err)
              this.loading = false
            })
        }
      },

      /**
       * 新增按钮操作
       */
      handleAdd() {
        this.resetForm()
        this.open = true
        this.title = '添加账号'
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getRoleList()
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.formData.id !== undefined) {
              update(_this.actionDataItem).then((response) => {
                if (response.result) {
                  _this.msgSuccess(response.message)
                  _this.modalObject.status = false
                  _this.handleRefresh()
                } else {
                  _this.msgError(response.message)
                  _this.modalObject.status = false
                }
              })
            } else {
              addSubAccount(_this.actionDataItem).then((res) => {
                if (res.result) {
                  _this.msgSuccess(res.message)
                  _this.open = false
                  _this.getList()
                } else {
                  _this.msgError(res.message)
                  _this.open = false
                }
              })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        batchDelete(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      }
    }
  }
</script>
