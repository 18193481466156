/*
#***********************************************
#
#      Filename: src/utils/aliOss.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 阿里oss封装
#        Create: 2021-10-27 17:36:11
# Last Modified: 2021-10-27 17:37:48
#***********************************************
*/
import OSS from 'ali-oss'

/**
 * [accessKeyId] {String} 通过阿里云控制台创建的AccessKey
 * [accessKeySecret] {String} 通过阿里云控制台创建的AccessSecret
 * [bucket] {String} 通过控制台或PutBucket创建的bucket
 * [region] {String} bucket所在的区域， 默认oss-cn-hangzhou
 */
const client = new OSS({
  region: 'oss-cn-zhangjiakou',
  accessKeyId: 'LTAI4G7P1WaoJy9Q6ii4zceC',
  accessKeySecret: 'aFX8zMSwWfHCTn0qDPePjWUf1tZVlY',
  bucket: 'matrixoss',
  secure: true
})

/**
 * 上传文件，大小不能超过5GB
 * @param {string} objName OSS存储路径和文件名称
 * @param {string} fileUrl 本地文件url
 * @returns Promise
 */
export const put = async function (objName, fileUrl) {
  try {
    const result = await client.put(`ad/${objName}`, fileUrl)
    console.log(result)
    return result
  } catch (err) {
    console.log(err)
  }
}

/**
 * 获取文件UUID
 */
export const getUUID = function () {
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
