<!--
#***********************************************
#
#      Filename: src/views/List/ParkingSpaceSales.vue
#
#        Author: xiaowang - 1839648563@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 车位销售
#        Create: 2023-06-05 15:28:15
#***********************************************
-->

<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :permissions="permissions"
      :total-item="total"
      @filter-change="filterChange"
      @add="add('新增车位销售信息', beforeAdd)"
      @refresh="handleRefresh"></ContentHeader>
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" size="mini" stripe border @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-for="(item, ind) in tableHeadFields"
        v-show="item.isShow"
        :key="ind"
        :label="item.label"
        :width="item.width"
        :align="item.align"
        :prop="item.prop">
        <template slot-scope="scope">
          <template v-if="ind == 2">
            <div v-if="scope.row.saleStatus == 1" size="mini" type="success">未售</div>
            <div v-if="scope.row.saleStatus == 2" size="mini" type="warning">已售</div>
            <div v-if="scope.row.saleStatus == 3" size="mini" type="danger">锁定</div>
          </template>
          <template v-else-if="ind == 3">
            <div v-if="scope.row.tenureType == 1" size="mini" type="success">人防</div>
            <div v-if="scope.row.tenureType == 2" size="mini" type="warning">个人</div>
            <div v-if="scope.row.tenureType == 3" size="mini" type="danger">公司</div>
          </template>
          <template v-else-if="ind == 4">
            <div v-if="scope.row.useType == 1" size="mini" type="success">未出租</div>
            <div v-if="scope.row.useType == 2" size="mini" type="warning">已出租</div>
            <div v-if="scope.row.useType == 3" size="mini" type="danger">个人使用</div>
            <div v-if="scope.row.useType == 4" size="mini" type="danger">公司自用</div>
          </template>
          <template v-else-if="ind == 9">
            {{ formOperName(scope.row.oper) }}
          </template>
          <template v-else>
            {{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-yellow" @click="edit(scope.row, '编辑车位销售信息', beforeEdit)">
              <i class="el-icon-edit"></i>
              编辑
            </div>
            <div class="gz-text-btn gz-text-btn-red" @click="handleDelete(scope.row.id, '删除车位销售信息', handleDel)">
              <i class="el-icon-delete"></i>
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="1000px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-divider content-position="left">车位信息</el-divider>
          <el-col :span="24">
            <el-form-item label="车位号:" prop="parkingNumber">
              <SaleSelectPicker
                key="shopPicker"
                v-model="parkform.placeNo"
                :options="parkListData"
                :loading="loading"
                :placeholder="'请选择车位号'"
                :has-all="false"
                :disabled="!!actionDataItem.id"
                @loadMore="loadMore"
                @remoteMethod="remoteMethod"
                @change="change"></SaleSelectPicker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产权室号:" prop="titleRoomNumber">
              <el-input v-model="actionDataItem.titleRoomNumber" :disabled="true" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="小区名称:" prop="communityName">
              <el-input v-model="actionDataItem.communityName" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="楼宇:" prop="building">
              <el-input v-model="actionDataItem.building" :disabled="true" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="楼层:" prop="floor">
              <el-input v-model="actionDataItem.floor" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="区域:" prop="region">
              <el-input v-model="actionDataItem.region" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="权属类型:" prop="tenureType">
              <el-select
                v-model="actionDataItem.tenureType"
                :disabled="true"
                placeholder="请选择权属类型"
                @change="handleTenureTypeChange($event)">
                <el-option
                  v-for="item in tenureTypeOption"
                  :key="item.tenureType"
                  :label="item.tenureTypeName"
                  :value="item.tenureType" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-divider content-position="left">销售信息</el-divider>
          <!-- <el-col :span="8">
            <el-form-item label="付款方式:" prop="collectionType">
              <el-select
                v-model="actionDataItem.collectionType"
                placeholder="请选择付款方式"
                @change="handleuseTypeChange($event)">
                <el-option
                  v-for="item in collectionOption"
                  :key="item.useType"
                  :label="item.useTypeName"
                  :value="item.useType" />
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="购买人:" prop="purchaser">
              <el-input v-model="actionDataItem.purchaser" placeholder="请输入购买人" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电话号码:" prop="phone">
              <el-input v-model="actionDataItem.phone" placeholder="请输入电话号码" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="actionDataItem.remark" type="textarea" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider content-position="left">款项信息</el-divider>
        <!-- <el-row v-for="(item, index) in addList" :key="index" :gutter="20">
          <el-col :span="11">
            <el-form-item label="款项名称:">
              <el-input v-model="item.feeTypeName" placeholder="请输入收款项目" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="款项金额:">
              <el-input v-model="item.fee" placeholder="请输入金额" />
            </el-form-item>
          </el-col>
          <el-col :span="2" class="iconCol">
            <i class="el-icon-remove" @click="handleRemoveForm(index)"></i>
          </el-col>
        </el-row> -->
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="款项名称:">
              <el-input v-model="addList[0].feeTypeName" placeholder="请输入收款项目" />
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="款项金额:">
              <el-input v-model="addList[0].fee" placeholder="请输入收款金额" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="2" class="iconCol">
            <i class="el-icon-circle-plus" @click="handleAddForm"></i>
          </el-col> -->
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import SaleSelectPicker from '@/components/saleSelectPicker'
  import ContentHeader from '@/components/ContentHeader'
  import moment from 'moment'
  import {
    list,
    getReport,
    add,
    update,
    deleteItem,
    getParking,
    getSaleInfo,
    getParkingNotSell,
    getCommunityInfo
  } from '@/api/ParkingSpaceSales'
  export default {
    name: 'Parking',
    components: { ContentHeader, SaleSelectPicker },
    mixins: [Pagination, Filter, ModalMixins, CommonInfo],
    data() {
      return {
        // 编辑添加弹框 付款项
        parkingForm: {
          feeTypeName: '',
          fee: '',
          id: ''
        },
        // 车位号选择
        parkform: {
          id: '',
          placeNo: ''
        },
        parkListData: [],
        addList: [
          {
            feeTypeName: '销售车位款',
            fee: 0
          }
        ],
        // 编辑表单数据验证规则
        rules: {
          // parkingNumber: [{ required: true, message: '车位号不能为空', trigger: 'blur' }],
          // titleRoomNumber: [{ required: true, message: '产权室号不能为空', trigger: 'blur' }],
          // purchaser: [{ required: true, message: '购买人不能为空', trigger: 'blur' }],
          phone: [{ required: true, message: '电话号码不能为空', trigger: 'blur' }]
        },
        // 组件头部配置项
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: false,
            searchPlaceholder: '车位号/购买人/租赁人/电话号码'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: false,
            downloadTemplateShow: false,
            importDataShow: false,
            more: []
          },
          // 车位销售主列表筛选字段配置
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateTimeRange',
                name: 'dateRange'
              },
              {
                type: 'select',
                name: 'Salestatus',
                placeholder: '请选择销售状态',
                options: [
                  { label: '未售', value: 1 },
                  { label: '已售', value: 2 },
                  { label: '锁定', value: 3 }
                ]
              },
              {
                type: 'select',
                name: 'status',
                placeholder: '请选择使用类型',
                options: [
                  { label: '未出租', value: 1 },
                  { label: '已出租', value: 2 },
                  { label: '个人使用', value: 3 },
                  { label: '公司自用', value: 4 }
                ]
              },
              {
                type: 'select',
                name: 'operator',
                placeholder: '请选择操作人员',
                options: []
              }
            ]
          }
        },
        // 主列表字段
        tableHeadFields: [
          // {
          //   label: '小区名称',
          //   isShow: true,
          //   width: '200',
          //   align: 'center',
          //   prop: 'communityName'
          // },
          // {
          //   label: '楼宇',
          //   isShow: true,
          //   width: '200',
          //   align: 'center',
          //   prop: 'building'
          // },
          // {
          //   label: '楼层',
          //   isShow: true,
          //   width: '200',
          //   align: 'center',
          //   prop: 'floor'
          // },
          // {
          //   label: '区域',
          //   isShow: true,
          //   width: '200',
          //   align: 'center',
          //   prop: 'region'
          // },
          {
            label: '车位号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'plateNumber'
          },
          {
            label: '产权室号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'titleRoomNumber'
          },
          {
            label: '销售状态',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'saleStatus'
          },
          {
            label: '权属类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'tenureType'
          },
          {
            label: '使用类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'useType'
          },
          {
            label: '购买人',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'purchaser'
          },
          {
            label: '租赁人',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'lessor'
          },
          {
            label: '电话号码',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'phone'
          },
          {
            label: '销售日期',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'saleDate'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'oper'
          },
          {
            label: '备注',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'remark'
          }
        ],
        // 主列表绑定字段
        dataList: [],
        tenureTypeOption: [
          {
            tenureTypeName: '人防',
            tenureType: 1
          },
          {
            tenureTypeName: '个人',
            tenureType: 2
          },
          {
            tenureTypeName: '公司',
            tenureType: 3
          }
        ],
        useTypeOption: [
          { useType: 1, useTypeName: '未出租' },
          { useType: 2, useTypeName: '已出租' },
          { useType: 3, useTypeName: '个人使用' },
          { useType: 4, useTypeName: '公司自用' }
        ],
        // collectionOption: [
        //   { collectionType: 1, collectionTypeName: '一次性' },
        //   { collectionType: 2, collectionTypeName: '按揭' }
        // ],
        modalObject: {
          title: '编辑车位',
          visible: false
        },
        formData: {
          id: null,
          communityName: '',
          communityId: '',
          building: '',
          floor: '',
          region: '',
          parkingNumber: '',
          titleRoomNumber: '',
          saleStatus: null,
          tenureType: null,
          useType: null,
          purchaser: '',
          lessor: '',
          phoneNumber: '',
          collectionItem: '',
          collectionPrice: '',
          saleDate: '',
          operator: '',
          remark: ''
        },
        startTime: '',
        endTime: '',
        parkingData: [],

        selectForm: {
          page: 1,
          size: 50
        },
        loading: false,
        isDisabled: false,
        searchKw: null,
        parkData: {},
        priceData: [],
        communityData: {},
        infoId: '',
        permissions: {
          create: 'sellinfo:create',
          info: 'sellinfo:info',
          delete: 'sellinfo:delete',
          update: 'sellinfo:update',
          export: 'sellinfo:export',
          import: 'sellinfo:import'
        }
      }
    },

    computed: {},

    mounted() {
      this.parkingData = JSON.parse(localStorage.getItem('GZ-ParkingList'))
      this.getList()
      this.getRpt()
    },

    methods: {
      init: async function (query) {
        this.loading = true
        // 获取选项数据，后端需支持 page  size  name搜索
        const res = await getParking({
          current: this.selectForm.page,
          size: this.selectForm.size,
          parkingId: this.pid,
          status: 0,
          searchKw: query
        })
        this.parkListData = res.data.records
        this.loading = false
      },
      change(value) {
        console.log(value)
        this.searchKw = value
        const id = value
        getCommunityInfo(id).then((res) => {
          this.communityData = res.data
          if (this.communityData) {
            this.$forceUpdate()
            this.actionDataItem.communityName = this.communityData.communityName || null
            this.actionDataItem.communityId = this.communityData.communityId || null
            this.actionDataItem.floor = this.communityData.floor || null
            this.actionDataItem.region = this.communityData.areaCode || null
            this.actionDataItem.titleRoomNumber = this.communityData.cqsNo || null
            this.actionDataItem.building = this.communityData.apartment || null
            this.actionDataItem.plateNumber = this.communityData.placeNo || null
            this.actionDataItem.plateId = this.communityData.id || null
            this.actionDataItem.tenureType = this.communityData.cqType || null
            this.parkData = this.communityData
          }
        })
      },
      visibleChange(status) {},
      loadMore(page) {
        this.selectForm.page = page
        this.init()
      },
      remoteMethod(query, page) {
        this.searchKw = query
        this.selectForm.page = page
        this.init(query)
      },

      // 主列表请求
      getList() {
        this.loading = true
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.parkingId = this.pid
        queryData.operatorsId = this.filterData.operator
        queryData.saleStatus = this.filterData.Salestatus
        queryData.tenureType = this.filterData.tenureType
        queryData.useType = this.filterData.status
        this.filterData.searchStr && (queryData.searchKeyWords = this.filterData.searchStr)
        if (this.filterData.dateRange) {
          queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        }
        list(queryData)
          .then((res) => {
            if (res.result) {
              this.dataList = res.data.records
              this.total = res.data.total
              this.loading = false
            } else {
              this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.loading = false
          })
      },
      // 获取操作人员
      getRpt() {
        this.loading = true
        const pid = this.pid
        const data = {
          pId: pid
        }
        getReport(data)
          .then((res) => {
            const repData = []
            res.data.forEach((item) => {
              repData.push({ label: item.name, value: item.id })
            })
            this.settings.dropDownFilterArray.arr[4].options = repData
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.loading = false
          })
      },
      // 获取车位号
      getParkList() {
        const parkId = this.pid
        const data = {
          parkingId: parkId,
          current: 1,
          size: 50,
          status: 0
        }
        getParking(data).then((res) => {
          if (res.result) {
            this.parkListData = res.data.records
          } else {
            this.noticeError(res.message)
          }
          console.log(res)
        })
      },
      // 页面刷新
      handleRefresh() {
        this.getList()
      },
      // 获取权属类型id
      handleTenureTypeChange(e) {},
      // 获取使用类型id
      handleuseTypeChange(e) {},
      // 编辑前
      beforeEdit(data) {
        this.infoId = data.id
        this.getInfo()
        this.parkform.placeNo = data.plateNumber
        this.actionDataItem = Object.assign({}, data)
        // this.actionDataItem.itemList = []
      },
      // 添加前
      beforeAdd() {
        this.getPlaceListWithNotSell()
        this.actionDataItem = {}
        this.addList = [
          {
            feeTypeName: '销售车位款',
            fee: 0
          }
        ]
        this.parkform.placeNo = ''
      },

      getInfo() {
        const id = this.infoId
        getSaleInfo(id)
          .then((res) => {
            if (res.result) {
              this.addList = []
              res.data.itemList.map((item, index) => {
                this.addList.push({
                  id: item.id,
                  feeTypeName: item.feeTypeName,
                  fee: item.fee
                })
              })
            } else {
              this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      // 编辑
      submitForm() {
        this.loading = true
        this.actionDataItem.itemList = this.addList
        this.actionDataItem.parkingId = this.pid
        this.actionDataItem.communityId = this.communityId
        // if (this.addList.lenth <= 0) {
        //   this.noticeError('至少添加一项款项')
        //   return
        // }
        // if (this.parkingForm.feeTypeName && this.parkingForm.fee) {
        //   this.handleAddForm()
        // }
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (this.modalObject.currentAction === 'edit') {
              update(this.actionDataItem)
                .then((res) => {
                  if (res.result) {
                    this.noticeSuccess(res.message)
                    this.handleRefresh()
                    this.modalObject.status = false
                    this.actionDataItem.itemList = []
                  } else {
                    this.actionDataItem.itemList = []
                    this.noticeError('业务处理失败')
                    this.modalObject.status = false
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
                .finally(() => {
                  this.loading = false
                })
            } else {
              console.log(this.actionDataItem, '?????-添加')
              add(this.actionDataItem)
                .then((res) => {
                  if (res.result) {
                    this.noticeSuccess(res.message)
                    this.handleRefresh()
                    this.modalObject.status = false
                    this.actionDataItem.itemList = []
                    this.parkform.placeNo = ''
                  } else {
                    this.noticeError('业务处理失败')
                    this.modalObject.status = false
                    this.actionDataItem.itemList = []
                    this.parkform.placeNo = ''
                  }
                })
                .finally(() => {
                  this.loading = false
                })
            }
          }
        })
      },

      handleSelect() {},
      // 列表行删除
      handleDel(id) {
        deleteItem(id)
          .then((res) => {
            if (res.result) {
              this.noticeSuccess('删除成功')
              this.handleRefresh()
            } else {
              this.noticeError(res.message)
            }
          })
          .catch((err) => {
            this.noticeError('请求失败')
            console.error(err)
          })
      },
      // 添加formItem
      handleAddForm() {
        const { fee, feeTypeName } = this.parkingForm
        if (!fee || !feeTypeName) {
          this.noticeError('请填写收款项目或收款金额')
          return
        }
        this.addList.push(this.parkingForm)
        this.parkingForm = {
          fee: 0,
          feeTypeName: ''
        }
      },
      // 移除formItem
      handleRemoveForm(index) {
        this.addList.splice(index, 1)
      },

      handleRemoveFormList(index) {
        this.priceData.splice(index, 1)
      },
      getPlaceListWithNotSell() {
        console.log('parking_List', this.parkListData)
        getParkingNotSell(this.pid).then((res) => {
          if (res.result) {
            this.parkListData.splice(0, this.parkListData.lenth, ...res.data)
          }
        })
      }
    }
  }
</script>
<style lang="scss">
  .iconCol {
    [class^='el-icon-'],
    [class*=' el-icon-'] {
      font-size: 24px !important;
      margin-top: 6px;
      color: #ff820d;
    }
  }
</style>
