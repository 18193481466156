export function getAttr(obj, attr) {
  if (!attr) {
    return obj
  }
  const attrArr = attr.split('.')
  let tempObj = JSON.parse(JSON.stringify(obj))
  for (let index = 0; index < attrArr.length; index++) {
    const attrName = attrArr[index]
    tempObj = tempObj[attrName]
  }
  return tempObj
}
