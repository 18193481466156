<!--
#***********************************************
#
#      Filename: src/views/Person/Attendance.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 考勤管理
#        Create: 2021-11-15 09:15:09
# Last Modified: 2021-11-15 09:43:45
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @export="handleExport"
      @filter-change="filterChange"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { list } from '@/api/attendance' // eslint-disable-line
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'Attendance',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: false,
            filterToggleShow: false,
            tableHeadToggleShow: true,
            searchPlaceholder: ''
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateTimeRange',
                name: 'dateTimeRange'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '值班人员',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'username'
          },
          {
            label: '上班打卡时间',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'startTime'
          },
          {
            label: '下班打卡时间',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'endTime'
          },
          {
            label: '上班总时长（小时）',
            isShow: true,
            align: 'center',
            prop: 'totalTime'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          export: 'attendance:export'
        }
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 获取列表数据
       */
      getList() {
        if (!this.pid) {
          this.noticeError('未选定停车场')
          return
        }
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.pId = this.pid
        this.filterData.searchKey && (queryData.searchKw = this.filterData.searchKey)
        if (this.filterData.dateTimeRange) {
          queryData.startTime = this.parseTime(this.filterData.dateTimeRange[0] / 1000)
          queryData.endTime = this.parseTime(this.filterData.dateTimeRange[1] / 1000)
        }

        list(queryData)
          .then((res) => {
            _this.dataList = res.data.records
            _this.total = res.data.total
          })
          .catch((err) => {
            console.error('考勤列表查询出错', err.message)
            _this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出考勤信息？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            queryData.current = 1
            queryData.size = 999
            queryData.pId = this.pid
            this.filterData.searchKey && (queryData.searchKw = this.filterData.searchKey)
            if (this.filterData.dateTimeRange) {
              queryData.startTime = this.parseTime(this.filterData.dateTimeRange[0] / 1000)
              queryData.endTime = this.parseTime(this.filterData.dateTimeRange[1] / 1000)
            }

            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = _this.formatJson(filterVal, res.data.records)
                  export_json_to_excel(tHeader, data, '考勤信息')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
                console.log('导出考勤信息请求失败:', err.message)
              })
          })
          .catch((err) => {
            console.log('导出考勤信息失败:', err.message)
          })
      }
    }
  }
</script>
