<!--
#***********************************************
#
#      Filename: src/views/Person/Role.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 角色管理
#        Create: 2021-10-28 16:52:04
# Last Modified: 2021-11-15 09:41:15
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      @filter-change="filterChange"
      @add="add('新增角色')"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop">
        <template slot-scope="scope">
          {{ scope.row.updateTime || scope.row.createTime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-yellow" @click="edit(scope.row, '编辑角色')">编辑</div>
            <div class="gz-text-btn" @click="authConfig(scope.row)">权限配置</div>
            <div class="gz-text-btn gz-text-btn-red" @click="handleDelete(scope.row.id, '删除账号', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="角色名称:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请输入角色名称" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="角色说明:" prop="remark">
              <el-input v-model="actionDataItem.remark" type="textarea" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 权限配置dialog -->
    <el-dialog title="权限编辑" :visible.sync="showAuthModal" append-to-body width="468px" class="gz-common-dialog">
      <el-tree
        ref="tree"
        :data="authTree"
        show-checkbox
        node-key="id"
        highlight-current
        :props="{
          children: 'children',
          label: 'name'
        }" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitAuthForm">确 定</el-button>
        <el-button @click="authCancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 权限配置dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { add, list as roleList, update, deleteItem, treeList, privilegeList, setPrivileges } from '@/api/role'

  export default {
    name: 'Role',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          name: '',
          remark: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          name: [{ required: true, message: '角色名称不能为空', trigger: 'blur' }],
          remark: [{ required: true, message: '角色说明不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '姓名/账号名称/电话号码'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            more: [
              {
                label: '批量删除',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateRange',
                name: 'dateRange'
              },
              {
                type: 'checkbox',
                name: 'isSync',
                label: '是否同步'
              },
              {
                type: 'select',
                name: 'status',
                placeholder: '请选择广告状态',
                options: [
                  { value: 1, label: '待审核' },
                  { value: 2, label: '待支付' },
                  { value: 3, label: '已上线' },
                  { value: 4, label: '已结束' }
                ]
              },
              {
                type: 'select',
                name: 'isChecked',
                placeholder: '请选择审核状态',
                options: [
                  { value: 0, label: '待审核' },
                  { value: 1, label: '通过' },
                  { value: 2, label: '拒绝' }
                ]
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '角色名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          { label: '角色说明', isShow: true, align: 'center', prop: 'remark' },
          {
            label: '最新修改时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'createTime '
          }
        ],

        /**
         * 开发时用的数据
         */
        dataList: [],

        /**
         * 是否显示权限配置
         */
        showAuthModal: false,

        /**
         * 权限树
         */
        authTree: null,

        /**
         * 当前角色id
         */
        currentRoleId: null
      }
    },

    mounted() {
      this.getRoleList()
    },

    methods: {
      /**
       * 获取角色列表
       */
      getRoleList() {
        const _this = this
        const queryData = {}
        roleList(queryData)
          .then((res) => {
            _this.dataList = res.data.records
            _this.total = res.data.total
          })
          .catch((err) => {
            console.error('角色列表查询出错', err)
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getRoleList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.modalObject.currentAction === 'edit') {
              const id = this.actionDataItem.id
              delete this.actionDataItem.id
              update(id, _this.actionDataItem)
                .then((res) => {
                  if (res.result) {
                    _this.noticeSuccess('修改成功')
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(res.message)
                  }
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
                .finally(() => {
                  _this.modalObject.status = false
                })
            } else {
              add(_this.actionDataItem)
                .then((res) => {
                  if (res.result) {
                    _this.noticeSuccess('添加成功')
                    _this.modalObject.status = false
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(res.message)
                    _this.modalObject.status = false
                  }
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
                .finally(() => {
                  _this.modalObject.status = false
                })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      },

      /**
       * 权限配置点击事件
       */
      authConfig(obj) {
        const _this = this
        this.currentRoleId = obj.id
        treeList().then((res) => {
          if (res.result) {
            _this.authTree = res.data
            privilegeList(obj.id).then((pres) => {
              if (res.result) {
                const privilegeTree = pres.data
                _this.privilegeTree = privilegeTree.map((item) => {
                  return {
                    id: item.privilegeInfo.id,
                    label: item.privilegeInfo.name
                  }
                })
                _this.$refs.tree.setCheckedNodes(_this.privilegeTree)
              } else {
                _this.msgError(pres.message)
              }
            })
          } else {
            _this.msgError(res.message)
          }
        })
        this.showAuthModal = true
      },

      /**
       * 权限菜单弹框确定
       */
      submitAuthForm() {
        const _this = this
        const checkedList = this.$refs.tree.getCheckedNodes()
        console.log('checkList', checkedList)
        console.log(this.$refs.tree.getCheckedKeys())

        const filterCheckeList = checkedList.filter((item) => {
          return item.type !== 1
        })

        const filterArr = []
        filterCheckeList.forEach((item) => {
          filterArr.push(item.id)
        })
        const queryData = {}
        queryData.privilegeIds = filterArr
        setPrivileges(this.currentRoleId, queryData)
          .then((res) => {
            if (res.result) {
              _this.msgSuccess('设置成功')
            } else {
              _this.msgError(res.message)
            }
          })
          .catch((e) => {
            _this.msgError(e.message)
          })
      },

      /**
       * 权限弹框取消点击事件
       */
      authCancel() {
        this.showAuthModal = false
        this.currentRoleId = null
      }
    }
  }
</script>
