<!--
#***********************************************
#
#      Filename: gz-adserver-front/src/views/login.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 登录页面
#        Create: 2021-08-18 11:23:51
# Last Modified: 2021-11-11 21:56:28
#***********************************************
-->
<template>
  <div class="main">
    <div class="item-wrapper">
      <div class="item-box" @click="handleClickPlatformTwo">
        <div class="box-header">驾到智云车管平台总账号管理</div>
        <div class="icon-wrapper">
          <svg-icon icon-class="platform-one" />
        </div>
      </div>
      <div class="item-box" @click="handleClickPlatformOne">
        <div class="box-header">驾到智云车管平台</div>
        <div class="icon-wrapper">
          <svg-icon icon-class="platform-two" />
        </div>
      </div>
    </div>

    <!--  底部  -->
    <div class="gz-login-footer">
      <span>Copyright © 2018-2021 gz All Rights Reserved.</span>
    </div>
    <!-- end 底部 -->
  </div>
</template>

<script>
  export default {
    name: 'Index',

    data() {
      return {}
    },

    methods: {
      handleClickPlatformOne() {
        this.$router.replace({ path: 'platformOneLogin' })
      },

      handleClickPlatformTwo() {
        this.$router.replace({ path: 'platformTwoLogin' })
      }
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss">
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url('../../assets/images/login-background.jpg');
    background-size: cover;

    .item-wrapper {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 200px;
      justify-content: space-around;
    }

    .item-box {
      width: 400px;
      height: 300px;
      background: white;
      padding: 40px;
      opacity: 0.4;
      border-radius: 20px;
      &:hover {
        opacity: 0.8;
      }

      .box-header {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
      }

      .icon-wrapper {
        height: 200px;
        display: grid;
        align-items: center;
        justify-content: center;
        font-size: 100px;
      }
    }
  }

  .gz-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }
</style>
