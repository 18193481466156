<!--
#***********************************************
#
#      Filename: src/views/Account/Info.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 个人资料页面
#        Create: 2022-02-11 10:14:49
# Last Modified: 2022-02-11 10:19:28
#***********************************************
-->
<template>
  <div class="app-container">
    <el-card class="box-card">
      <el-form ref="form" :model="info" :rules="rules" label-width="100px">
        <div class="info">
          <div class="info-head">基本信息</div>
          <div class="info-body">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="公司名称:" prop="name">
                  <el-input v-model="info.name" placeholder="请输入公司名称" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="公司简称:" prop="shortName">
                  <el-input v-model="info.shortName" placeholder="请输入公司简称" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="联系人:" prop="contactName">
                  <el-input v-model="info.contactName" placeholder="请输入联系人" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系方式:" prop="contactMobile">
                  <el-input v-model="info.contactMobile" placeholder="请输入联系方式" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="合作方式:" prop="type">
                  <el-select v-model="info.type" placeholder="请选择合作方式">
                    <el-option label="公司" :value="1" />
                    <el-option label="个人" :value="2" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="地址:" prop="region">
                  <el-cascader
                    v-model="info.region"
                    :options="regionTreeList"
                    :props="{ label: 'name', value: 'name' }"
                    filterable
                    clearable
                    placeholder="请输入地址" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="info supplement">
          <div class="info-head">补充信息</div>
          <div class="info-body">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="详细地址:" prop="address">
                  <el-input v-model="info.address" placeholder="请输入详细地址" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="主营业务:" prop="bizDesc">
                  <el-input v-model="info.bizDesc" placeholder="请输入主营业务" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="资质证明:" prop="qualification">
                  <el-upload
                    class="upload-wrapper"
                    drag
                    action="#"
                    :http-request="uploadImg"
                    :show-file-list="false"
                    :before-upload="beforeUpload">
                    <el-image v-if="info.qualification" class="preview" :src="info.qualification" fit="fit">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline" style="font-size: 96px; color: #ccc" />
                      </div>
                    </el-image>
                    <i v-else class="el-icon-picture-outline" style="font-size: 96px; margin-top: 20px; color: #ccc" />
                    <div class="el-upload__text">
                      将文件拖到此处，或
                      <em>点击上传</em>
                      只能上传 jpg/png 文件,且不超过2Mb
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-form-item>
                <el-button type="warning" @click="submitForm">保存</el-button>
              </el-form-item>
            </el-row>
          </div>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import { info, fill } from '@/api/ent'
  import { getUUID, put } from '@/utils/aliOss.js'
  import { regionTree } from '@/api/common'
  import { RX_MOBILE } from '@/utils/constants/regex'

  export default {
    name: 'Profile',

    data() {
      return {
        info: {},
        rules: {
          name: [
            { required: true, message: '请输入公司名称', trigger: 'blur' },
            {
              min: 5,
              max: 200,
              message: '企业名称限5-200个字符',
              trigger: 'blur'
            }
          ],
          shortName: [
            { required: true, message: '请输入公司简称', trigger: 'blur' },
            { min: 2, max: 16, message: '公司简称限2-16个字符', trigger: 'blur' }
          ],
          contactName: [
            { required: true, message: '请输入联系人', trigger: 'blur' },
            {
              min: 2,
              max: 30,
              message: '联系人名称限2-30个字符',
              trigger: 'blur'
            }
          ],
          contactTel: [
            { required: true, message: '请输入联系方式', trigger: 'blur' },
            { pattern: RX_MOBILE, message: '联系电话格式不符', trigger: 'blur' }
          ],
          type: [{ required: true, message: '请选择合作方式', trigger: 'blur' }],
          region: [{ required: true, message: '请选择地址', trigger: 'blur' }],
          address: [
            { required: true, message: '请填写详细地址', trigger: 'blur' },
            {
              min: 2,
              max: 200,
              message: '详细地址限2-200个字符',
              trigger: 'blur'
            }
          ],
          bizDesc: [
            { required: true, message: '请填写主营业务', trigger: 'blur' },
            {
              min: 2,
              max: 200,
              message: '主营业务限2-200个字符',
              trigger: 'blur'
            }
          ],
          qualification: [{ required: true, message: '请上传资质证明', trigger: 'blur' }]
        },

        regionTreeList: []
      }
    },

    created() {
      this.getInfo()
      this.getRegionTree()
    },

    methods: {
      /**
       * 获取区域树
       */
      getRegionTree() {
        const _this = this
        regionTree()
          .then((res) => {
            if (res.result) {
              _this.regionTreeList = res.data
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((e) => {
            console.error('区域树请求失败', e)
          })
      },

      /**
       * 获取用户信息
       */
      getInfo() {
        const _this = this
        info()
          .then((res) => {
            if (res.result) {
              _this.info = res.data
              _this.info.contactTel = res.data.contactMobile
              _this.info.region = res.data.region.split(',')
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.error('获取企业个人信息失败:', err)
          })
      },

      /**
       * 上传资质证明文件删除
       */
      handleCertificateRemove() {
        this.info.qualification = ''
      },

      /**
       * 上传资质文件
       */
      uploadImg(file) {
        const _this = this
        const objName = getUUID()

        // 获取文件的扩展后缀名
        const fileInfoArr = file.file.name.split('.')
        const ext = fileInfoArr[fileInfoArr.length - 1]

        put(`${objName}.${ext}`, file.file, 'manage/qualification')
          .then((res) => {
            _this.info.qualification = res.url
            _this.noticeSuccess('资质文件上传成功')
          })
          .catch((e) => {
            console.log('资质文件上传失败', e)
            _this.noticeError('资质文件上传失败')
          })
      },

      /**
       * 资质文件上传前预处理（文件大小、类型等的判断）
       */
      beforeUpload(file) {
        const isLt2M = file.size < 2 * 1024 * 1024

        if (file.type.indexOf('image/') === -1) {
          this.noticeError('文件格式错误，请上传图片类型,如:JPG、PNG后缀的文件')
          return false
        }
        if (!isLt2M) {
          this.noticeError('上传文件大小不能超过2MB!')
          return false
        }
        return true
      },

      /**
       * 保存更改
       */
      submitForm() {
        const _this = this
        const postData = Object.assign({}, this.info)
        postData.region = postData.region.join(',')
        this.$refs.form.validate((valid) => {
          if (valid) {
            fill(postData)
              .then((res) => {
                if (res.result) {
                  _this.noticeSuccess('修改成功')
                  _this.getInfo()
                  // vue-router更新用户信息
                } else {
                  _this.noticeError(res.message)
                }
              })
              .catch((e) => {
                console.log('修改资料信息请求失败', e)
              })
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .box-card {
    padding: 40px;
    .user-avatar {
      text-align: center;
    }
  }
  .info {
    width: 800px;

    .info-head {
      font-size: 16px;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 2;
      margin-bottom: 20px;
      padding-left: 10px;
      border-left: 4px solid #faa13c;
    }
    .content-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .content-item {
        margin-top: 40px;
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        .item-label {
          width: 80px;
          text-align: right;
          color: #cccccc;
          height: 14px;
          margin-bottom: 0;
        }
        .item-value {
          margin-left: 10px;
          color: #1a1a1a;
          .item-edit {
            color: #cccccc;
            margin-left: 10px;
            &:hover {
              color: #1a1a1a;
            }
          }
        }
        .content-input-wrapper {
          margin-left: 10px;
          .content-input {
            border: 0;
          }
        }
        .content-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
  .supplement {
    margin-top: 40px;
  }
  .upload-wrapper {
    .gz-banner-img-review {
      display: grid;
      color: #cccccc;
      position: relative;
      .gz-banner-img {
        height: 120px;
      }
      .gz-banner-action-del {
        font-size: 24px;
        color: white;
        left: 50%;
        top: 20%;
        z-index: 999;
        position: absolute;
        background: #cccccc;
        padding: 10px;
        border-radius: 10px;
      }
    }
    .preview {
      width: 96px;
      height: 96px;
      margin-top: 20px;
    }
  }
  ::v-deep .el-select {
    width: 100%;
  }

  ::v-deep .el-upload {
    width: 100%;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
  }
  ::v-deep .el-cascader {
    width: 100%;
  }
  ::v-deep .el-upload {
    width: 100%;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
  }
</style>
