<template>
  <div class="fee-rule">
    <el-col :lg="12" :md="18">
      <el-form ref="ruleForm" :model="ruleForm" status-icon :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="当前停车场" prop="desc">
          <el-input v-model="parkingName" readonly />
        </el-form-item>
        <el-form-item label="描述" prop="desc">
          <el-input v-model="ruleForm.desc" type="textarea" :rows="5" autocomplete="off" />
        </el-form-item>
        <el-form-item label="收费公示牌" prop="image">
          <el-upload
            class="image-uploader"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="upload"
            action="#">
            <img
              v-if="ruleForm.image && ruleForm.image.length > 1"
              alt="没有图片"
              :src="ruleForm.image"
              class="image" />
            <i v-else class="el-icon-plus image-uploader-icon" />
          </el-upload>
        </el-form-item>
        <el-form-item label="JS代码" prop="jsContent">
          <el-input v-model.number="ruleForm.jsContent" type="textarea" :rows="5" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit('ruleForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </div>
</template>

<script>
  import CommonInfo from '@/views/mixins/commonInfo'
  import { fileUpload } from '@/api/common'
  import { configInfo, putFeeRule } from '@/api/parking'

  export default {
    name: 'FeeRule',
    mixins: [CommonInfo],
    data() {
      var validateDesc = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入收费规则描述'))
        } else if (value.length > 200) {
          callback(new Error('收费规则描述限200字'))
        } else {
          callback()
        }
      }
      var validateImage = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('收费公示牌不能为空'))
        } else {
          callback()
        }
      }

      var validateJsContent = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('收费规则不能为空'))
        } else {
          callback()
        }
      }
      return {
        parkingId: 51,
        parkingName: '',
        ruleForm: {
          desc: '',
          image: '',
          jsContent: ''
        },
        rules: {
          desc: [{ validator: validateDesc, trigger: 'blur' }],
          image: [{ validator: validateImage, trigger: 'blur' }],
          jsContent: [{ validator: validateJsContent, trigger: 'blur' }]
        }
      }
    },
    mounted() {
      // 获取配置
      const _this = this
      configInfo(this.pid)
        .then((data) => {
          if (!data.result) {
            console.error('停车场配置数据获取失败', data.status)
            _this.$message.error('当前停车场配置数据获取失败:' + data.message)
            return
          }

          var info = data.data
          _this.ruleForm.jsContent = info.feeRuleContent
          _this.ruleForm.image = info.parkingInfo ? info.parkingInfo.ruleImage : ''
          _this.ruleForm.desc = info.parkingInfo ? info.parkingInfo.ruleRemark : ''
          _this.parkingName = info.parkingInfo ? info.parkingInfo.name : ''
        })
        .catch((e) => {
          console.error('配置数据获取失败', e)
          _this.$message.error('当前停车场配置数据获取失败')
        })
    },
    methods: {
      beforeUpload(file) {
        const fileSize = file.size / 1024 / 1024
        if (fileSize > 2) {
          this.msgError('文件大小限2M')
          return false
        }
      },
      upload(params) {
        const _this = this
        const _file = params.file

        // 通过formData对象上传文件
        const formData = new FormData()
        formData.append('file', _file)

        const queryData = {}
        queryData.type = 0

        fileUpload(formData, queryData)
          .then((res) => {
            if (res.result) {
              _this.noticeSuccess('上传成功')
              _this.ruleForm.image = res.data.url
            } else {
              _this.noticeError('上传失败:' + res.message)
            }
          })
          .catch((err) => {
            _this.noticeError('文件上传请求失败', err)
          })
      },
      // 提交
      submit() {
        const _this = this
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$confirm('确定提交数据吗？', '保存收费规则')
              .then(() => {
                // 确定
                // 保存
                putFeeRule(_this.pid, {
                  desc: _this.ruleForm.desc,
                  image: _this.ruleForm.image,
                  jsContent: _this.ruleForm.jsContent
                })
                  .then((data) => {
                    if (!data.result) {
                      console.error('保存收费规则失败', data)
                      _this.$message.error('保存失败:' + data.message)
                    } else {
                      _this.$message.info('保存成功')
                    }
                  })
                  .catch((e) => {
                    console.error('保存收费规则失败', e)
                    _this.$message.error('服务器请求失败')
                  })
              })
              .catch(() => {
                // 取消
                console.log('取消保存')
              })
          } else {
            _this.$message.error('数据校验不通过')
          }
        })
      }
    }
  }
</script>

<style>
  .image-uploader .el-upload {
    border: 1px dashed #000000;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .image-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .image-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .image {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
