import { render, staticRenderFns } from "./FixedVehicles.vue?vue&type=template&id=c4d5e124&scoped=true&"
import script from "./FixedVehicles.vue?vue&type=script&lang=js&"
export * from "./FixedVehicles.vue?vue&type=script&lang=js&"
import style0 from "./FixedVehicles.vue?vue&type=style&index=0&id=c4d5e124&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.2_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_prettier@2.8.4_vue-temp_6x2x3rs2sv5gs43jiflgnwbt3i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4d5e124",
  null
  
)

export default component.exports