<!--
#***********************************************
#
#      Filename: src/views/Setting/Monthly.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 物业费设置
#        Create: 2021-10-28 17:01:21
# Last Modified: 2021-11-30 09:41:45
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '修改物业费设置')">
              编辑
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->
    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="120px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="物业费类型:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请选择类型" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收费标准:" prop="price">
              <el-input v-model="actionDataItem.price" placeholder="请输入费用" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import axios from 'axios'
  import { info, update } from '@/api/propertyFee' // eslint-disable-line

  export default {
    name: 'PropertySetUp',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          name: '',
          price: '',
          mode: '',
          delayDays: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          name: [{ required: true, message: '物业费类型不能为空', trigger: 'blur' }],
          price: [{ required: true, message: '物业费用不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: false,
            searchShow: false,
            filterToggleShow: false,
            tableHeadToggleShow: false,
            searchPlaceholder: '物业费类型'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            importDataShow: false,
            exportDataShow: false,
            downloadTemplateShow: false,
            more: false
            // [
            //   {
            //     label: '批量删除',
            //     emitEvent: 'batch-delete'
            //   }
            // ]
          },
          dropDownFilterArray: {}
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          { label: '物业费类型', isShow: true, align: 'center', prop: 'name' },
          {
            label: '收费标准（m²/元）',
            isShow: true,
            align: 'center',
            prop: 'price'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          // create: 'parking:vip:type:create',
          // info: 'parking:vip:type:info',
          // delete: 'parking:vip:type:delete',
          // update: 'parking:vip:type:update'
        }
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      async loadData() {
        const _this = this
        await axios
          .get('../data/propertyFee.json')
          .then((res) => {
            const feeData = new Array(2)
            feeData[0] = { name: '住房', price: res.data.data.houseUnitPrice }
            feeData[1] = { name: '商铺', price: res.data.data.shopUnitPrice }
            _this.total = 2
            _this.dataList = feeData
          })
          .catch((err) => {
            console.error('物业费设置查询出错', err)
            _this.noticeError(err)
          })
      },
      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // this.loadData()
        // _this.loading = false
        info(this.communityId)
          .then((res) => {
            if (res.result) {
              const feeData = new Array(2)
              feeData[0] = { name: '住房', price: res.data.houseUnitPrice }
              feeData[1] = { name: '商铺', price: res.data.shopUnitPrice }
              _this.total = 2
              _this.dataList = feeData
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            _this.noticeError(err)
          })
          .finally(() => {
            this.loading = false
          })
      },
      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.modalObject.currentAction === 'edit') {
              const postData = {}
              postData.houseUnitPrice = _this.dataList[0].price
              postData.shopUnitPrice = _this.dataList[1].price
              if (_this.actionDataItem.name === '住房') {
                postData.houseUnitPrice = _this.actionDataItem.price
              } else {
                postData.shopUnitPrice = _this.actionDataItem.price
              }
              postData.houseFeeCalcType = 1
              postData.opAid = 0
              postData.shopFeeCalcType = 1

              update(_this.communityId, postData)
                .then((response) => {
                  if (response.result) {
                    _this.noticeSuccess(response.message)
                    _this.modalObject.status = false
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(response.message)
                    _this.modalObject.status = false
                  }
                })
                .catch((err) => {
                  _this.noticeError(err)
                })
            }
          }
        })
      }
    }
  }
</script>
