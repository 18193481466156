<!--
#***********************************************
#
#      Filename: src/views/List/Room.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 房间管理页面
#        Create: 2021-11-10 15:05:18
# Last Modified: 2021-11-28 15:44:41
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :import-actions="importActions"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增房间')"
      @refresh="handleRefresh"
      @export="handleExport"
      @download="handleDownload"
      @import-success="handleImportSuccess"
      @batch-delete="handleBatchDelete('批量删除白名单', handleBatchDel)">
      <div slot="custom-left">
        <el-button type="primary" size="mini" plain @click="handleImportRecord">导入记录</el-button>
      </div>
    </ContentHeader>
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" size="mini" stripe border @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-for="(col, index) in tableHeadFields"
        :key="index"
        :label="col.label"
        :width="col.width"
        :align="col.align"
        :prop="col.prop">
        <template slot-scope="scope">
          <template v-if="index == 9">
            <div v-if="scope.row.status == 0" class="text-red">空置</div>
            <div v-else class="text-green">已使用</div>
          </template>
          <template v-else>
            {{ scope.row[col.prop] ? scope.row[col.prop] : '--' }}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-yellow" @click="edit(scope.row, '修改房间', beforeEdit)">
              <i class="el-icon-edit"></i>
              编辑
            </div>
            <div class="gz-text-btn gz-text-btn-red" @click="handleDelete(scope.row.id, '删除房间', handleDel)">
              <i class="el-icon-delete"></i>
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog"
      @close="handleDialogClose">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="小区名称:" prop="communityName">
              <el-input :value="currentCommunityName" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="区号:" prop="areaNumber">
              <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="楼号:" prop="buildingNumber">
              <el-input v-model="actionDataItem.buildingNumber" placeholder="请输入楼号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单元号:" prop="unit">
              <el-input v-model="actionDataItem.unit" placeholder="请输入单元号" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="楼层号:" prop="floor">
              <el-input v-model="actionDataItem.floor" placeholder="请输入楼层号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="房间号:" prop="number">
              <el-input v-model="actionDataItem.number" placeholder="请输入房间号" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="建筑面积:" prop="areaTotal">
              <el-input-number v-model="actionDataItem.areaTotal" :precision="2" placeholder="请输入建筑面积" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="套内面积:" prop="areaInside">
              <el-input-number v-model="actionDataItem.areaInside" :precision="2" placeholder="请输入套内面积" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注:" prop="remark">
              <el-input v-model="actionDataItem.remark" type="textarea" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 导入记录dialog -->
    <el-dialog
      title="导入记录"
      :visible.sync="importInfoDialog.show"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-table :data="importInfoDialog.data" size="mini" stripe border>
        <el-table-column label="批次号" :width="200" align="center" prop="id" />
        <el-table-column label="开始时间" :width="200" align="center" prop="createTime" />
        <el-table-column label="状态" :width="200" align="center" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">
              <i class="el-icon-time" />
            </span>
            <span v-if="scope.row.status === 1">
              <i class="el-icon-loading" />
            </span>
            <span v-if="scope.row.status === 2" style="color: green">
              <i class="el-icon-circle-check" />
            </span>
          </template>
        </el-table-column>
        >
        <el-table-column label="完成时间" :width="200" align="center" prop="finishTime" />
        <el-table-column label="导入总数(条)" :width="200" align="center" prop="total" />
        <el-table-column label="成功数(条)" :width="200" align="center" prop="success" />
        <el-table-column label="失败数(条)" :width="200" align="center" prop="success">
          // eslint-disable-next-line vue/no-unused-vars
          <template slot-scope="scope">
            <span>
              {{ scope.row.total - scope.row.success > 0 ? scope.row.total - scope.row.success : '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="200" align="center" prop="success">
          <template slot-scope="scope">
            <span v-if="scope.row.total - scope.row.success <= 0 || scope.row.status !== 2">--</span>
            <span v-else class="gz-fail-export" @click="handleFailExport(scope.row.id)">导出失败数据</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页模块 -->
      <pagination
        v-show="importInfoDialog.total > 0"
        :total="importInfoDialog.total"
        :page.sync="importInfoDialog.current"
        :limit.sync="importInfoDialog.size" />
      <!-- end 分页模块 -->
    </el-dialog>

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { add, deleteItem, list, update, importBatchList, exportErrorRecords } from '@/api/room'
  import { comInfo } from '@/api/community'
  import { RX_ALPHA_AND_DIGITS, RX_DIGITS } from '@/utils/constants/regex'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'Room',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        currentCommunityName: '',
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          buildingNumber: '',
          communityName: '',
          areaNumber: '',
          floor: '',
          number: '',
          unit: '',
          areaTotal: '',
          areaInside: '',
          remark: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          areaNumber: [
            { required: true, message: '区域号不能为空', trigger: 'blur' },
            {
              type: 'string',
              min: 1,
              max: 5,
              message: '区域号限1-5个字符',
              trigger: 'blur'
            },
            {
              pattern: RX_ALPHA_AND_DIGITS,
              message: '区域号只能是字母和数字',
              trigger: 'blur'
            }
          ],
          buildingNumber: [
            { required: true, message: '楼号不能为空', trigger: 'blur' },
            {
              type: 'string',
              min: 1,
              max: 5,
              message: '楼号限1-5个字符',
              trigger: 'blur'
            },
            {
              pattern: RX_ALPHA_AND_DIGITS,
              message: '楼号只能是字母和数字',
              trigger: 'blur'
            }
          ],
          unit: [
            { required: true, message: '单元号不能为空', trigger: 'blur' },
            {
              type: 'string',
              min: 1,
              max: 5,
              message: '单元号限1-5个字符',
              trigger: 'blur'
            },
            {
              pattern: RX_ALPHA_AND_DIGITS,
              message: '单元号只能是字母和数字',
              trigger: 'blur'
            }
          ],
          floor: [
            { required: true, message: '楼层号不能为空', trigger: 'blur' },
            {
              type: 'string',
              min: 1,
              max: 5,
              message: '楼层号限1-5个字符',
              trigger: 'blur'
            },
            { pattern: RX_DIGITS, message: '楼层号只能是数字', trigger: 'blur' }
          ],
          number: [
            { required: true, message: '房间号不能为空', trigger: 'blur' },
            {
              type: 'string',
              min: 1,
              max: 5,
              message: '房间号限1-5个字符',
              trigger: 'blur'
            },
            {
              pattern: RX_ALPHA_AND_DIGITS,
              message: '房间号只能是字母和数字',
              trigger: 'blur'
            }
          ],
          areaTotal: [{ required: true, message: '建筑面积不能为空', trigger: 'blur' }],
          areaInside: [{ required: true, message: '套内面积不能为空', trigger: 'blur' }],
          remark: [{ max: 200, message: '备注信息限定200个字符', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '房间号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            downloadTemplateShow: true,
            importDataShow: true,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'input',
                name: 'areaNumber',
                placeholder: '请填写区域号'
              },
              {
                type: 'input',
                name: 'buildingNumber',
                placeholder: '请填写楼号'
              },
              {
                type: 'input',
                name: 'unit',
                placeholder: '请填写单元号'
              },
              {
                type: 'input',
                name: 'floor',
                placeholder: '请填写楼层号'
              },
              {
                type: 'select',
                name: 'status',
                placeholder: '房屋状态',
                options: [
                  {
                    value: 0,
                    label: '空置'
                  },
                  {
                    value: 1,
                    label: '已使用'
                  }
                ]
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '小区名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'communityName'
          },
          {
            label: '区号',
            isShow: true,
            width: '60',
            align: 'center',
            prop: 'areaNumber'
          },
          {
            label: '楼号',
            isShow: true,
            width: '60',
            align: 'center',
            prop: 'buildingNumber'
          },
          {
            label: '单元号',
            isShow: true,
            width: '60',
            align: 'center',
            prop: 'unit'
          },
          {
            label: '楼层号',
            isShow: true,
            width: '60',
            align: 'center',
            prop: 'floor'
          },
          {
            label: '房间号',
            isShow: true,
            width: '60',
            align: 'center',
            prop: 'number'
          },
          {
            label: '房间全名',
            isShow: true,
            width: '120',
            align: 'center',
            prop: 'fullNumber'
          },
          {
            label: '建筑面积',
            isShow: true,
            width: '120',
            align: 'center',
            prop: 'areaTotal'
          },
          {
            label: '套内面积',
            isShow: true,
            width: '120',
            align: 'center',
            prop: 'areaInside'
          },
          {
            label: '房间状态',
            isShow: true,
            width: '100',
            align: 'center',
            prop: 'status'
          },
          {
            label: '备注',
            isShow: true,
            align: 'left',
            prop: 'remark'
          }
        ],

        /**
         * 查看导入对话框控制对象
         */
        importInfoDialog: {
          show: false,
          current: 1,
          size: 10,
          total: 0,
          data: []
        },
        permissions: {
          create: 'house:create',
          info: 'house:info',
          delete: 'house:delete',
          update: 'house:update',
          export: 'house:export',
          import: 'house:import'
        }
      }
    },

    computed: {
      // 导入地址
      importActions() {
        return process.env.VUE_APP_BASE_API + '/housing/house/batchImport/' + this.communityId
      }
    },

    created() {
      this.getList()
      this.getComInfo()
    },

    methods: {
      /**
       * 获取当前停车场小区信息
       */
      getComInfo() {
        const _this = this
        const comId = this.currentParking.communityId
        comInfo(comId).then((res) => {
          console.log('xxx', res)
          _this.currentCommunityName = res.data.name
        })
      },
      /**
       * dialog关闭触发事件
       */
      handleDialogClose() {
        this.$refs.form.resetFields()
      },

      /**
       * 编辑前数据预处理
       */
      beforeEdit(data) {
        this.actionDataItem.floor = String(data.floor)
      },

      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // 组装查询条件
        if (this.communityId !== undefined && this.communityId !== null) {
          const queryData = {}
          queryData.current = this.currentPage
          queryData.size = this.pageSize
          queryData.communityId = this.communityId
          this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
          this.filterData.areaNumber && (queryData.areaNumber = this.filterData.areaNumber)
          this.filterData.buildingNumber && (queryData.buildingNumber = this.filterData.buildingNumber)
          this.filterData.floor && (queryData.floor = this.filterData.floor)
          this.filterData.unit && (queryData.unit = this.filterData.unit)
          ;(this.filterData.status || this.filterData.status === 0) && (queryData.status = this.filterData.status)

          list(queryData)
            .then((res) => {
              if (res.result) {
                console.log('房间', res.data)
                _this.dataList = res.data.records
                _this.total = res.data.total
              } else {
                _this.noticeError(res.message)
              }
            })
            .catch((err) => {
              console.error('房间列表查询出错', err)
            })
            .finally(() => {
              _this.loading = false
            })
        }
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            _this.actionDataItem.communityId = _this.communityId
            if (_this.modalObject.currentAction === 'edit') {
              const id = _this.actionDataItem.id
              _this.actionDataItem.houseId = _this.actionDataItem.id
              delete _this.actionDataItem.id
              delete _this.actionDataItem.communityId
              delete _this.actionDataItem.communityName
              // _this.actionDataItem.floor = _this.actionDataItem.floor
              update(id, _this.actionDataItem)
                .then((res) => {
                  if (res.result) {
                    _this.noticeSuccess('更新成功')
                    _this.modalObject.status = false
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(res.message)
                  }
                })
                .catch((e) => {
                  console.error('房屋修改失败', e)
                })
            } else {
              add(_this.actionDataItem)
                .then((res) => {
                  if (res.result) {
                    _this.noticeSuccess('添加成功')
                    _this.modalObject.status = false
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(res.message)
                  }
                })
                .catch((e) => {
                  console.error('房屋添加失败', e)
                })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            if (res.result) {
              _this.noticeSuccess('删除成功')
              _this.handleRefresh()
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.error('房屋删除失败', err)
          })
      },

      /**
       * 批量删除记录
       */
      handleBatchDel(ids) {
        this.noticeError('暂未实现批量删除接口')
        // const _this = this
        // const idArr = []
        // ids.forEach(item => {
        //   idArr.push(item.id)
        // })
        // const postData = {}
        // postData.ids = idArr.join(',')
        // batchDelete(postData).then(res => {
        //   if (res.result) {
        //     _this.msgSuccess('批量删除成功')
        //     _this.handleRefresh()
        //   } else {
        //     _this.msgError(res.message)
        //   }
        // }).catch(err => {
        //   _this.msgError('请求失败')
        //   console.error(err)
        // })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('房间数据导出最多支持1000条数据。如需全量导出，请联系客服人员', '警告', {
          confirmButtonText: '确定导出',
          cancelButtonText: '取消导出',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.current = 1
            queryData.size = 1000
            queryData.communityId = this.communityId
            this.filterData.searchStr && (queryData.number = this.filterData.searchStr)
            this.filterData.areaNumber && (queryData.areaNumber = this.filterData.areaNumber)
            this.filterData.buildingNumber && (queryData.buildingNumber = this.filterData.buildingNumber)
            this.filterData.floor && (queryData.floor = this.filterData.floor)
            this.filterData.unit && (queryData.unit = this.filterData.unit)
            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = _this.formatJson(filterVal, res.data.records)
                  export_json_to_excel(tHeader, data, '房间信息')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
                console.log('导出房间信息失败:', err)
              })
          })
          .catch((err) => {
            console.log('取消导出', err)
          })
      },

      /**
       * 下载模板
       */
      handleDownload() {
        window.open(
          ' http://matrixoss.oss-cn-zhangjiakou.aliyuncs.com/upload/files/20220518/%E6%88%BF%E5%B1%8B%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF-v1.0.xlsx'
        )
      },

      /**
       * 上传成功
       */
      handleImportSuccess() {
        this.noticeSuccess('上传成功')
        this.handleRefresh()
      },

      /**
       * 查看导入记录按键点击
       */
      handleImportRecord() {
        const queryData = {}
        queryData.communityId = this.communityId
        queryData.current = this.importInfoDialog.current
        queryData.size = this.importInfoDialog.size
        importBatchList(queryData)
          .then((res) => {
            if (res.result) {
              this.importInfoDialog.show = true
              this.importInfoDialog.data = res.data.records
              this.importInfoDialog.total = res.data.total
            } else {
              this.noticeError('获取失败', res.message)
            }
          })
          .catch((e) => {
            console.log(e)
            this.noticeError('获取失败', e.message)
          })
      },

      /**
       * 导出失败数据按键点击
       */
      handleFailExport(id) {
        exportErrorRecords(id)
          .then((res) => {
            const blob = new Blob([res.data])
            // 获取heads中的filename文件名
            const downloadElement = document.createElement('a')
            // 创建下载的链接
            const href = window.URL.createObjectURL(blob)
            downloadElement.href = href
            // 下载后文件名
            downloadElement.download = '批次失败数据.xlsx'
            document.body.appendChild(downloadElement)
            // 点击下载
            downloadElement.click()
            // 下载完成移除元素
            document.body.removeChild(downloadElement)
            // 释放掉blob对象
            window.URL.revokeObjectURL(href)
            this.noticeSuccess('导出成功')
          })
          .catch((e) => {
            this.noticeError('导出失败' + e.message)
          })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .gz-fail-export {
    color: #faa13c;
    cursor: pointer;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
</style>
