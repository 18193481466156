<!--
#***********************************************
#
#      Filename: src/views/Detail/FixedVehicles.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 固定车位明细
#        Create: 2021-11-11 14:47:29
# Last Modified: 2021-11-26 16:30:30
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @add="add('新增固定车位缴费', beforeAdd)"
      @filter-change="filterChange"
      @check="handleCheckDetails"
      @refresh="handleRefresh"
      @export="handleExport" />
    <!-- end 数据筛选 -->
    <el-table border :data="data" class="tableBox">
      <el-table-column
        v-for="(item, index) in tableDetailData"
        v-show="item.isShow"
        :key="index"
        :label="item.label"
        :width="item.width"
        :align="item.align"
        :prop="item.prop"
        :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <template v-if="index == 4">
            <span style="color: rgb(218, 146, 3); margin-right: 4px; font-size: 16px">&yen;</span>
            <span>{{ scope.row.statAmount }}</span>
          </template>
          <template v-else>
            {{ scope.row[item.prop] }}
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div class="content-header">缴费总额:￥{{ amountData.statAmount || 0 }}</div> -->
    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop">
        <template slot-scope="scope">
          {{ getPlaceListStr(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop">
        <template slot-scope="scope">
          {{ getCqsListStr(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <!-- <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop" /> -->
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.offline == 1" class="text-navy">&#8226;&nbsp;线下缴费</span>
          <span v-else class="text-teal">&#8226;&nbsp;线上缴费</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[9].isShow"
        :label="tableHeadFields[9].label"
        :width="tableHeadFields[9].width"
        :align="tableHeadFields[9].align"
        :prop="tableHeadFields[9].prop">
        <template slot-scope="scope">
          {{ unixToDateStr(scope.row.payTimeUnix) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[10].isShow"
        :label="tableHeadFields[10].label"
        :width="tableHeadFields[10].width"
        :align="tableHeadFields[10].align"
        :prop="tableHeadFields[10].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 1" class="text-navy">已支付</span>
          <span v-if="scope.row.status === 2" class="text-teal">待支付</span>
          <span v-if="scope.row.status === -1" class="text-teal">申请退款中</span>
          <span v-if="scope.row.status === -2" class="text-teal">已退款</span>
          <!-- <span v-if="scope.row.status === -3" class="text-teal">作废/取消</span> -->
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[11].isShow"
        :label="tableHeadFields[11].label"
        :width="tableHeadFields[11].width"
        :align="tableHeadFields[11].align"
        :prop="tableHeadFields[11].prop" />
      <el-table-column
        v-if="tableHeadFields[12].isShow"
        :label="tableHeadFields[12].label"
        :width="tableHeadFields[12].width"
        :align="tableHeadFields[12].align"
        :prop="tableHeadFields[12].prop">
        <template slot-scope="scope">
          {{ formOperName(scope.row.creatorId) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[13].isShow"
        :label="tableHeadFields[13].label"
        :width="tableHeadFields[13].width"
        :align="tableHeadFields[13].align"
        :prop="tableHeadFields[13].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.invoiceStatus === 1" class="text-navy">已开票</span>
          <span v-if="scope.row.invoiceStatus === 2" class="text-teal">开票中</span>
          <span v-if="scope.row.invoiceStatus === 3" class="text-teal">开票失败</span>
          <span v-if="scope.row.invoiceStatus === 4" class="text-teal">未开票</span>
          <span v-if="scope.row.invoiceStatus === 5" class="text-teal">线下开票</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[14].isShow"
        :label="tableHeadFields[14].label"
        :width="tableHeadFields[14].width"
        :align="tableHeadFields[14].align"
        :prop="tableHeadFields[14].prop" />
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button
            :type="
              scope.row.invoiceStatus == 1 || scope.row.invoiceStatus == 2 || scope.row.invoiceStatus == 5
                ? 'default'
                : 'success'
            "
            size="mini"
            :disabled="scope.row.invoiceStatus == 1 || scope.row.invoiceStatus == 2 || scope.row.invoiceStatus == 5"
            @click="handleCheckInvoicing(scope.row)">
            开票状态变更
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="车位号:" prop="placeId">
              <el-select v-model="placeId" filterable placeholder="请选择车位" @change="handlePlaceChange">
                <el-option v-for="(item, index) in placeList" :key="index" :label="item.placeNo" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务编号:" prop="id">
              <el-input v-model="actionDataItem.id" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider>车位信息</el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item label="车位总数:" prop="placeTxt">
              <el-input :value="actionDataItem.placeCount" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="关联车位:" prop="placeTxt">
              <el-table :data="actionDataItem.placeList" stripe border size="mini">
                <el-table-column label="车位编号" prop="placeNo" width="200"></el-table-column>
                <el-table-column label="产权室号" prop="cqsNo" width="140"></el-table-column>
                <el-table-column label="备注" prop="remark"></el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider>用户信息</el-divider>
        <template v-if="actionDataItem && actionDataItem.vipTypeInfo">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="相关用户:" prop="placeTxt">
                <el-table :data="actionDataItem.itemList" stripe border size="mini">
                  <el-table-column label="相关用户" prop="name" width="200"></el-table-column>
                  <el-table-column label="用户类型" prop="userType" width="140">
                    <template slot-scope="scope">
                      <span v-if="scope.row.userType === 1" class="text-navy">业主</span>
                      <span v-if="scope.row.userType === 2" class="text-teal">商户</span>
                      <span v-if="scope.row.userType === 3" class="text-teal">其他</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="关联车牌号" prop="carNumbers">
                    <template slot-scope="scope">
                      <span v-for="(carNumber, index) in scope.row.carNumbers" :key="index">
                        {{ carNumber.carNumber }}
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>

          <el-divider>计费信息</el-divider>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="计费类型:" prop="vipTypeInfo.name">
                <el-input :value="actionDataItem.vipTypeInfo.name" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="单位费用:" prop="vipTypeInfo.price">
                <el-input :value="actionDataItem.vipTypeInfo.price" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <el-divider />
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="缴费时长:" prop="period">
              <el-select v-model="actionDataItem.period" @change="handlePeriodChange">
                <el-option label="当年" value="1" />
                <el-option label="明年" value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应缴金额:">
              <el-input v-model="actionDataItem.amount" disabled />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="开始日期:">
              <el-input v-model="actionDataItem.periodStart" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束日期:">
              <el-input v-model="actionDataItem.periodEnd" disabled />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <el-drawer
      title="计费汇总"
      size="35%"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      class="drawerBox">
      <div class="timeBox">
        <el-date-picker
          v-model="value"
          value-format="timestamp"
          size="mini"
          start-placeholder="开始时间"
          type="datetimerange"
          end-placeholder="结束时间"
          @change="timeChange" />
        <button @click="handleCheck">查询</button>
        <button @click="handleDetailRefresh">重置</button>
        <button @click="handleDetailExport">导出</button>
      </div>
      <div class="detailHeader">
        <el-table :data="detailData" border>
          <el-table-column
            v-for="(item, index) in tableDetail"
            v-show="item.isShow"
            :key="index"
            :label="item.label"
            :width="item.width"
            :align="item.align"
            :prop="item.prop"
            :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <template v-if="index == 2">
                <span style="color: rgb(218, 146, 3); margin-right: 4px; font-size: 16px">&yen;</span>
                <span>{{ scope.row.detailAmount }}</span>
              </template>
              <template v-else>
                {{ scope.row[item.prop] }}
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="spaceSalesContent">
        <el-table class="tableBox" ref="elTable" :data="tableData" border>
          <el-table-column
            v-for="(item, index) in tableDetailOptions"
            v-show="item.isShow"
            :key="index"
            :label="item.label"
            :width="item.width"
            :align="item.align"
            :prop="item.prop"
            :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <template v-if="index == 2">
                <span style="color: rgb(218, 146, 3); margin-right: 4px; font-size: 16px">&yen;</span>
                <span>{{ scope.row.amount }}</span>
              </template>
              <template v-else>
                {{ scope.row[item.prop] }}
              </template>
            </template>
          </el-table-column>
        </el-table>
        <DetailPagination
          :total="detailTotal"
          :page.sync="pageIndex"
          :limit.sync="pageDetailSize"
          @pagination="pagination"></DetailPagination>
      </div>
    </el-drawer>
    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import DetailPagination from '@/components/detailPagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { list, create, preCalcAmountWithFixed } from '@/api/fixedParkingOrder'
  import { list as pList } from '@/api/parking'
  import {
    getInfoByPlaceId,
    getRentList,
    getStatistic,
    getPayStatic,
    getInvoiceStatus,
    getParkList
  } from '@/api/fixedParkingSpace'
  import moment from 'moment'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'FixedVehicles',

    components: { ContentHeader, DetailPagination },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        checkStart: '',
        checkEnd: '',
        payStatus: '',
        payWay: '',
        amountDetailData: {},
        amountData: {},
        tableData: [],
        dialog_loading: false,
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          communityId: '',
          buildingNumber: '',
          areaNumber: '',
          floor: '',
          number: '',
          remark: '',
          unit: '',
          amount: '',
          periodStart: '',
          periodEnd: ''
        },
        startTime: '',
        endTime: '',
        value: '',
        placeId: '',

        drawer: false,
        direction: 'rtl',
        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          // placeId: [{ required: true, message: '车位号不能为空', trigger: 'blur' }],
          carNumber: [{ required: true, message: '车牌号不能为空', trigger: 'blur' }],
          period: [{ required: true, message: '缴费时长不能为空', trigger: 'blur' }]
          // unit: [{ required: true, message: '单元号不能为空', trigger: 'blur' }],
          // floor: [{ required: true, message: '楼层号不能为空', trigger: 'blur' }],
          // number: [{ required: true, message: '房间号不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '订单编号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            importDataShow: false,
            paymentDetailsShow: true,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateRange',
                name: 'dateRange'
              },
              {
                type: 'select',
                name: 'offline',
                placeholder: '缴费方式',
                options: [
                  { label: '线上缴费', value: 2 },
                  { label: '线下缴费', value: 1 }
                ]
              },
              {
                type: 'select',
                name: 'status',
                placeholder: '缴费状态',
                options: [
                  { label: '已支付', value: 1 },
                  { label: '待支付', value: 2 },
                  { label: '退款中', value: -1 },
                  { label: '已退款', value: -2 }
                  // { label: '作废/取消', value: -3 }
                ]
              }
            ]
          }
        },
        detailTotal: 0, // 总数
        pageIndex: 1, // 当前第几页
        pageDetailSize: 10, // 每页条数
        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          { label: '订单编号', isShow: true, width: '200', align: 'center', prop: 'orderNo' },
          { label: '车位号', isShow: true, width: '200', align: 'center', prop: 'placeList.placeNos' },
          { label: '产权编号', isShow: true, width: '200', align: 'center', prop: 'placeList.cqsNos' },
          { label: '业主姓名', isShow: true, width: '200', align: 'center', prop: 'masterInfo.name' },
          { label: '电话号码', isShow: true, width: '200', align: 'center', prop: 'masterInfo.mobile' },
          // { label: '区号', isShow: true, width: '200', align: 'center', prop: 'houseInfo.areaNumber' },
          // { label: '楼号', isShow: true, width: '200', align: 'center', prop: 'houseInfo.buildingId' },
          // { label: '单元号', isShow: true, width: '200', align: 'center', prop: 'houseInfo.unit' },
          // { label: '房间号', isShow: true, width: '200', align: 'center', prop: 'houseInfo.fullNumber' },
          { label: '开始日期', isShow: true, width: '200', align: 'center', prop: 'periodStart' },
          { label: '到期日期', isShow: true, width: '200', align: 'center', prop: 'periodEnd' },
          { label: '缴费金额（元）', isShow: true, width: '200', align: 'center', prop: 'amount' },
          { label: '缴费方式', isShow: true, width: '200', align: 'center', prop: 'xxx' },
          { label: '缴费时间', isShow: true, width: '200', align: 'center', prop: 'payTimeUnix' },
          { label: '缴费状态', isShow: true, width: '200', align: 'center', prop: 'status' },
          { label: '合同编号', isShow: true, width: '200', align: 'center', prop: 'agreementId' },
          { label: '操作人员', isShow: true, width: '200', align: 'center', prop: 'creatorId' },
          { label: '开票状态', isShow: true, width: '200', align: 'center', prop: 'invoiceStatus' },
          { label: '备注', isShow: true, width: '200', align: 'center', prop: 'remark' }
        ],
        tableDetailOptions: [
          { label: '车位编号', isShow: true, align: 'left', prop: 'placeNo' },
          { label: '客户姓名', isShow: true, width: '178', align: 'center', prop: 'name' },
          { label: '已收停车费', isShow: true, width: '178', align: 'right', prop: 'amount' }
        ],

        tableDetailData: [
          { label: '缴费开始时间', isShow: true, align: 'left', prop: 'checkStart' },
          { label: '缴费结束时间', isShow: true, align: 'left', prop: 'checkEnd' },
          { label: '缴费方式', isShow: true, width: '178', align: 'center', prop: 'payWay' },
          { label: '缴费状态', isShow: true, width: '178', align: 'right', prop: 'payStatus' },
          { label: '缴费总额', isShow: true, width: '178', align: 'right', prop: 'statAmount' }
        ],

        data: [
          {
            checkStart: '',
            checkEnd: '',
            payWay: '',
            payStatus: '',
            statAmount: ''
          }
        ],

        tableDetail: [
          { label: '计费开始时间', isShow: true, align: 'left', prop: 'detailCheckStart' },
          { label: '计费结束时间', isShow: true, align: 'left', prop: 'detailCheckEnd' },
          { label: '计费总额', isShow: true, width: '178', align: 'right', prop: 'detailAmount' }
        ],

        detailData: [
          {
            detailCheckStart: '',
            detailCheckEnd: '',
            detailAmount: ''
          }
        ],
        /**
         * 车位列表
         */
        placeList: [],

        /**
         * 支付记录
         */
        payRecord: [],

        /**
         * 支付记录Modal
         */
        showPayRecordModal: false,

        /**
         * 当前车牌号列表
         */
        currentCarnumberList: [],
        permissions: {
          create: 'fee-record:parking-fixed:info:create',
          info: 'fee-record:parking-fixed:info:info',
          delete: 'fee-record:parking-fixed:info:delete',
          update: 'fee-record:parking-fixed:info:update',
          export: 'fee-record:parking-fixed:info:export',
          import: 'fee-record:parking-fixed:info:import'
        },
        detailCurrent: 1,
        detailSize: 10
      }
    },

    mounted() {
      this.getList()
      this.getPlateNumberList()
      // this.getPlaceList()
    },

    methods: {
      beforeAdd() {
        this.placeId = ''
      },

      handleClose(done) {
        done()
      },
      /**
       * 获取车位号字符串
       */
      getPlaceListStr(data) {
        const placeList = []
        if (data && data.placeList) {
          data.placeList.forEach((item) => {
            console.log('item', item, item.placeNo)
            placeList.push(item.placeNo)
          })
          return placeList.join(',')
        }
        return ''
      },

      handleCheckInvoicing(item) {
        console.log(item)
        this.$confirm('确认更改本条订单开票状态?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const queryData = {}
          queryData.id = item.id
          getInvoiceStatus(queryData).then((res) => {
            if (res.result) {
              this.handleRefresh()
              this.noticeSuccess('操作成功')
            }
          })
        })
      },

      pagination(val) {
        console.log(val)
        this.pageIndex = val.page
        this.pageDetailSize = val.limit
        this.getPayDetail()
      },
      /**
       * 获取产权编号字符串
       */
      getCqsListStr(data) {
        const cqsList = []
        if (data && data.placeList) {
          data.placeList.forEach((item) => {
            console.log('item', item, item.cqsNo)
            cqsList.push(item.cqsNo)
          })
          return cqsList.join(',')
        }
        return ''
      },

      timeChange(e) {
        console.log(e)
        this.startTime = moment(e[0]).format('YYYY-MM-DD')
        this.endTime = moment(e[1]).format('YYYY-MM-DD')
      },

      handleCheck() {
        this.getPayDetail()
        this.getStatisticList()
      },

      handleDetailRefresh() {
        this.value = []
        this.startTime = ''
        this.endTime = ''
        this.getPayDetail()
        this.getStatisticList()
      },

      getStatisticList() {
        const queryData = {}
        queryData.placeType = 1
        queryData.periodStartTime = this.startTime
        queryData.periodEndTime = this.endTime
        queryData.parkingId = this.pid
        getStatistic(queryData).then((res) => {
          if (res?.data) {
            this.amountDetailData = res.data
            this.detailData[0].detailAmount = this.amountDetailData.statAmount
          } else {
            this.amountDetailData = {}
          }
          console.log(res)
        })
      },

      getPayStatic() {
        const queryData = {}
        queryData.parkingId = this.pid
        queryData.placeType = 1
        if (this.filterData.dateRange) {
          queryData.payStartTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD')
          queryData.payEndTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD')
        } else {
          queryData.payStartTime = ''
          queryData.payEndTime = ''
        }
        getPayStatic(queryData).then((res) => {
          if (res?.data) {
            this.amountData = res.data
            this.data[0].statAmount = this.amountData.statAmount
          } else {
            this.amountData = {}
          }
        })
      },
      /**
       * 获取列表数据
       */
      getList() {
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.parkingId = this.pid
        queryData.placeType = 1
        this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
        this.filterData.status && (queryData.status = this.filterData.status)
        if (this.filterData.status === 0 || this.filterData.status) {
          queryData.status = this.filterData.status
          this.payStatus = this.filterData.status
          if (this.payStatus === 1) {
            this.payStatus = '已支付'
          } else if (this.payStatus === 2) {
            this.payStatus = '待支付'
          } else if (this.payStatus === -1) {
            this.payStatus = '退款中'
          } else {
            this.payStatus = '已退款'
          }
          this.data[0].payStatus = this.payStatus
        }
        if (this.filterData.offline === 1 || this.filterData.offline === 2) {
          queryData.offline = this.filterData.offline
          this.payWay = this.filterData.offline
          if (this.payWay === 2) {
            this.payWay = '线上缴费'
          } else {
            this.payWay = '线下缴费'
          }
          this.data[0].payWay = this.payWay
        }
        if (this.filterData.dateRange) {
          queryData.payStartTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD')
          queryData.payEndTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD')
          this.checkStart = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD')
          this.checkEnd = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD')
          this.data[0].checkStart = this.checkStart
          this.data[0].checkEnd = this.checkEnd
          this.getPayStatic()
        } else {
          queryData.payStartTime = ''
          queryData.payEndTime = ''
          this.getPayStatic()
        }
        console.log(queryData)
        list(queryData)
          .then((res) => {
            this.dataList = res.data.records
            this.total = res.data.total
            this.loading = false
          })
          .catch((err) => {
            console.error('固定车位缴费明细查询出错', err)
            this.loading = false
          })
      },

      getPayDetail() {
        const queryData = {}
        queryData.current = this.pageIndex
        queryData.size = this.pageDetailSize
        queryData.parkingId = this.pid
        queryData.periodStartTime = this.startTime
        queryData.periodEndTime = this.endTime
        this.detailData[0].detailCheckStart = this.startTime
        this.detailData[0].detailCheckEnd = this.endTime
        queryData.placeType = 1
        getRentList(queryData).then((res) => {
          if (res.data?.records) {
            this.tableData = res.data.records
            this.detailTotal = res.data.total
          } else {
            this.tableData = []
          }
        })
      },
      /**
       * 获取车位列表数据
       */
      // getPlaceList() {
      //   const queryData = {}
      //   queryData.current = 1
      //   queryData.size = 999
      //   queryData.parkingId = this.pid
      //   queryData.status = 1
      //   queryData.placeType = 1 // 固定车位
      //   pList(queryData)
      //     .then((res) => {
      //       this.placeList = res.data.records
      //     })
      //     .catch((err) => {
      //       console.error('车位列表查询出错', err)
      //     })
      // },

      getPlateNumberList() {
        if (this.pid) {
          const queryData = {}
          queryData.parkingId = this.pid
          queryData.status = 1
          queryData.placeType = 1
          getParkList(queryData)
            .then((res) => {
              if (res.result) {
                this.placeList = res.data
                // this.unusedParkingSpaceList = this.parkingSpaceList.filter((item) => item.status === 0)
              } else {
                this.noticeError('车位号列表请求失败')
              }
            })
            .catch((res) => {
              console.log('车位号列表查询出错', res)
            })
        }
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
        this.getStatisticList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        this.dialog_loading = true
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            console.log('_this.actionDataItem', _this.actionDataItem)
            const postData = {
              vipId: _this.actionDataItem.id,
              buyMonths: _this.actionDataItem.period
            }
            create(postData)
              .then((response) => {
                if (response.result) {
                  _this.noticeSuccess(response.message)
                  _this.modalObject.status = false
                  _this.handleRefresh()
                } else {
                  _this.noticeError(response.message)
                }
                this.dialog_loading = false
              })
              .catch((e) => {
                console.log('提交信息失败', e.message)
                this.dialog_loading = false
              })
          }
        })
      },

      /**
       * 用户选择车位号后查询对应的业务编号
       */
      handlePlaceChange(id) {
        if (this.actionDataItem.carNumber) {
          this.actionDataItem.carNumber = ''
        }
        this.dialog_loading = true
        if (id) {
          getInfoByPlaceId(id).then((res) => {
            this.actionDataItem = res.data
            if (this.actionDataItem.periodStart) {
              this.actionDataItem.periodStart = moment(this.actionDataItem.periodStart).format('YYYY-MM-DD HH:mm:ss')
            }
            if (this.actionDataItem.periodEnd) {
              this.actionDataItem.periodEnd = moment(this.actionDataItem.periodEnd).format('YYYY-MM-DD HH:mm:ss')
            }
            this.dialog_loading = false
          })
        }
      },

      /**
       * 缴费时长变更
       */
      handlePeriodChange(val) {
        this.dialog_loading = true
        const _this = this
        const postData = {
          buyMonths: val
        }
        preCalcAmountWithFixed(this.actionDataItem.id, postData).then((res) => {
          if (res.result) {
            _this.$set(_this.actionDataItem, 'amount', parseFloat(res.data.amount).toFixed(2))
            _this.$set(_this.actionDataItem, 'periodStart', res.data.periodStart)
            _this.$set(_this.actionDataItem, 'periodEnd', res.data.periodEnd)
            this.dialog_loading = false
          } else {
            _this.noticeError(res.message)
          }
        })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出固定车位缴费明细,最多支持同时200数据?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            queryData.current = 1
            queryData.size = 200
            queryData.parkingId = this.pid
            queryData.placeType = 1
            this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
            if (this.filterData.dateRange) {
              queryData.payStartTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD')
              queryData.payEndTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD')
            }
            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.records.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'masterInfo.name') {
                        if (v.masterInfo && v.masterInfo.name) {
                          return v.masterInfo.name
                        }
                        return ''
                      } else if (j === 'placeList.placeNos') {
                        return this.getPlaceListStr(v)
                      } else if (j === 'placeList.cqsNos') {
                        return this.getCqsListStr(v)
                      } else if (j === 'masterInfo.mobile') {
                        if (v.masterInfo && v.masterInfo.mobile) {
                          return v.masterInfo.mobile
                        }
                        return ''
                      } else if (j === 'houseInfo.areaNumber') {
                        if (v.houseInfo && v.houseInfo.areaNumber) {
                          return v.houseInfo.areaNumber
                        }
                        return ''
                      } else if (j === 'houseInfo.buildingId') {
                        if (v.houseInfo && v.houseInfo.buildingNumber) {
                          return v.houseInfo.buildingNumber
                        }
                        return ''
                      } else if (j === 'houseInfo.unit') {
                        if (v.houseInfo && v.houseInfo.unit) {
                          return v.houseInfo.unit
                        }
                        return ''
                      } else if (j === 'houseInfo.number') {
                        if (v.houseInfo && v.houseInfo.number) {
                          return v.houseInfo.number
                        }
                        return ''
                      } else if (j === 'payTimeUnix') {
                        if (v.payTimeUnix) {
                          return this.unixToDateStr(v.payTimeUnix)
                        }
                        return ''
                      } else if (j === 'creatorId') {
                        if (v.creatorId) {
                          return this.formOperName(v.creatorId)
                        }
                        return ''
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '固定车位缴费明细')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      handleCheckDetails() {
        console.log('触发，？？？？')
        this.drawer = true
        this.getPayDetail()
        this.getStatisticList()
      },
      unixToDateStr(data) {
        if (data) {
          return moment(data * 1000).format('yyyy-MM-DD HH:mm:ss')
        } else {
          return ''
        }
      },
      handleDetailExport() {
        this.dialog_loading = true
        this.$confirm('是否导出非固定车位缴费明细?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            queryData.current = 1
            queryData.size = 999
            queryData.parkingId = this.pid
            queryData.placeType = 2
            queryData.periodStartTime = this.startTime
            queryData.periodEndTime = this.endTime
            getRentList(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  let tableHeader = []
                  const tableHeaders = []
                  tableHeader = this.$refs['elTable'].store.states._columns
                  console.log(tableHeader)
                  if (tableHeader && tableHeader.length > 0) {
                    tableHeader.forEach((column, _index) => {
                      if (column.property) {
                        tableHeaders.push({
                          id: _index,
                          show: true,
                          prop: column.property,
                          label: column.label,
                          sortable: column.sortable,
                          align: column.align,
                          width: column.width,
                          template: true
                        })
                      }
                    })
                  }
                  tableHeaders.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.records.map((v) => {
                    console.log(v)
                    return filterVal.map((j) => {
                      if (j === 'placeNo') {
                        return v.placeNo
                      } else if (j === 'name') {
                        return v.name
                      } else if (j === 'amount') {
                        return v.amount
                      } else {
                        return v[j]
                      }
                    })
                  })
                  console.log(data)
                  export_json_to_excel(tHeader, data, '非固定车位缴费明细')
                })
                this.noticeSuccess('导出成功')
                this.dialog_loading = false
              })
              .catch((err) => {
                this.noticeError(err.message)
                this.dialog_loading = false
              })
          })
          .catch((err) => {
            this.noticeError(err.message)
            this.dialog_loading = false
          })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .content-header {
    width: 100%;
    height: 40px;
    text-align: left;
    line-height: 40px;
    font-weight: bolder;
    font-size: 24px;
    padding-left: 10px;
    box-sizing: border-box;
    > text {
      display: inline-block;
    }
  }

  .detailHeader {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: bolder;
  }
  .spaceSalesContent {
    margin: 0 20px;
  }

  .tableBox {
    margin: 20px 0;
  }

  .timeBox {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 40px;
    box-sizing: border-box;
    > button {
      margin: 0 10px;
      width: 90px;
      height: 35px;
      border-radius: 10px;
      color: white;
      background-color: orange;
      border: none;
    }
  }
</style>
