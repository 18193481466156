<!--
#***********************************************
#
#      Filename: src/views/List/Owner.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 业主列表
#        Create: 2021-11-10 22:10:51
# Last Modified: 2021-11-28 16:17:36
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :import-actions="importActions"
      @filter-change="filterChange"
      @add="add('新增业主', beforeAdd)"
      @refresh="handleRefresh"
      @export="handleExport"
      @download="handleDownload"
      @import-success="handleImportSuccess"
      @batch-delete="handleBatchDelete('批量删除业主', handleBatchDel)">
      <div slot="custom-left">
        <el-button type="primary" size="mini" plain @click="handleImportRecord">导入记录</el-button>
      </div>
    </ContentHeader>
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" size="mini" stripe border @selection-change="changeSelectItems">
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="gz-house-group">
            <div v-for="(item, index) in props.row.refHouses" :key="index" class="gz-house-item">
              <div class="gz-house-item">
                <div class="gz-house-info">
                  {{
                    item.houseInfo.areaNumber +
                    '区 ' +
                    item.houseInfo.buildingNumber +
                    '号楼 ' +
                    item.houseInfo.unit +
                    '单元 ' +
                    item.houseInfo.number
                  }}
                </div>
                <div class="gz-owner-type">
                  <div class="m-t-5">
                    <el-tag v-if="item.type === 1" size="mini" type="success">业主</el-tag>
                    <el-tag v-if="item.type === 2" size="mini" type="warning">随住</el-tag>
                    <el-tag v-if="item.type === 3" size="mini" type="danger">租户</el-tag>
                  </div>
                </div>
                <div class="gz-house-handle">
                  <el-button size="mini" type="info" plain @click="handleMemberInfo(item.houseId)">查看成员</el-button>
                </div>
              </div>
            </div>
          </div>

          <div class="gz-house-group">
            <div v-for="(item, index) in props.row.refShops" :key="index" class="gz-house-item">
              <div class="gz-house-item">
                <div class="gz-house-info">
                  {{
                    item.shopInfo.areaNumber +
                    '区 ' +
                    item.shopInfo.buildingNumber +
                    '号楼 ' +
                    item.shopInfo.unit +
                    '单元 ' +
                    item.shopInfo.number
                  }}
                </div>
                <div class="gz-owner-type">
                  <div class="m-t-5">
                    <el-tag v-if="item.shopInfo.status === 1" size="mini" type="success">商铺主</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop">
        <template slot-scope="scope">
          {{ formatMobile(scope.row.mobile) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column label="操作" :width="300" align="center">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-yellow" @click="edit(scope.row, '修改业主', beforeEdit(scope.row, 1))">
              编辑
            </div>
            <div class="gz-text-btn gz-text-btn-red" @click="handleDelete(scope.row.id, '删除业主', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog"
      @close="handleClose">
      <el-form ref="form" label-width="100px" :model="dialogFormData" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="业主姓名:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请输入业主姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话号码:" prop="mobile">
              <el-input v-model="actionDataItem.mobile" placeholder="请输入电话号码" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="房间类型:" prop="type">
              <el-select v-model="actionDataItem.type" placeholder="房间类型" @change="selectChanged">
                <el-option label="住宅" :value="1" />
                <el-option label="商铺" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="成员类型:">
              <el-select v-model="actionDataItem.ownerType" placeholder="请先选择房间类型再选成员类型">
                <el-option v-for="item in memberTypes" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item>
              <el-button type="success" @click="handleAddRoomItem()">
                {{ showRoomType == true ? '添加房间' : '添加商铺' }}
              </el-button>
            </el-form-item>
          </el-col>
          <el-form-item label="">
            <TableSelect
              v-if="actionDataItem.type == 1"
              ref="tabselect"
              class="m-b-20"
              :memberlist1="currMemberList"
              :tablecolumns="currTableColumns" />
          </el-form-item>

          <el-table
            v-if="actionDataItem.type == 1 && dialogFormData.tableData > 0"
            ref="dynamicTable"
            :data="dialogFormData.tableData"
            border
            :row-key="getRowKeys"
            :cell-style="getCellStyle"
            :header-cell-style="getHeaderCellStyle">
            <el-table-column
              v-for="(item, index) in currColumns"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :show-overflow-tooltip="true" />
            <el-table-column label="操作" :width="200" align="center" fixed="right">
              <template slot-scope="scope">
                <div class="gz-text-btn-group">
                  <div
                    class="gz-text-btn gz-text-btn-red"
                    @click="handleDelete(scope.$index, '删除', handleDelSignedRoom)">
                    删除
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-divider />
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注:" prop="remark">
              <el-input v-model="actionDataItem.remark" type="textarea" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 导入记录dialog -->
    <el-dialog
      title="导入记录"
      :visible.sync="importInfoDialog.show"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-table :data="importInfoDialog.data" size="mini" stripe border>
        <el-table-column label="批次号" :width="200" align="center" prop="id" />
        <el-table-column label="开始时间" :width="200" align="center" prop="createTime" />
        <el-table-column label="状态" :width="200" align="center" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">
              <i class="el-icon-time" />
            </span>
            <span v-if="scope.row.status === 1">
              <i class="el-icon-loading" />
            </span>
            <span v-if="scope.row.status === 2" style="color: green">
              <i class="el-icon-circle-check" />
            </span>
          </template>
        </el-table-column>
        <el-table-column label="完成时间" :width="200" align="center" prop="finishTime" />
        <el-table-column label="导入总数(条)" :width="200" align="center" prop="total" />
        <el-table-column label="成功数(条)" :width="200" align="center" prop="success" />
        <el-table-column label="失败数(条)" :width="200" align="center" prop="success">
          // eslint-disable-next-line vue/no-unused-vars
          <template slot-scope="scope">
            <span>
              {{ scope.row.total - scope.row.success > 0 ? scope.row.total - scope.row.success : '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="200" align="center" prop="success">
          <template slot-scope="scope">
            <span v-if="scope.row.total - scope.row.success <= 0 || scope.row.status !== 2">--</span>
            <span v-else class="gz-fail-export" @click="handleFailExport(scope.row.id)">导出失败数据</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页模块 -->
      <pagination
        v-show="importInfoDialog.total > 0"
        :total="importInfoDialog.total"
        :page.sync="importInfoDialog.current"
        :limit.sync="importInfoDialog.size" />
      <!-- end 分页模块 -->
    </el-dialog>
    <!-- end 导入记录dialog -->

    <!-- 查看成员dialog -->
    <el-dialog
      title="查看成员"
      :visible.sync="reviewMemberDialog.show"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-table :data="reviewDataList" size="mini" stripe border>
        <el-table-column label="成员姓名" :width="200" align="center" prop="ownerInfo.name" />
        <el-table-column label="电话号码" :width="200" align="center" prop="ownerInfo.mobile" />
        <el-table-column label="成员类型" :width="200" align="center" prop="type">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">业主</span>
            <span v-if="scope.row.type == 2">随住</span>
            <span v-if="scope.row.type == 3">租户</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" align="center" prop="ownerInfo.remark" />
      </el-table>
      <!-- 分页模块 -->
      <pagination
        v-show="reviewMemberDialog.total > 0"
        :total="reviewMemberDialog.total"
        :page.sync="reviewMemberDialog.current"
        :limit.sync="reviewMemberDialog.size" />
      <!-- end 分页模块 -->
    </el-dialog>

    <!-- end 查看成员dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import TableSelect from '@/components/TableSelect'
  import {
    list,
    // add,
    // ownerAdd,
    // ownerUpdate,
    deleteItem,
    update,
    create,
    relationList,
    importBatchList,
    exportErrorRecords
  } from '@/api/owner'
  import { tree, list as roomList } from '@/api/room'
  import { shopList } from '@/api/shop'
  import { RX_MOBILE } from '@/utils/constants/regex'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'Owner',

    components: { ContentHeader, TableSelect },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        currMemberList: [],
        currTableColumns: [],
        /**
         * 当前数据表列与数据
         */
        currColumns: [],
        currDataList: [],

        // 筛选房间数据
        memberlist2: [],
        tablecolumns: [
          {
            prop: 'fullNumber',
            label: '房间全号'
          },
          {
            prop: 'number',
            label: '房间号'
          },
          {
            prop: 'buildingNumber',
            label: '楼栋号'
          },
          {
            prop: 'floor',
            label: '楼层'
          },
          {
            prop: 'unit',
            label: '单元号'
          },
          {
            prop: 'areaNumber',
            label: '区域号'
          }
        ],
        dataList: [],

        // 成员类型
        memberTypes: [],

        // 动态房间数据列
        dynamictablecolumns: [
          {
            prop: 'houseInfo.fullNumber',
            label: '房间全号'
          },
          {
            prop: 'houseInfo.number',
            label: '房间号'
          },
          {
            prop: 'houseInfo.buildingNumber',
            label: '楼栋号'
          },
          {
            prop: 'houseInfo.floor',
            label: '楼层'
          },
          {
            prop: 'houseInfo.unit',
            label: '单元号'
          },
          {
            prop: 'houseInfo.areaNumber',
            label: '区域号'
          }
        ],

        /**
         * 商铺列表
         */
        shoplist: [],
        shoptablecolumns: [
          {
            prop: 'fullNumber',
            label: '商铺全号'
          },
          {
            prop: 'buildingId',
            label: '楼栋ID'
          },
          {
            prop: 'areaTotal',
            label: '商铺面积'
          },
          {
            prop: 'floor',
            label: '楼层'
          },
          {
            prop: 'unit',
            label: '单元号'
          }
        ],
        dynamicshopcolumns: [
          {
            prop: 'shopInfo.fullNumber',
            label: '商铺全号'
          },
          {
            prop: 'shopInfo.buildingId',
            label: '楼栋ID'
          },
          {
            prop: 'shopInfo.areaTotal',
            label: '商铺面积'
          },
          {
            prop: 'shopInfo.floor',
            label: '楼层'
          },
          {
            prop: 'shopInfo.unit',
            label: '单元号'
          }
        ],

        // 编辑时传递的数据
        currData: {},

        processIds: [],

        // 显示类型切换信息
        showRoomType: true,

        /**
         * 查看成员dialog显示控制
         */
        reviewMemberDialog: {
          show: false,
          current: 1,
          size: 10,
          total: 0
        },

        /**
         * 成员列表
         */
        reviewDataList: [],

        /**
         * 添加成员dialog显示控制
         */
        addMemberDialogShow: false,

        /**
         * 添加成员dialog表单数据
         */
        addMemberDialogObj: {
          name: '',
          mobile: '',
          type: '',
          remark: '',
          refHouses: [
            {
              houseId: '',
              ownerType: ''
            }
          ]
        },

        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          name: '',
          mobile: '',
          type: '',
          refHouses: [
            {
              houseId: '',
              ownerType: ''
            }
          ],
          remark: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          // name: [{ required: true, message: '业主姓名不能为空', trigger: 'blur' }],
          // mobile: [
          //   { required: true, message: '手机号码不能为空', trigger: 'blur' },
          //   { pattern: RX_MOBILE, message: '手机号码格式不正确', trigger: 'blur' }
          // ],
          // houseInfo: [{ required: true, message: '房间信息不能为空', trigger: 'blur' }],
          // member: [{
          //   name: [{ required: true, message: '成员姓名不能为空', trigger: 'blur' }],
          //   mobile: [
          //     { required: true, message: '成员电话不能为空', trigger: 'blur' },
          //     { pattern: RX_MOBILE, message: '手机号码格式不正确', trigger: 'blur' }
          //   ],
          //   type: [{ required: true, message: '成员类型不能为空', trigger: 'change' }]
          // }],
          // refHouses: [{
          //   houseId: [{ required: true, message: '房间信息不能为空', trigger: 'blur' }],
          //   ownerType: [{ required: true, message: '房间关系不能为空', trigger: 'blur' }]
          // }]
        },

        /**
         * 成员添加表单数据验证规则
         */
        addMemberDialogRules: {
          name: [{ required: true, message: '成员姓名不能为空', trigger: 'blur' }],
          mobile: [
            { required: true, message: '电话号码不能为空', trigger: 'blur' },
            { pattern: RX_MOBILE, message: '手机号码格式不正确', trigger: 'blur' }
          ],
          type: [{ required: true, message: '成员类型不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '业主姓名'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            downloadTemplateShow: true,
            importDataShow: true,
            more: [
              // {
              //   label: '批量删除',
              //   emitEvent: 'batch-delete'
              // }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'input',
                name: 'mobile',
                placeholder: '请填写电话'
              },
              {
                type: 'input',
                name: 'houseNo',
                placeholder: '请填写房间号'
              },
              {
                type: 'select',
                name: 'ownerType',
                placeholder: '请选择业主类型',
                options: [
                  { label: '业主', value: '1' },
                  { label: '随住', value: '2' },
                  { label: '租客', value: '3' }
                ]
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          { label: '业主姓名', isShow: true, width: '200', align: 'center', prop: 'name' },
          { label: '小区名称', isShow: true, width: '200', align: 'center', prop: 'communityName' },
          { label: '手机号码', isShow: true, width: '200', align: 'center', prop: 'mobile' },
          { label: '备注', isShow: true, align: 'center', prop: 'remark' }
        ],

        /**
         * 级联选项
         */
        options: [],

        /**
         * 查看导入对话框控制对象
         */
        importInfoDialog: {
          show: false,
          current: 1,
          size: 10,
          total: 0,
          data: []
        },

        dialogFormData: {
          id: 34,
          name: 'hello',
          tableData: [
            {
              name: 'name1',
              id: 'id1',
              unit: 'unit1',
              content: '',
              createDate: '',
              updateDate: ''
            },
            {
              name: 'name2',
              id: 'id2',
              unit: 'unit1',
              content: '',
              createDate: '',
              updateDate: ''
            }
          ]
        }
      }
    },

    computed: {
      // 导入地址
      importActions() {
        return process.env.VUE_APP_BASE_API + '/housing/owner/batchImport/' + this.currentParking.communityId
      }
    },

    mounted() {
      this.currColumns = this.dynamictablecolumns
      this.getList()
      this.getPropertyTree()
      this.getRoomList()
      this.getShopList()
    },

    methods: {
      /**
       * 查看成员按键点击
       */
      handleMemberInfo(id) {
        const cid = this.communityId
        const queryData = {}
        queryData.current = this.reviewMemberDialog.current
        queryData.size = this.reviewMemberDialog.size
        queryData.houseId = id
        relationList(cid, queryData)
          .then((res) => {
            if (res.result) {
              this.reviewDataList = res.data.records
              this.reviewMemberDialog.show = true
              this.reviewMemberDialog.total = res.data.total
            } else {
              this.noticeError('获取房屋成员失败' + res.message)
            }
          })
          .catch((err) => {
            this.noticeError('获取房屋成员失败' + err.message)
          })
      },

      beforeAdd() {
        this.getPropertyTree

        this.actionDataItem = {}
        this.dialogFormData = {}

        // 筛选列表
        this.currMemberList = []
        this.currTableColumns = this.tablecolumns
        this.showRoomType = true

        // 数据列表
        this.currColumns = this.dynamictablecolumns
        // 签约数据列表
        this.dialogFormData.tableData = []
      },

      handleClose() {
        // this.$refs.form.resetFields()
        if (this.modalObject.currentAction === 'edit') {
          this.getPropertyTree()
        }
      },

      selectChanged(value) {
        // 1 住房 2 商铺
        switch (value) {
          case 1:
            this.currMemberList = this.memberlist2
            this.currTableColumns = this.tablecolumns
            this.showRoomType = true
            this.currColumns = this.dynamictablecolumns
            this.dialogFormData.tableData = this.dialogFormData.refHouses

            this.memberTypes = [
              { id: '1', name: '业主', label: '业主' },
              { id: '2', name: '随住', label: '随住' },
              { id: '3', name: '租户', label: '租户' }
            ]
            break
          case 2:
            this.currMemberList = this.shoplist
            this.currTableColumns = this.shoptablecolumns
            this.showRoomType = false
            this.currColumns = this.dynamicshopcolumns
            this.dialogFormData.tableData = this.dialogFormData.refShops
            this.memberTypes = [{ id: '1', name: '商铺主', label: '商铺主' }]
            break
          default:
        }
      },

      /**
       * 获取房间列表
       */
      getRoomList() {
        const _this = this
        const queryData = {}
        queryData.communityId = this.communityId
        queryData.current = 1 // this.currentPage
        queryData.size = 999 // this.pageSize
        roomList(queryData)
          .then((res) => {
            _this.memberlist2 = res.data.records
          })
          .catch((e) => {
            _this.noticeError(e.message)
          })
      },

      /**
       * 获取商铺列表
       */
      getShopList() {
        const _this = this
        const queryData = {}
        queryData.communityId = this.communityId
        queryData.current = 1 // this.currentPage
        queryData.size = 999 // this.pageSize
        shopList(queryData)
          .then((res) => {
            _this.shoplist = res.data.records
          })
          .catch((e) => {
            _this.noticeError(e.message)
          })
      },

      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true
        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
        this.filterData.mobile && (queryData.mobile = this.filterData.mobile)
        this.filterData.houseNo && (queryData.houseNo = this.filterData.houseNo)
        this.filterData.ownerType && (queryData.ownerType = this.filterData.ownerType)

        list(this.communityId, queryData)
          .then((res) => {
            if (res.result) {
              _this.dataList = res.data.records
              _this.total = res.data.total
            } else {
              _this.noticeError(res.message)
            }
            this.loading = false
          })
          .catch((err) => {
            console.error('业主列表查询出错', err)
            this.loading = false
          })
      },

      /**
       * 获取小区房间树形信息
       */
      getPropertyTree() {
        const _this = this
        tree(this.communityId)
          .then((res) => {
            if (res.result) {
              const options = res.data.map((item) => {
                item.value = item.areaNumber
                item.label = '区域' + item.areaNumber
                // 遍历楼号
                if (item.buildings && item.buildings.length > 0) {
                  item.children = item.buildings.map((itemBuilding) => {
                    itemBuilding.value = itemBuilding.number
                    itemBuilding.label = '楼号' + itemBuilding.number
                    // 遍历单元
                    if (itemBuilding.units && itemBuilding.units.length > 0) {
                      itemBuilding.children = itemBuilding.units.map((itemUnit) => {
                        itemUnit.value = itemUnit.number
                        itemUnit.label = '单元' + itemUnit.number
                        // 遍历楼层
                        if (itemUnit.floors && itemUnit.floors.length > 0) {
                          itemUnit.children = itemUnit.floors.map((itemFloor) => {
                            itemFloor.value = itemFloor.floor
                            itemFloor.label = '楼层' + itemFloor.floor
                            // 遍历房间
                            if (itemFloor.houses && itemFloor.houses.length > 0) {
                              itemFloor.children = itemFloor.houses.map((itemHouse) => {
                                itemHouse.value = itemHouse.id
                                itemHouse.label = '房间' + itemHouse.fullNumber
                                if (itemHouse.status !== 0) {
                                  // 因为要添加随住,所以把这里的disable放开
                                  itemHouse.disabled = true
                                }
                                return itemHouse
                              })
                            }
                            if (!itemFloor.children || itemFloor.children.length === 0) {
                              itemFloor.disabled = true
                            }
                            return itemFloor
                          })
                        }
                        if (!itemUnit.children || itemUnit.children.length === 0) {
                          itemUnit.disabled = true
                        }
                        return itemUnit
                      })
                    }
                    if (!itemBuilding.children || itemBuilding.children.length === 0) {
                      itemBuilding.disabled = true
                    }
                    return itemBuilding
                  })
                }
                if (!item.children || item.children.length === 0) {
                  item.disabled = true
                }
                return item
              })
              _this.options = options
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.error('获取小区房间树形信息出错', err)
          })
      },

      /**
       * 编辑前执行
       */
      beforeEdit(data, e) {
        this.actionDataItem = data
        this.dialogFormData = data

        // 筛选列表
        this.currMemberList = this.memberlist2
        this.currTableColumns = this.tablecolumns
        this.showRoomType = true

        // 数据列表
        this.currColumns = this.dynamictablecolumns
        // 签约数据列表
        this.dialogFormData.tableData = data.refHouses
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            const ids = this.$refs.tabselect.dataForm.processDefinitionId
            const id = _this.actionDataItem.id
            if (_this.modalObject.currentAction === 'edit') {
              const postData = {}
              postData.communityId = _this.communityId

              if (_this.actionDataItem.type === 1) {
                const refHouses = []
                _this.dialogFormData.tableData.forEach((item) => {
                  refHouses.push({
                    houseId: item.houseId,
                    ownerType: 1
                  })
                })

                ids.forEach((item) => {
                  refHouses.push({
                    houseId: item,
                    ownerType: _this.dialogFormData.ownerType
                  })
                })

                const refShops = []
                _this.actionDataItem.refShops.forEach((item) => {
                  refShops.push({
                    shopId: item.shopId,
                    ownerType: 1
                  })
                })
                postData.refHouses = refHouses
                postData.refShops = refShops
                postData.mobile = _this.actionDataItem.mobile
                postData.name = _this.actionDataItem.name
                postData.remark = _this.actionDataItem.remark

                update(id, postData).then((response) => {
                  if (response.result) {
                    _this.noticeSuccess(response.message)
                    _this.modalObject.status = false
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(response.message)
                  }
                })
              } else {
                // _this.actionDataItem.type === 2 增加商铺
                const refHouses = []
                _this.actionDataItem.refHouses.forEach((item) => {
                  refHouses.push({
                    houseId: item.houseId,
                    ownerType: 1
                  })
                })
                const refShops = []
                _this.dialogFormData.tableData.forEach((item) => {
                  refShops.push({
                    shopId: item.shopId,
                    ownerType: 1
                  })
                })
                ids.forEach((item) => {
                  refShops.push({
                    shopId: item,
                    ownerType: 1
                  })
                })
                postData.refHouses = refHouses
                postData.refShops = refShops
                postData.mobile = _this.actionDataItem.mobile
                postData.name = _this.actionDataItem.name
                postData.remark = _this.actionDataItem.remark

                update(id, postData).then((response) => {
                  if (response.result) {
                    _this.noticeSuccess(response.message)
                    _this.modalObject.status = false
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(response.message)
                  }
                })
              }
            } else {
              const postData = {}
              postData.communityId = _this.communityId
              const refHouses = []
              // _this.actionDataItem.refHouses.forEach(item => {
              //   refHouses.push({
              //     houseId: item.houseId[4],
              //     ownerType: item.ownerType
              //   })
              // })
              ids.forEach((item) => {
                refHouses.push({
                  houseId: item,
                  ownerType: 1
                })
              })
              postData.refHouses = refHouses

              postData.mobile = _this.actionDataItem.mobile
              postData.name = _this.actionDataItem.name
              postData.remark = _this.actionDataItem.remark
              create(postData).then((res) => {
                if (res.result) {
                  _this.noticeSuccess(res.message)
                  _this.modalObject.status = false
                  _this.handleRefresh()
                } else {
                  _this.noticeError(res.message)
                }
              })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            if (res.result) {
              _this.noticeSuccess('删除成功')
              _this.handleRefresh()
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.error('删除业主信息失败', err)
          })
      },

      /**
       * 动态删除已签约商铺
       */
      handleDelSignedRoom(id) {
        const _this = this
        _this.dialogFormData.tableData.splice(id, 1)
      },

      /**
       * 批量删除记录
       */
      handleBatchDel(ids) {
        this.noticeError('暂时没有实现批量删除接口')
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出业主列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.current = 1
            queryData.size = 999
            this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
            this.filterData.mobile && (queryData.mobile = this.filterData.mobile)
            this.filterData.houseNo && (queryData.houseNo = this.filterData.houseNo)
            this.filterData.ownerType && (queryData.ownerType = this.filterData.ownerType)

            list(this.communityId, queryData)
              .then((res) => {
                if (res.result) {
                  console.log('导出数据', res.result)
                  require.ensure([], () => {
                    let tHeader = []
                    let filterVal = []
                    this.tableHeadFields.forEach((item) => {
                      tHeader.push(item.label)
                      filterVal.push(item.prop)
                    })
                    // 补充
                    tHeader = tHeader.concat(['区号', '楼号', '单元号', '房间号', '类型'])
                    filterVal = filterVal.concat(['areaNumber', 'buildingNumber', 'unit', 'number', 'ownerType'])
                    const data = res.data.records.map((v) => {
                      return filterVal.map((j) => {
                        if (j === 'areaNumber') {
                          return v.refHouses[0].houseInfo.areaNumber
                        } else if (j === 'buildingNumber') {
                          return v.refHouses[0].houseInfo.buildingNumber
                        } else if (j === 'unit') {
                          return v.refHouses[0].houseInfo.unit
                        } else if (j === 'number') {
                          return v.refHouses[0].houseInfo.number
                        } else if (j === 'ownerType') {
                          return ['', '业主', '随住', '租户'][Number(v.refHouses[0].type)]
                        } else {
                          return v[j]
                        }
                      })
                    })
                    export_json_to_excel(tHeader, data, '业主信息')
                  })
                  _this.noticeSuccess('导出成功')
                }
              })
              .catch((err) => {
                _this.noticeError(err.message)
                console.log('导出业主信息请求失败:', err.message)
              })
          })
          .catch((err) => {
            console.log('导出信息失败', err)
          })
      },

      /**
       * 下载模板
       */
      handleDownload() {
        window.open(
          'http://matrixoss.oss-cn-zhangjiakou.aliyuncs.com/upload/files/20220518/%E4%B8%9A%E4%B8%BB%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF-v1.0.xlsx'
        )
      },

      /**
       * 上传成功
       */
      handleImportSuccess() {
        this.noticeSuccess('上传成功')
        this.handleRefresh()
      },

      /**
       * 添加成员按钮点击事件
       */
      handleAddMemberItem() {
        // 在actionDataItem中添加成员
        this.actionDataItem.member.push({
          name: '',
          mobile: '',
          type: '',
          remark: ''
        })
        // 在表单验证规则中添加成员
        this.rules.member.push({
          name: [{ required: true, message: '成员姓名不能为空', trigger: 'blur' }],
          mobile: [
            { required: true, message: '成员电话不能为空', trigger: 'blur' },
            { pattern: RX_MOBILE, message: '手机号码格式不正确', trigger: 'blur' }
          ],
          type: [{ required: true, message: '成员类型不能为空', trigger: 'blur' }]
        })
      },

      /**
       * 添加房屋按钮点击事件
       */
      handleAddRoomItem() {
        // 在actionDataItem中添加
        this.actionDataItem.refHouses.push({
          houseId: '',
          ownerType: ''
        })
        // 在表单验证规则中添加
        this.rules.refHouses.push({
          houseId: [{ required: true, message: '房间信息不能为空', trigger: 'blur' }],
          ownerType: [{ required: true, message: '房间关系不能为空', trigger: 'blur' }]
        })
      },

      /**
       * 删除成员按钮点击事件
       */
      handleDeleteMemberItem(index) {
        // 在actionDataItem中删除成员
        this.actionDataItem.member.splice(index, 1)
        // 在表单验证规则中删除成员
        this.rules.member.splice(index, 1)
      },

      /**
       * 删除房屋按钮点击事件
       */
      handleDeleteRoomItem() {
        // 在actionDataItem中删除成员
        if (this.actionDataItem.refHouses.length > 1) {
          this.actionDataItem.refHouses.pop()
          // 在表单验证规则中删除成员
          // this.rules.rooms.splice(index, 1)
          this.rules.refHouses.pop()
        }
      },

      /**
       * overwrite mixins中的edit
       */
      edit(dataObj, title, cb) {
        // const _this = this
        this.modalObject.currentAction = 'edit'
        this.modalObject.title = title
        this.modalObject.status = true

        // const objectKey = Object.keys(this.formData)
        // const filteredFormData = {}
        // filteredFormData.id = dataObj.id
        // objectKey.map(item => {
        //   filteredFormData[item] = dataObj[item]
        // })
        // filteredFormData.refHouses = []
        // // 这里要把成员信息更新到actionDataItem中,默认把业主的第一间房的信息放进去
        // if (dataObj.refHouses.length > 0) {
        //   dataObj.refHouses.forEach(item => {
        //     filteredFormData.refHouses.push({
        //       houseId: [
        //         item.houseInfo.areaNumber,
        //         item.houseInfo.buildingNumber,
        //         item.houseInfo.unit,
        //         item.houseInfo.floor,
        //         item.houseId
        //       ],
        //       ownerType: item.type
        //     })
        //     const areaNumberIndex = _this.options.findIndex(item1 => item1.areaNumber === item.houseInfo.areaNumber)
        //     const buildingIndex = _this.options[areaNumberIndex].children.findIndex(item1 => item1.id === item.houseInfo.buildingId)
        //     const unitIndex = _this.options[areaNumberIndex].children[buildingIndex].children.findIndex(item1 => item1.value === item.houseInfo.unit)
        //     const floorIndex = _this.options[areaNumberIndex].children[buildingIndex].children[unitIndex].children.findIndex(item1 => item1.value === item.houseInfo.floor)
        //     const houseIndex = _this.options[areaNumberIndex].children[buildingIndex].children[unitIndex].children[floorIndex].children.findIndex(item1 => item1.value === item.houseId)
        //     _this.options[areaNumberIndex].children[buildingIndex].children[unitIndex].children[floorIndex].children[houseIndex].disabled = false
        //   })
        // }
        // this.actionDataItem = filteredFormData
        // console.log('xxx', dataObj)
      },

      /**
       * 添加成员按键点击处理
       */
      handleAddMember(houseObj) {
        this.addMemberDialogShow = true
        this.addMemberDialogObj = {
          name: '',
          mobile: '',
          type: '',
          remark: ''
        }
        this.addMemberDialogObj.houseId = houseObj.houseId
      },

      /**
       * 获取业主信息
       */
      getOwnerDes(arr) {
        return arr.find((item) => item.relationType === 1)
      },

      /**
       * 过滤掉业主
       */
      filterOwnerArr(arr) {
        return arr.filter((item) => item.relationType !== 1)
      },

      /**
       * 查看导入记录按键点击
       */
      handleImportRecord() {
        const queryData = {}
        queryData.communityId = this.communityId
        queryData.current = this.importInfoDialog.current
        queryData.size = this.importInfoDialog.size
        importBatchList(queryData)
          .then((res) => {
            if (res.result) {
              this.importInfoDialog.show = true
              this.importInfoDialog.data = res.data.records
              this.importInfoDialog.total = res.data.total
            } else {
              this.noticeError('获取失败', res.message)
            }
          })
          .catch((e) => {
            console.log(e)
            this.noticeError('获取失败', e.message)
          })
      },

      /**
       * 导出失败数据按键点击
       */
      handleFailExport(id) {
        exportErrorRecords(id)
          .then((res) => {
            const blob = new Blob([res.data])
            // 获取heads中的filename文件名
            const downloadElement = document.createElement('a')
            // 创建下载的链接
            const href = window.URL.createObjectURL(blob)
            downloadElement.href = href
            // 下载后文件名
            downloadElement.download = '批次失败数据.xlsx'
            document.body.appendChild(downloadElement)
            // 点击下载
            downloadElement.click()
            // 下载完成移除元素
            document.body.removeChild(downloadElement)
            // 释放掉blob对象
            window.URL.revokeObjectURL(href)
            this.noticeSuccess('导出成功')
          })
          .catch((e) => {
            this.noticeError('导出失败' + e.message)
          })
      }
    }
  }
</script>
<style scoped lang="scss">
  .gz-house-group {
    // display: flex;
    align-items: center;

    .gz-house-item {
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 5px;
      .gz-house-info {
        width: 300px;
      }
      .gz-owner-type,
      .gz-house-handle {
        width: 200px;
      }
    }
  }

  .gz-table-expand {
    font-size: 12px;
    padding-left: 20px;

    .gz-card-item-des {
      opacity: 0.6;
    }
  }
  .gz-house-info-pop {
    padding: 0;
  }

  .gz-owner {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .gz-dialog-member-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    .gz-member {
      width: 100%;
      text-align: center;
    }
  }

  .gz-icon-owner,
  .gz-icon-member {
    width: 34px;
    height: 34px;
  }
  .gz-fail-export {
    color: #faa13c;
    cursor: pointer;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
</style>
