/*
#***********************************************
#
#      Filename: gz-report-front/src/api/fileParkingOrder/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 月租缴费明细相关接口
#        Create: 2021-11-18 15:50:03
# Last Modified: 2021-11-26 14:03:28
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 线下缴费
export function create(data) {
  const postData = qs.stringify(data)
  return request({
    url: '/vip/order/create',
    method: 'post',
    data: postData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 获取单个缴费信息
export function info(id) {
  return request({
    url: '/vip/order/info/' + id,
    method: 'get'
  })
}

// 缴费列表
export function list(data) {
  return request({
    url: '/vip/order/list',
    method: 'get',
    params: data
  })
}

// 非固定车预计算价格
export function preCalcAmount(id, data) {
  return request({
    url: '/vip/order/preCalcAmount/' + id,
    method: 'get',
    params: data
  })
}
// 固定车预计算价格
export function preCalcAmountWithFixed(id, data) {
  return request({
    url: '/vip/order/preCalcAmountWithFixed/' + id,
    method: 'get',
    params: data
  })
}
