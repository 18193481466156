<!--
#***********************************************
#
#      Filename: gz-adserver-front/src/views/login.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 登录页面
#        Create: 2021-08-18 11:23:51
# Last Modified: 2021-11-11 21:56:28
#***********************************************
-->
<template>
  <div class="login">
    <div class="login-box">
      <img class="gz-login-logo" src="~@/assets/logo/logo.png" />
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
        <h3 class="title">驾到智云车管平台总账号登录</h3>
        <router-link to="register" class="to-register">没有账号？去注册</router-link>
        <el-form-item prop="account">
          <el-input
            v-model="loginForm.account"
            type="text"
            auto-complete="off"
            placeholder="账号"
            @keyup.enter.native="handleLogin">
            <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            type="password"
            auto-complete="off"
            placeholder="密码"
            @keyup.enter.native="handleLogin">
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="captcha">
              <el-input
                v-model="loginForm.captcha"
                type="text"
                auto-complete="off"
                placeholder="验证码"
                @keyup.enter.native="handleLogin">
                <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-image
              title="点击更换验证码"
              style="cursor: pointer"
              class="captcha-img"
              :src="captchaImg"
              @click="refreshCaptcha" />
          </el-col>
        </el-row>
        <el-form-item>
          <el-checkbox v-model="loginForm.rememberMe">记住密码</el-checkbox>
        </el-form-item>
        <el-form-item style="width: 100%">
          <el-button
            :loading="loading"
            size="medium"
            type="primary"
            style="width: 100%"
            @click.native.prevent="handleLogin">
            <span v-if="!loading">登 录</span>
            <span v-else>登录中...</span>
          </el-button>
        </el-form-item>
      </el-form>
      <!--  底部  -->
      <div class="el-login-footer">
        <span>Copyright © 2018-2023 gz All Rights Reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'
  import { encrypt, decrypt } from '@/utils/jsencrypt'
  import { captchaUrl } from '@/config'
  import { platformTwoRoutes } from '@/router'
  import { comList } from '@/api/community'
  import { setCommunityList } from '@/utils/auth'
  import Axios from 'axios'
  import { getUUID } from '@/utils/aliOss'

  export default {
    name: 'PlatformTwoLogin',
    components: {},
    data() {
      return {
        // 验证码图片
        captchaImg: captchaUrl,
        applyId: 'captcha_applyId',
        // 表单数据
        loginForm: {
          account: '',
          password: '',
          captcha: '',
          rememberMe: false,
          applyId: ''
        },

        // 表单验证规则
        loginRules: {
          account: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
          password: [{ required: true, trigger: 'blur', message: '密码不能为空' }],
          captcha: [{ required: true, trigger: 'blur', message: '验证码不能为空' }]
        },
        loading: false,
        redirect: undefined
      }
    },

    watch: {
      $route: {
        handler: function (route) {
          this.redirect = route.query && route.query.redirect
        },
        immediate: true
      }
    },

    created() {
      this.getCookie()
    },

    methods: {
      /**
       * 获取cookie数据
       */
      getCookie() {
        const account = Cookies.get('all_username')
        const password = Cookies.get('all_password')
        const rememberMe = Cookies.get('all_rememberMe')
        this.loginForm = {
          account: account === undefined ? this.loginForm.account : account,
          password: password === undefined ? this.loginForm.password : decrypt(password),
          rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
          applyId: ''
        }
      },

      /**
       * 登录
       */
      handleLogin() {
        const _this = this
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            _this.loading = true
            if (_this.loginForm.rememberMe) {
              Cookies.set('all_username', _this.loginForm.account, { expires: 30 })
              Cookies.set('all_password', encrypt(_this.loginForm.password), {
                expires: 30
              })
              Cookies.set('call_aptchaCode', _this.loginForm.captcha, {
                expires: 30
              })
              Cookies.set('all_rememberMe', _this.loginForm.rememberMe, {
                expires: 30
              })
            } else {
              Cookies.remove('all_username')
              Cookies.remove('all_password')
              Cookies.remove('all_captchaCode')
              Cookies.remove('all_rememberMe')
            }
            console.log('提交的数据', _this.loginForm)
            _this.$store
              .dispatch('user/Login', _this.loginForm)
              .then(() => {
                // 在这里获取账号的区域列表
                const queryData = {
                  current: 1,
                  size: 999,
                  status: 1
                }
                comList(queryData).then((res) => {
                  // 将区域列表信息存储到storage中
                  setCommunityList(res.data.records)
                  _this.$store.commit('user/SET_COMMUNITY_LIST', res.data.records)
                  console.log('路由对象', _this.$router)
                  console.log('路由列表', _this.$router.getRoutes())
                  console.log('总平台路由列表', platformTwoRoutes)
                  const manageIndex = platformTwoRoutes.findIndex((item) => item.name === 'Manage')
                  const manageRouteChildren = []
                  const communityList = _this.$store.state.user.communityList
                  console.log('vuex中的区域列表', communityList)
                  communityList.forEach((item) => {
                    const childRoute = {
                      path: '/manage/' + item.id,
                      commponent: (resolve) => require('@/views/PlatformTwo/Manage/Manage'),
                      name: 'Manage',
                      meta: { title: item.name, icon: 'user', permission: true }
                    }
                    manageRouteChildren.push(childRoute)
                  })
                  platformTwoRoutes[manageIndex].children = manageRouteChildren
                  console.log('添加区域信息后的总平台路由列表', platformTwoRoutes)
                  _this.$router.addRoutes(platformTwoRoutes)
                  _this.$store.commit('permission/SET_ROUTES', platformTwoRoutes)
                  _this.$store.commit('permission/SET_SIDEBAR_ROUTERS', platformTwoRoutes)
                  _this.$store.commit('permission/SET_PLATFORM', 'platformTwo')
                  _this.$router.replace({ path: '/workplate/plate' })
                })
              })
              .catch((e) => {
                _this.loading = false
                // _this.noticeError(e.message)
                console.error('登陆失败', e.message)
              })
          }
        })
      },

      /**
       * 刷新验证码
       */
      refreshCaptcha() {
        this.applyId = getUUID()
        this.loginForm.applyId = this.applyId
        Axios.get(captchaUrl + '?t=' + Date.parse(new Date()), {
          params: {
            applyId: this.applyId
          },
          responseType: 'blob'
        }).then((response) => {
          this.captchaImg = window.URL.createObjectURL(response.data)
        })
      }
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss">
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url('../../../assets/images/login-background.jpg');
    background-size: cover;

    .login-box {
      width: 1060px;
      height: 630px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-image: url('../../../assets/images/login_box_bg.png');
      background-size: 100% 100%;
      position: relative;

      .gz-login-logo {
        position: absolute;
        top: 40px;
        right: 250px;
      }

      .login-form {
        border-radius: 6px;
        background: #ffffff;
        width: 370px;
        margin-right: 20px;
        padding: 25px 25px 5px 25px;

        .title {
          margin: 0px auto 30px auto;
          text-align: center;
          color: #707070;
        }

        .to-register {
          text-align: right;
          width: 100%;
          display: block;
          margin-bottom: 30px;
          color: blue;
          font-size: 12px;
        }

        .input-icon {
          height: 39px;
          width: 14px;
          margin-left: 2px;
        }
        .captcha-img {
          height: 36px;
        }
      }
    }
  }

  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }
</style>
