<!--
#***********************************************
#
#      Filename: src/views/Detail/FixedVehicles.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 月租缴费明细
#        Create: 2021-11-11 14:47:29
# Last Modified: 2021-11-26 16:30:30
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @add="add('新增固定车位缴费')"
      @export="handleExport"
      @filter-change="filterChange"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table
      :data="dataList"
      size="mini"
      stripe
      border
      show-summary
      :summary-method="getSummaries"
      @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.startTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.endTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop" />
      <el-table-column
        v-if="tableHeadFields[9].isShow"
        :label="tableHeadFields[9].label"
        :width="tableHeadFields[9].width"
        :align="tableHeadFields[9].align"
        :prop="tableHeadFields[9].prop" />
      <el-table-column
        v-if="tableHeadFields[10].isShow"
        :label="tableHeadFields[10].label"
        :width="tableHeadFields[10].width"
        :align="tableHeadFields[10].align"
        :prop="tableHeadFields[10].prop" />
      <el-table-column
        v-if="tableHeadFields[11].isShow"
        :label="tableHeadFields[11].label"
        :width="tableHeadFields[11].width"
        :align="tableHeadFields[11].align"
        :prop="tableHeadFields[11].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.dateline) }}
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import moment from 'moment'
  import { list, exportRecord } from '@/api/monthDetail'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'MonthDetail',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          communityId: '',
          buildingNumber: '',
          areaNumber: '',
          floor: '',
          number: '',
          remark: '',
          unit: ''
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '请输入车牌号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateRange',
                name: 'dateRange'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '车牌号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carNumber'
          },
          {
            label: '姓名',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '区号/科室/部门/公司',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'region'
          },
          {
            label: '楼号/子科室/部门',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'tower'
          },
          {
            label: '单元号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'element'
          },
          {
            label: '房间号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'roomNum'
          },
          {
            label: '开始时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'startTime'
          },
          {
            label: '结束时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'endTime'
          },
          {
            label: '月租缴费',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'price'
          },
          {
            label: '支付类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'payTypeCN'
          },
          {
            label: '车辆类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carTypeCN'
          },
          {
            label: '创建时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'dateline'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          export: 'fee-record:parking-vip:export'
        }
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 总计方法
       */
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 1) {
            sums[index] = '总计'
            return
          }
          if (index === 9) {
            const values = data.map((item) => Number(item[column.property]))
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              sums[index] += ' 元'
            }
          }
        })
        return sums
      },

      /**
       * 获取列表数据
       */
      getList() {
        this.loading = true
        const _this = this

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.pId = this.pid
        this.filterData.searchStr && (queryData.carNumber = this.filterData.searchStr)
        if (this.filterData.dateRange) {
          queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        }

        list(queryData)
          .then((res) => {
            _this.dataList = res.data
            _this.total = res.total
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出月租缴费列表?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.pId = this.pid
            queryData.current = this.currentPage
            queryData.size = this.pageSize
            this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)
            if (this.filterData.dateRange) {
              queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
              queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
            }
            exportRecord(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'startTime') {
                        return _this.parseTime(v[j])
                      } else if (j === 'endTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '月租缴费列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      }
    }
  }
</script>
