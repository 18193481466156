<!--
#***********************************************
#
#      Filename: src/views/Contract/ContractItem.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 合同
#        Create: 2021-10-28 16:49:39
# Last Modified: 2021-11-29 10:04:48
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      @filter-change="filterChange"
      @export="handleExport"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 0" class="text-navy">&#8226;&nbsp;待生效</span>
          <span v-if="scope.row.status === 1" class="text-teal">&#8226;&nbsp;有效</span>
          <span v-if="scope.row.status === -1" class="text-olive">&#8226;&nbsp;到期</span>
          <span v-if="scope.row.status === -2" class="text-red">&#8226;&nbsp;作废</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />

      <el-table-column label="操作" :width="200" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-green" @click="handlePreview(scope.row)">查看</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="968px"
      class="gz-common-dialog"
      @close="handleClose">
      <div class="btn-wrapper">
        <el-button-group>
          <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="prePage">上一页</el-button>
          <el-button type="primary" size="mini" @click="nextPage">
            下一页
            <i class="el-icon-arrow-right el-icon--right" />
          </el-button>
        </el-button-group>
        <div style="margintop: 10px; color: #faa13c">{{ pageNum }} / {{ pageTotalNum }}</div>
      </div>
      <pdf :page="pageNum" :src="pdfUrl" @progress="loadedRatio = $event" @num-pages="pageTotalNum = $event" />
    </el-dialog>

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { list } from '@/api/agreement'
  import Pdf from 'vue-pdf'

  export default {
    name: 'ContractItem',

    components: { ContentHeader, Pdf },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: false,
            tableHeadToggleShow: true,
            searchPlaceholder: '合同编号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: []
          }
          // dropDownFilterArray: {
          //   arr: [
          //     {
          //       type: 'dateRange',
          //       name: 'dateRange'
          //     },
          //     {
          //       type: 'select',
          //       name: 'status',
          //       placeholder: '请选择合同状态',
          //       options: [
          //         { label: '待生效', value: 0 },
          //         { label: '有效', value: 1 },
          //         { label: '到期', value: -1 },
          //         { label: '作废', value: -2 }
          //       ]
          //     }
          //   ]
          // }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '合同编号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'id'
          },
          {
            label: '业主姓名',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '手机号码',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'mobile'
          },
          {
            label: '开始时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'periodStart'
          },
          {
            label: '结束时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'periodEnd'
          },
          {
            label: '合同金额（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'amount'
          },
          {
            label: '合同状态',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'status'
          },
          {
            label: '签约时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'createTime'
          }
        ],

        /**
         * 预览的pdf地址
         */
        pdfUrl: '',

        /**
         * 当前pdf页
         */
        pageNum: 1,

        /**
         * pdf总页数
         */
        pageTotalNum: 1,

        /**
         * 当前页面加载进度，范围0-1
         */
        loadedRatio: 0
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      handleClose() {
        this.pageNum = 1
      },

      /**
       * 查看合同按键点击事件
       */
      handlePreview(data) {
        this.modalObject.title = '合同查看'
        this.modalObject.status = true
        this.pdfUrl = decodeURIComponent(data.tplFile)
        this.getNumPages()
      },

      /**
       * 上一页
       */
      prePage() {
        let page = this.pageNum
        page = page > 1 ? page - 1 : this.pageTotalNum
        this.pageNum = page
      },

      /**
       * 下一页
       */
      nextPage() {
        let page = this.pageNum
        page = page < this.pageTotalNum ? page + 1 : 1
        this.pageNum = page
      },

      /**
       * 计算pdf页码总数
       */
      getNumPages() {
        const _this = this
        try {
          const loadingTask = Pdf.createLoadingTask(this.pdfUrl)
          console.log('xxx', loadingTask)
          loadingTask.promise
            .then((pdf) => {
              _this.pageTotalNum = pdf.numPages
            })
            .catch((e) => {
              _this.noticeError('pdf加载失败', e.message)
            })
        } catch (e) {
          console.log('xxx', e)
        }
      },

      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // 组装查询条件
        if (this.communityId) {
          const queryData = {}
          queryData.current = this.currentPage
          queryData.size = this.pageSize
          this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
          console.log(queryData, '--------')
          list(this.pid, queryData)
            .then((res) => {
              if (res.result) {
                _this.dataList = res.data.records
                _this.total = res.data.total
              } else {
                _this.noticeError(res.message)
              }
            })
            .catch((err) => {
              _this.noticeError(err.message)
              console.error('子账号列表查询出错', err)
            })
            .finally(() => {
              _this.loading = false
            })
        }
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 导出数据
       */
      handleExport() {
        this.noticeError('暂时没有实现导出数据接口')
        // const _this = this
        // this.$confirm(
        //   '是否导出房间列表？',
        //   '警告',
        //   {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }
        // ).then(() => {
        //   // 组装查询条件
        //   const queryData = {}
        //   queryData.current = this.currentPage
        //   queryData.size = this.pageSize
        //   queryData.communityId = this.communityId
        //   this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
        //   this.filterData.areaNumber && (queryData.areaNumbr = this.filterData.areaNumber)
        //   this.filterData.buildingNumber && (queryData.buildingNumber = this.filterData.buildingNumber)
        //   this.filterData.unit && (queryData.unit = this.filterData.unit)
        //   this.filterData.number && (queryData.number = this.filterData.number)

        //   exportRecord(queryData)
        //     .then(res => {
        //       if (res.result) {
        //         require.ensure([], () => {
        //           const tHeader = ['姓名', '车牌号', '电话', '创建时间', '备注']
        //           const filterVal = ['name', 'carNumber', 'phone', 'createTime', 'remark']
        //           const list = res.data
        //           const data = _this.formatJson(filterVal, list)
        //           export_json_to_excel(tHeader, data, '白名单列表')
        //         })
        //         _this.msgSuccess('导出成功')
        //       } else {
        //         _this.msgError(res.message)
        //       }
        //     }).catch(err => {
        //       _this.megError('导出失败')
        //       console.error(err)
        //     })
        // }).catch(err => {
        //   console.log('取消导出')
        // })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .btn-wrapper {
    text-align: center;
  }
</style>
