<!--
#***********************************************
#
#      Filename: src/views/Setting/Contract.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 合同功能
#        Create: 2021-10-28 17:02:42
# Last Modified: 2021-10-28 17:03:27
#***********************************************
-->
<template>
  <div>合同功能</div>
</template>
<script>
  export default {}
</script>
