<!--
#***********************************************
#
#      Filename: src/views/Detail/FixedVehicles.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 物业缴纳明细
#        Create: 2022-09-04 14:47:29
# Last Modified: 2022-09-04 16:30:30
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      @filter-change="filterChange"
      @refresh="handleRefresh"
      @export="handleExport" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop">
        <template slot-scope="scope">
          {{ formOperName(scope.row.opAid) }}
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { propertyFeeList } from '@/api/finance'
  import moment from 'moment'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')
  export default {
    name: 'PropertyPayment',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          communityId: '',
          buildingNumber: '',
          areaNumber: '',
          floor: '',
          number: '',
          remark: '',
          unit: '',
          amount: '',
          periodStart: '',
          periodEnd: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          placeId: [{ required: true, message: '车位号不能为空', trigger: 'blur' }],
          carNumber: [{ required: true, message: '车牌号不能为空', trigger: 'blur' }],
          period: [{ required: true, message: '缴费时长不能为空', trigger: 'blur' }]
          // unit: [{ required: true, message: '单元号不能为空', trigger: 'blur' }],
          // floor: [{ required: true, message: '楼层号不能为空', trigger: 'blur' }],
          // number: [{ required: true, message: '房间号不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: false,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: ''
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateRange',
                name: 'dateRange'
              },
              {
                type: 'checkbox',
                name: 'isSync',
                label: '是否同步'
              },
              {
                type: 'select',
                name: 'status',
                placeholder: '待定',
                options: []
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '缴费流水',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'id'
          },
          {
            label: '商铺信息',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'mchInfo.externalName'
          },
          {
            label: '商户名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'mchInfo.name'
          },
          {
            label: '商户联系方式',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'mchInfo.phone'
          },
          {
            label: '物业费',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'pureShopAmt'
          },
          {
            label: '总价',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'totalAmt'
          },
          {
            label: '缴费时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'startDate'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'username'
          }
        ],

        /**
         * 车位列表
         */
        placeList: [],

        /**
         * 支付记录
         */
        payRecord: [],

        /**
         * 支付记录Modal
         */
        showPayRecordModal: false,

        /**
         * 当前车牌号列表
         */
        currentCarnumberList: []
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      dadda(scope) {
        console.log('scope', scope)
      },

      /**
       * 获取列表数据
       */
      getList() {
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.placeType = 2
        this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)
        if (this.filterData.dateRange) {
          queryData.payStartTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD')
          queryData.payEndTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD')
        }

        propertyFeeList(this.communityId, queryData)
          .then((res) => {
            // opAid
            this.dataList = res.data.records

            this.total = res.data.total
            this.loading = false
          })
          .catch((err) => {
            console.error('物业费缴费明细查询出错', err)
            this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出缴费明细?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            queryData.current = 1
            queryData.size = 999
            queryData.placeType = 2
            this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)
            if (this.filterData.dateRange) {
              queryData.payStartTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD')
              queryData.payEndTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD')
            }
            propertyFeeList(this.communityId, queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.records.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'masterInfo.name') {
                        return v.masterInfo.name
                      } else if (j === 'masterInfo.mobile') {
                        return v.masterInfo.mobile
                      } else if (j === 'houseInfo.areaNumber') {
                        return v.houseInfo.areaNumber
                      } else if (j === 'houseInfo.buildingId') {
                        return v.houseInfo.buildingNumber
                      } else if (j === 'houseInfo.unit') {
                        return v.houseInfo.unit
                      } else if (j === 'houseInfo.number') {
                        return v.houseInfo.number
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '物业费缴费明细')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      }
    }
  }
</script>
