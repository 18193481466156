<!--
#***********************************************
#
#      Filename: src/views/Account/Manage.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 区域下停车场列表页面
#        Create: 2022-02-11 10:14:49
# Last Modified: 2022-02-11 10:19:28
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      @add="add('新增停车场')"
      @filter-change="filterChange"
      @refresh="handleRefresh"
      @export="handleExport" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          <div class="nowrap" :title="scope.row.address">
            {{ scope.row.address }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.groundType === 1">地下停车场</span>
          <span v-else-if="scope.row.groundType === 2">开放停车场</span>
          <span v-else>未知类型停车场</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.structureType === 1">立体车库停车场</span>
          <span v-else-if="scope.row.structureType === 2">普通车位停车场</span>
          <span v-else>未知属性结构</span>
        </template>
      </el-table-column>
      >
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.functionType === 1">医院</span>
          <span v-else-if="scope.row.functionType === 2">商场</span>
          <span v-else-if="scope.row.functionType === 3">交通枢纽（机场等）</span>
          <span v-else-if="scope.row.functionType === 4">小区</span>
          <span v-else-if="scope.row.functionType === 5">景区</span>
          <span v-else-if="scope.row.functionType === 6">检测站</span>
          <span v-else-if="scope.row.functionType === 7">写字楼</span>
          <span v-else-if="scope.row.functionType === 8">酒店</span>
          <span v-else-if="scope.row.functionType === 9">学校</span>
          <span v-else-if="scope.row.functionType === 10">事业单位</span>
          <span v-else-if="scope.row.functionType === 11">银行</span>
          <span v-else-if="scope.row.functionType === 20">其他</span>
          <span v-else>未知功能类型</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.dateline) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="350" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-light-blue" @click="handleInfoModal(scope.row.id)">应用信息</div>
            <div class="gz-text-btn gz-text-btn-yellow" @click="navigateTo(scope.row.id)">进入</div>
            <div class="gz-text-btn gz-text-btn-green" @click="edit(scope.row, '编辑停车场', beforeEdit)">编辑</div>
            <div class="gz-text-btn gz-text-btn-red" @click="handleDelete(scope.row.id, '删除停车场', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->

    <el-dialog
      title="停车场应用信息"
      :visible.sync="appInfo.status"
      append-to-body
      width="580px"
      class="gz-common-dialog"
      @close="handleModalCloseEvent">
      <el-form ref="form" label-width="100px" :model="appInfo.data">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="停车场名称:">
              <el-input v-model="appInfo.data.name" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="apiKey:">
              <el-input v-model="appInfo.data.apiKey" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="apiSecret:">
              <el-input v-model="appInfo.data.apiSecret" disabled />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="880px"
      class="gz-common-dialog"
      @close="handleModalCloseEvent">
      <div class="step-wrapper">
        <el-steps :active="activeStep" finish-status="success" process-status="finish" space="336px">
          <el-step title="基本信息" />
          <el-step title="收费规则" />
        </el-steps>
      </div>
      <el-divider />
      <div class="form-wrapper">
        <el-form v-if="activeStep === 0" ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="停车场名称:" prop="name">
                <el-input v-model="actionDataItem.name" placeholder="请输入停车场名称" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="联系人:" prop="contactName">
                <el-input v-model="actionDataItem.contactName" placeholder="请输入联系人姓名" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话:" prop="contactMobile">
                <el-input v-model="actionDataItem.contactMobile" placeholder="请输入联系电话" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="固定车位:" prop="fixedPlaceCount">
                <el-input v-model.number="actionDataItem.fixedPlaceCount" placeholder="请输入固定车位数量" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="非固定车位:" prop="nonfixedPlaceCount">
                <el-input v-model.number="actionDataItem.nonfixedPlaceCount" placeholder="请输入非固定车位数量" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="地址:" prop="area">
                <div class="area-select-wrapper">
                  <el-select v-model="actionDataItem.province" placeholder="省" @change="handleProvinceChange">
                    <el-option
                      v-for="(item, index) in provinceList"
                      :key="index"
                      :label="item.name"
                      :value="item.name" />
                  </el-select>
                  <el-select v-model="actionDataItem.city" placeholder="市/州" @change="handleCityChange">
                    <el-option v-for="(item, index) in cityList" :key="index" :label="item.name" :value="item.name" />
                  </el-select>
                  <el-select v-model="actionDataItem.area" placeholder="县/区">
                    <el-option v-for="(item, index) in areaList" :key="index" :label="item.name" :value="item.name" />
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="详细地址:" prop="address">
                <el-input v-model="actionDataItem.address" placeholder="请输入详细地址" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="地面类型:" prop="groundType">
                <el-select v-model="actionDataItem.groundType" placeholder="请选择地面类型">
                  <el-option label="地下停车场" :value="1" />
                  <el-option label="开放停车场" :value="2" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="属性结构:" prop="structureType">
                <el-select v-model="actionDataItem.structureType" placeholder="请选择属性结构">
                  <el-option label="立体车库停车场" :value="1" />
                  <el-option label="普通车位停车场" :value="2" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="功能类型:" prop="functionType">
                <el-select v-model="actionDataItem.functionType" placeholder="请选择功能类型">
                  <el-option label="医院" :value="1" />
                  <el-option label="商场" :value="2" />
                  <el-option label="交通枢纽（机场等）" :value="3" />
                  <el-option label="小区" :value="4" />
                  <el-option label="景区" :value="5" />
                  <el-option label="检测站" :value="6" />
                  <el-option label="写字楼" :value="7" />
                  <el-option label="酒店" :value="8" />
                  <el-option label="学校" :value="9" />
                  <el-option label="事业单位" :value="10" />
                  <el-option label="银行" :value="11" />
                  <el-option label="其他" :value="20" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="充电桩:" prop="chargingPile">
                <el-radio-group v-model="actionDataItem.chargingPile">
                  <el-radio :label="1">有</el-radio>
                  <el-radio :label="2">无</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form
          v-else-if="activeStep === 1"
          ref="form1"
          label-width="0px"
          :model="actionDataItem"
          :rules="rulesStepTwo">
          <div class="step-two-label">1.输入收费规则</div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="" prop="ruleRemark">
                <el-input v-model="actionDataItem.ruleRemark" type="textarea" />
              </el-form-item>
            </el-col>
          </el-row>
          <div class="step-two-label">2.上传图片,支持上传图片格式：jpg、jpeg、png</div>
          <el-upload
            class="step-two-upload"
            action="#"
            :http-request="uploadImg"
            :show-file-list="false"
            :before-upload="beforeUpload"
            drag>
            <div class="upload-wrapper">
              <div>
                <i class="el-icon-picture-outline" style="font-size: 96px" />
              </div>
              <el-button>选取图片</el-button>
            </div>
          </el-upload>
        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button v-if="activeStep === 1" type="primary" @click="submitForm">确 定</el-button>
        <el-button v-else type="primary" @click="handleNextStep">下一步</el-button>
        <el-button v-if="activeStep === 1" type="primary" @click="handlePreStep">上一步</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { getUUID, put } from '@/utils/aliOss.js'
  import { parkCreate, parkInfo, parkList, parkUpdate, parkDel, parkAppInfo } from '@/api/community'
  import { regionTree } from '@/api/common'
  import { RX_MOBILE } from '@/utils/constants/regex'
  import { platformOneRoutes } from '@/router'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'Manage',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 当前步骤
         */
        activeStep: 0,
        formData: {
          chargingPile: 1
        },

        /**
         * 小区id
         */
        areaId: null,

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '停车场名称'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: []
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '停车场名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '固定车位',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'fixedPlaceCount'
          },
          {
            label: '非固定车位',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'nonfixedPlaceCount'
          },
          {
            label: '地址',
            isShow: true,
            width: '400',
            align: 'center',
            prop: 'address'
          },
          {
            label: '地面类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'groundType'
          },
          {
            label: '属性结构',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'structureType'
          },
          {
            label: '功能类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'functionType'
          },
          {
            label: '创建时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'dateline'
          }
        ],

        rules: {
          name: [
            { required: true, message: '停车场名称不能为空', trigger: 'blur' },
            {
              min: 5,
              max: 30,
              message: '停车场名称限5-30个字符',
              trigger: 'blur'
            }
          ],
          contactName: [
            { required: true, message: '联系人姓名不能为空', trigger: 'blur' },
            {
              min: 2,
              max: 30,
              message: '联系人姓名限2-30个字符',
              trigger: 'blur'
            }
          ],
          contactMobile: [
            { required: true, message: '联系电话不能为空', trigger: 'blur' },
            { pattern: RX_MOBILE, message: '联系电话格式不符', trigger: 'blur' }
          ],
          fixedPlaceCount: [{ type: 'number', message: '固定车位必须为数字', trigger: 'blur' }],
          nonfixedPlaceCount: [{ type: 'number', message: '非固定车位必须为数字', trigger: 'blur' }],
          area: [{ required: true, message: '地址不能为空', trigger: 'blur' }],
          address: [
            { required: true, message: '详细地址不能为空', trigger: 'blur' },
            {
              min: 2,
              max: 200,
              message: '详细地址限2-200个字符',
              trigger: 'blur'
            }
          ],
          groundType: [{ required: true, message: '请选择地面类型', trigger: 'blur' }],
          structureType: [{ required: true, message: '请选择属性结构', trigger: 'blur' }],
          functionType: [{ required: true, message: '请选择功能类型', trigger: 'blur' }],
          chargingPile: [{ required: true, message: '请选择有无充电桩', trigger: 'blur' }]
        },

        /**
         * step two 表单验证规则
         */
        rulesStepTwo: {
          ruleRemark: [{ required: true, message: '请选择有无充电桩', trigger: 'blur' }]
        },

        /**
         * 区域树
         */
        regionTreeList: null,

        /**
         * 省份列表
         */
        provinceList: [],

        /**
         * 城市列表
         */
        cityList: [],

        /**
         * 区域列表
         */
        areaList: [],

        /**
         * 停车场应用信息
         */
        appInfo: {
          status: false,
          data: {
            name: '',
            apiKey: '',
            apiSecret: ''
          }
        }
      }
    },
    created() {
      const fullPath = this.$route.fullPath
      const reg = new RegExp(/\/manage\/(\d+)$/)
      const tmp = fullPath.match(reg)
      this.areaId = tmp[1]
      this.getList()
      this.getRegionTree()
    },

    methods: {
      /**
       * 省级区域变化
       */
      handleProvinceChange(val) {
        const provinceData = this.regionTreeList.find((item) => item.name === val)
        const cityData = provinceData.children
        const cityList = []
        cityData.forEach((item) => {
          cityList.push(item)
        })
        this.cityList = cityList
      },

      /**
       * 市级区域变化
       */
      handleCityChange(val) {
        const cityData = this.cityList.find((item) => item.name === val)
        const areaData = cityData.children
        const areaList = []
        areaData.forEach((item) => {
          areaList.push(item)
        })
        this.areaList = areaList
      },

      /**
       * 获取区域树
       */
      getRegionTree() {
        regionTree().then((res) => {
          this.regionTreeList = res.data
          const provinceList = []
          res.data.forEach((item) => {
            provinceList.push(item)
          })
          this.provinceList = provinceList
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        parkDel(id)
          .then((res) => {
            if (res.result) {
              _this.noticeSuccess('删除成功')
              _this.handleRefresh()
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.error(err)
          })
      },

      /**
       * 编辑前预处理
       */
      beforeEdit(data) {
        console.log(data, '------')
        parkInfo(data.id)
          .then((res) => {
            this.actionDataItem = res.data
            this.actionDataItem.contactName = res.data.contacts
            this.actionDataItem.contactMobile = res.data.contactsNumber
          })
          .catch((e) => {
            console.log(e.message)
          })
      },

      /**
       * 进入按钮点击事件
       */
      navigateTo(data) {
        // 这里要重置路由
        this.$router.addRoutes(platformOneRoutes)
        this.$store.commit('permission/SET_ROUTES', platformOneRoutes)
        this.$store.commit('permission/SET_SIDEBAR_ROUTERS', platformOneRoutes)
        this.$store.commit('permission/SET_PLATFORM', 'platformOne')
        this.$router.replace({ path: '/workplate/plate' })
      },

      /**
       * 对话框关闭事件
       */
      handleModalCloseEvent() {
        this.activeStep = 0
      },

      /**
       * 上传收费规则照片
       */
      uploadImg(file) {
        const _this = this
        const objName = getUUID()
        const fileInfoArr = file.file.name.split('.')
        const ext = fileInfoArr[fileInfoArr.length - 1]

        put(`${objName}.${ext}`, file.file, 'manage/ruleImg')
          .then((res) => {
            console.log('文件上传返回结果', res)
            _this.actionDataItem.ruleImage = res.url
            _this.noticeSuccess('文件上传成功')
          })
          .catch(() => {
            _this.noticeError('文件上传失败')
          })
      },

      /**
       * 上传预处理
       */
      beforeUpload(file) {
        const isLt2M = file.size < 2 * 1024 * 1024

        if (file.type.indexOf('image/') === -1) {
          this.noticeError('文件格式错误，请上传图片类型,如:JPG，PNG后缀的文件')
          return false
        }
        if (!isLt2M) {
          this.noticeError('上传头像图片大小不能超过2M!')
          return false
        }
        return true
      },

      /**
       * 下一步按钮点击事件
       */
      handleNextStep() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.activeStep === 0) {
              _this.activeStep++
            }
          }
        })
      },

      /**
       * 上一步按钮点击事件
       */
      handlePreStep() {
        if (this.activeStep === 1) {
          this.activeStep--
        }
      },

      /**
       * 获取列表信息
       */
      getList() {
        const _this = this
        const queryData = {
          communityId: _this.areaId,
          current: _this.currentPage,
          size: _this.pageSize
        }
        if (_this.filterData.searchStr) {
          queryData.searchKw = _this.filterData.searchStr
        }
        parkList(queryData)
          .then((res) => {
            _this.dataList = res.data.records
            _this.total = res.data.total
          })
          .catch((e) => {
            console.error('api请求Error:', e.message)
          })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出停车场列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {
              communityId: _this.areaId,
              current: _this.currentPage,
              size: _this.pageSize
            }
            parkList(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.records.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'groundType') {
                        return ['', '地下停车场', '开放停车场'][v[j]]
                      }
                      if (j === 'structureType') {
                        return ['', '立体车库停车场', '普通车位停车场'][v[j]]
                      }
                      if (j === 'functionType') {
                        let str = ''
                        switch (v[j]) {
                          case 1:
                            str = '医院'
                            break
                          case 2:
                            str = '商场'
                            break
                          case 3:
                            str = '交通枢纽（机场等）'
                            break
                          case 4:
                            str = '小区'
                            break
                          case 5:
                            str = '景区'
                            break
                          case 6:
                            str = '检测站'
                            break
                          case 7:
                            str = '写字楼'
                            break
                          case 8:
                            str = '酒店'
                            break
                          case 9:
                            str = '学校'
                            break
                          case 10:
                            str = '事业单位'
                            break
                          case 11:
                            str = '银行'
                            break
                          case 20:
                            str = '其他'
                            break

                          default:
                            break
                        }
                        return str
                      }

                      if (j === 'dateline') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '停车场列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 停车场创建按钮点击事件
       */
      submitForm() {
        const _this = this

        // 验证收费规则图片是否已上传
        if (!this.actionDataItem.ruleImage) {
          this.noticeError('请在提交表单前上传收费规则图片')
          return
        }
        this.$refs.form1.validate((valid) => {
          if (valid) {
            const postData = Object.assign({}, _this.actionDataItem)
            postData.useEquipmentType = 0
            const communityId = this.areaId
            if (_this.modalObject.currentAction === 'edit') {
              parkUpdate(_this.actionDataItem.id, _this.actionDataItem).then((res) => {
                if (res.result) {
                  _this.modalObject.status = false
                  _this.handleRefresh()
                } else {
                  _this.noticeError(res.message)
                }
              })
            } else {
              parkCreate(communityId, postData).then((res) => {
                if (res.result) {
                  _this.modalObject.status = false
                  _this.handleRefresh()
                } else {
                  _this.noticeError(res.message)
                }
              })
            }
          }
        })
      },

      /**
       * 应用信息按键点击
       */
      handleInfoModal(id) {
        parkAppInfo(id)
          .then((res) => {
            if (res.result) {
              this.appInfo.data = res.data
              this.appInfo.status = true
            } else {
              this.noticeError(res.message)
            }
          })
          .catch((e) => {
            console.log('获取应用信息失败', e)
          })
      }
    }
  }
</script>
<style scoped lang="scss">
  .area-select-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  .step-wrapper {
    padding: 0 50px;
  }
  .form-wrapper {
    padding: 0 30px;
  }
  .step-two-label {
    color: #1a1a1a;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 5px;
  }
  .upload-wrapper {
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  ::v-deep .el-upload {
    width: 100%;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
  }
</style>
