<!--
#***********************************************
#
#      Filename: src/views/Setting/OnlinePay.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 线上支付设置
#        Create: 2021-10-28 16:56:26
# Last Modified: 2021-11-30 10:48:35
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      @filter-change="filterChange"
      @add="add('新增线上支付设置')"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-yellow" @click="edit(scope.row, '修改线上支付设置')">编辑</div>
            <div class="gz-text-btn gz-text-btn-red" @click="handleDelete(scope.row.id, '删除线上支付设置', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="120px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="支付方式:" prop="type">
              <el-select v-model="actionDataItem.type" placeholder="请选择支付方式">
                <el-option v-for="(item, index) in bankList" :key="index" :label="item.payName" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户号:" prop="merchantNo">
              <el-input v-model="actionDataItem.merchantNo" placeholder="请输入商户号" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="支付秘钥:" prop="payKey">
              <el-input v-model="actionDataItem.payKey" placeholder="请输入支付秘钥" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="小程序APPID:" prop="appletAppId">
              <el-input v-model="actionDataItem.appletAppId" placeholder="请输入小程序APPID" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注" prop="comments">
              <el-input v-model="actionDataItem.comments" type="textarea" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { add, bankList, batchDelete, deleteItem, info, list, setBank, update } from '@/api/parkingBank' // eslint-disable-line

  export default {
    name: 'OnlinePay',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {},

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          type: [{ required: true, message: '支付方式不能为空', trigger: 'blur' }],
          merchantNo: [{ required: true, message: '商户号不能为空', trigger: 'blur' }],
          payKey: [{ required: true, message: '支付秘钥不能为空', trigger: 'blur' }],
          appletAppId: [{ required: true, message: '小程序APPID不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: false,
            tableHeadToggleShow: false,
            searchPlaceholder: '姓名/账号名称/电话号码'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            importDataShow: false,
            more: [
              {
                label: '批量删除',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {}
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '支付方式',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'type'
          },
          {
            label: '商户号',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'merchantNo'
          },
          {
            label: '支付密钥',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'payKey'
          },
          {
            label: '小程序APPID',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'appId'
          },
          {
            label: '备注',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'comments'
          },
          {
            label: '更新时间',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'updateline'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'left',
            prop: 'oper'
          }
        ],

        /**
         * 银行列表
         */
        bankList: []
      }
    },

    mounted() {
      this.getList()
      this.getBankList()
    },

    methods: {
      /**
       * 获取银行列表
       */
      getBankList() {
        const _this = this
        bankList()
          .then((res) => {
            _this.bankList = res.data
          })
          .catch((e) => {
            _this.noticeError(e.message)
          })
      },

      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // 组装查询条件
        if (this.pid) {
          const queryData = {}
          queryData.pid = this.pid
          queryData.current = this.currentPage
          queryData.size = this.pageSize
          this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)

          list(queryData)
            .then((res) => {
              this.dataList = res.data.records
              this.total = res.data.total
            })
            .catch((err) => {
              _this.noticeError(err.message)
              console.error('子账号列表查询出错', err)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            const id = _this.actionDataItem.id
            if (id) {
              update(id, _this.actionDataItem).then((response) => {
                if (response.result) {
                  _this.msgSuccess(response.message)
                  _this.modalObject.status = false
                  _this.handleRefresh()
                } else {
                  _this.msgError(response.message)
                  _this.modalObject.status = false
                }
              })
            } else {
              add(_this.actionDataItem).then((res) => {
                if (res.result) {
                  _this.msgSuccess(res.message)
                  _this.modalObject.status = false
                  _this.handleRefresh()
                } else {
                  _this.msgError(res.message)
                  _this.modalObject.status = false
                }
              })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        batchDelete(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      }
    }
  }
</script>
