<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" size="mini" stripe border @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-for="(item, index) in tableHeadFields"
        v-show="item.isShow"
        :key="index"
        :label="item.label"
        :width="item.width"
        :align="item.align"
        :prop="item.prop"
        :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <template v-if="index == 10">
            {{ formOperName(scope.row.oper) }}
          </template>
          <template v-else>
            {{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}
          </template>
        </template>
      </el-table-column>

      <el-table-column label="操作" :width="200" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-red" @click="pay(scope.row, '收退款项', beforePay)">收退款项</div>
            <div class="gz-text-btn gz-text-btn-red" @click="openDrawer(scope.row, '账单详情', beforeOpenDetails)">
              账单详情
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->
    <!-- 点击收款弹框 -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="1000px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :rules="rules" :model="itemFormData">
        <!-- <el-divider></el-divider> -->
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="车位号" prop="plateNumber">
              <el-input v-model="actionDataItem.plateNumber" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="产权室号" prop="titleRoomNumber">
              <el-input v-model="actionDataItem.titleRoomNumber" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="购买人" prop="purchaser">
              <el-input v-model="actionDataItem.purchaser" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="电话号" prop="phone">
              <el-input v-model="actionDataItem.phone" autocomplete="off" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>

        <el-row :gutter="20">
          <el-col :span="11">
            <!-- <el-form-item label="收款项目" prop="sellInfoItemId">
              <el-input v-model="payRecordData[0]"></el-input>
              <el-select v-model="itemFormData.sellInfoItemId" placeholder="请选择收款种类" @change="selectChange">
                <el-option
                  v-for="(item, index) in payRecordData"
                  :key="index"
                  :label="item.feeTypeName"
                  :value="item.id" />
              </el-select>
            </el-form-item> -->
            <el-form-item label="收款项目" prop="feeName">
              <el-input v-model="itemFormData.feeName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="销售金额" prop="sellFee">
              <el-input v-model="itemFormData.sellFee" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="应收金额" prop="disReceiveFee">
              <el-input v-model="sellInfoItem.disReceiveFee" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="已收金额" prop="payedFee">
              <el-input v-model="sellInfoItem.payedFee" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="款项方向" prop="receiveType">
              <el-select v-model="itemFormData.receiveType" placeholder="请选择">
                <el-option v-for="item in useRefundOptions" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="收款金额" prop="receiveFee">
              <el-input v-model="itemFormData.receiveFee" placeholder="请输入收款金额"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item label="付款方式:" prop="collectionType">
              <el-select
                v-model="itemFormData.collectionType"
                placeholder="请选择付款方式"
                @change="handleCollectionTypeChange($event)">
                <el-option
                  v-for="item in collectionOption"
                  :key="item.collectionType"
                  :label="item.collectionTypeName"
                  :value="item.collectionType" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="22">
            <el-form-item label="备注" prop="col1">
              <textarea
                v-model="itemFormData.col1"
                type="textarea"
                :rows="2"
                placeholder="请输入备注信息"
                class="el-textarea__inner" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->

    <!-- 点击账单详情 -->
    <el-drawer
      :title="drawerObject.title"
      :visible.sync="drawerObject.status"
      :direction="direction"
      size="70%"
      class="drawers">
      <el-table :data="tableData" border class="spaceSalesContent">
        <el-table-column
          v-for="(item, index) in tableDetailOptions"
          v-show="item.isShow"
          :key="index"
          :label="item.label"
          :width="item.width"
          :align="item.align"
          :prop="item.prop"
          :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <template v-if="index == 3">
              <div v-if="scope.row.receiveType == 1" size="mini" type="success">收款</div>
              <div v-if="scope.row.receiveType == 2" size="mini" type="warning">退款</div>
            </template>
            <template v-else-if="index == 1">
              <div v-if="scope.row.receiveType == 2">
                {{ scope.row[item.prop] ? '-' + scope.row[item.prop] : '--' }}
              </div>
              <div v-else>{{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}</div>
            </template>
            <template v-else>
              {{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center" width="200">
          <template slot-scope="scope">
            <div class="gz-text-btn-group">
              <div class="gz-text-btn gz-text-btn-red" @click="getpdfyes(scope.row)">导出预览</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
    <el-drawer
      title="预览"
      :visible.sync="drawer"
      :direction="directions"
      :before-close="handleClose"
      size="50%"
      class="drawers">
      <div class="content-box">
        <el-table :data="exportPdfData" id="pdfDom" border class="tableContent">
          <el-table-column
            v-for="(item, index) in exportPdfOptions"
            v-show="item.isShow"
            :key="index"
            :label="item.label"
            :width="item.width"
            :align="item.align"
            :prop="item.prop"
            :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <template v-if="index == 3">
                <div v-if="scope.row.receiveType == 1" size="mini" type="success">收款</div>
                <div v-if="scope.row.receiveType == 2" size="mini" type="warning">退款</div>
              </template>
              <template v-else>
                {{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="exportBtn" @click="toExport('pdfDom', '订单发票')">导出</div>
    </el-drawer>
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import DrawerMixins from '@/views/mixins/drawer'
  import { list, saveReceiveInfoItem, getSellInfoItemList, detail, getReport } from '@/api/ParkingSpaceSalesDetail'
  import moment from 'moment'

  export default {
    name: 'ParkingSpaceSalesDetail',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo, DrawerMixins],

    data() {
      return {
        drawer: false,
        directions: 'ttb',
        isShowCard: true,
        // isShowBox: false,
        htmlTitle: '',
        // 定义一个全局ID用于回显数据
        editid: '',
        // 点击账单详情表格数据展示
        tableData: [
          {
            name: '000',
            price: '1222',
            time: '20022',
            sense: '退款'
          }
        ],
        dialogVisible: false,
        visible: false,
        // dialogVisible: false,
        dialogFormVisible: false,
        // 定义的空数组
        dataList: [],
        // 点击收退款自定义数据
        form: {
          phoneNumber: '',
          parkingPlateNumber: '',
          titleRoomNumber: '',
          purchaser: '',
          collection: '',
          received: '',
          remark: '',
          refund: '',
          receivable: ''
        },
        // 状态添加修改表单数据验证规则
        rules: {
          receiveType: [{ required: true, message: '款项种类必选', trigger: 'blur' }],
          receiveFee: [
            { required: true, message: '金额不能为空', trigger: 'blur' },
            {
              pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
              message: '请输入正确金额格式,可保留两位小数'
            }
          ]
        },

        // contentHeader组件配置

        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: false,
            searchPlaceholder: '业务编号/车位号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: false,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateTimeRange',
                name: 'dateRange'
              },
              {
                type: 'select',
                name: 'chargeType',
                placeholder: '选择尾款状态',
                options: [
                  { label: '已结', value: 2 },
                  { label: '未结', value: 1 }
                ]
              },
              {
                type: 'select',
                name: 'operator',
                placeholder: '选择操作人员',
                options: []
              }
            ]
          }
        },
        permissions: {
          export: 'parking:order:tmp:export'
        },
        formData: [
          {
            businessNumber: '',
            sellInfoId: '',
            plateNumber: '',
            titleRoomNumber: '',
            purchaser: '',
            phone: '',
            receiveFee: '',
            disReceiveFee: '',
            saleFee: '',
            paymentTime: '',
            oper: '',
            feeName: '',
            sellFee: '',
            collectionType: ''
          }
        ],
        /**
         */
        sellInfoItem: {},
        itemFormData: {
          sellInfoId: '',
          receiveInfoId: '',
          sellInfoItemId: '',
          feeName: '',
          sellFee: '',
          receiveFee: '',
          receiveType: '',
          collectionType: ''
        },
        // 主列表显示字段信息
        tableHeadFields: [
          {
            label: '业务编号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'businessNumber'
          },
          {
            label: '订单编号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'sellInfoId'
          },
          {
            label: '车位号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'plateNumber'
          },
          {
            label: '产权室号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'titleRoomNumber'
          },
          {
            label: '购买人',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'purchaser'
          },
          {
            label: '电话号码',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'phone'
          },
          {
            label: '收款总额',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'receiveFee'
          },
          {
            label: '未收总额',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'disReceiveFee'
          },
          {
            label: '应收总额',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'saleFee'
          },
          {
            label: '缴款日期',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'paymentTime'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'oper'
          },
          {
            label: '备注',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'remark'
          }
        ],
        // 下拉列表假数据
        useTypeOptions: [
          {
            userTypeIds: 1,
            userTypes: 'aa',
            sellamount: 500
          },
          {
            userTypeIds: 2,
            userTypes: 'bb',
            sellamount: 600
          }
        ],
        tableDetailOptions: [
          { label: '款项名称', isShow: true, width: '200', align: 'center', prop: 'feeName' },
          { label: '款项金额', isShow: true, width: '200', align: 'right', prop: 'receiveFee' },
          { label: '款项时间', isShow: true, width: '180', align: 'center', prop: 'paymentTime' },
          { label: '款项方向', isShow: true, width: '100', align: 'center', prop: 'receiveType' }
        ],
        exportPdfOptions: [
          { label: '款项名称', isShow: true, width: '200', align: 'center', prop: 'feeName' },
          { label: '款项金额', isShow: true, width: '200', align: 'right', prop: 'receiveFee' },
          { label: '款项时间', isShow: true, width: '180', align: 'center', prop: 'paymentTime' },
          { label: '款项方向', isShow: true, align: 'center', prop: 'receiveType' }
        ],
        // 抽屉
        direction: 'rtl',
        useRefundOptions: [
          {
            id: 1,
            name: '收款'
          },
          {
            id: 2,
            name: '退款'
          }
        ],
        collectionOption: [
          { collectionType: 1, collectionTypeName: 'pos机' },
          { collectionType: 2, collectionTypeName: '现金' },
          { collectionType: 3, collectionTypeName: '线上' }
        ],
        payRecordData: [],
        exportPdfData: []
      }
    },
    watch: {
      // 'itemFormData.receiveType'(e) {
      //   if (e === 2) {
      //     this.itemFormData.receiveFee = '-' + this.itemFormData.receiveFee
      //   }
      // },
    },
    mounted() {
      this.getList()
      this.getRpt()
    },
    methods: {
      handleCollectionTypeChange(e) {},

      selectChange(data) {
        this.payRecordData.map((item) => {
          if (item.id === data) {
            this.sellInfoItem = item
          }
        })
        if (this.sellInfoItem) {
          this.itemFormData.sellFee = this.sellInfoItem.fee
          this.itemFormData.feeName = this.sellInfoItem.feeTypeName
        }
      },
      // 获取列表数据
      getList() {
        console.log('//??//')
        this.loading = true
        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.parkingId = this.pid
        this.filterData.searchStr && (queryData.searchKeyWords = this.filterData.searchStr)
        queryData.operatorsId = this.filterData.operator
        queryData.status = this.filterData.chargeType
        if (this.filterData.dateRange) {
          queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        }
        list(queryData)
          .then((res) => {
            if (res.result) {
              this.loading = false
              this.dataList = res.data.records
              this.total = res.data.total
            } else {
              this.loading = false
              this.noticeError(res.message)
            }
          })
          .catch((err) => {
            this.noticeError(err.message)
          })
          .finally(() => {
            this.loading = false
          })
      },

      // 获取操作人员
      getRpt() {
        this.loading = true
        const pid = this.pid
        const data = {
          pId: pid
        }
        getReport(data)
          .then((res) => {
            const repData = []
            res.data.forEach((item) => {
              repData.push({ label: item.name, value: item.id })
            })
            this.settings.dropDownFilterArray.arr[3].options = repData
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.loading = false
          })
      },

      // 刷新页面
      handleRefresh() {
        this.getList()
      },
      // 点击收退款项

      beforeOpenDetails(datas) {
        this.drawerObject.status = true
        const id = datas.id
        const data = {
          receiveInfoId: id
        }
        detail(data)
          .then((res) => {
            if (res.result) {
              this.tableData = res.data
            } else {
              this.noticeError(res.message)
            }
          })
          .catch((err) => {
            this.noticeError(err.message)
          })
          .finally(() => {
            this.loading = false
          })
      },

      beforePay(data) {
        // this.itemFormData = {}
        getSellInfoItemList(data.sellInfoId).then((res) => {
          if (res.result) {
            this.payRecordData = res.data
            console.log(this.payRecordData, '====')
            this.itemFormData.feeName = this.payRecordData[0].feeTypeName
            this.itemFormData.sellFee = this.payRecordData[0].fee
            this.itemFormData.sellInfoItemId = this.payRecordData[0].id
            this.sellInfoItem = this.payRecordData[0]
          }
        })
        this.modalObject.status = true
        this.actionDataItem = data
        console.log(this.actionDataItem)
        this.itemFormData.sellInfoId = data.sellInfoId
        console.log(data.sellInfoId)
        this.itemFormData.receiveInfoId = data.id
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
      // 打印
      getpdfyes(data) {
        this.drawer = true
        this.drawerObject.status = false
        const dataArray = []
        if (data) {
          dataArray.push(data)
        }
        this.exportPdfData = dataArray
        console.log(this.exportPdfData)
      },
      toExport() {
        console.log(this.exportPdfData)
        this.getPdf('pdfDom', '订单发票')
        this.drawer = false
      },
      // 点击取消
      submitForm() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            saveReceiveInfoItem(this.itemFormData).then((res) => {
              if (res.result) {
                this.$refs.form.resetFields()
                this.noticeSuccess('保存成功')
                this.handleRefresh()
                this.modalObject.status = false
              } else {
                this.noticeError('业务错误')
                this.modalObject.status = false
              }
            })
          }
        })
      }
    }
  }
</script>
<style scoped="scoped" lang="scss">
  .drawers {
    width: calc(100% - 200px);
    left: 200px;
    right: 0;

    .spaceSalesContent {
      width: 90%;
      min-height: 88px;
      max-height: calc(100vh - 147px);
      margin: 0 auto;
      overflow-y: scroll;
    }
    // ::-webkit-scrollbar {
    //   width: 0;
    //   height: 0;
    //   border: none;
    // }
    .content-box {
      width: 776px;
      margin: 10px auto;
      padding: 0 40px;
      box-sizing: border-box;
    }

    .exportBtn {
      position: absolute;
      right: 38px;
      top: 224px;
      background-color: blue;
      border-radius: 5px;
      width: 70px;
      height: 40px;
      color: white;
      font-weight: bolder;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }
</style>
