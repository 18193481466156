/*
#***********************************************
#
#      Filename: src/api/role/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 角色相关API
#        Create: 2021-11-10 12:27:57
# Last Modified: 2021-11-26 14:09:00
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 添加角色
export function add(data) {
  return request({
    url: '/role/add',
    method: 'post',
    params: data
  })
}

// 更新角色
export function update(id, data) {
  return request({
    url: '/role/update/' + id,
    method: 'post',
    params: data
  })
}

// 批量删除
export function deleteItem(id) {
  return request({
    url: '/role/delete/' + id,
    method: 'post'
  })
}

// 角色详情
export function info(id) {
  return request({
    url: '/role/info/' + id,
    method: 'get'
  })
}

// 数据列表
export function list(data) {
  return request({
    url: '/role/list',
    method: 'get',
    params: data
  })
}

// 权限tree列表
export function treeList() {
  return request({
    url: '/role/privilege/treeList',
    method: 'get'
  })
}

// 角色已分配的权限列表
export function privilegeList(id) {
  return request({
    url: '/role/' + id + '/privilegeList',
    method: 'get'
  })
}

// 角色已分配的权限列表
export function setPrivileges(id, data) {
  const query = qs.stringify(data, { indices: false })
  return request({
    url: '/role/' + id + '/setPrivileges',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
