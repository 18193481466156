/*
#***********************************************
#
#      Filename: src/api/parkingPassage/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 进出口管理
#        Create: 2021-11-15 10:54:45
# Last Modified: 2021-11-26 14:06:49
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 添加出入口
export function add(data) {
  const query = qs.stringify(data)
  return request({
    url: '/parkingPassages/add',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 批量删除
export function batchDelete(data) {
  const query = qs.stringify(data, { indices: false })
  return request({
    url: '/parkingPassages/batchdel?' + query,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 删除数据
export function deleteItem(id) {
  return request({
    url: '/parkingPassages/delete/' + id,
    method: 'post'
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/parkingPassages/info/' + id,
    method: 'get'
  })
}

// 数据列表
export function list(data) {
  return request({
    url: '/parkingPassages/list',
    method: 'get',
    params: data
  })
}

// 数据列表
export function gatelist(data) {
  return request({
    url: '/parkingPassages/parkingGate/list/' + data,
    method: 'get'
  })
}

// 更新账号
export function update(data) {
  const query = qs.stringify(data)
  return request({
    url: '/parkingPassages/update',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}
