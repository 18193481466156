<!--
#***********************************************
#
#      Filename: src/views/redirect.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 重定向
#        Create: 2021-08-18 22:36:25
# Last Modified: 2021-10-28 11:19:48
#***********************************************
-->
<script>
  export default {
    created() {
      const { params, query } = this.$route
      const { path } = params
      this.$router.replace({ path: '/' + path, query })
    },
    render: function (h) {
      // avoid warning message
      return h()
    }
  }
</script>
