<!--
#***********************************************
#
#      Filename: src/views/Report/TmpVehicleReport.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 自然日报表
#        Create: 2021-10-28 16:42:53
# Last Modified: 2021-11-11 17:28:55
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @export="handleExport"
      @filter-change="filterChange"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->
    <!-- :summary-method="getSummaries" -->
    <!-- show-summary -->
    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-for="(col, index) in tableHeadFields"
        :key="index"
        :label="col.label"
        :width="col.width"
        :align="col.align"
        :prop="col.prop">
        <template slot-scope="scope">
          <template v-if="index == 2">
            <span style="color: rgb(218, 146, 3); margin-right: 4px; font-size: 16px">&yen;</span>
            <span>{{ scope.row.totalPrice.toFixed(2) }}</span>
          </template>
          <template v-else-if="index == 3">
            <span style="color: rgb(218, 146, 3); margin-right: 4px; font-size: 16px">&yen;</span>
            <span>{{ scope.row.payPrice.toFixed(2) }}</span>
          </template>
          <template v-else-if="index == 4">
            <span style="color: rgb(218, 146, 3); margin-right: 4px; font-size: 16px">&yen;</span>
            <span>{{ scope.row.pendingPrice.toFixed(2) }}</span>
          </template>
          <template v-else>
            {{ scope.row[col.prop] }}
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div class="chart-block">
      <chart-line label="连接时延(ms)" :data="testData" :threshold="30"></chart-line>
    </div> -->
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { list } from '@/api/daily'
  import moment from 'moment'
  // import ChartLine from '@/views/components/myCharts/chartLine.vue'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'TmpVehicleReport',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        // testData: [
        //   {
        //     name: '上海',
        //     data: [
        //       ['2022-08-06 17:20:20', 15],
        //       ['2022-08-06 17:30:20', 20],
        //       ['2022-08-06 17:40:20', 30],
        //       ['2022-08-06 17:50:20', 25],
        //       ['2022-08-06 18:00:20', 35],
        //       ['2022-08-06 18:10:20', 10]
        //     ]
        //   },
        //   {
        //     name: '北京',
        //     data: [
        //       ['2022-08-06 17:20:20', 25],
        //       ['2022-08-06 17:30:20', 10],
        //       ['2022-08-06 17:40:20', 20],
        //       ['2022-08-06 17:50:20', 15],
        //       ['2022-08-06 18:00:20', 30],
        //       ['2022-08-06 18:10:20', 35]
        //     ]
        //   }
        // ],
        total: 0,
        parkingData: [],
        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: false,
            filterToggleShow: true,
            tableHeadToggleShow: false,
            searchPlaceholder: ''
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateTimeRange',
                name: 'dateTimeRange'
              },
              {
                type: 'select',
                name: 'parking',
                placeholder: '请选择停车场',
                options: []
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '停车场',
            isShow: true,
            align: 'left',
            prop: 'PName'
          },
          {
            label: '日期',
            isShow: true,
            width: '260',
            align: 'center',
            prop: 'timeStr'
          },
          {
            label: '总金额（元）',
            isShow: true,
            width: '160',
            align: 'right',
            prop: 'totalPrice'
          },
          {
            label: '线下支付（元）',
            isShow: true,
            width: '160',
            align: 'right',
            prop: 'payPrice'
          },
          {
            label: '线上支付（元）',
            isShow: true,
            width: '160',
            align: 'right',
            prop: 'pendingPrice'
          },
          {
            label: '订单数量',
            isShow: true,
            width: '140',
            align: 'right',
            prop: 'totalCount'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          create: 'report:day:create',
          info: 'report:day:info',
          delete: 'report:day:delete',
          update: 'report:day:update',
          export: 'report:day:export',
          import: 'report:day:import'
        }
      }
    },
    mounted() {
      this.parkingData = JSON.parse(localStorage.getItem('GZ-ParkingList'))
      this.getList()
    },
    methods: {
      getList() {
        this.loading = true
        const repData = []
        this.parkingData.forEach((item) => {
          repData.push({ label: item.name, value: item.id })
        })
        console.log(repData)
        this.settings.dropDownFilterArray.arr[1].options = repData
        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.parkingId = this.filterData.parking
        // this.filterData.searchStr && (queryData.searchStr = this.filterData.searchStr)
        if (this.filterData.dateTimeRange) {
          queryData.startDate = moment(this.filterData.dateTimeRange[0]).format('YYYY-MM-DD')
          queryData.endDate = moment(this.filterData.dateTimeRange[1]).format('YYYY-MM-DD')
        }
        list(queryData)
          .then((res) => {
            this.dataList = res.data.records
            this.total = res.data.total
          })
          .catch((err) => {
            this.noticeError(err.message)
          })
          .finally(() => {
            this.loading = false
          })
      },
      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出统计报表?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.parkingId = this.pid
            queryData.current = 1
            queryData.size = 9999
            this.filterData.searchStr && (queryData.searchStr = this.filterData.searchStr)
            if (this.filterData.dateTimeRange) {
              queryData.startDate = moment(this.filterData.dateTimeRange[0]).format('YYYY-MM-DD')
              queryData.endDate = moment(this.filterData.dateTimeRange[1]).format('YYYY-MM-DD')
            }
            console.log('export queryData:', queryData)
            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.records.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'startTime') {
                        return _this.parseTime(v[j])
                      } else if (j === 'endTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '统计报表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      }
    }
  }
</script>

<style scoped>
  /* .chart-block {
    height: 300px;
  } */
</style>
