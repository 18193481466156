<!--
#***********************************************
#
#      Filename: src/views/Detail/TmpVehicle.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 临停车明细
#        Create: 2021-11-11 14:35:48
# Last Modified: 2021-11-27 21:25:16
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @export="handleExport"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" size="mini" stripe border @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop" />
      <el-table-column
        v-if="tableHeadFields[9].isShow"
        :label="tableHeadFields[9].label"
        :width="tableHeadFields[9].width"
        :align="tableHeadFields[9].align"
        :prop="tableHeadFields[9].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.fromTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[10].isShow"
        :label="tableHeadFields[10].label"
        :width="tableHeadFields[10].width"
        :align="tableHeadFields[10].align"
        :prop="tableHeadFields[10].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.toTime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="200" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-yellow" @click="handlePayRecord(scope.row)">支付记录</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->

    <!-- 支付记录弹框 -->
    <el-dialog
      title="支付记录"
      :visible.sync="showPayRecordModal"
      append-to-body
      width="968px"
      class="gz-common-dialog">
      <el-table :data="payRecord" stripe size="mini">
        <el-table-column label="车牌号" align="center" prop="carNumber" />
        <el-table-column label="停车订单编号" :width="200" align="center" prop="orderId" />
        <el-table-column label="支付金额" :width="100" align="center" prop="amount" />
        <el-table-column label="支付类型" :width="100" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.payType === 6">免费出场</span>
            <span v-if="scope.row.payType === 1">现金+线上支付</span>
            <span v-if="scope.row.payType === 2">现金+优惠支付</span>
            <span v-if="scope.row.payType === 3">优免+线上支付</span>
            <span v-if="scope.row.payType === 7">优免+现金+线上</span>
            <span v-if="scope.row.payType === 4">现金支付</span>
            <span v-if="scope.row.payType === 5">线上支付</span>
            <span v-if="scope.row.payType === 30">优免支付</span>
            <span v-if="scope.row.payType === 34">优惠支付</span>
          </template>
        </el-table-column>
        <el-table-column label="支付时间" :width="200" align="center">
          <template slot-scope="scope">
            <span>
              {{ parseTime(scope.row.payTime) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="支付订单编号" :width="200" align="center" prop="orderNo" />
      </el-table>
    </el-dialog>
    <!-- end 支付记录弹框 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { list, payLog } from '@/api/order'
  import moment from 'moment'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'TmpVehicle',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          communityId: '',
          buildingNumber: '',
          areaNumber: '',
          floor: '',
          number: '',
          remark: '',
          unit: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          communityId: [{ required: true, message: '小区名称不能为空', trigger: 'blur' }],
          areaNumber: [{ required: true, message: '区号不能为空', trigger: 'blur' }],
          buildingNumber: [{ required: true, message: '楼号不能为空', trigger: 'blur' }],
          unit: [{ required: true, message: '单元号不能为空', trigger: 'blur' }],
          floor: [{ required: true, message: '楼层号不能为空', trigger: 'blur' }],
          number: [{ required: true, message: '房间号不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: false,
            searchPlaceholder: '车牌号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateTimeRange',
                name: 'dateRange'
              },
              // {
              //   type: 'select',
              //   name: 'chargeType',
              //   placeholder: '收费类型',
              //   options: [
              //     { label: '免费', value: 0 },
              //     { label: '收费', value: 1 }
              //   ]
              // },
              {
                type: 'select',
                name: 'payType',
                placeholder: '支付类型',
                options: [
                  { label: '免费出场', value: 6 },
                  { label: '现金+线上支付', value: 1 },
                  { label: '现金+优惠支付', value: 2 },
                  { label: '优免+线上支付', value: 3 },
                  { label: '优免+现金+线上', value: 7 },
                  { label: '现金支付', value: 4 },
                  { label: '线上支付', value: 5 },
                  { label: '优免支付', value: 30 },
                  { label: '优惠支付', value: 34 }
                ]
              },
              {
                type: 'select',
                name: 'sluiceState',
                placeholder: '开闸方式',
                options: [
                  { label: '免费开闸', value: 1 },
                  { label: '收费开闸', value: 2 }
                ]
              },
              {
                type: 'select',
                name: 'status',
                placeholder: '是否已出场',
                options: [
                  { label: '已出场', value: 1 },
                  { label: '未出场', value: 4 }
                ]
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '订单编号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'orderId'
          },
          {
            label: '车牌号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carNumber'
          },
          {
            label: '停车时长',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'totalTime'
          },
          {
            label: '应付金额（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'totalPrice'
          },
          {
            label: '现金支付（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'payPrice'
          },
          {
            label: '线上支付（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'pendingPrice'
          },
          {
            label: '优惠/优免（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'discountPrice'
          },
          {
            label: '支付方式',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'payTypeCN'
          },
          {
            label: '开闸方式',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'sluiceStateCN'
          },
          {
            label: '进场时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'fromTime'
          },
          {
            label: '出场时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'toTime'
          }
        ],

        /**
         * 支付记录
         */
        payRecord: [],

        /**
         * 支付记录Modal
         */
        showPayRecordModal: false,

        /**
         * 权限信息
         */
        permissions: {
          export: 'parking:order:tmp:export'
        }
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.pId = this.pid
        queryData.status = this.filterData.status
        queryData.payType = this.filterData.payType
        queryData.chargeType = this.filterData.chargeType || ''
        queryData.sluiceState = this.filterData.sluiceState
        this.filterData.searchStr && (queryData.carNumber = this.filterData.searchStr)
        if (this.filterData.dateRange) {
          queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        }

        list(queryData)
          .then((res) => {
            _this.dataList = res.data
            _this.total = res.total
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 点击查看支付记录
       */
      handlePayRecord(obj) {
        const _this = this
        const queryData = {}
        queryData.pId = this.pid
        queryData.orderId = obj.orderId
        queryData.current = 1
        queryData.size = 999
        payLog(queryData)
          .then((res) => {
            _this.payRecord = res.data
            _this.showPayRecordModal = true
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出临停车列表?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            queryData.current = 1
            queryData.size = 9999
            queryData.pId = this.pid
            queryData.status = this.filterData.status
            queryData.payType = this.filterData.payType
            queryData.chargeType = this.filterData.chargeType
            queryData.sluiceState = this.filterData.sluiceState
            this.filterData.searchStr && (queryData.carNumber = this.filterData.searchStr)
            if (this.filterData.dateRange) {
              queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
              queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
            }
            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'fromTime') {
                        return _this.parseTime(v[j])
                      } else if (j === 'toTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '临停车列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      }
    }
  }
</script>
