<!--
#***********************************************
#
#      Filename: src/views/Report/UnFixedVehiclesReport.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 非固定车位报表
#        Create: 2021-11-11 16:45:04
# Last Modified: 2021-11-12 14:01:19
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :permissions="permissions"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      @filter-change="filterChange"
      @refresh="handleRefresh"
      @export="handleExport" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table
      :data="dataList"
      stripe
      border
      size="mini"
      show-summary
      :summary-method="getSummaries"
      @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop">
        <template slot-scope="scope">
          {{ scope.row.date.slice(0, 10) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { vipReport } from '@/api/vip'
  import moment from 'moment'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'UnFixedVehiclesReport',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          communityId: '',
          buildingNumber: '',
          areaNumber: '',
          floor: '',
          number: '',
          remark: '',
          unit: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          communityId: [{ required: true, message: '小区名称不能为空', trigger: 'blur' }],
          areaNumber: [{ required: true, message: '区号不能为空', trigger: 'blur' }],
          buildingNumber: [{ required: true, message: '楼号不能为空', trigger: 'blur' }],
          unit: [{ required: true, message: '单元号不能为空', trigger: 'blur' }],
          floor: [{ required: true, message: '楼层号不能为空', trigger: 'blur' }],
          number: [{ required: true, message: '房间号不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: false,
            filterToggleShow: true,
            tableHeadToggleShow: false,
            searchPlaceholder: '车位号/产权室号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateRange',
                name: 'dateRange'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          { label: '日期', isShow: true, align: 'center', prop: 'date' },
          {
            label: '实收金额（元）',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'realTotalAmt'
          },
          {
            label: '现金支付（元）',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'offlineAmt'
          },
          {
            label: '线上支付（元）',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'onlineAmt'
          },
          {
            label: '开票金额（元）',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'invoiceAmt'
          }
        ],
        permissions: {
          create: 'report:unfixed:info:create',
          info: 'report:unfixed:info:info',
          delete: 'report:unfixed:info:delete',
          update: 'report:unfixed:info:update',
          export: 'report:unfixed:info:export',
          import: 'report:unfixed:info:import'
        }
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 总计方法
       */
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 1) {
            sums[index] = '总计'
            return
          }
          if (index === 2 || index === 3 || index === 4 || index === 5) {
            const values = data.map((item) => Number(item[column.property]))
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              if (index !== 9) {
                sums[index] += ' 元'
              }
            }
          }
        })
        return sums
      },

      /**
       * 获取列表数据
       */
      getList() {
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.parkingId = this.pid
        queryData.placeType = 2
        this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)
        if (this.filterData.dateRange) {
          queryData.startDate = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD')
          queryData.endDate = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD')
        }

        vipReport(queryData)
          .then((res) => {
            if (res.result) {
              this.dataList = res.data.records
              this.total = res.data.total
            } else {
              this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.error('非固定车位报表查询出错', err)
          })
          .finally(() => {
            this.loading = false
          })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否非导出固定车位统计报表?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.current = 1
            queryData.size = 9999
            queryData.parkingId = this.pid
            queryData.placeType = 2
            this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)
            if (this.filterData.dateRange) {
              queryData.startDate = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD')
              queryData.endDate = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD')
            }
            vipReport(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.records.map((v) => {
                    return filterVal.map((j) => {
                      return v[j]
                    })
                  })
                  export_json_to_excel(tHeader, data, '非固定车位统计报表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      }
    }
  }
</script>
