<!--
#***********************************************
#
#      Filename: src/views/Setting/Monthly.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 车位月租设置
#        Create: 2021-10-28 17:01:21
# Last Modified: 2021-11-30 09:41:45
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增车位类型')"
      @refresh="handleRefresh"
      @export="handleExport"
      @download="handleDownload"
      @import-success="handleImportSuccess"
      @batch-delete="handleBatchDelete('批量删除车位月租设置', handleBatchDel)" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.mode === 1" class="text-navy">&#8226;&nbsp;自然月缴费</span>
          <span v-else-if="scope.row.mode === 2" class="text-teal">&#8226;&nbsp;整月缴费</span>
          <span v-else-if="scope.row.mode === 3" class="text-olive">&#8226;&nbsp;合同缴费</span>
          <span v-else class="text-red">&#8226;&nbsp;未知缴费方式</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '修改车位月租设置')">
              编辑
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除车位月租设置', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->
    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="120px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="车位类型:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请选择车位类型" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="月租费用:" prop="price">
              <el-input v-model="actionDataItem.price" placeholder="请输入月租费用" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="缴费模式:" prop="mode">
              <el-select v-model="actionDataItem.mode" placeholder="请选择缴费模式">
                <el-option label="自然月缴费" :value="1" />
                <el-option label="整月缴费" :value="2" />
                <el-option label="合同缴费" :value="3" />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="到期缴费时限:" prop="delayDays">
              <el-input v-model="actionDataItem.delayDays" placeholder="请输入到期缴费时限" />
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { createType, deleteType, typeList, updateType } from '@/api/vip' // eslint-disable-line

  export default {
    name: 'Monthly',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          name: '',
          price: '',
          mode: '',
          delayDays: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          name: [{ required: true, message: '车位类型不能为空', trigger: 'blur' }],
          price: [{ required: true, message: '月租费用不能为空', trigger: 'blur' }],
          mode: [{ required: true, message: '缴费模式不能为空', trigger: 'blur' }],
          delayDays: [{ required: true, message: '缴费时限不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: false,
            tableHeadToggleShow: false,
            searchPlaceholder: '车位类型'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            importDataShow: false,
            exportDataShow: false,
            downloadTemplateShow: false,
            more: [
              {
                label: '批量删除',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {}
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '车位类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '月租费用（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'price'
          },
          {
            label: '缴费模式',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'mode'
          },
          {
            label: '添加时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'createTime'
          },
          { label: '操作人员', isShow: true, align: 'center', prop: '' },
          {
            label: '添加时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'delayDays'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          create: 'parking:vip:type:create',
          info: 'parking:vip:type:info',
          delete: 'parking:vip:type:delete',
          update: 'parking:vip:type:update'
        }
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.parkingId = this.pid
        this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)

        typeList(queryData)
          .then((res) => {
            if (res.result) {
              _this.dataList = res.data.records
              console.log(_this.dataList, '-----')
              _this.total = res.data.total
            } else {
              _this.noticeError(res.message)
            }
            _this.loading = false
          })
          .catch((err) => {
            console.error('月租设置列表查询出错', err)
            _this.noticeError(err)
            _this.loading = false
          })
      },

      /**
       * 新增按钮操作
       */
      handleAdd() {
        this.resetForm()
        this.open = true
        this.title = '添加账号'
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.modalObject.currentAction === 'edit') {
              const postData = {}
              postData.parkingId = _this.pid
              postData.mode = _this.actionDataItem.mode
              postData.name = _this.actionDataItem.name
              postData.price = _this.actionDataItem.price
              postData.delayDays = _this.actionDataItem.delayDays
              console.log('_this.actionDataItem.delayDays', _this.actionDataItem.delayDays)
              updateType(_this.actionDataItem.id, postData).then((response) => {
                if (response.result) {
                  _this.noticeSuccess(response.message)
                  _this.modalObject.status = false
                  _this.handleRefresh()
                } else {
                  _this.noticeError(response.message)
                  _this.modalObject.status = false
                }
              })
            } else {
              const postData = {}
              postData.parkingId = _this.pid
              postData.mode = _this.actionDataItem.mode
              postData.name = _this.actionDataItem.name
              postData.price = _this.actionDataItem.price
              createType(postData)
                .then((res) => {
                  if (res.result) {
                    _this.noticeSuccess('添加成功')
                    _this.modalObject.status = false
                    _this.handleRefresh()
                  } else {
                    _this.noticeError('添加失败')
                  }
                })
                .catch((err) => {
                  console.log('添加月租设置请求失败', err)
                })
            }
          }
        })
      },
      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteType(id)
          .then((res) => {
            if (res.result) {
              _this.noticeSuccess('删除成功')
              _this.handleRefresh()
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.error('删除车位月租设置失败', err)
          })
      },

      /**
       * 批量删除记录
       */
      handleBatchDel(ids) {
        this.noticeError('暂时没有实现批量删除接口')
        // const _this = this
        // const idArr = []
        // ids.forEach(item => {
        //   idArr.push(item.id)
        // })
        // const postData = {}
        // postData.ids = idArr.join(',')
        // batchDelete(postData).then(res => {
        //   if (res.result) {
        //     _this.msgSuccess('批量删除成功')
        //     _this.handleRefresh()
        //   } else {
        //     _this.msgError(res.message)
        //   }
        // }).catch(err => {
        //   _this.msgError('请求失败')
        //   console.error(err)
        // })
      },

      /**
       * 导出数据
       */
      handleExport() {
        this.msgError('暂时没有实现导出数据接口')
        // const _this = this
        // this.$confirm(
        //   '是否导出房间列表？',
        //   '警告',
        //   {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }
        // ).then(() => {
        //   // 组装查询条件
        //   const queryData = {}
        //   queryData.current = this.currentPage
        //   queryData.size = this.pageSize
        //   queryData.communityId = this.communityId
        //   this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
        //   this.filterData.areaNumber && (queryData.areaNumbr = this.filterData.areaNumber)
        //   this.filterData.buildingNumber && (queryData.buildingNumber = this.filterData.buildingNumber)
        //   this.filterData.unit && (queryData.unit = this.filterData.unit)
        //   this.filterData.number && (queryData.number = this.filterData.number)

        //   exportRecord(queryData)
        //     .then(res => {
        //       if (res.result) {
        //         require.ensure([], () => {
        //           const tHeader = ['姓名', '车牌号', '电话', '创建时间', '备注']
        //           const filterVal = ['name', 'carNumber', 'phone', 'createTime', 'remark']
        //           const list = res.data
        //           const data = _this.formatJson(filterVal, list)
        //           export_json_to_excel(tHeader, data, '白名单列表')
        //         })
        //         _this.msgSuccess('导出成功')
        //       } else {
        //         _this.msgError(res.message)
        //       }
        //     }).catch(err => {
        //       _this.megError('导出失败')
        //       console.error(err)
        //     })
        // }).catch(err => {
        //   console.log('取消导出')
        // })
      },

      /**
       * 下载模板
       */
      handleDownload() {
        this.msgError('没有确定的下载地址')
        // window.open('http://matrixoss.oss-cn-zhangjiakou.aliyuncs.com/OPO/data/template/specialVehicle.xls')
      },

      /**
       * 上传成功
       */
      handleImportSuccess() {
        this.msgSuccess('上传成功')
        this.handleRefresh()
      }
    }
  }
</script>
