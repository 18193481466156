/*
#***********************************************
#
#      Filename: src/api/parkingBank/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 线上支付管理
#        Create: 2021-11-15 11:54:09
# Last Modified: 2021-11-26 14:05:59
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 添加支付方式
export function add(data) {
  const query = qs.stringify(data)
  return request({
    url: '/travelParkingBank/add',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 银行列表
export function bankList(data) {
  return request({
    url: '/travelParkingBank/bank/list',
    method: 'get',
    data: data
  })
}

// 批量删除
export function batchDelete(id) {
  return request({
    url: '/travelParkingBank/batchdel',
    method: 'post'
  })
}

// 删除数据
export function deleteItem(id) {
  return request({
    url: '/travelParkingBank/delete/' + id,
    method: 'post'
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/travelParkingBank/info/' + id,
    method: 'get'
  })
}

// 数据列表
export function list(data) {
  return request({
    url: '/travelParkingBank/list',
    method: 'get',
    params: data
  })
}

// 设置银行
export function setBank(data) {
  return request({
    url: '/travleParkingBank/update',
    method: 'post',
    params: data
  })
}
