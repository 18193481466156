<!--
#***********************************************
#
#      Filename: src/views/Setting/QrCode.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 二维码设置
#        Create: 2021-10-28 16:58:16
# Last Modified: 2021-11-16 17:12:59
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table
      :data="dataList"
      size="mini"
      stripe
      border
      @selection-change="changeSelectItems"
      @expand-change="handleExpandChange">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="gz-qrcode-group">
            <el-empty v-if="props.row.subQrcodes.length === 0" description="暂无配置二维码" />
            <template v-else>
              <el-card v-for="(item, index) in props.row.subQrcodes" :key="index" class="gz-qrcode-card">
                <div slot="header" class="bg-yellow">
                  <i class="el-icon-s-grid text-white">停车场二维码-{{ item.passgesName }}</i>
                </div>
                <el-form class="gz-table-expand" label-width="120px">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="通道名称:">
                        <span class="gz-card-item-des">
                          {{ item.passgesName }}
                        </span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="二维码名称:">
                        <span class="gz-card-item-des">
                          {{ item.codeName }}
                        </span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item label="二维码图片:">
                        <el-image :src="item.image" class="gz-qrcode-img" width="80px" height="80px" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <div class="gz-qrcode-btn-group">
                  <el-button type="success" @click="handleDownload(item)">下载二维码</el-button>
                  <el-button type="danger" @click="handleDelete(props.row.id)">删除二维码</el-button>
                </div>
              </el-card>
            </template>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop">
        <template slot-scope="scope">
          <div v-if="scope.row.type === 1">入口</div>
          <div v-if="scope.row.type === 2">场内</div>
          <div v-if="scope.row.type === 3">出口静态</div>
          <div v-if="scope.row.type === 4">出口动态(模板)</div>
          <div v-if="scope.row.type === 5">月租缴费</div>
          <div v-if="scope.row.type === 6">红包找零</div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.generate]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="handleGenerate(scope.row)">
              生成
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除账号', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { add, batchDelete, deleteItem, download, generate, info, list, subList, update } from '@/api/qrcode' // eslint-disable-line

  export default {
    name: 'PersonAccount',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          pids: '',
          username: '',
          name: '',
          roleName: '',
          phone: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          pids: [{ required: true, message: '所属停车场不能为空', trigger: 'blur' }],
          username: [{ required: true, message: '账号名称不能为空', trigger: 'blur' }],
          roleName: [{ required: true, message: '角色不能为空', trigger: 'blur' }],
          phone: [{ required: true, message: '手机号码不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: false,
            tableHeadToggleShow: false,
            searchPlaceholder: '二维码名称/对应位置'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: false,
            more: [
              {
                label: '批量下载',
                emitEvent: 'batch-download'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '二维码名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'type'
          },
          { label: '说明', isShow: true, align: 'center', prop: 'remark' }
        ],

        /**
         * 是否显示dialog
         */
        open: false,

        /**
         * 权限信息
         */
        permissions: {
          generate: 'settings:qrcode:generate',
          info: 'settings:qrcode:info',
          delete: 'settings:qrcode:delete'
        }
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 获取列表数据
       */
      getList() {
        this.loading = true

        if (this.pid) {
          // 组装查询条件
          const queryData = this.createBaseQueryData()
          this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)

          list(queryData)
            .then((res) => {
              res.data.records.forEach((item) => {
                item.subQrcodes = []
              })
              this.dataList = res.data.records
              this.total = res.data.total
            })
            .catch((err) => {
              console.error('二维码列表查询出错', err)
              this.msgError(err.message)
            })
        }
      },

      /**
       * 新增按钮操作
       */
      handleAdd() {
        this.resetForm()
        this.open = true
        this.title = '添加账号'
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.formData.id !== undefined) {
              update(_this.actionDataItem).then((response) => {
                if (response.result) {
                  _this.msgSuccess(response.message)
                  _this.modalObject.status = false
                  _this.handleRefresh()
                } else {
                  _this.msgError(response.message)
                  _this.modalObject.status = false
                }
              })
            } else {
              add(_this.actionDataItem).then((res) => {
                if (res.result) {
                  _this.msgSuccess(res.message)
                  _this.open = false
                  _this.getList()
                } else {
                  _this.msgError(res.message)
                  _this.open = false
                }
              })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        batchDelete(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      },

      /**
       * 行折叠展开触发事件处理
       */
      handleExpandChange(row, expandRows) {
        if (expandRows.length > 0) {
          this.getSubList(row.id)
        }
      },

      /**
       * 获取子二维码列表
       */
      getSubList(id) {
        const _this = this
        const queryData = {}
        queryData.PId = this.pid
        queryData.supId = id
        subList(queryData)
          .then((res) => {
            const index = _this.dataList.findIndex((item) => {
              return item.id === id
            })
            if (index >= 0) {
              _this.dataList[index].subQrcodes = res.data.records
            }
          })
          .catch((err) => {
            console.error('子二维码列表查询出错', err)
            _this.msgError(err.message)
          })
      },

      /**
       * 生成二维码
       */
      handleGenerate(obj) {
        const queryData = {}
        queryData.pId = this.pid
        queryData.id = obj.id
        generate(queryData)
          .then((res) => {
            this.msgSuccess('生成二维码成功')
            this.getSubList(obj.id)
          })
          .catch((err) => {
            console.error('生成失败', err)
          })
      },

      /**
       * 下载二维码
       */
      handleDownload(obj) {
        const a = document.createElement('a')
        a.download = name || 'pic'
        a.href = obj.image
        a.click()
      },

      /**
       * 删除二维码
       */
      handleDelete(id) {
        this.$confirm('是否确认删除此二维码？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            queryData.pId = this.pid
            queryData.supId = id
            deleteItem(queryData)
              .then((res) => {
                this.msgSuccess('删除二维码成功')
                this.getSubList(id)
              })
              .catch((err) => {
                console.error('删除失败', err)
              })
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }
</script>
<style scoped lang="scss">
  .gz-qrcode-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    .gz-qrcode-card {
      width: 100%;
      margin: 20px 300px 20px 50px;
      ::v-deep .el-card__header {
        background: #f39c12;
      }
    }
  }

  .gz-table-expand {
    font-size: 12px;
    padding-left: 20px;

    .gz-card-item-des {
      opacity: 0.6;
    }
  }
  .gz-qrcode-img {
    width: 80px;
    height: 80px;
  }
  .gz-qrcode-btn-group {
    width: 100%;
    text-align: center;
  }
</style>
