/*
#***********************************************
#
#      Filename: src/api/room/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 房间相关api接口
#        Create: 2021-11-10 15:27:39
# Last Modified: 2021-11-27 21:16:36
#***********************************************
*/
import request from '@/utils/request'

// 获取车位余量信息
export function getInfo(data) {
  return request({
    url: 'place/plate/total',
    method: 'get',
    params: data
  })
}

// 修改车位信息
export function updateVacancy(data) {
  return request({
    url: 'place/plate/update/num',
    method: 'put',
    data: data
  })
}
