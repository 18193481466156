<!--
#***********************************************
#
#      Filename: src/views/Setting/Equipment.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 操作设备管理
#        Create: 2021-10-28 16:59:39
# Last Modified: 2021-11-29 17:47:14
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增设备')"
      @export="handleExport"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" size="mini" stripe border @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.dateline) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.dateline) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="success">启用</el-tag>
          <el-tag v-else type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop">
        <template slot-scope="scope">
          <!-- {{ scope.row.updateAid || scope.row.createAid }} -->
          {{ formOperName(scope.row.operId) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '修改设备')">
              编辑
            </div>
            <div
              v-if="scope.row.status === 1"
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-red"
              @click="changeStatusClose(scope.row)">
              禁用
            </div>
            <div
              v-else
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-green"
              @click="changeStatusOpen(scope.row)">
              启用
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除进出口设备', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备名称:" prop="deviceName">
              <el-input v-model="actionDataItem.deviceName" placeholder="请输入设备名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备标识:" prop="deviceCode">
              <el-input v-model="actionDataItem.deviceCode" placeholder="请输入设备标识" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="呼叫号码:" prop="sip">
              <el-input v-model="actionDataItem.sip" placeholder="请输入呼叫号码" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { add, batchDelete, close, list, review, update } from '@/api/equipment' // eslint-disable-line
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'Equipment',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          deviceName: '',
          deviceCode: '',
          sip: '',
          deleted: 0
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          deviceName: [{ required: true, message: '设备名称不能为空', trigger: 'blur' }],
          deviceCode: [{ required: true, message: '设备标识不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: ''
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: false,
            more: [
              {
                label: '批量删除',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateRange',
                name: 'dateRange'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '设备名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'deviceName'
          },
          {
            label: '设备标识',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'deviceCode'
          },
          {
            label: '呼叫号码',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'sip'
          },
          {
            label: '添加时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'dateline'
          },
          {
            label: '更新时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'updateline'
          },
          {
            label: '状态',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'status'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'username'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          create: 'settings:client-device:create',
          info: 'settings:client-device:info',
          delete: 'settings:client-device:delete',
          update: 'settings:client-device:update',
          export: 'settings:client-device:export'
        }
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        if (!this.pid) {
          this.noticeError('未选定停车场')
          return
        }

        // 组装查询条件
        const queryData = this.createBaseQueryData()
        queryData.PId = this.pid
        this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)

        list(queryData)
          .then((res) => {
            console.log(res)
            _this.dataList = res.data.records
            _this.total = res.data.total
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            _this.actionDataItem.pId = _this.pid
            if (_this.modalObject.currentAction === 'edit') {
              update(_this.actionDataItem)
                .then((res) => {
                  _this.noticeSuccess('更新信息成功')
                  _this.handleRefresh()
                  _this.modalObject.status = false
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                  _this.modalObject.status = false
                })
                .finally(() => {
                  _this.modalObject.status = false
                  _this.modalObject.status = false
                })
            } else {
              add(_this.actionDataItem)
                .then((res) => {
                  _this.noticeSuccess('添加设备信息成功')
                  _this.getList()
                  _this.modalObject.status = false
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                  _this.modalObject.status = false
                })
                .finally(() => {
                  _this.actionDataItem.status = false
                  _this.modalObject.status = false
                })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        batchDelete(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      },
      changeStatusOpen(obj) {
        const _this = this
        const postData = {}
        postData.id = obj.id
        postData.status = 1
        review(postData)
          .then((res) => {
            _this.noticeSuccess('操作成功')
            _this.handleRefresh()
          })
          .catch((e) => {
            _this.noticeError(e.message)
            _this.handleRefresh()
          })
      },
      changeStatusClose(obj) {
        const _this = this
        const postData = {}
        postData.id = obj.id
        postData.status = 0
        close(postData)
          .then((res) => {
            _this.noticeSuccess('操作成功')
            _this.handleRefresh()
          })
          .catch((e) => {
            _this.noticeError(e.message)
            _this.handleRefresh()
          })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出操作设备列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            queryData.PId = this.pid
            this.filterData.searchKey && (queryData.kw = this.filterData.searchKey)

            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'dateline' || j === 'updateline') {
                        return _this.parseTime(v[j])
                      } else if (j === 'status') {
                        return ['启用', '禁用'][v[j]]
                      }
                      return v[j]
                    })
                  })
                  export_json_to_excel(tHeader, data, '操作设备列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
                console.log('操作设备列表请求失败:', err.message)
              })
          })
          .catch((err) => {
            console.log('操作设备列表失败:', err.message)
          })
      }
    }
  }
</script>
