<!--
#***********************************************
#
#      Filename: src/views/Setting/ShiftChange.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 自动换班管理
#        Create: 2021-10-28 16:53:42
# Last Modified: 2021-10-28 16:54:07
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增换班', beforeAdd)"
      @export="handleExport"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop">
        <template slot-scope="scope">
          {{ '星期' + ['一', '二', '三', '四', '五', '六', '日'][scope.row.weeks - 1] }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.dateline) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[9].isShow"
        :label="tableHeadFields[9].label"
        :width="tableHeadFields[9].width"
        :align="tableHeadFields[9].align"
        :prop="tableHeadFields[9].prop">
        <template slot-scope="scope">
          {{ ['一班倒', '二班倒', '三班倒'][scope.row.changeType - 1] }}
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '修改换班', beforeEdit)">
              编辑
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除换班', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="880px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="120px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="日期:" prop="weeks">
              <el-select v-model="actionDataItem.weeks" placeholder="请选择日期">
                <el-option label="星期一" :value="1" />
                <el-option label="星期二" :value="2" />
                <el-option label="星期三" :value="3" />
                <el-option label="星期四" :value="4" />
                <el-option label="星期五" :value="5" />
                <el-option label="星期六" :value="6" />
                <el-option label="星期日" :value="7" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="岗亭名称:" prop="name">
              <el-select v-model="actionDataItem.name" placeholder="请选择岗亭列表">
                <el-option v-for="(item, index) in sentryBoxList" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="倒班类型:" prop="changeType">
              <el-select v-model="actionDataItem.changeType" placeholder="请选择倒班类型">
                <el-option label="一班倒" :value="1" />
                <el-option label="两班倒" :value="2" />
                <el-option label="三班倒" :value="3" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="换班时间一:" prop="firstTime">
              <el-time-picker v-model="actionDataItem.firstTime" placeholder="请选择换班时间" value-format="HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="值班人员一:" prop="morName">
              <el-select v-model="actionDataItem.morName" placeholder="请选择值班人员">
                <el-option v-for="(item, index) in subList" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="actionDataItem.changeType === 2 || actionDataItem.changeType === 3" :gutter="20">
          <el-col :span="12">
            <el-form-item label="换班时间二:" prop="secondTime">
              <el-time-picker
                v-model="actionDataItem.secondTime"
                placeholder="请选择换班时间"
                value-format="HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="值班人员二:" prop="midName">
              <el-select v-model="actionDataItem.midName" placeholder="请选择值班人员">
                <el-option v-for="(item, index) in subList" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="actionDataItem.changeType === 3" :gutter="20">
          <el-col :span="12">
            <el-form-item label="换班时间三:" prop="thirdTime">
              <el-time-picker v-model="actionDataItem.thirdTime" placeholder="请选择换班时间" value-format="HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="值班人员三:" prop="eveName">
              <el-select v-model="actionDataItem.eveName" placeholder="请选择值班人员">
                <el-option v-for="(item, index) in subList" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { add, deleteItem, list, update } from '@/api/autoShift'
  import { list as sentryList } from '@/api/sentryBox'
  import { list as subaccountList } from '@/api/person'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'ShiftChange',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          changeType: '',
          eveName: '',
          firstTime: '',
          midName: '',
          morName: '',
          name: '',
          operId: '',
          pId: '',
          secondTime: '',
          thirdTime: '',
          weeks: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          weeks: [{ required: true, message: '日期不能为空', trigger: 'blur' }],
          name: [{ required: true, message: '岗亭名称不能为空', trigger: 'blur' }],
          changeType: [{ required: true, message: '倒班类型不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: false,
            tableHeadToggleShow: true,
            searchPlaceholder: '姓名/账号名称/电话号码'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            more: []
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '星期',
            isShow: true,
            width: '150',
            align: 'center',
            prop: 'weeks'
          },
          {
            label: '岗亭名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '第一次换班时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'firstTime'
          },
          {
            label: '班次一',
            isShow: true,
            width: '150',
            align: 'center',
            prop: 'morName'
          },
          {
            label: '第二次换班时间',
            isShow: true,
            width: '150',
            align: 'center',
            prop: 'secondTime'
          },
          {
            label: '班次二',
            isShow: true,
            width: '150',
            align: 'center',
            prop: 'midName'
          },
          {
            label: '第三次换班时间',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'thirdTime'
          },
          {
            label: '班次三',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'eveName'
          },
          {
            label: '创建时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'dateline'
          },
          {
            label: '倒班类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'changeType'
          }
        ],

        /**
         * 开发时用的数据
         */
        dataList: [],

        /**
         * 是否显示dialog
         */
        open: false,

        /**
         * 角色列表信息
         */
        roleList: [],

        /**
         * 岗亭列表
         */
        sentryBoxList: [],

        /**
         * 停车场下的子账号列表
         */
        subList: [],

        /**
         * 权限信息
         */
        permissions: {
          create: 'autoshift:create',
          info: 'autoshift:info',
          delete: 'autoshift:delete',
          update: 'autoshift:update',
          export: 'autoshift:export'
        }
      }
    },

    mounted() {
      this.getList()
      this.getSentryBoxList()
      this.getSubaccountList()
    },

    methods: {
      /**
       * 编辑账号预处理
       */
      beforeEdit(data) {
        const currentSentryBox = this.sentryBoxList.find((item) => item.name === data.name)
        this.actionDataItem.name = currentSentryBox.id
        const morName = this.subList.find((item) => {
          return item.username === data.morName
        })
        if (morName) {
          this.actionDataItem.morName = morName.id
        }
        const midName = this.subList.find((item) => {
          return item.username === data.midName
        })
        if (midName) {
          this.actionDataItem.midName = midName.id
        }
        const eveName = this.subList.find((item) => {
          return item.username === data.eveName
        })
        if (eveName) {
          this.actionDataItem.eveName = eveName.id
        }
      },

      /**
       * 获取岗亭列表
       */
      getSentryBoxList() {
        const _this = this
        const queryData = {}
        queryData.pId = this.pid
        sentryList(queryData)
          .then((res) => {
            _this.sentryBoxList = res.data.records
          })
          .catch((e) => {
            _this.noticeError(e.message)
          })
      },

      /**
       * 获取停车场下的子账号列表
       */
      getSubaccountList() {
        console.log('..////')
        const _this = this
        const queryData = {}
        queryData.pId = this.pid
        subaccountList(queryData)
          .then((res) => {
            console.log(res, '////')
            _this.subList = res.data.records
          })
          .catch((e) => {
            _this.noticeError(e.message)
          })
      },

      /**
       * 获取列表数据
       */
      getList() {
        if (!this.pid) {
          this.noticeError('未选定停车场')
          return
        }
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.pId = this.pid
        this.filterData.searchKey && (queryData.kw = this.filterData.searchKey)

        list(queryData)
          .then((res) => {
            console.log(res)
            _this.dataList = res.data.records
            _this.total = res.data.total
          })
          .catch((err) => {
            _this.noticeError(err.message)
            console.error('子账号列表查询出错', err)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 新增按钮操作
       */
      handleAdd() {
        this.resetForm()
        this.open = true
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      beforeAdd() {
        this.actionDataItem = {}
      },
      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.modalObject.currentAction === 'edit') {
              const postData = {}
              postData.id = _this.actionDataItem.id
              postData.changeType = _this.actionDataItem.changeType
              postData.firstTime = _this.actionDataItem.firstTime
              postData.secondTime = _this.actionDataItem.secondTime
              postData.thirdTime = _this.actionDataItem.thirdTime
              postData.midName = _this.actionDataItem.midName
              postData.morName = _this.actionDataItem.morName
              postData.eveName = _this.actionDataItem.eveName
              postData.name = _this.actionDataItem.name
              postData.operId = _this.uid
              postData.pId = _this.pid
              postData.weeks = _this.actionDataItem.weeks
              update(postData)
                .then((res) => {
                  _this.noticeSuccess('更新信息成功')
                  _this.handleRefresh()
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
                .finally(() => {
                  _this.modalObject.status = false
                })
            } else {
              const postData = {}
              postData.changeType = _this.actionDataItem.changeType
              postData.eveName = _this.actionDataItem.eveName
              postData.firstTime = _this.actionDataItem.firstTime
              postData.secondTime = _this.actionDataItem.secondTime
              postData.thirdTime = _this.actionDataItem.thirdTime
              postData.midName = _this.actionDataItem.midName
              postData.morName = _this.actionDataItem.morName
              postData.eveName = _this.actionDataItem.eveName
              postData.name = _this.actionDataItem.name
              postData.operId = _this.uid
              postData.pId = _this.pid
              postData.weeks = _this.actionDataItem.weeks
              console.log(postData)
              add(postData)
                .then((res) => {
                  _this.noticeSuccess('添加自动换班信息成功')
                  _this.handleRefresh()
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
                .finally((_this.modalObject.status = false))
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 格式停车场
       */
      formatParking(obj) {
        let formatedStr = ''
        if (obj.relationParkings && obj.relationParkings.length > 0) {
          obj.relationParkings.forEach((item, index) => {
            if (index === 0) {
              formatedStr += item.name
            } else {
              formatedStr += ',' + item.name
            }
          })
        }
        return formatedStr
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出自动换班列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.current = 1
            queryData.size = 999
            queryData.pId = this.pid
            this.filterData.searchKey && (queryData.kw = this.filterData.searchKey)
            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = _this.formatJson(filterVal, res.data)
                  export_json_to_excel(tHeader, data, '自动换班列表')
                })
                _this.noticeSuccess('自动换班成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
                console.log('导出自动换班列表请求失败:', err.message)
              })
          })
          .catch((err) => {
            console.log('导出自动换班列表失败:', err.message)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-date-editor {
    width: 100%;
  }
</style>
