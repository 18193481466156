<!--
#***********************************************
#
#      Filename: src/views/List/PropertyFeeManagement.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 物业费管理名单
#        Create: 2022-09-04 16:40:15
# Last Modified: 2022-09-04 16:40:15
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :permissions="permissions"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      @filter-change="filterChange"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />
      <el-table-column label="操作" :width="200" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '物业费续费', beforeEdit)">
              缴费
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.mchId, '删除', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog"
      @close="handleDialogClose">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="商户名称:" prop="name">
              <el-input v-model="actionDataItem.name" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商铺全号:" prop="fullNumber">
              <el-input v-model="actionDataItem.fullNumber" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-divider />
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="缴费时长:" prop="chargeMonths">
              <el-input v-model="actionDataItem.chargeMonths" placeholder="请输入月数" @input="stdValueChange" />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="应缴金额:" prop="amount">
              <el-input v-model="actionDataItem.amount" />
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <div style="margin-top: 10px; font-size: 14px">元</div>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开始时间:" prop="startDate">
              <el-date-picker v-model="actionDataItem.startDate" type="date" placeholder="选择日期" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间:" prop="endDate">
              <el-date-picker v-model="actionDataItem.endDate" type="date" placeholder="选择日期" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">保 存</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { shopList } from '@/api/shop'
  import { chargeProperty, propertyPreCalc } from '@/api/merchantList'

  export default {
    name: 'PropertyFeeManager',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          name: '',
          limitPrice: '',
          level: '',
          address: '',
          phone: '',
          settleDiscountScale: 1,
          yearExemption: '',
          minConsumePrice: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          // name: [{ required: true, message: '商户名称不能为空', trigger: 'blur' }],
          // limitPrice: [{ required: true, message: '减免金额不能为空', trigger: 'blur' }],
          // minConsumePrice: [{ required: true, message: '门槛金额不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '商户名称/优免主题'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: false,
            importDataShow: false,
            more: [
              {
                label: '批量删除',
                permission: 'delete',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'input',
                name: 'name',
                placeholder: '请输入商户名称'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '商铺号',
            isShow: true,
            width: '150',
            align: 'center',
            prop: 'number'
          },
          {
            label: '商铺全号',
            isShow: true,
            width: '250',
            align: 'center',
            prop: 'fullNumber'
          },
          {
            label: '面积（㎡）',
            isShow: true,
            width: '150',
            align: 'center',
            prop: 'areaTotal'
          },
          {
            label: '商铺状态',
            isShow: true,
            width: '100',
            align: 'center',
            prop: 'status'
          },
          {
            label: '区域号',
            isShow: true,
            width: '100',
            align: 'center',
            prop: 'areaNumber'
          },
          {
            label: '楼栋号',
            isShow: true,
            width: '100',
            align: 'center',
            prop: 'buildingNumber'
          },
          {
            label: '产权所属',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'signed'
          },
          {
            label: '备注',
            isShow: true,
            width: '150',
            align: 'center',
            prop: 'remark'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {}
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * dialog关闭触发事件
       */
      handleDialogClose() {
        this.$refs.form.resetFields()
      },

      /**
       * 编辑前执行
       */
      beforeEdit(data) {
        console.log('------')
        this.actionDataItem.id = data.id // 商铺ID
        this.actionDataItem.fullNumber = data.fullNumber // 商铺全号
        this.actionDataItem.name = data.signMchInfo.name // 商户名称
      },

      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.communityId = this.communityId
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        shopList(queryData)
          .then((res) => {
            if (res.result) {
              _this.dataList = res.data.records
              _this.total = res.data.total
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            const postData = {
              chargeShops: [
                {
                  additionItems: [
                    {
                      monthlyPrice: 0,
                      name: '扩展项1',
                      subAmt: 0
                    }
                  ],
                  amount: _this.actionDataItem.amount,
                  chargeMonths: _this.actionDataItem.chargeMonths,
                  shopId: _this.actionDataItem.id,
                  startDate: _this.actionDataItem.startDate
                }
              ],
              opAid: 0,
              remark: 'remark',
              totalAmt: _this.actionDataItem.amount
            }
            chargeProperty(_this.communityId, postData)
              .then((res) => {
                _this.noticeSuccess('缴费成功')
                _this.modalObject.status = false
                _this.handleRefresh()
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          }
        })
      },

      /**
       * 预计算物业费
       */
      getPreCalcProperty() {
        const _this = this
        this.loading = true
        // 组装查询条件
        const queryData = {}
        queryData.chargeShops = [
          {
            additionItems: [
              {
                monthlyPrice: 0,
                name: '扩展项1'
              }
            ],
            chargeMonths: _this.actionDataItem.chargeMonths,
            shopId: _this.actionDataItem.id,
            startDate: ''
          }
        ]
        propertyPreCalc(_this.communityId, queryData)
          .then((res) => {
            if (res.result) {
              _this.actionDataItem.amount = res.data.totalAmt
              _this.actionDataItem.startDate = res.data.chargeShops[0].startDate
              _this.actionDataItem.endDate = res.data.chargeShops[0].endDate
              // _this.actionDataItem.chargeMonths = res.data.chargeMonths
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 月数改变
       */
      stdValueChange() {
        this.getPreCalcProperty()
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .el-input-number {
    width: 100%;
  }
  ::v-deep .el-input__inner {
    text-align: left;
  }
</style>
