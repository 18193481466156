/*
#***********************************************
#
#      Filename: src/api/attendance/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 考勤相关API
#        Create: 2021-11-29 09:21:07
# Last Modified: 2021-11-29 15:04:07
#***********************************************
*/
import request from '@/utils/request'

// 信息列表
export function list(data) {
  return request({
    url: '/sys/work/list',
    method: 'get',
    params: data
  })
}

// 信息详情
export function info(id) {
  return request({
    url: '/sys/work/info/' + id,
    method: 'get'
  })
}
