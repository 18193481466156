<!--
#***********************************************
#
#      Filename: src/views/Record/AccessRecord.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 车辆进出记录
#        Create: 2021-10-28 14:10:27
# Last Modified: 2021-11-18 16:10:32
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @export="handleExport"
      @filter-change="filterChange"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe size="mini" border @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.fromTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          <el-popover v-if="scope.row.cloudEnterPicture" placement="right" width="400" trigger="click">
            <i slot="reference" class="el-icon-picture-outline-round">点击显示</i>
            <el-image
              :src="scope.row.cloudEnterPicture + '?x-oss-process=image/resize,m_fill,w_900,h_500'"
              :preview-src-list="[scope.row.cloudEnterPicture]" />
          </el-popover>
          <i v-else class="el-icon-warning-outline">暂无图片</i>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop">
        <template slot-scope="scope">
          {{ scope.row.toTime === 0 ? '-' : parseTime(scope.row.toTime) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop">
        <template slot-scope="scope">
          <el-popover v-if="scope.row.cloudExitPicture" placement="right" width="400" trigger="click">
            <i slot="reference" class="el-icon-picture-outline-round">点击显示</i>
            <el-image
              :src="scope.row.cloudExitPicture + '?x-oss-process=image/resize,m_fill,w_900,h_500'"
              :preview-src-list="[scope.row.cloudExitPicture]" />
          </el-popover>
          <i v-else class="el-icon-warning-outline">暂无图片</i>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.chargeType === 0" class="text-green">&#9679;免费</span>
          <span v-if="scope.row.chargeType === 1" class="text-yellow">&#9679;收费</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.payType === 6" class="text-green">&#9679;免费出场</span>
          <span v-if="scope.row.payType === 1" class="text-yellow">&#9679;现金+线上支付</span>
          <span v-if="scope.row.payType === 2" class="text-aqua">&#9679;现金+优惠支付</span>
          <span v-if="scope.row.payType === 3" class="text-blue">&#9679;优免+线上支付</span>
          <span v-if="scope.row.payType === 7" class="text-black">&#9679;优免+现金+线上</span>
          <span v-if="scope.row.payType === 4" class="text-teal">&#9679;现金支付</span>
          <span v-if="scope.row.payType === 5" class="text-olive">&#9679;线上支付</span>
          <span v-if="scope.row.payType === 30" class="text-orange">&#9679;优免支付</span>
          <span v-if="scope.row.payType === 34" class="text-fuchsia">&#9679;优惠支付</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[9].isShow"
        :label="tableHeadFields[9].label"
        :width="tableHeadFields[9].width"
        :align="tableHeadFields[9].align"
        :prop="tableHeadFields[9].prop" />
      <el-table-column
        v-if="tableHeadFields[10].isShow"
        :label="tableHeadFields[10].label"
        :width="tableHeadFields[10].width"
        :align="tableHeadFields[10].align"
        :prop="tableHeadFields[10].prop" />
      <el-table-column
        v-if="tableHeadFields[11].isShow"
        :label="tableHeadFields[11].label"
        :width="tableHeadFields[11].width"
        :align="tableHeadFields[11].align"
        :prop="tableHeadFields[11].prop" />
      <el-table-column
        v-if="tableHeadFields[12].isShow"
        :label="tableHeadFields[12].label"
        :width="tableHeadFields[12].width"
        :align="tableHeadFields[12].align"
        :prop="tableHeadFields[12].prop" />
      <el-table-column
        v-if="tableHeadFields[13].isShow"
        :label="tableHeadFields[13].label"
        :width="tableHeadFields[13].width"
        :align="tableHeadFields[13].align"
        :prop="tableHeadFields[13].prop" />
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { list, exportRecord } from '@/api/order' // eslint-disable-line
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')
  import moment from 'moment'

  export default {
    name: 'AccessRecord',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          communityId: '',
          buildingNumber: '',
          areaNumber: '',
          floor: '',
          number: '',
          remark: '',
          unit: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          communityId: [{ required: true, message: '小区名称不能为空', trigger: 'blur' }],
          areaNumber: [{ required: true, message: '区号不能为空', trigger: 'blur' }],
          buildingNumber: [{ required: true, message: '楼号不能为空', trigger: 'blur' }],
          unit: [{ required: true, message: '单元号不能为空', trigger: 'blur' }],
          floor: [{ required: true, message: '楼层号不能为空', trigger: 'blur' }],
          number: [{ required: true, message: '房间号不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: false,
            searchPlaceholder: '车牌号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateTimeRange',
                name: 'dateRange'
              },
              {
                type: 'checkbox',
                name: 'isSync',
                label: '是否同步'
              },
              {
                type: 'select',
                name: 'chargeType',
                placeholder: '收费类型',
                options: [
                  { label: '免费', value: 0 },
                  { label: '收费', value: 1 }
                ]
              },
              {
                type: 'select',
                name: 'payType',
                placeholder: '支付类型',
                options: [
                  { label: '免费出场', value: 6 },
                  { label: '现金+线上支付', value: 1 },
                  { label: '现金+优惠支付', value: 2 },
                  { label: '优免+线上支付', value: 3 },
                  { label: '优免+现金+线上', value: 7 },
                  { label: '现金支付', value: 4 },
                  { label: '线上支付', value: 5 },
                  { label: '优免支付', value: 30 },
                  { label: '优惠支付', value: 34 }
                ]
              },
              {
                type: 'select',
                name: 'sluiceState',
                placeholder: '开闸方式',
                options: [
                  { label: '免费开闸', value: 1 },
                  { label: '收费开闸', value: 2 }
                ]
              },
              {
                type: 'select',
                name: 'status',
                placeholder: '是否已出场',
                options: [
                  { label: '已出场', value: 1 },
                  { label: '未出场', value: 4 }
                ]
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '车牌号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carNumber'
          },
          {
            label: '订单编号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'orderId'
          },
          {
            label: '进场时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'fromTime'
          },
          {
            label: '进场图片',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'cloudEnterPicture'
          },
          {
            label: '出场时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'toTime'
          },
          {
            label: '出场图片',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'cloudExitPicture'
          },
          {
            label: '停车时长',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'totalTime'
          },
          {
            label: '收费类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'chargeTypeCN'
          },
          {
            label: '支付方式',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'payTypeCN'
          },
          {
            label: '开闸方式',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'sluiceStateCN'
          },
          {
            label: '应收金额(元)',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'totalPrice'
          },
          {
            label: '现金支付(元)',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'payPrice'
          },
          {
            label: '线上支付(元)',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'pendingPrice'
          },
          {
            label: '优免/优惠(元)',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'discountPrice'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          export: 'parking:order:export'
        }
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 获取列表数据
       */
      getList() {
        if (this.pid) {
          // 组装查询条件
          this.loading = true
          const queryData = {}
          queryData.current = this.currentPage
          queryData.size = this.pageSize
          queryData.pId = this.pid
          queryData.type = this.filterData.type
          if (this.filterData.chargeType !== undefined) {
            queryData.chargeType = this.filterData.chargeType
          }
          this.filterData.searchStr && (queryData.carNumber = this.filterData.searchStr)
          this.filterData.payType && (queryData.payType = this.filterData.payType)
          this.filterData.sluiceState && (queryData.sluiceState = this.filterData.sluiceState)
          this.filterData.status && (queryData.status = this.filterData.status)
          if (this.filterData.dateRange) {
            queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
            queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
          }

          list(queryData)
            .then((res) => {
              this.dataList = res.data
              this.total = res.total
            })
            .catch((err) => {
              this.noticeError(err.message)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出车辆进出记录？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.current = 1
            queryData.size = 9999
            queryData.pId = this.pid
            queryData.type = this.filterData.type
            if (this.filterData.chargeType !== undefined) {
              queryData.chargeType = this.filterData.chargeType
            }
            this.filterData.searchStr && (queryData.carNumber = this.filterData.searchStr)
            this.filterData.payType && (queryData.payType = this.filterData.payType)
            this.filterData.sluiceState && (queryData.sluiceState = this.filterData.sluiceState)
            this.filterData.status && (queryData.status = this.filterData.status)
            if (this.filterData.dateRange) {
              queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
              queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
            }

            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'fromTime') {
                        return _this.parseTime(v[j])
                      } else if (j === 'toTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '车辆进出记录')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      }
    }
  }
</script>
