<!--
#***********************************************
#
#      Filename: src/views/Person/Account.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 账号管理
#        Create: 2021-11-04 08:42:26
# Last Modified: 2021-11-10 22:06:17
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增账号')"
      @export="handleExport"
      @refresh="handleRefresh">
      <!--
      <template #custom-left>
        <el-checkbox
          v-model="filterData.isSync"
          size="mini"
        >
          是否同步
        </el-checkbox>
      </template>
      -->
    </ContentHeader>
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop">
        <template slot-scope="scope">
          {{ formatRole(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          {{ scope.row.phone && scope.row.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3') }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop">
        <template slot-scope="scope">
          <el-radio v-model="scope.row.status" :label="1">
            {{ scope.row.status === 1 ? '启用' : '停用' }}
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop">
        <template slot-scope="scope">
          <el-switch :value="scope.row.sync" size="mini" />
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop">
        <template slot-scope="scope">
          <div class="nowrap" :title="formatParking(scope.row)">
            {{ formatParking(scope.row) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.dateline) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop" />
      <el-table-column label="操作" :width="400" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '修改账号', beforeEdit)">
              编辑
            </div>
            <div
              class="gz-text-btn"
              :class="scope.row.status === 1 ? 'gz-text-btn-red' : 'gz-text-btn-green'"
              @click="changeStatus(scope.row)">
              {{ scope.row.status === 1 ? '停用' : '启用' }}
            </div>
            <div class="gz-text-btn gz-text-btn-light-blue" @click="handleChangePwd(scope.row)">修改密码</div>
            <div class="gz-text-btn gz-text-btn-pink" @click="handleResetPwd(scope.row)">重置密码</div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除账号', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="880px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="所属停车场:" prop="pids">
              <el-select v-model="actionDataItem.pids" multiple placeholder="请选择所属停车场">
                <el-option v-for="item in parkingList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="账号名称:" prop="username">
              <el-input v-model="actionDataItem.username" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名:" prop="name">
              <el-input v-model="actionDataItem.name" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属角色:" prop="roleid">
              <el-select v-model="actionDataItem.roleid" multiple placeholder="请选择">
                <el-option v-for="item in roleList" :key="item.value" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码:" prop="phone">
              <el-input v-model="actionDataItem.phone" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="" prop="isSync">
              <el-checkbox v-model="actionDataItem.isSync">同步至本地</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 修改密码dialog -->
    <el-dialog title="修改密码" :visible.sync="changePwdObj.show" append-to-body width="500px" class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="changePwdObj.data" :rules="changePwdObj.rules">
        <el-row :gutter="20">
          <el-form-item label="旧密码:" prop="oldPassword">
            <el-input v-model="changePwdObj.data.oldPassword" placeholder="请输入旧密码" show-password />
          </el-form-item>
        </el-row>
        <el-row :gutter="20">
          <el-form-item label="新密码:" prop="newPassword">
            <el-input v-model="changePwdObj.data.newPassword" placeholder="请输入新密码" show-password />
          </el-form-item>
        </el-row>
        <el-row :gutter="20">
          <el-form-item label="密码确认:" prop="confirmPassword">
            <el-input v-model="changePwdObj.data.confirmPassword" placeholder="请确认您要修改的密码" show-password />
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleChangePwdConfirm">确 定</el-button>
        <el-button @click="handelChangePwdCancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { changePwd, resetPwd, addSubAccount, deleteItem, list, update } from '@/api/person'
  import { list as roleList } from '@/api/role'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'Account',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          pids: '',
          username: '',
          name: '',
          roleid: '',
          phone: '',
          isSync: false
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          pids: [{ required: true, message: '所属停车场不能为空', trigger: 'blur' }],
          name: [
            { required: true, message: '姓名不能为空', trigger: 'blur' },
            { min: 2, max: 30, message: '姓名限2-30个字符', trigger: 'blur' }
          ],
          username: [
            { required: true, message: '账号名称不能为空', trigger: 'blur' },
            { min: 2, max: 30, message: '账号名称限2-30个字符', trigger: 'blur' }
          ],
          roleid: [{ required: true, message: '角色不能为空', trigger: 'blur' }],
          phone: [{ pattern: /^1[3|5|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'change' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: false,
            tableHeadToggleShow: true,
            searchPlaceholder: '姓名/账号名称/电话号码'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            more: []
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '账号名称',
            isShow: true,
            width: '150',
            align: 'center',
            prop: 'username'
          },
          {
            label: '姓名',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '所属角色',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'roles'
          },
          {
            label: '手机号码',
            isShow: true,
            width: '150',
            align: 'center',
            prop: 'phone'
          },
          {
            label: '状态',
            isShow: true,
            width: '150',
            align: 'center',
            prop: 'status'
          },
          {
            label: '同步到本地',
            isShow: true,
            width: '150',
            align: 'center',
            prop: 'sync'
          },
          {
            label: '所属停车场',
            isShow: true,
            width: '300',
            align: 'center',
            prop: 'relationParkings'
          },
          {
            label: '创建时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'dateline'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'createSaInfo.name'
          }
        ],

        /**
         * 修改密码对话框
         */
        changePwdObj: {
          show: false,
          data: {
            id: '',
            newPassword: '',
            oldPassword: ''
          },
          rules: {
            newPassword: [{ required: true, message: '新密码不能为空', trigger: 'blur' }],
            oldPassword: [{ required: true, message: '旧密码不能为空', trigger: 'blur' }],
            confirmPassword: [
              { required: true, message: '确认密码不能为空', trigger: 'blur' },
              {
                validator: (rule, value, callback) => {
                  if (value !== this.changePwdObj.data.newPassword) {
                    callback(new Error('两次输入的密码不一致'))
                  } else {
                    callback()
                  }
                },
                trigger: 'blur'
              }
            ]
          }
        },

        /**
         * 是否显示dialog
         */
        open: false,

        /**
         * 角色列表信息
         */
        roleList: [],

        /**
         * 权限信息
         */
        permissions: {
          create: 'employee:create',
          info: 'employee:info',
          delete: 'employee:delete',
          update: 'employee:update',
          export: 'employee:export'
        }
      }
    },

    mounted() {
      this.getRoleList()
      this.getList()
    },

    methods: {
      /**
       * 重置密码
       */
      handleResetPwd(obj) {
        const _this = this
        this.$confirm('是否重置账号密码？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const id = obj.id
            const postData = {}
            postData.id = id
            postData.newPassword = '123456'
            resetPwd(id, postData)
              .then((res) => {
                _this.noticeSuccess('重置密码成功')
              })
              .catch((e) => {
                _this.noticeError(e.message)
              })
          })
          .catch((e) => {
            console.log(e.message)
          })
      },

      /**
       * 编辑账号预处理
       */
      beforeEdit(data) {
        this.actionDataItem.id = data.id
        const pids = []
        if (data.relationParkings && data.relationParkings.length > 0) {
          data.relationParkings.forEach((item) => pids.push(item.id))
        }
        const roleid = []
        if (data.roles && data.roles.length > 0) {
          data.roles.forEach((item) => roleid.push(item.id))
        }
        this.actionDataItem.pids = pids
        this.actionDataItem.roleid = roleid
        this.actionDataItem.isSync = data.sync
      },

      /**
       * 获取角色列表
       */
      getRoleList() {
        const _this = this
        const queryData = {}
        roleList(queryData)
          .then((res) => {
            _this.roleList = res.data.records
          })
          .catch((err) => {
            _this.noticeError(err.message)
            console.error('角色列表查询出错', err)
          })
      },

      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        // this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        this.filterData.searchStr && (queryData.kw = this.filterData.searchStr)

        list(queryData)
          .then((res) => {
            _this.dataList = res.data.records
            console.log(_this.dataList)
            const data = JSON.stringify(_this.dataList)
            localStorage.setItem('operData', data)
            // localStorage.setItem('operData', JSON.string )
            _this.total = res.data.total
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
          .finally(() => {
            // _this.loading = false
          })
      },

      /**
       * 新增按钮操作
       */
      handleAdd() {
        this.resetForm()
        this.open = true
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getRoleList()
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.modalObject.currentAction === 'edit') {
              const postData = {}
              // 这里取得是当前停车场的entId，如果停车场是多选的请后端注意对应关系
              postData.enable = true
              postData.mobile = _this.actionDataItem.phone
              postData.username = _this.actionDataItem.username
              postData.name = _this.actionDataItem.name
              postData.parkingIds = _this.actionDataItem.pids
              postData.roleIds = _this.actionDataItem.roleid
              postData.syncToLocal = _this.actionDataItem.isSync
              postData.userType = 2
              update(_this.actionDataItem.id, postData)
                .then((res) => {
                  console.log(postData)
                  if (res.result) {
                    _this.noticeSuccess('更新信息成功')
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(res.message)
                  }
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
                .finally(() => {
                  _this.modalObject.status = false
                })
            } else {
              const postData = {}
              postData.enable = true
              postData.mobile = _this.actionDataItem.phone
              postData.username = _this.actionDataItem.username
              postData.name = _this.actionDataItem.name
              postData.opSaId = _this.uid
              postData.parkingIds = _this.actionDataItem.pids
              // 这里设置初始密码为123456
              postData.password = '123456'
              postData.roleIds = _this.actionDataItem.roleid
              postData.syncToLocal = _this.actionDataItem.isSync
              // 1.物业总账号 2.物业子账号
              postData.userType = 2
              addSubAccount(postData)
                .then((res) => {
                  if (res.result) {
                    _this.noticeSuccess('添加子账号成功')
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(res.message)
                  }
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
                .finally((_this.modalObject.status = false))
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 格式停车场
       */
      formatParking(obj) {
        let formatedStr = ''
        if (obj.relationParkings && obj.relationParkings.length > 0) {
          obj.relationParkings.forEach((item, index) => {
            if (index === 0) {
              formatedStr += item.name
            } else {
              formatedStr += ',' + item.name
            }
          })
        }
        return formatedStr
      },

      /**
       * 格式角色
       */
      formatRole(obj) {
        let formatedStr = ''
        if (obj.roles && obj.roles.length > 0) {
          obj.roles.forEach((item, index) => {
            if (index === 0) {
              formatedStr += item.name
            } else {
              formatedStr += ',' + item.name
            }
          })
        }
        return formatedStr
      },

      /**
       * 开启修改密码对话框
       */
      handleChangePwd(obj) {
        this.changePwdObj.show = true
        this.changePwdObj.data.id = obj.id
      },

      /**
       * 修改密码对话框取消
       */
      handelChangePwdCancel() {
        this.changePwdObj.show = false
      },

      /**
       * 修改密码对话框确定
       */
      handleChangePwdConfirm() {
        const _this = this
        this.$confirm('是否修改账号密码？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            _this.actionChangePwd()
          })
          .catch((e) => {
            console.log(e.message)
          })
      },

      /**
       * 修改密码
       */
      actionChangePwd() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            const formData = {}
            formData.id = this.changePwdObj.data.id
            formData.newPassword = this.changePwdObj.data.newPassword
            formData.oldPassword = this.changePwdObj.data.oldPassword
            changePwd(this.changePwdObj.data.id, formData)
              .then((res) => {
                _this.noticeSuccess('修改成功')
                _this.changePwdObj.show = false
              })
              .catch((e) => {
                _this.noticeError(e.message)
              })
          }
        })
      },

      /**
       * 停用启用按钮点击事件
       */
      changeStatus(obj) {
        const _this = this
        const postData = {}
        const pids = []
        if (obj.relationParkings && obj.relationParkings.length > 0) {
          obj.relationParkings.forEach((item) => pids.push(item.id))
        }
        const roleid = []
        if (obj.roles && obj.roles.length > 0) {
          obj.roles.forEach((item) => roleid.push(item.id))
        }
        postData.enable = obj.status !== 1
        postData.mobile = obj.phone
        postData.username = obj.username
        postData.name = obj.name
        postData.parkingIds = pids
        postData.roleIds = roleid
        postData.syncToLocal = obj.sync
        postData.userType = 2
        update(obj.id, postData)
          .then((res) => {
            if (res.result) {
              _this.noticeSuccess('更改账号状态成功')
              _this.handleRefresh()
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((e) => {
            _this.noticeError(e.message)
          })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出账号列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            queryData.current = 1
            queryData.size = 999
            this.filterData.searchKey && (queryData.kw = this.filterData.searchKey)
            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.records.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'roles') {
                        return _this.formatRole(v)
                      } else if (j === 'relationParkings') {
                        return _this.formatRole(v)
                      } else if (j === 'status') {
                        return v.status === 1 ? '启用' : '停用'
                      } else if (j === 'dateline') {
                        return _this.parseTime(v.dateline)
                      } else {
                        return v[j]
                      }
                    })
                  })

                  export_json_to_excel(tHeader, data, '账号列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
                console.log('导出账号列表请求失败:', err.message)
              })
          })
          .catch((err) => {
            console.log('导出账号列表失败:', err.message)
          })
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .el-radio__label {
    font-size: 12px;
  }
  ::v-deep .el-switch__core::after {
    width: 12px;
    height: 12px;
  }
  ::v-deep .el-switch__core {
    height: 16px;
  }
  ::v-deep .el-switch {
    height: 14px;
    line-height: 14px;
  }
</style>
