<!--
#***********************************************
#
#      Filename: src/views/List/RentManagement.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 租金管理名单
#        Create: 2022-09-04 16:40:15
# Last Modified: 2022-09-04 16:40:15
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :permissions="permissions"
      :total-item="total"
      @filter-change="filterChange"
      @refresh="handleRefresh"
      @export="handleExport"
      @import-success="handleImportSuccess" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column label="" width="50" type="expand">
        <template slot-scope="props">
          <el-table border style="width: 100%" :data="props.row.refShops" size="medium">
            <el-table-column label="商铺全号" prop="fullNumber" width="200" />
            <el-table-column label="楼栋ID" prop="buildingId" width="200" />
            <el-table-column label="商铺面积" prop="areaTotal" width="200" />
            <el-table-column label="楼层" prop="floor" width="200" />
            <el-table-column label="单元号" prop="unit" width="200" />
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop">
        <template slot-scope="scope">
          {{ formOperName(scope.row.opAid) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="200" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '租金续费', beforeEdit)">
              缴费
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.mchId, '删除优免', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog"
      @close="handleDialogClose">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="商户名称:" prop="name">
              <el-input v-model="actionDataItem.name" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-divider />
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="缴费时长:" prop="chargeMonths">
              <el-input v-model="actionDataItem.chargeMonths" placeholder="请输入月数" @input="stdValueChange" />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="应缴金额:" prop="amount">
              <el-input v-model="actionDataItem.amount" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <div style="margin-top: 10px; font-size: 14px">元</div>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开始时间:" prop="startDate">
              <el-date-picker v-model="actionDataItem.startDate" type="date" placeholder="选择日期" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间:" prop="endDate">
              <el-date-picker v-model="actionDataItem.endDate" type="date" placeholder="选择日期" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">保 存</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { rentalList, chargeRental, rentalPreCalc } from '@/api/merchantList'
  import moment from 'moment'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'RentManagement',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        formData: {
          id: '',
          name: '',
          area: '',
          period: '',
          amount: '',
          begin: '',
          end: ''
        },
        /**
         * 商户列表
         */
        merchantList: [],

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          name: [{ required: true, message: '商户名称不能为空', trigger: 'blur' }],
          limitPrice: [{ required: true, message: '减免金额不能为空', trigger: 'blur' }],
          minConsumePrice: [{ required: true, message: '门槛金额不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '商户名称/优免主题'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: [
              {
                label: '批量删除',
                permission: 'delete',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'input',
                name: 'name',
                placeholder: '请输入商户名称'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '商户名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'mchInfo.name'
          },
          {
            label: '租金单价(元/月)',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'rentPerMonth'
          },
          {
            label: '签约月数',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'periodMonths'
          },
          {
            label: '开始时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'periodStart'
          },
          {
            label: '结束时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'periodEnd'
          },
          {
            label: '缴费状态',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'rentFeeStatus'
          },
          {
            label: '缴费时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'updateTime'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'opAid'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          create: 'comsume-limit:bind',
          info: 'comsume-limit:info',
          delete: 'comsume-limit:unbind',
          update: 'comsume-limit:bind',
          export: 'comsume-limit:export',
          import: 'comsume-limit:import'
        }
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * dialog关闭触发事件
       */
      handleDialogClose() {
        this.$refs.form.resetFields()
      },

      /**
       * 编辑前执行
       */
      beforeEdit(data) {
        this.actionDataItem.id = data.id
        this.actionDataItem.name = data.mchInfo.name
        this.actionDataItem.startDate = data.periodStart
        this.actionDataItem.endDate = data.periodEnd
        this.actionDataItem.chargeMonths = 0
      },

      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true
        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.placeType = 2
        this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)
        if (this.filterData.dateRange) {
          queryData.payStartTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD')
          queryData.payEndTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD')
        }
        rentalList(this.communityId, queryData)
          .then((res) => {
            if (res.result) {
              _this.dataList = res.data.records
              _this.total = res.data.total
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            const postData = {}
            postData.amount = _this.actionDataItem.amount
            postData.chargeMonths = _this.actionDataItem.chargeMonths
            postData.remark = 'remark'
            postData.startDate = _this.actionDataItem.startDate
            chargeRental(_this.actionDataItem.id, postData)
              .then((res) => {
                _this.noticeSuccess('缴费成功')
                _this.modalObject.status = false
                _this.handleRefresh()
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          }
        })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出租金名单?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.current = this.currentPage
            queryData.size = this.pageSize
            queryData.parkingId = this.pid
            this.filterData.searchStr && (queryData.kw = this.filterData.searchStr)
            rentalList(this.communityId, queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.orders.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'startTime') {
                        return _this.parseTime(v[j])
                      } else if (j === 'endTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '租金名单列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 预计算租金费
       */
      getPreCalcRent() {
        const _this = this
        this.loading = true
        // 组装查询条件
        const queryData = {}
        queryData.chargeMonths = _this.actionDataItem.chargeMonths
        rentalPreCalc(_this.actionDataItem.id, queryData)
          .then((res) => {
            if (res.result) {
              _this.actionDataItem.amount = res.data.amount
              _this.actionDataItem.startDate = res.data.startDate
              _this.actionDataItem.endDate = res.data.endDate
              // _this.actionDataItem.chargeMonths = res.data.chargeMonths
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 月数改变
       */
      stdValueChange() {
        this.getPreCalcRent()
      },

      /**
       * 上传成功
       */
      handleImportSuccess() {
        this.noticeSuccess('上传成功')
        this.handleRefresh()
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .el-input-number {
    width: 100%;
  }
  ::v-deep .el-input__inner {
    text-align: left;
  }
</style>
