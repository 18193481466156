<!--
#***********************************************
#
#      Filename: src/views/List/BlackList.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 黑名单
#        Create: 2021-10-28 16:38:32
# Last Modified: 2021-11-29 18:02:17
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-for="(col, index) in tableHeadFields"
        :key="index"
        :label="col.label"
        :width="col.width"
        :align="col.align"
        :prop="col.prop">
        <template slot-scope="scope">
          <template v-if="col.prop == 'status'">
            {{ scope.row.status | eventTypeLabel }}
          </template>
          <template v-else-if="col.prop == 'dateline'">
            {{ parseTime(scope.row.dateline) || '--' }}
          </template>
          <template v-else>
            {{ scope.row[col.prop] ? scope.row[col.prop] : '--' }}
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  const eventType = [
    {
      value: 1,
      label: '信息1'
    },
    {
      value: 2,
      label: '信息2'
    }
  ]
  export default {
    name: 'BlackList',

    components: { ContentHeader },

    filters: {
      eventTypeLabel(value) {
        const obj = eventType.find((item) => item.value === value)
        console.log(obj, value)
        if (obj && Object.keys(obj).length > 0) {
          return obj.label
        } else {
          return '--'
        }
      }
    },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            searchPlaceholder: '标识符'
          },
          right: {
            refreshShow: true,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'select',
                name: 'status',
                placeholder: '事件类型',
                options: eventType
              },
              {
                type: 'dateTimeRange',
                name: 'dateline',
                placeholder: '时间'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '功能名称',
            isShow: true,
            width: '260',
            align: 'center',
            prop: 'name'
          },
          {
            label: '标识符',
            isShow: true,
            width: '260',
            align: 'center',
            prop: 'identifier'
          },
          {
            label: '事件类型',
            isShow: true,
            width: '260',
            align: 'center',
            prop: 'status'
          },
          { label: '输出参数', isShow: true, align: 'center', prop: 'remark' },
          {
            label: '更新时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'dateline'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {},

        /**
         * 事件类型
         */
        eventType: eventType
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 获取列表数据
       */
      getList() {
        if (this.pid) {
          this.loading = true

          // 组装查询条件
          let queryData = {}
          queryData.pId = this.pid
          queryData.current = this.currentPage
          queryData.size = this.pageSize
          this.filterData.searchStr && (queryData.identifier = this.filterData.searchStr)
          if (Object.keys(this.filterData).length > 0) {
            queryData = { ...queryData, ...this.filterData }
          }
          console.log('--queryData----', queryData)

          setTimeout(() => {
            this.dataList = [{ status: 1 }, { status: 2, dateline: '2024-04-07' }, {}]
            this.total = 12
            this.loading = false
          }, 100)

          // list(queryData)
          //   .then((res) => {
          //     this.dataList = res.data.records
          //     this.total = res.data.total
          //   })
          //   .catch((err) => {
          //     this.noticeError(err.message)
          //   })
          //   .finally(() => {
          //     this.loading = false
          //   })
        }
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      }
    }
  }
</script>
<style></style>
