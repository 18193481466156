/*
#***********************************************
#
#      Filename: src/api/person/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 系统人员管理相关接口
#        Create: 2021-11-05 11:35:57
# Last Modified: 2021-11-26 14:08:04
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 添加人员
export function addSubAccount(data) {
  return request({
    url: '/subaccount/add',
    method: 'post',
    data: data
  })
}

// 删除角色
export function deleteItem(id) {
  return request({
    url: '/subaccount/delete/' + id,
    method: 'post'
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/subaccount/info/' + id,
    method: 'get'
  })
}

// 数据列表
export function list(data) {
  return request({
    url: '/subaccount/list',
    method: 'get',
    params: data
  })
}

// 修改密码
export function changePwd(id, data) {
  const postData = qs.stringify(data)
  return request({
    url: '/subaccount/changePwd/' + id,
    method: 'post',
    data: postData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 重置密码
export function resetPwd(id, data) {
  const postData = qs.stringify(data)
  return request({
    url: '/subaccount/resetPwd/' + id,
    method: 'post',
    data: postData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 更新账号
export function update(id, data) {
  return request({
    url: '/subaccount/update/' + id,
    method: 'post',
    data: data
  })
}
