/*
#***********************************************
#
#      Filename: src/api/equipment/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 操作设备管理相关api
#        Create: 2021-11-17 11:42:45
# Last Modified: 2021-11-26 13:57:44
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 添加设备
export function add(data) {
  const query = qs.stringify(data)
  return request({
    url: '/opDevice/add',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 设备禁用
export function close(data) {
  const query = qs.stringify(data)
  return request({
    url: '/opDevice/close',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 删除设备
export function deleteItem(id, data) {
  return request({
    url: '/opDevice/delete/' + id,
    method: 'post',
    params: data
  })
}

// index
export function index() {
  return request({
    url: '/opDevice/index',
    method: 'get'
  })
}

// info
export function info(id) {
  return request({
    url: '/opDevice/info/' + id,
    method: 'get'
  })
}

// list
export function list(data) {
  return request({
    url: '/opDevice/list',
    method: 'get',
    params: data
  })
}

// 审核
export function review(data) {
  const query = qs.stringify(data)
  return request({
    url: '/opDevice/review',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 更新
export function update(data) {
  const query = qs.stringify(data)
  return request({
    url: '/opDevice/update',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}
