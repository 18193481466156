/*
#***********************************************
#
#      Filename: src/api/person/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 车位销售明细相关接口
#        Create: 2023-06-13 11:35:57
# Last Modified: 2023-06-13 14:08:04
#***********************************************
*/
import request from '@/utils/request' // 封装好的axios

// 表格信息
export function list(data) {
  return request({
    url: '/sellinfo/receiveInfo/list',
    method: 'get',
    params: data
  })
}
// 点击保存按钮
export function saveReceiveInfoItem(data) {
  return request({
    url: '/sellinfo/receiveInfo/item',
    method: 'post',
    params: data
  })
}
// 收退款项xialakuang
export function getSellInfoItemList(id) {
  return request({
    url: `/sellinfo/sellinfo/item/list/${id}`,
    method: 'get'
  })
}
// 账单详情
export function detail(data) {
  return request({
    url: '/sellinfo/receiveInfo/item/list',
    method: 'get',
    params: data
  })
}

// 获取操作人员
export function getReport(data) {
  return request({
    url: '/sellinfo/oper',
    method: 'get',
    params: data
  })
}
