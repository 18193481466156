<!--
#***********************************************
#
#      Filename: src/views/index.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 首页
#        Create: 2021-08-18 22:12:58
# Last Modified: 2021-11-15 16:38:38
#***********************************************
-->
<template>
  <div class="app-container home">
    <el-row :gutter="24">
      <el-col :span="16">
        <el-card class="content-container elevation-2">
          <h2>工作台</h2>
          <span>当前版本:v1.4.1.20240711</span>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card v-if="false" class="content-container elevation-2">
          <h3>更新记录</h3>
          <span>当前版本:v1.4.1.20240711</span>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  // import MuMoreSelect from '../../components/muMoreSelect/index.vue'
  export default {
    name: 'Index',
    // components: { MuMoreSelect },
    data() {
      return {
        aaa: ''
      }
    }
  }
</script>
<style lang="scss" scope>
  .app-container {
    line-height: 32px;
    span {
      font-weight: 600;
    }
    .content-container {
      min-height: 78vh;
    }
  }
</style>
