<!--
#***********************************************
#
#      Filename: src/views/List/Owner.vue
#
#        Author: xiaowang 1839648563@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 业主列表
#        Create: 2023-07-17 15:10:51
# Last Modified: 2021-11-28 16:17:36
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      :import-actions="importActions"
      @filter-change="filterChange"
      @add="add('新增业主', beforeAdd)"
      @refresh="handleRefresh"
      @export="handleExport"
      @download="handleDownload"
      @import-success="handleImportSuccess"
      @batch-delete="handleBatchDelete('批量删除业主', handleBatchDel)">
      <div slot="custom-left">
        <el-button type="primary" size="mini" plain @click="handleImportRecord">导入记录</el-button>
      </div>
    </ContentHeader>
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table stripe :data="dataList" size="mini" border>
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-for="(item, index) in tableHeadFields"
        v-show="item.isShow"
        :key="index"
        :label="item.label"
        :width="item.width"
        :align="item.align"
        :prop="item.prop">
        <template slot-scope="scope">
          <template v-if="index == 2">
            {{ formatMobile(scope.row.mobile) }}
          </template>
          <template v-else>
            {{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="300" align="center">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              class="gz-text-btn gz-text-btn-blue"
              @click="openDrawer(scope.row, '住宅添加', beforeHouse(scope.row))">
              住宅
            </div>
            <div
              class="gz-text-btn gz-text-btn-green"
              @click="openDrawer(scope.row, '商铺添加', beforeShop(scope.row))">
              商铺
            </div>
            <div class="gz-text-btn gz-text-btn-yellow" @click="edit(scope.row, '修改业主', beforeEdit(scope.row, 1))">
              编辑
            </div>
            <div class="gz-text-btn gz-text-btn-red" @click="handleDelete(scope.row.id, '删除业主', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog"
      @close="handleClose">
      <el-form ref="form" label-width="100px" :model="dialogFormData">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="业主姓名:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请输入业主姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话号码:" prop="mobile">
              <el-input v-model="actionDataItem.mobile" placeholder="请输入电话号码" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-divider />
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注:" prop="remark">
              <el-input v-model="actionDataItem.remark" type="textarea" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->
    <!-- 住宅商铺添加 -->
    <el-drawer
      :title="drawerObject.title"
      :visible.sync="drawerObject.status"
      :direction="direction"
      size="70%"
      class="drawers">
      <el-form
        v-show="drawerObject.title == '住宅添加'"
        class="demo-form-inline"
        size="mini"
        label-width="90px"
        :rules="{}">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="选择房间：">
              <SelectPicker
                key="housePicker"
                v-model="houseForm.communityName"
                :options="houseListData"
                :loading="loading"
                :placeholder="'请选择住宅'"
                :has-all="false"
                @visibleChange="visibleChange"
                @loadMore="loadMore"
                @remoteMethod="remoteMethod"
                @change="change"></SelectPicker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="成员类型:">
              <el-select placeholder="请选择成员类型" v-model="memberTypeValues">
                <el-option v-for="item in memberTypes" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">添加</el-button>
        </el-form-item>
      </el-form>
      <el-form
        v-show="drawerObject.title == '商铺添加'"
        class="demo-form-inline"
        size="mini"
        label-width="90px"
        :rules="{}">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="选择商铺：">
              <SelectPicker
                key="shopPicker"
                v-model="shopForm.communityName"
                :options="shopListData"
                :loading="loadings"
                :placeholder="'请选择商铺'"
                :has-all="false"
                @visibleChange="visibleChanges"
                @loadMore="loadMores"
                @remoteMethod="remoteMethods"
                @change="changes"></SelectPicker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="成员类型:">
              <el-select v-model="memberTypeValue" placeholder="请选择成员类型">
                <el-option v-for="item in memberTypes" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="onSubmits">添加</el-button>
        </el-form-item>
      </el-form>
      <el-table v-if="drawerObject.title == '住宅添加'" :data="houseList" size="mini" stripe border class="tables">
        <el-table-column
          v-for="(it, ind) in houseTableOptions"
          v-show="it.isShow"
          :key="ind"
          :label="it.label"
          :width="it.width"
          :align="it.align"
          :prop="it.prop">
          <template slot-scope="scope">
            <template v-if="ind == 8">
              <div v-if="scope.row.type == 1" size="mini" type="success">业主</div>
              <div v-if="scope.row.type == 2" size="mini" type="warning">随住</div>
              <div v-if="scope.row.type == 3" size="mini" type="danger">租户</div>
            </template>
            <template v-else>
              {{ getCurrentAttr(scope.row, it.prop) }}
            </template>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="100" align="center">
          <template slot-scope="scope">
            <div class="gz-text-btn-group">
              <div class="gz-text-btn gz-text-btn-red" @click="handleDeleteHouse(scope.row)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-else :data="shopList" size="mini" stripe border class="tables">
        <el-table-column
          v-for="(it, ind) in shopTableOptions"
          v-show="it.isShow"
          :key="ind"
          :label="it.label"
          :width="it.width"
          :align="it.align"
          :prop="it.prop">
          <template slot-scope="scope">
            <template v-if="ind == 8">
              <div v-if="scope.row.ownerType == 1" size="mini" type="success">业主</div>
              <div v-if="scope.row.ownerType == 2" size="mini" type="warning">随住</div>
              <div v-if="scope.row.ownerType == 3" size="mini" type="danger">租户</div>
            </template>
            <template v-else>
              {{ getCurrentAttr(scope.row, it.prop) }}
            </template>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="100" align="center">
          <template slot-scope="scope">
            <div class="gz-text-btn-group">
              <div class="gz-text-btn gz-text-btn-red" @click="handleDeleteShop(scope.row)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
    <!-- end 住宅商铺添加 -->
    <!-- 导入记录dialog -->
    <el-dialog
      title="导入记录"
      :visible.sync="importInfoDialog.show"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-table :data="importInfoDialog.data" size="mini" stripe border>
        <el-table-column label="批次号" :width="200" align="center" prop="id" />
        <el-table-column label="开始时间" :width="200" align="center" prop="createTime" />
        <el-table-column label="状态" :width="200" align="center" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">
              <i class="el-icon-time" />
            </span>
            <span v-if="scope.row.status === 1">
              <i class="el-icon-loading" />
            </span>
            <span v-if="scope.row.status === 2" style="color: green">
              <i class="el-icon-circle-check" />
            </span>
          </template>
        </el-table-column>
        <el-table-column label="完成时间" :width="200" align="center" prop="finishTime" />
        <el-table-column label="导入总数(条)" :width="200" align="center" prop="total" />
        <el-table-column label="成功数(条)" :width="200" align="center" prop="success" />
        <el-table-column label="失败数(条)" :width="200" align="center" prop="success">
          // eslint-disable-next-line vue/no-unused-vars
          <template slot-scope="scope">
            <span>
              {{ scope.row.total - scope.row.success > 0 ? scope.row.total - scope.row.success : '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="200" align="center" prop="success">
          <template slot-scope="scope">
            <span v-if="scope.row.total - scope.row.success <= 0 || scope.row.status !== 2">--</span>
            <span v-else class="gz-fail-export" @click="handleFailExport(scope.row.id)">导出失败数据</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页模块 -->
      <pagination
        v-show="importInfoDialog.total > 0"
        :total="importInfoDialog.total"
        :page.sync="importInfoDialog.current"
        :limit.sync="importInfoDialog.size" />
      <!-- end 分页模块 -->
    </el-dialog>
    <!-- end 导入记录dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import DrawerMixins from '@/views/mixins/drawer'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import SelectPicker from '@/components/selectPicker'
  import { getAttr } from '@/utils/propData'
  import {
    list,
    deleteItem,
    update,
    create,
    importBatchList,
    exportErrorRecords,
    OwnerHouseList,
    checkOwnerHouse,
    OwnerHouse,
    deleteHouse,
    OwnerShopList,
    checkOwnerShop,
    OwnerShop,
    deleteShop,
    exportExcel
  } from '@/api/owner'

  export default {
    name: 'Owner',

    components: { ContentHeader, SelectPicker },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo, DrawerMixins],

    data() {
      return {
        // 住宅列表
        houseListData: [],
        // 商铺列表
        shopListData: [],
        // 主列表
        dataList: [],
        // 成员类型
        memberTypes: [
          { id: '1', name: '业主', label: '业主' },
          { id: '2', name: '随住', label: '随住' },
          { id: '3', name: '租户', label: '租户' }
        ],
        // 抽屉
        drawer: false,
        direction: 'rtl',
        // contentHeader组件配置
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '业主姓名'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            downloadTemplateShow: true,
            importDataShow: true,
            more: [
              // {
              //   label: '批量删除',
              //   emitEvent: 'batch-delete'
              // }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'input',
                name: 'mobile',
                placeholder: '请填写电话'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          { label: '业主姓名', isShow: true, width: '200', align: 'center', prop: 'name' },
          { label: '小区名称', isShow: true, width: '200', align: 'center', prop: 'communityName' },
          { label: '手机号码', isShow: true, width: '200', align: 'center', prop: 'mobile' },
          { label: '备注', isShow: true, align: 'center', prop: 'remark' }
        ],
        // 房屋下拉显示
        houseList: [],
        // 房屋列表
        houseTableOptions: [
          { label: '房间编号', isShow: true, width: '150', align: 'center', prop: 'houseInfo.fullNumber' },
          { label: '建筑面积', isShow: true, width: '150', align: 'center', prop: 'houseInfo.areaTotal' },
          { label: '室内面积', isShow: true, width: '150', align: 'center', prop: 'houseInfo.areaInside' },
          { label: '小区名称', isShow: true, width: '150', align: 'center', prop: 'houseInfo.communityName' },
          { label: '区号', isShow: true, width: '100', align: 'center', prop: 'houseInfo.areaNumber' },
          { label: '楼号', isShow: true, width: '100', align: 'center', prop: 'houseInfo.buildingNumber' },
          { label: '单元号', isShow: true, width: '100', align: 'center', prop: 'houseInfo.buildingNumber' },
          { label: '房间号', isShow: true, align: 'center', prop: 'houseInfo.floor' },
          { label: '成员类型', isShow: true, align: 'center', prop: 'type' }
        ],
        // 商铺下拉显示
        shopList: [],
        // 商铺列表
        shopTableOptions: [
          { label: '房间编号', isShow: true, width: '150', align: 'center', prop: 'shopInfo.fullNumber' },
          { label: '建筑面积', isShow: true, width: '150', align: 'center', prop: 'shopInfo.areaTotal' },
          { label: '室内面积', isShow: true, width: '150', align: 'center', prop: 'shopInfo.areaInside' },
          { label: '小区名称', isShow: true, width: '150', align: 'center', prop: 'shopInfo.communityName' },
          { label: '区号', isShow: true, width: '100', align: 'center', prop: 'shopInfo.areaNumber' },
          { label: '楼号', isShow: true, width: '100', align: 'center', prop: 'shopInfo.buildingNumber' },
          { label: '单元号', isShow: true, width: '100', align: 'center', prop: 'shopInfo.buildingNumber' },
          { label: '房间号', isShow: true, align: 'center', prop: 'shopInfo.floor' },
          { label: '成员类型', isShow: true, align: 'center', prop: 'ownerType' }
        ],
        /**
         * 查看导入对话框控制对象
         */
        importInfoDialog: {
          show: false,
          current: 1,
          size: 10,
          total: 0,
          data: []
        },

        dialogFormData: {
          id: 34,
          name: 'hello',
          tableData: [
            {
              name: 'name1',
              id: 'id1',
              unit: 'unit1',
              content: '',
              createDate: '',
              updateDate: ''
            },
            {
              name: 'name2',
              id: 'id2',
              unit: 'unit1',
              content: '',
              createDate: '',
              updateDate: ''
            }
          ]
        },
        // 房屋下拉搜索
        searchKw: '',
        // 商铺下拉搜素
        searchKws: '',
        // 房屋模糊搜索
        houseForm: {
          id: '',
          communityName: ''
        },
        // 商铺模糊搜索
        shopForm: {
          id: '',
          communityName: ''
        },
        selectHouseForm: {
          page: 1,
          size: 100,
          name: ''
        },
        selectShopForm: {
          page: 1,
          size: 100,
          name: ''
        },
        loading: false,
        loadings: false,
        searchHouse: null,
        searchShop: null,
        memberTypeValues: '',
        memberTypeValue: '',
        ownerID: '',
        ownerIDs: '',
        permissions: {
          create: 'housing:owner:info:create',
          info: 'housing:owner:info:info',
          delete: 'housing:owner:info:delete',
          update: 'housing:owner:info:update',
          export: 'housing:owner:info:export',
          import: 'housing:owner:info:import'
        }
      }
    },

    computed: {
      // 导入地址
      importActions() {
        return process.env.VUE_APP_BASE_API + '/housing/owner/batchImport/' + this.currentParking.communityId
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      getCurrentAttr(obj, prop) {
        return getAttr(obj, prop)
      },

      inits: async function (query) {
        this.loadings = true
        // 获取选项数据，后端需支持 page  size  name搜索
        const res = await OwnerShopList({
          current: this.selectShopForm.page,
          size: this.selectShopForm.size,
          communityId: this.communityId,
          searchKw: query
        })
        this.shopListData = res.data.records
        this.loadings = false
      },
      changes(value) {
        this.searchShop = value
      },
      visibleChanges(status) {},
      loadMores(page) {
        this.selectShopForm.page = page
        this.inits()
      },
      remoteMethods(query, page) {
        this.searchKws = query
        this.selectShopForm.page = page
        this.inits(query)
      },

      init: async function (query) {
        this.loading = true
        // 获取选项数据，后端需支持 page  size  name搜索
        const res = await OwnerHouseList({
          current: this.selectHouseForm.page,
          size: this.selectHouseForm.size,
          communityId: this.communityId,
          searchKw: query
        })
        this.houseListData = res.data.records
        this.loading = false
      },
      change(value) {
        this.searchHouse = value
      },
      visibleChange(status) {},
      loadMore(page) {
        this.selectHouseForm.page = page
        this.init()
      },
      remoteMethod(query, page) {
        this.searchKw = query
        this.selectHouseForm.page = page
        this.init(query)
      },

      // 打开住宅弹框
      beforeHouse(data) {
        this.ownerID = data.id
        this.getRoomList(data)
        this.getHouse()
      },

      beforeAdd() {
        this.actionDataItem = {}
      },

      // 获取房间信息
      getRoomList(data) {
        const queryData = {}
        queryData.communityId = this.communityId
        queryData.current = 1
        queryData.size = 100
        queryData.searchKw = this.searchKw
        OwnerHouseList(queryData)
          .then((res) => {
            if (res.result) {
              this.houseListData = res.data.records
            } else {
              this.noticeError(res.message)
            }
          })
          .catch((e) => {
            this.noticeError(e.message)
          })
      },

      // 添加住宅
      onSubmit() {
        this.getCheckOut()
      },

      getCheckOut() {
        const queryData = {}
        queryData.houseId = this.searchHouse
        queryData.ownerType = this.memberTypeValues
        queryData.ownerId = this.ownerID
        console.log(queryData)
        if (queryData.houseId === null) {
          this.noticeError('请选择房间')
          return
        } else if (queryData.ownerType === '') {
          this.noticeError('请选择成员类型')
          return
        }
        checkOwnerHouse(queryData).then((res) => {
          if (res.result) {
            this.noticeSuccess('添加成功')
            this.getHouse()
          } else {
            this.noticeError(res.message)
          }
        })
      },

      // 获取房屋列表
      getHouse() {
        const queryData = {}
        queryData.HOwnerId = this.ownerID
        queryData.communityId = this.communityId
        queryData.current = 1
        queryData.size = 100
        OwnerHouse(queryData)
          .then((res) => {
            if (res.result) {
              this.houseList = res.data.records
              this.houseForm.communityName = ''
              this.memberTypeValues = ''
            } else {
              this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },

      // 删除住宅列表数据
      handleDeleteHouse(data) {
        console.log(data)
        const queryData = {}
        queryData.houseId = data.houseId
        queryData.ownerId = data.hOwnerId
        deleteHouse(queryData).then((res) => {
          if (res.result) {
            this.noticeSuccess('删除成功')
            this.getHouse()
          } else {
            this.noticeError(res.message)
          }
          console.log(res)
        })
      },

      // 打开商铺弹框
      beforeShop(data) {
        this.ownerIDs = data.id
        this.getShopList()
        this.getShop()
      },

      // 获取商铺信息
      getShopList() {
        const queryData = {}
        queryData.communityId = this.communityId
        queryData.current = 1
        queryData.size = 100
        queryData.searchKw = this.searchKws
        OwnerShopList(queryData).then((res) => {
          if (res.result) {
            this.shopListData = res.data.records
          } else {
            this.noticeError(res.message)
          }
        })
      },

      // 添加商铺
      onSubmits() {
        this.getCheckOuts()
      },

      getCheckOuts() {
        const queryData = {}
        queryData.shopId = this.searchShop
        queryData.ownerType = this.memberTypeValue
        queryData.ownerId = this.ownerIDs
        if (queryData.shopId === null) {
          this.noticeError('请选择商铺')
          return
        } else if (queryData.ownerType === '') {
          this.noticeError('请选择成员类型')
          return
        }
        checkOwnerShop(queryData).then((res) => {
          if (res.result) {
            this.noticeSuccess('添加成功')
            this.getShop()
          } else {
            this.noticeError(res.message)
          }
        })
      },

      // 获取商户列表
      getShop() {
        const queryData = {}
        queryData.ownerId = this.ownerIDs
        queryData.communityId = this.communityId
        queryData.current = 1
        queryData.size = 100
        OwnerShop(queryData)
          .then((res) => {
            if (res.result) {
              this.shopList = res.data.records
              this.shopForm.communityName = ''
              this.memberTypeValue = ''
            } else {
              this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },

      // 删除商铺列表页数据
      handleDeleteShop(data) {
        console.log(data)
        const queryData = {}
        queryData.shopId = data.shopId
        queryData.ownerId = data.ownerId
        deleteShop(queryData).then((res) => {
          if (res.result) {
            this.noticeSuccess('删除成功')
            this.getShop()
          } else {
            this.noticeError(res.message)
          }
        })
      },

      // 关闭弹框
      handleClose() {},

      /**
       * 获取业主列表数据
       */
      getList() {
        this.loading = true
        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
        this.filterData.mobile && (queryData.mobile = this.filterData.mobile)
        list(this.communityId, queryData)
          .then((res) => {
            if (res.result) {
              this.dataList = res.data.records
              this.total = res.data.total
            } else {
              this.noticeError(res.message)
            }
            this.loading = false
          })
          .catch((err) => {
            console.error('业主列表查询出错', err)
            this.loading = false
          })
      },

      /**
       * 编辑前执行
       */
      beforeEdit(data, e) {
        this.actionDataItem = data
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (this.modalObject.currentAction === 'edit') {
              const id = this.actionDataItem.id
              const postData = {}
              postData.communityId = this.communityId
              postData.mobile = this.actionDataItem.mobile
              postData.name = this.actionDataItem.name
              postData.remark = this.actionDataItem.remark
              update(id, postData).then((res) => {
                if (res.result) {
                  this.noticeSuccess(res.message)
                  this.modalObject.status = false
                  this.handleRefresh()
                } else {
                  this.noticeError(res.message)
                }
              })
            } else {
              const postData = {}
              postData.communityId = this.communityId
              postData.mobile = this.actionDataItem.mobile
              postData.name = this.actionDataItem.name
              postData.remark = this.actionDataItem.remark
              create(postData).then((res) => {
                console.log(res, 'xinzen')
                if (res.result) {
                  this.noticeSuccess(res.message)
                  this.modalObject.status = false
                  this.handleRefresh()
                } else {
                  this.noticeError(res.message)
                }
              })
            }
          }
        })
      },

      /**
       * 主列表删除
       */
      handleDel(id) {
        deleteItem(id)
          .then((res) => {
            if (res.result) {
              this.noticeSuccess('删除成功')
              this.handleRefresh()
            } else {
              this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.error('删除业主信息失败', err)
          })
      },

      /**
       * 批量删除记录
       */
      handleBatchDel(ids) {
        this.noticeError('暂时没有实现批量删除接口')
      },

      /**
       * 导出数据
       */
      handleExport() {
        this.$confirm('是否导出业主列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          exportExcel(this.communityId)
            .then((res) => {
              console.log(res, '-----')
              const blob = new Blob([res.data], { type: res.data.type })
              console.log(blob, '---')
              const fileName = '业主列表.xls'
              const downloadElement = document.createElement('a')
              const href = window.URL.createObjectURL(blob)
              downloadElement.href = href
              downloadElement.download = fileName
              document.body.appendChild(downloadElement)
              downloadElement.click()
              document.body.removeChild(downloadElement)
              window.URL.revokeObjectURL(href)
            })
            .catch((err) => {
              console.error('下载excel失败 失败%o', err)
            })
        })
      },
      /**
       * 下载模板
       */
      handleDownload() {
        window.open(
          'http://matrixoss.oss-cn-zhangjiakou.aliyuncs.com/upload/files/20220518/%E4%B8%9A%E4%B8%BB%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF-v1.0.xlsx'
        )
      },

      /**
       * 上传成功
       */
      handleImportSuccess() {
        this.noticeSuccess('上传成功')
        this.handleRefresh()
      },

      /**
       * overwrite mixins中的edit
       */
      edit(dataObj, title, cb) {
        // const _this = this
        this.modalObject.currentAction = 'edit'
        this.modalObject.title = title
        this.modalObject.status = true
      },

      /**
       * 添加成员按键点击处理
       */
      handleAddMember(houseObj) {
        this.addMemberDialogShow = true
        this.addMemberDialogObj = {
          name: '',
          mobile: '',
          type: '',
          remark: ''
        }
        this.addMemberDialogObj.houseId = houseObj.houseId
      },

      /**
       * 获取业主信息
       */
      getOwnerDes(arr) {
        return arr.find((item) => item.relationType === 1)
      },

      /**
       * 过滤掉业主
       */
      filterOwnerArr(arr) {
        return arr.filter((item) => item.relationType !== 1)
      },

      /**
       * 查看导入记录按键点击
       */
      handleImportRecord() {
        const queryData = {}
        queryData.communityId = this.communityId
        queryData.current = this.importInfoDialog.current
        queryData.size = this.importInfoDialog.size
        importBatchList(queryData)
          .then((res) => {
            if (res.result) {
              this.importInfoDialog.show = true
              this.importInfoDialog.data = res.data.records
              this.importInfoDialog.total = res.data.total
            } else {
              this.noticeError('获取失败', res.message)
            }
          })
          .catch((e) => {
            console.log(e)
            this.noticeError('获取失败', e.message)
          })
      },

      /**
       * 导出失败数据按键点击
       */
      handleFailExport(id) {
        exportErrorRecords(id)
          .then((res) => {
            const blob = new Blob([res.data])
            // 获取heads中的filename文件名
            const downloadElement = document.createElement('a')
            // 创建下载的链接
            const href = window.URL.createObjectURL(blob)
            downloadElement.href = href
            // 下载后文件名
            downloadElement.download = '批次失败数据.xlsx'
            document.body.appendChild(downloadElement)
            // 点击下载
            downloadElement.click()
            // 下载完成移除元素
            document.body.removeChild(downloadElement)
            // 释放掉blob对象
            window.URL.revokeObjectURL(href)
            this.noticeSuccess('导出成功')
          })
          .catch((e) => {
            this.noticeError('导出失败' + e.message)
          })
      }
    }
  }
</script>
<style scoped lang="scss">
  .gz-house-group {
    // display: flex;
    align-items: center;

    .gz-house-item {
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 5px;
      .gz-house-info {
        width: 300px;
      }
      .gz-owner-type,
      .gz-house-handle {
        width: 200px;
      }
    }
  }

  .gz-table-expand {
    font-size: 12px;
    padding-left: 20px;

    .gz-card-item-des {
      opacity: 0.6;
    }
  }
  .gz-house-info-pop {
    padding: 0;
  }

  .gz-owner {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .gz-dialog-member-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    .gz-member {
      width: 100%;
      text-align: center;
    }
  }

  .gz-icon-owner,
  .gz-icon-member {
    width: 34px;
    height: 34px;
  }
  .gz-fail-export {
    color: #faa13c;
    cursor: pointer;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }

  .drawers {
    width: calc(100% - 200px);
    left: 200px;
    right: 0;
  }
  .tables {
    margin: 0 auto;
    width: 98%;
  }

  .demo-form-inline {
    display: flex;
  }
</style>
