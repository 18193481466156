<!--
#***********************************************
#
#      Filename: src/views/List/FixedParkingSpace.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 固定车位列表
#        Create: 2021-10-28 10:34:14
# Last Modified: 2021-11-28 23:24:02
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :import-actions="importActions"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增非固定车位', beforeAdd)"
      @refresh="handleRefresh"
      @export="handleExport"
      @download="handleDownload"
      @import-success="handleImportSuccess"
      @batch-delete="handleBatchDelete('批量删除非固定车位名单', handleBatchDel)">
      <div slot="custom-left">
        <el-button type="primary" size="mini" plain @click="handleImportRecord">导入记录</el-button>
      </div>
    </ContentHeader>
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" size="mini" stripe border @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop">
        <template slot-scope="scope">
          {{ getPlaceNos(scope.row.placeList) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.placeList" :key="index">
            {{ item.cqsNo }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" /> -->
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.itemList" :key="index">
            {{ item.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.itemList" :key="index">
            {{ ['业主', '商户', '其他'][item.userType - 1] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.itemList" :key="index">
            {{ item.mobile }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop">
        <template slot-scope="scope">
          <div v-for="(item, index) in getCarNumberArr(scope.row)" :key="index">
            {{ item }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop"></el-table-column>
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop">
        <template slot-scope="scope">
          <span class="text-navy">&#8226;&nbsp;{{ getPlaceTypeStr(scope.row.type) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[9].isShow"
        :label="tableHeadFields[9].label"
        :width="tableHeadFields[9].width"
        :align="tableHeadFields[9].align"
        :prop="tableHeadFields[9].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 0" class="text-green" @click="klakd(scope.row)">&#8226;&nbsp;待缴费</span>
          <span v-if="scope.row.status === -1" class="text-red" @click="klakd(scope.row)">
            &#8226;&nbsp;过期/缴费锁定
          </span>
          <span v-if="scope.row.status === 1" class="text-green" @click="klakd(scope.row)">&#8226;&nbsp;待活动</span>
          <span v-if="scope.row.status === 2" class="text-green" @click="klakd(scope.row)">&#8226;&nbsp;生效中</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[10].isShow"
        :label="tableHeadFields[10].label"
        :width="tableHeadFields[10].width"
        :align="tableHeadFields[10].align"
        :prop="tableHeadFields[10].prop"></el-table-column>
      <el-table-column
        v-if="tableHeadFields[11].isShow"
        :label="tableHeadFields[11].label"
        :width="tableHeadFields[11].width"
        :align="tableHeadFields[11].align"
        :prop="tableHeadFields[11].prop" />
      <el-table-column
        v-if="tableHeadFields[12].isShow"
        :label="tableHeadFields[12].label"
        :width="tableHeadFields[12].width"
        :align="tableHeadFields[12].align"
        :prop="tableHeadFields[12].prop" />
      <el-table-column
        v-if="tableHeadFields[13].isShow"
        :label="tableHeadFields[13].label"
        :width="tableHeadFields[13].width"
        :align="tableHeadFields[13].align"
        :prop="tableHeadFields[13].prop">
        <template slot-scope="scope">
          <!-- {{ scope.row.updateAid || scope.row.createAid }} -->
          {{ formOperName(scope.row.createAid) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[14].isShow"
        :label="tableHeadFields[14].label"
        :width="tableHeadFields[14].width"
        :align="tableHeadFields[14].align"
        :prop="tableHeadFields[14].prop"></el-table-column>
      <!-- <el-table-column
        v-if="tableHeadFields[14].isShow"
        :label="tableHeadFields[14].label"
        :width="tableHeadFields[14].width"
        :align="tableHeadFields[14].align"
        :prop="tableHeadFields[14].prop" />
      <el-table-column
        v-if="tableHeadFields[15].isShow"
        :label="tableHeadFields[15].label"
        :width="tableHeadFields[15].width"
        :align="tableHeadFields[15].align"
        :prop="tableHeadFields[15].prop" />   -->
      <el-table-column
        v-if="tableHeadFields[15].isShow"
        :label="tableHeadFields[15].label"
        :width="tableHeadFields[15].width"
        :align="tableHeadFields[15].align"
        :prop="tableHeadFields[15].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.possibleChargeOnline === 1" class="text-green">&#8226;&nbsp;是</span>
          <span v-if="scope.row.possibleChargeOnline === 2" class="text-red">&#8226;&nbsp;否</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[16].isShow"
        :label="tableHeadFields[16].label"
        :width="tableHeadFields[16].width"
        :align="tableHeadFields[16].align"
        :prop="tableHeadFields[16].prop" />
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-yellow" @click="edit(scope.row, '修改非固定车位', beforeEdit)">
              <i class="el-icon-edit"></i>
              编辑
            </div>
            <div class="gz-text-btn gz-text-btn-yellow" @click="parkingChange(scope.row)">
              <i class="el-icon-edit"></i>
              车位变更
            </div>
            <div class="gz-text-btn gz-text-btn-red" @click="handleDelete(scope.row.id, '删除非固定车位', handleDel)">
              <i class="el-icon-delete"></i>
              删除
            </div>
            <!-- <button>变更</button> -->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog"
      @close="handleClose">
      <el-form ref="form" label-width="140px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="车位号:" prop="placeNo">
              <el-select
                v-model="actionDataItem.placeNo"
                multiple
                filterable
                placeholder="请选择车位号"
                :disabled="isDisabled">
                <el-option
                  v-for="(item, index) in unusedParkingSpaceList"
                  :key="index"
                  :label="item.placeNo"
                  :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="月租类型:" prop="type">
              <el-select v-model="actionDataItem.type" placeholder="请选择月租类型" :disabled="isDisabled">
                <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="能否线上缴费:" prop="possibleChargeOnline" class="tableTitle">
              <template slot="label">
                <span>
                  能否线上缴费:
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="此属性决定是否在小程序端显示本条信息"
                    placement="left">
                    <i class="el-icon-question" style="font-size: 16px; vertical-align: middle"></i>
                  </el-tooltip>
                </span>
              </template>
              <el-select v-model="actionDataItem.possibleChargeOnline" @change="selectPayTypeChange">
                <el-option v-for="item in isPayType" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <template
          v-if="
            (actionDataItem.master && (actionDataItem.master.userId || actionDataItem.master.name)) ||
            modalObject.currentAction != 'edit'
          ">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="人员类型:" prop="userType">
                <el-select
                  v-model="actionDataItem.userType"
                  placeholder="请选择人员类型"
                  :disabled="isDisabled"
                  @change="selectUserTypeChange">
                  <el-option label="业主" :value="1" />
                  <el-option label="商户" :value="2" />
                  <el-option label="其他" :value="3" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                v-if="actionDataItem.userType !== 3"
                :label="
                  actionDataItem.userType === 1
                    ? '业主姓名:'
                    : actionDataItem.userType === 2
                    ? '商户姓名:'
                    : '业主姓名:'
                "
                prop="master.userId">
                <el-select
                  v-if="actionDataItem.userType === 1"
                  v-model="actionDataItem.master.userId"
                  placeholder="请选择业主"
                  filterable
                  :disabled="isDisabled"
                  @change="handleOwnerchange">
                  <el-option v-for="(item, index) in ownersList" :key="index" :label="item.name" :value="item.id" />
                </el-select>
                <el-select
                  v-else-if="actionDataItem.userType === 2"
                  v-model="actionDataItem.ownerId"
                  placeholder="请选择商户"
                  filterable
                  :disabled="isDisabled"
                  @change="handleMerchantChange">
                  <el-option v-for="(item, index) in merchantList" :key="index" :label="item.name" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item v-else label="姓名:" prop="ownerName">
                <el-input v-model="actionDataItem.ownerName" placeholder="请输入姓名" :disabled="isDisabled" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="车牌号:" required>
                <div
                  class="gz-dynamtic-input-wrapper"
                  style="grid-gap: 10px"
                  :style="{ 'grid-template-columns': 'repeat(4, 1fr)' }">
                  <el-form-item
                    v-for="(item, index) in actionDataItem.ownerCarNumber"
                    :key="index"
                    label=""
                    :prop="'ownerCarNumber.' + index + '.carNumber'">
                    <el-input v-model="item.carNumber" placeholder="请输入车牌号码" />
                  </el-form-item>
                  <div class="gz-add-and-sub-btn-group">
                    <el-button size="mini" type="primary" icon="el-icon-plus" circle @click="handleAddOwnerCar()" />
                    <el-button size="mini" type="primary" icon="el-icon-minus" circle @click="handleDeleteOwnerCar()" />
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <!-- 成员信息 -->
        <div v-if="actionDataItem.userType !== 2">
          <div v-for="(item, index) in actionDataItem.members" :key="index">
            <el-divider />
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="人员类型:" :prop="'members.' + index + '.userType'">
                  <el-select v-model="item.userType" placeholder="请选择人员类型">
                    <el-option label="业主" :value="1" />
                    <el-option label="商户" :value="2" />
                    <el-option label="其他" :value="3" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  v-if="item.userType !== 3"
                  :label="item.userType === 1 ? '业主姓名:' : item.userType === 2 ? '商户姓名:' : '业主姓名:'"
                  :prop="'members.' + index + '.userId'">
                  <el-select
                    v-if="item.userType === 1"
                    v-model="item.userId"
                    placeholder="请选择业主"
                    filterable
                    @change="handleOwnerchange">
                    <el-option v-for="(item, index) in ownersList" :key="index" :label="item.name" :value="item.id" />
                  </el-select>
                  <el-select
                    v-else-if="item.userType === 2"
                    v-model="item.userId"
                    placeholder="请选择商户"
                    @change="handleMerchantChange">
                    <el-option v-for="(item, index) in merchantList" :key="index" :label="item.name" :value="item.id" />
                  </el-select>
                </el-form-item>
                <el-form-item v-else label="姓名:" :prop="'members.' + index + '.memberName'">
                  <el-input v-model="item.memberName" placeholder="请输入姓名" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电话号码:" :prop="'members.' + index + '.memberMobile'">
                  <el-input v-model="item.memberMobile" placeholder="请填写成员联系方式"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="车牌号:" required>
                  <div
                    class="gz-dynamtic-input-wrapper"
                    style="grid-gap: 10px"
                    :style="{ 'grid-template-columns': 'repeat(4, 1fr)' }">
                    <el-form-item v-for="(item1, index1) in item.memberCarNumber" :key="index1" label="">
                      <el-input v-model="item1.carNumber" placeholder="请输入车牌号码" />
                    </el-form-item>
                    <div class="gz-add-and-sub-btn-group">
                      <el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-plus"
                        circle
                        @click="handleAddMemberCar(index)" />
                      <el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-minus"
                        circle
                        @click="handleDeleteMemberCar(index)" />
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 删除按钮 -->
            <el-form-item>
              <el-button type="info" @click="deleteMember(index)">删除成员</el-button>
            </el-form-item>
            <!-- end删除按钮 -->
          </div>
        </div>

        <el-row>
          <el-divider v-if="actionDataItem.userType == 1 || actionDataItem.userType == 3" />
          <el-form-item v-if="actionDataItem.userType == 1 || actionDataItem.userType == 3">
            <el-button type="primary" @click="addMember">添加成员</el-button>
          </el-form-item>
        </el-row>
        <el-row :gutter="20">
          <el-form-item label="备注" prop="remark">
            <el-col :span="24">
              <textarea
                v-model="actionDataItem.remark"
                type="textarea"
                :rows="2"
                placeholder="请输入变更内容的描述"
                class="el-textarea__inner" />
            </el-col>
          </el-form-item>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->
    <!-- 车位变更dialog -->
    <el-dialog title="车位变更" :visible.sync="dialogVisible" width="30%" :before-close="close">
      <el-form ref="form" label-width="140px" :model="actionDataItem">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="需变更车位号:">
              <el-select ref="optionRefs" v-model="itemData.oldId" placeholder="请选择车位号">
                <el-option v-for="item in plateData" :key="item.id" :label="item.placeNo" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="变更车位号:">
              <el-select ref="optionRef" v-model="itemData.id" placeholder="请选择车位号">
                <el-option v-for="item in plateNoData" :key="item.id" :label="item.placeNo" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFormData">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导入记录dialog -->
    <el-dialog
      title="导入记录"
      :visible.sync="importInfoDialog.show"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-table :data="importInfoDialog.data" size="mini" stripe border>
        <el-table-column label="批次号" :width="200" align="center" prop="id" />
        <el-table-column label="开始时间" :width="200" align="center" prop="createTime" />
        <el-table-column label="状态" :width="200" align="center" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">
              <i class="el-icon-time" />
            </span>
            <span v-if="scope.row.status === 1">
              <i class="el-icon-loading" />
            </span>
            <span v-if="scope.row.status === 2" style="color: green">
              <i class="el-icon-circle-check" />
            </span>
          </template>
        </el-table-column>
        >
        <el-table-column label="完成时间" :width="200" align="center" prop="finishTime" />
        <el-table-column label="导入总数(条)" :width="200" align="center" prop="total" />
        <el-table-column label="成功数(条)" :width="200" align="center" prop="success" />
        <el-table-column label="失败数(条)" :width="200" align="center" prop="success">
          // eslint-disable-next-line vue/no-unused-vars
          <template slot-scope="scope">
            <span>
              {{ scope.row.total - scope.row.success > 0 ? scope.row.total - scope.row.success : '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="200" align="center" prop="success">
          <template slot-scope="scope">
            <span v-if="scope.row.total - scope.row.success <= 0 || scope.row.status !== 2">--</span>
            <span v-else class="gz-fail-export" @click="handleFailExport(scope.row.id)">导出失败数据</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页模块 -->
      <pagination
        v-show="importInfoDialog.total > 0"
        :total="importInfoDialog.total"
        :page.sync="importInfoDialog.current"
        :limit.sync="importInfoDialog.size" />
      <!-- end 分页模块 -->
    </el-dialog>

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { merchantList } from '@/api/merchantList'
  import moment from 'moment'
  import { relationList } from '@/api/owner'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')
  import {
    list,
    add,
    info,
    deleteItem,
    update,
    importBatchList,
    exportErrorRecords,
    getExport,
    getPlateNoList,
    getPlaceTypeChange,
    getParkList
  } from '@/api/fixedParkingSpace'
  import { list as pList } from '@/api/parking'
  import { simpleList as oList } from '@/api/owner'
  import { typeList, vipBatchDelete } from '@/api/vip'

  export default {
    name: 'FixedParkingSpace',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        dialogVisible: false,
        dialog_loading: false,
        /**
         * 账号添加修改表单数据绑定
         */
        parkform: {
          id: '',
          placeNo: ''
        },
        itemData: {
          placeNo: '',
          id: '',
          oldPlaceNo: '',
          oldId: ''
        },
        value1: '',
        plateData: [],
        formData: {
          userType: 1,
          placeNo: '',
          ownerId: '',
          mobile: '',
          type: '',
          possibleChargeOnline: '',
          ownerCarNumber: [
            {
              carNumber: ''
            }
          ],
          members: [],
          master: {
            userId: '',
            name: ''
          },
          typeList: []
        },
        plateNoData: [],
        editDatashow: false,
        isDisabled: true,
        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          userType: [{ required: true, message: '车主类型不能为空', trigger: 'blur' }],
          type: [{ required: true, message: '月租类型不能为空', trigger: 'blur' }],
          placeNo: [{ required: true, message: '车位号不能为空', trigger: 'blur' }],
          // ownerName: [
          //   { required: true, message: '姓名不能为空', trigger: 'blur' },
          //   { min: 2, max: 30, message: '姓名限2-30个字符', trigger: 'blur' }
          // ],
          // ownerId: [{ validator: checkOwnerId, trigger: 'blur' }],
          // mobile: [
          //   { required: true, message: '电话号码不能为空', trigger: 'blur' },
          //   { pattern: RX_MOBILE, message: '请检查电话号码格式', trigger: 'blur' }
          // ],
          ownerCarNumber: [
            {
              carNumber: [{ required: true, message: '车牌号码不能为空', trigger: 'blur' }]
            }
          ],
          memberCarNumber: [
            {
              // carNumber: [{ required: true, message: '车牌号码不能为空', trigger: 'blur' }]
            }
          ],
          ownerItem: {},
          merchantItem: {}
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '业务编号/车位号/产权室号/姓名/电话'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            importDataShow: true,
            downloadTemplateShow: true,
            more: [
              {
                label: '批量删除',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'select',
                name: 'status',
                placeholder: '请选择车位状态',
                options: [
                  { label: '过期/缴费锁定', value: -1 },
                  { label: '待缴费', value: 0 },
                  { label: '待活动', value: 1 },
                  { label: '生效中', value: 2 }
                ]
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          { label: '月租编号', isShow: true, width: '200', align: 'center', prop: 'id' },
          { label: '车位号', isShow: true, width: '100', align: 'center', prop: 'placeList' },
          { label: '产权室号', isShow: true, width: '100', align: 'center', prop: 'cqsNo' },
          { label: '业主姓名', isShow: true, width: '140', align: 'center', prop: 'houseInfo.owners' },
          { label: '车主类型', isShow: true, width: '100', align: 'center', prop: 'itemList.userTypes' },
          { label: '手机号码', isShow: true, width: '100', align: 'center', prop: 'itemList.mobile' },
          { label: '车牌号', isShow: true, width: '100', align: 'center', prop: 'itemList.carNumbers' },
          { label: '月租车位数', isShow: true, width: '100', align: 'center', prop: 'placeCount' },
          { label: '车位类型', isShow: true, width: '120', align: 'center', prop: 'placeType' },
          { label: '车位状态', isShow: true, width: '160', align: 'center', prop: 'status' },
          { label: '创建时间', isShow: true, width: '200', align: 'center', prop: 'createTime' },
          { label: '开始时间', isShow: true, width: '200', align: 'center', prop: 'periodStart' },
          { label: '到期时间', isShow: true, width: '200', align: 'center', prop: 'periodEnd' },
          { label: '操作人', isShow: true, width: '200', align: 'center', prop: 'username' },
          { label: '合同编号', isShow: true, width: '200', align: 'center', prop: 'agreementId' },
          { label: '能否线上缴费', isShow: true, width: '200', align: 'center', prop: 'possibleChargeOnline' },
          { label: '备注', isShow: true, width: '200', align: 'center', prop: 'remark' }
          // { label: '小区名称', isShow: true, width: '200', align: 'center', prop: 'houseInfo.communityName' },
          // { label: '区号', isShow: true, width: '60', align: 'center', prop: 'houseInfo.areaNumber' },
          // { label: '楼号', isShow: true, width: '60', align: 'center', prop: 'houseInfo.buildingNumber' },
          // { label: '单元号', isShow: true, width: '60', align: 'center', prop: 'houseInfo.unit' },
          // { label: '房间号', isShow: true, width: '60', align: 'center', prop: 'houseInfo.number' },
        ],

        isPayType: [
          { label: '是', value: 1 },
          { label: '否', value: 2 }
        ],
        /**
         * 停车位列表
         */
        parkingSpaceList: [],

        /**
         * 未占用的停车位列表
         */
        unusedParkingSpaceList: [],

        /**
         * 业主列表
         */
        ownersList: [],

        /**
         * 关联房间列表
         */
        houseList: [],

        /**
         * 关联成员列表
         */
        memberList: [],

        /**
         * 月租类型列表
         */
        typeList: [],

        /**
         * 商户列表
         */
        merchantList: [],

        /**
         * 查看导入对话框控制对象
         */
        importInfoDialog: {
          show: false,
          current: 1,
          size: 10,
          total: 0,
          data: []
        },
        numberDey: 1,
        permissions: {
          create: 'unfixed-parking:create',
          info: 'unfixed-parking:info',
          delete: 'unfixed-parking:delete',
          update: 'unfixed-parking:update',
          export: 'unfixed-parking:export',
          import: 'unfixed-parking:import'
        },
        ownerItem: {},
        merchantItem: {}
      }
    },

    computed: {
      communityName() {
        const _this = this
        const item = this.parkingList.find((item) => {
          return item.communityId === _this.communityId
        })
        return item ? item.communityName : ''
      },

      importActions() {
        return process.env.VUE_APP_BASE_API + '/vip/importBatch/submit?parkingId=' + this.pid + '&placeType=2'
      }
    },

    mounted() {
      this.getList()
      this.getMerchantList()
      // this.getParkingSpaceList()
      this.getOwnerList()
      this.getTypeList()
      // this.geFixedListTree()
    },

    methods: {
      /**
       * 获取商户列表
       */
      getMerchantList() {
        const _this = this
        const queryData = {}
        queryData.current = 1
        queryData.size = 999
        queryData.communityId = _this.communityId
        merchantList(_this.pid, queryData)
          .then((res) => {
            _this.merchantList = res.data.records
          })
          .catch((e) => {
            _this.noticeError(e.message)
          })
      },

      handlePlateNoChange(item) {
        console.log(item)
      },
      /**
       * 获取车位号
       */
      getPlaceNos(objPlaceList) {
        const placeNosArr = []
        objPlaceList.forEach((item) => {
          placeNosArr.push(item.placeNo)
        })
        return placeNosArr.join(',')
      },

      /**
       * 删除成员
       */
      deleteMember(index) {
        if (index === 0) {
          this.actionDataItem.members.shift()
        } else {
          this.actionDataItem.members.splice(index, 1)
        }
      },

      /**
       * 添加成员
       */
      addMember() {
        const _this = this
        if (_this.actionDataItem.members === undefined) {
          _this.actionDataItem.members = []
        }
        _this.actionDataItem.members.push({
          memberName: '',
          memberMobile: '',
          userType: 3,
          memberCarNumber: [
            {
              carNumber: ''
            }
          ]
        })
      },
      /**
       * 车主类型变动，重置表单
       */
      selectUserTypeChange(e) {
        this.actionDataItem.userType = e
        if (e === 1 || e === 2) {
          this.rules['master.userId'] = [{ required: true, message: '主要负责人不能为空', trigger: 'blur' }]
        } else {
          this.rules['ownerName'] = [
            { required: true, message: '姓名不能为空', trigger: 'blur' },
            { min: 2, max: 30, message: '姓名限2-30个字符', trigger: 'blur' }
          ]
        }
        this.$refs.form.clearValidate()
      },

      /**
       * 查看导入记录按键点击
       */
      handleImportRecord() {
        const queryData = {}
        queryData.parkingId = this.pid
        queryData.current = this.importInfoDialog.current
        queryData.size = this.importInfoDialog.size
        importBatchList(queryData)
          .then((res) => {
            if (res.result) {
              this.importInfoDialog.show = true
              this.importInfoDialog.data = res.data.records
              this.importInfoDialog.total = res.data.total
            } else {
              this.noticeError('获取失败', res.message)
            }
          })
          .catch((e) => {
            console.log(e)
            this.noticeError('获取失败', e.message)
          })
      },

      handleClose() {
        this.$refs.form.resetFields()
        if (this.modalObject.currentAction === 'edit') {
          this.unusedParkingSpaceList.pop()
        }
        this.editDatashow = false
      },
      close() {
        this.dialogVisible = false
      },
      /**
       * 获取车位类型字符串
       */
      getPlaceTypeStr(placeTypeId) {
        if (this.typeList && this.typeList.length > 0) {
          const matchedItem = this.typeList.find((item) => item.id === placeTypeId)
          if (matchedItem) {
            return matchedItem.name
          }
        }
        return '未知类型'
      },

      /**
       * 获取车辆字符串
       */
      getCarNumberArr(data) {
        const carNumberArr = []
        data.itemList.forEach((item) => {
          item.carNumbers.forEach((item1) => {
            carNumberArr.push(item1.carNumber)
          })
        })
        return carNumberArr
      },

      /**
       * 获取类型列表
       */
      getTypeList() {
        const queryData = {}
        queryData.current = 1
        queryData.size = 999
        queryData.parkingId = this.pid
        typeList(queryData).then((res) => {
          this.typeList = res.data.records
        })
      },

      submitFormData() {
        const queryData = {}
        queryData.parkingId = this.pid
        queryData.newPlaceId = JSON.stringify(this.itemData.id)
        queryData.oldPlaceId = JSON.stringify(this.itemData.oldId)
        getPlaceTypeChange(queryData).then((res) => {
          if (res.result) {
            this.noticeSuccess('车位变更成功')
            this.dialogVisible = false
            this.handleRefresh()
            this.itemData = {}
          }
        })
      },

      /**
       * 获取列表数据
       */
      getList() {
        this.loading = true
        // 组装查询条件
        if (this.pid) {
          const queryData = {}
          queryData.current = this.currentPage
          queryData.size = this.pageSize
          queryData.parkingId = this.pid
          queryData.placeType = 2
          queryData.status = this.filterData.status
          this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
          if (this.filterData.dateRange) {
            queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
            queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
          }
          list(queryData)
            .then((res) => {
              // this.loading = false
              this.dataList = res.data.records
              if (this.dataList) {
                this.dataList.forEach((item) => {
                  if (item.periodEnd && item.periodStart) {
                    item.periodEnd = moment(item.periodEnd).format('YYYY-MM-DD HH:mm:ss')
                    item.periodStart = moment(item.periodStart).format('YYYY-MM-DD HH:mm:ss')
                  }
                })
              }
              // this.dataList = res.data.records
              this.total = res.data.total
            })
            .catch((err) => {
              console.error('固定车位列表查询出错', err)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },

      /**
       * 获取车位号列表
       */
      // getParkingSpaceList() {
      //   const _this = this
      //   // 组装查询条件
      //   if (this.pid) {
      //     const queryData = {}
      //     queryData.parkingId = this.pid
      //     queryData.size = 999

      //     pList(queryData)
      //       .then((res) => {
      //         if (res.result) {
      //           _this.parkingSpaceList = res.data.records
      //           _this.unusedParkingSpaceList = _this.parkingSpaceList.filter((item) => item.status === 0)
      //         } else {
      //           _this.noticeError('车位号列表请求失败')
      //         }
      //       })
      //       .catch((res) => {
      //         console.log('车位号列表查询出错', res)
      //       })
      //   }
      // },

      getPlateNumberList() {
        if (this.pid) {
          const queryData = {}
          queryData.parkingId = this.pid
          queryData.status = 0
          getParkList(queryData)
            .then((res) => {
              if (res.result) {
                this.unusedParkingSpaceList = res.data
                // this.unusedParkingSpaceList = this.parkingSpaceList.filter((item) => item.status === 0)
              } else {
                this.noticeError('车位号列表请求失败')
              }
            })
            .catch((res) => {
              console.log('车位号列表查询出错', res)
            })
        }
      },

      /**
       * 获取业主列表信息
       */
      getOwnerList() {
        const queryData = {
          size: 10000
        }
        if (this.communityId) {
          oList(this.communityId, queryData)
            .then((res) => {
              this.ownersList = res.data.records
            })
            .catch((err) => {
              console.error('业主列表查询出错', err)
              this.noticeError(err.message)
            })
        }
      },

      parkingChange(item) {
        this.dialogVisible = true
        this.plateData = item.placeList
        const queryData = {}
        queryData.parkingId = this.pid
        getPlateNoList(queryData).then((res) => {
          this.plateNoData = res.data
          console.log(res)
        })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
        // this.getParkingSpaceList()
        this.getTypeList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        _this.dialog_loading = true
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.modalObject.currentAction === 'edit') {
              const request = {}
              const ownerCarNumbers = []
              // 业主车牌数组
              _this.actionDataItem.ownerCarNumber.forEach((item) => {
                ownerCarNumbers.push(item.carNumber)
              })
              request.companions = []
              if (_this.actionDataItem.master) {
                let ownerInfo
                if (_this.actionDataItem.userType === 1) {
                  ownerInfo = _this.ownersList.find((item) => {
                    // return item.id === _this.actionDataItem.ownerId
                    return item.id === _this.actionDataItem.master.userId
                  })
                  console.log('ownerInfo1', ownerInfo)
                } else if (_this.actionDataItem.userType === 3) {
                  ownerInfo = {
                    ownerId: 0,
                    name: _this.actionDataItem.ownerName
                  }
                  console.log('ownerInfo2', ownerInfo)
                } else {
                  ownerInfo = _this.merchantList.find((item) => {
                    // return item.id === _this.actionDataItem.ownerId
                    return item.id === _this.actionDataItem.master.userId
                  })
                  ownerInfo = {
                    ownerId: _this.actionDataItem.master.userId,
                    name: ownerInfo.name
                  }
                  console.log('ownerInfo2', ownerInfo)
                }
                if (this.actionDataItem.userType === 2) {
                  // 成员(业主)
                  const companionsOwnerMerchant = {
                    carNumbers: ownerCarNumbers,
                    mobile: _this.merchantItem.mobile,
                    userId: _this.merchantItem.id,
                    name: _this.merchantItem.name,
                    userType: 2
                  }
                  request.companions = [companionsOwnerMerchant]
                } else if (this.actionDataItem.userType === 1) {
                  const companionsOwner = {
                    carNumbers: ownerCarNumbers,
                    mobile: _this.actionDataItem.mobile,
                    name: ownerInfo.name,
                    userId: ownerInfo.id,
                    userType: 1
                  }
                  request.companions = [companionsOwner]
                } else {
                  const companionsOtherOwner = {
                    carNumbers: ownerCarNumbers,
                    mobile: _this.actionDataItem.mobile,
                    name: _this.actionDataItem.ownerName,
                    userId: 0,
                    userType: 3
                  }
                  request.companions = [companionsOtherOwner]
                }
              }
              // 成员(成员) 商户是没有成员的
              let companionsMember = []
              if (_this.actionDataItem.userType !== 2 && _this.actionDataItem.members !== undefined) {
                companionsMember = _this.actionDataItem.members.map((item) => {
                  var dataId = item.userId
                  let memberInfo
                  if (_this.actionDataItem.userType === 2) {
                    const selectMemberInfo = _this.merchantList.find((item1) => {
                      return item1.id === dataId
                    })
                    memberInfo = {
                      userId: selectMemberInfo.id,
                      name: selectMemberInfo.name,
                      userType: 2
                    }
                  } else if (_this.actionDataItem.userType === 1) {
                    const selectMemberInfo = _this.ownersList.find((item1) => {
                      console.log('item1', item1)
                      return item1.id === dataId
                    })
                    console.log('memberList', _this.ownersList)
                    console.log('dataId', dataId)
                    console.log('selectMemberInfo', selectMemberInfo)
                    memberInfo = {
                      userId: selectMemberInfo.id,
                      name: selectMemberInfo.name,
                      userType: 1
                    }
                  } else {
                    memberInfo = {
                      userId: 0,
                      name: item.memberName,
                      userType: 3
                    }
                  }
                  // 成员车牌数组
                  const memberCarNumbers = []
                  item.memberCarNumber.forEach((item) => {
                    memberCarNumbers.push(item.carNumber)
                  })

                  const member = {
                    carNumbers: memberCarNumbers,
                    mobile: item.memberMobile,
                    name: memberInfo.name,
                    userId: memberInfo.userId,
                    userType: memberInfo.userType
                  }
                  return member
                })
              }

              if (companionsMember) {
                request.companions = request.companions.concat(companionsMember)
              }
              if (_this.actionDataItem.houseId !== null) {
                // var houseId = _this.actionDataItem.houseId
                // _this.actionDataItem.houseId = ''
              }

              request.houseId = _this.actionDataItem.houseId || 0
              request.masterUserId = _this.actionDataItem.ownerId || 0
              request.opAid = _this.uid
              request.parkingId = _this.pid
              request.placeIds = _this.actionDataItem.placeNo
              // 什么是固定车位，什么是非固定车位,不支持的车位类型是什么
              request.placeType = 2
              request.type = _this.actionDataItem.type
              request.userType = _this.actionDataItem.userType
              request.changeRemark = _this.actionDataItem.remark
              request.remark = _this.actionDataItem.remark
              request.possibleChargeOnline = _this.actionDataItem.possibleChargeOnline
              const postData = request
              update(this.actionDataItem.id, postData)
                .then((response) => {
                  if (response.result) {
                    _this.noticeSuccess('修改成功')
                    _this.modalObject.status = false
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(response.message)
                  }
                  _this.dialog_loading = false
                })
                .catch((e) => {
                  console.log('固定车位更新请求失败', e.message)
                  _this.dialog_loading = false
                })
            } else {
              const request = {}
              const ownerCarNumbers = []
              // 业主车牌数组
              _this.actionDataItem.ownerCarNumber.forEach((item) => {
                ownerCarNumbers.push(item.carNumber)
              })

              // 业主信息
              let ownerInfo
              if (_this.actionDataItem.userType === 1) {
                ownerInfo = _this.ownersList.find((item) => {
                  if (item.id === _this.actionDataItem.master.userId) {
                    return item
                  }
                })
                console.log('ownerInfo1', ownerInfo, _this.ownersList, _this.actionDataItem.ownerId)
              } else if (_this.actionDataItem.userType === 3) {
                ownerInfo = {
                  ownerId: 0,
                  name: _this.actionDataItem.ownerName
                }
                console.log('ownerInfo3', ownerInfo, _this.ownersList, _this.actionDataItem.ownerId)
              } else {
                ownerInfo = _this.merchantList.find((item) => {
                  return item.id === _this.actionDataItem.master.userId
                })
                ownerInfo = {
                  ownerId: _this.actionDataItem.master.userId,
                  name: ownerInfo.name
                }
                console.log('ownerInfo2', ownerInfo, _this.ownersList, _this.actionDataItem.ownerId)
              }
              if (this.actionDataItem.userType === 2) {
                // 成员(业主)
                const companionsOwnerMerchant = {
                  carNumbers: ownerCarNumbers,
                  mobile: _this.merchantItem.mobile,
                  userId: _this.merchantItem.id,
                  name: _this.merchantItem.name,
                  userType: 2
                }
                request.companions = [companionsOwnerMerchant]
              } else if (this.actionDataItem.userType === 1) {
                const companionsOwner = {
                  carNumbers: ownerCarNumbers,
                  mobile: _this.actionDataItem.mobile,
                  name: ownerInfo.name,
                  userId: ownerInfo.id,
                  userType: 1
                }
                request.companions = [companionsOwner]
              } else {
                const companionsOtherOwner = {
                  carNumbers: ownerCarNumbers,
                  mobile: _this.actionDataItem.mobile,
                  name: _this.actionDataItem.ownerName,
                  userId: 0,
                  userType: 3
                }
                request.companions = [companionsOtherOwner]
              }
              // 成员(成员) 商户是没有成员的
              let companionsMember = []
              if (_this.actionDataItem.userType !== 2) {
                companionsMember = _this.actionDataItem.members.map((item) => {
                  let memberInfo
                  var selectMemberInfo
                  if (_this.actionDataItem.userType !== 3) {
                    console.log(_this.actionDataItem.userType, '--++++')
                    selectMemberInfo = _this.memberList.find((item1) => {
                      return item1.ownerInfo.id === item.id
                    })
                    memberInfo = {
                      userId: selectMemberInfo.ownerInfo.id,
                      name: selectMemberInfo.ownerInfo.name
                    }
                  } else {
                    memberInfo = {
                      userId: 0,
                      name: item.memberName
                    }
                  }

                  // 成员车牌数组
                  const memberCarNumbers = []
                  item.memberCarNumber.forEach((item) => {
                    memberCarNumbers.push(item.carNumber)
                  })

                  const member = {
                    carNumbers: memberCarNumbers,
                    mobile: item.memberMobile,
                    name: memberInfo.name,
                    userId: memberInfo.userId
                  }
                  return member
                })
              }

              if (companionsMember) {
                request.companions = request.companions.concat(companionsMember)
              }
              request.houseId = _this.actionDataItem.houseId || 0
              request.masterUserId = _this.actionDataItem.ownerId || 0
              request.opAid = _this.uid
              request.parkingId = _this.pid
              request.placeIds = _this.actionDataItem.placeNo
              // 什么是固定车位，什么是非固定车位,不支持的车位类型是什么
              request.placeType = 2
              request.type = _this.actionDataItem.type
              request.userType = _this.actionDataItem.userType
              request.possibleChargeOnline = _this.actionDataItem.possibleChargeOnline
              console.log('createData', request)

              const postData = request
              add(postData)
                .then((res) => {
                  if (res.result) {
                    _this.modalObject.status = false
                    _this.noticeSuccess('添加成功')
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(res.message)
                    _this.modalObject.status = false
                  }
                  _this.dialog_loading = false
                })
                .catch((e) => {
                  console.log(e)
                  _this.noticeError(e)
                  _this.dialog_loading = false
                })
            }
          }
        })
      },

      selectPayTypeChange(e) {},

      beforeAdd() {
        this.isDisabled = false
        this.getPlateNumberList()
        this.actionDataItem.possibleChargeOnline = 1
      },

      beforeEdit(dataListist) {
        console.log(this.actionDataItem)
        this.isDisabled = true
        this.editDatashow = true
        this.dialog_loading = true
        // 重整车位号
        const _this = this
        var data
        info(dataListist.id).then((res) => {
          data = res.data
          _this.handleHousechange(data.houseId)
          const placeNo = []
          data.placeList.forEach((item) => {
            placeNo.push(item.id)
          })
          var currentParkingSpace = []
          currentParkingSpace = _this.parkingSpaceList.filter((item) => {
            return placeNo.indexOf(item.id) >= 0
          })
          currentParkingSpace.forEach((item) => {
            _this.unusedParkingSpaceList.push(item)
          })

          // 固定车位是单车位号
          _this.actionDataItem.placeNo = placeNo
          _this.actionDataItem.type = data.type
          _this.actionDataItem.remark = data.remark
          var master = data.itemList.find((item) => item.master)
          if (master) {
            console.log('master', master)
            _this.actionDataItem.master = master
          }

          var member = data.itemList.filter((item) => !item.master)
          if (data.userType === 1) {
            const ownerInfo = _this.ownersList.find((item) => {
              return item.id === data.masterUserId
            })
            if (ownerInfo) {
              _this.actionDataItem.ownerId = data.masterUserId
              _this.actionDataItem.mobile = ownerInfo.mobile
              _this.houseList = ownerInfo.refHouses
            }
            // _this.actionDataItem.houseId = data.houseInfo.fullNumber
            if (data.houseInfo?.refOwners) {
              _this.memberList = data.houseInfo.refOwners.filter((item) => item.id !== _this.actionDataItem.ownerId)
            } else {
              _this.memberList = dataListist.itemList[0].carNumbers.forEach((item) => {
                return item.carNumber
              })
            }
          } else if (data.userType === 2) {
            if (master) {
              _this.actionDataItem.ownerId = master.userId
              _this.actionDataItem.mobile = master.mobile
            }
          } else {
            if (master) {
              _this.actionDataItem.ownerName = master.name
              _this.actionDataItem.mobile = master.mobile
            }
          }

          // 业主车牌号
          const ownerCarNumber = []
          if (master && master.carNumbers && master.carNumbers.length > 0) {
            master.carNumbers.forEach((item) => {
              ownerCarNumber.push({ carNumber: item.carNumber })
            })
          }

          _this.actionDataItem.ownerCarNumber = ownerCarNumber

          // 成员
          _this.actionDataItem.members = member.map((item) => {
            const carNumberArr = item.carNumbers.map((item1) => {
              return {
                carNumber: item1.carNumber
              }
            })
            const memberInfo = {
              memberMobile: item.mobile,
              memberName: item.name,
              memberCarNumber: carNumberArr,
              userId: item.userId,
              userType: item.userType
            }
            return memberInfo
          })
          console.log('members', _this.actionDataItem.members)
          const memberCarNumebr = []
          if (member && member.length > 0) {
            member[0].carNumbers.forEach((item) => {
              memberCarNumebr.push({ carNumber: item.carNumber })
            })
          }
          _this.actionDataItem.memberCarNumber = memberCarNumebr
          this.dialog_loading = false
        })
        console.log('editDataItem', _this.actionDataItem)
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      },
      /**
       * 查看变更记录
       */
      /**
       * 批量删除记录
       */
      handleBatchDel(ids) {
        // this.noticeError('暂时没有实现批量删除接口')
        const _this = this
        const idArr = []
        ids.forEach((item) => {
          idArr.push(item.id)
        })
        const postData = {}
        postData.ids = idArr.join(',')
        vipBatchDelete(postData.ids)
          .then((res) => {
            if (res.result) {
              _this.msgSuccess('批量删除成功')
              _this.handleRefresh()
            } else {
              _this.msgError(res.message)
            }
          })
          .catch((err) => {
            _this.msgError('请求失败')
            console.error(err)
          })
      },

      /**
       * 导出数据
       */
      handleExport() {
        this.$confirm('是否导出非固定车位列表', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.parkingId = this.pid
            queryData.placeType = 2
            queryData.status = this.filterData.status
            if (this.filterData.dateRange) {
              queryData.payStartTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
              queryData.payEndTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
            }
            getExport(queryData).then((res) => {
              const data = new Blob([res.data], {
                type: 'application/vnd.ms-excel;charset=utf-8'
              })
              const downloadUrl = window.URL.createObjectURL(data)
              const anchor = document.createElement('a')
              anchor.href = downloadUrl
              anchor.download = '非固定车位.xlsx' // 表格名称.文件类型
              anchor.click()
              window.URL.revokeObjectURL(res.data) // 消除请求接口返回的list数据
            })
          })
          .catch((err) => {
            console.log('取消导出', err.message)
          })
      },

      /**
       * 下载模板
       */
      handleDownload() {
        window.open(
          'http://matrixoss.oss-cn-zhangjiakou.aliyuncs.com/upload/files/20220518/%E9%9D%9E%E5%9B%BA%E5%AE%9A%E8%BD%A6%E4%BD%8D-%E6%9C%88%E7%A7%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF-v1.0.xlsx'
        )
      },

      /**
       * 上传成功
       */
      handleImportSuccess() {
        this.msgSuccess('上传成功')
        this.handleRefresh()
      },

      /**
       * 添加业主车牌按钮点击事件
       */
      handleAddOwnerCar() {
        // 在actionDataItem中添加车牌
        if (this.actionDataItem.ownerCarNumber === undefined) {
          this.actionDataItem.ownerCarNumber = []
        }
        this.actionDataItem.ownerCarNumber.push({
          carNumber: ''
        })
        // 在表单验证规则中添加成员
        // this.rules.ownerCarNumber.push({
        //   carNumber: [{ required: true, message: '车牌号码不能为空', trigger: 'blur' }]
        // })
      },

      /**
       * 添加成员车牌按钮点击事件
       */
      handleAddMemberCar(index) {
        // 在actionDataItem中添加车牌
        this.actionDataItem.members[index].memberCarNumber.push({
          carNumber: ''
        })
        // 在表单验证规则中添加成员
        // this.rules.members[index].memberCarNumber.push({
        //   carNumber: [{ required: true, message: '车牌号码不能为空', trigger: 'blur' }]
        // })
      },

      /**
       * 删除业主车牌号码按钮点击事件
       */
      handleDeleteOwnerCar() {
        // 在actionDataItem中删除成员
        this.actionDataItem.ownerCarNumber.pop()
        // 在表单验证规则中删除成员
        // this.rules.ownerCarNumber.pop()
      },

      /**
       * 删除成员车牌号码按钮点击事件
       */
      handleDeleteMemberCar(index) {
        // 在actionDataItem中删除成员
        this.actionDataItem.members[index].memberCarNumber.pop()
        // 在表单验证规则中删除成员
        // this.rules.members[index].memberCarNumber.pop()
      },

      /**
       * 业主改变
       */
      handleOwnerchange(id) {
        this.ownerItem = this.ownersList.find((item) => {
          return item.id === id
        })
        console.log('ownerItemChange', this.ownerItem)
        this.actionDataItem.mobile = this.ownerItem.mobile
        this.actionDataItem.houseId = ''
        this.memberList = []
        this.houseList = this.ownerItem.refHouses
      },

      /**
       * 商户姓名变动
       */
      handleMerchantChange(id) {
        this.merchantItem = this.merchantList.find((item) => {
          return item.id === id
        })
        this.actionDataItem.mobile = this.merchantItem.phone
      },

      /**
       * 房间改变
       */
      handleHousechange(id) {
        const _this = this
        const postData = {
          current: 1,
          size: 999,
          houseId: id
        }
        // this.actionDataItem.houseId = id
        relationList(this.communityId, postData)
          .then((res) => {
            if (res.result) {
              _this.memberList = res.data.records.filter((item) => item.ownerInfo.id !== this.actionDataItem.ownerId)
              _this.$forceUpdate()
            } else {
              _this.noticeError('操作失败', res.message)
            }
          })
          .catch((e) => {
            _this.noticeError('房间成员请求失败', e.message)
          })
      },

      /**
       * 成员改变
       */
      handleMemberchange(index, id) {
        console.log('id', id)
        console.log('index', index)
        var memberInfo = this.memberList.filter((item) => {
          return item.hOwnerId === id
        })
        console.log('memberInfo', memberInfo)
        this.actionDataItem.members[index].memberMobile = memberInfo[index].ownerInfo.mobile
      },
      /**
       * 导出失败数据按键点击
       */
      handleFailExport(id) {
        exportErrorRecords(id)
          .then((res) => {
            const blob = new Blob([res.data])
            // 获取heads中的filename文件名
            const downloadElement = document.createElement('a')
            // 创建下载的链接
            const href = window.URL.createObjectURL(blob)
            downloadElement.href = href
            // 下载后文件名
            downloadElement.download = '批次失败数据.xlsx'
            document.body.appendChild(downloadElement)
            // 点击下载
            downloadElement.click()
            // 下载完成移除元素
            document.body.removeChild(downloadElement)
            // 释放掉blob对象
            window.URL.revokeObjectURL(href)
            this.noticeSuccess('导出成功')
          })
          .catch((e) => {
            this.noticeError('导出失败' + e.message)
          })
      }
    }
  }
</script>
<style scoped lang="scss">
  .gz-dynamtic-input-wrapper {
    display: grid;
    grid-column-gap: 20px;
  }
  .gz-fail-export {
    color: #faa13c;
    cursor: pointer;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
</style>
