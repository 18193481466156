/*
#***********************************************
#
#      Filename: src/api/agreement/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 企业信息相关API
#        Create: 2021-11-29 09:21:07
# Last Modified: 2021-11-29 15:04:07
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 补充企业信息
export function fill(data) {
  const postData = qs.stringify(data)
  console.log('xxx', postData)
  return request({
    url: '/profile/ent/info/fill',
    method: 'post',
    data: postData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 企业(个人信息)
export function info() {
  return request({
    url: '/profile/ent/info',
    method: 'get'
  })
}
