<!--
#***********************************************
#
#      Filename: src/views/List/BlackList.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 黑名单
#        Create: 2021-10-28 16:38:32
# Last Modified: 2021-11-29 18:02:17
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :import-actions="importActions"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增黑名单')"
      @refresh="handleRefresh"
      @export="handleExport"
      @download="handleDownload"
      @import-success="handleImportSuccess"
      @batch-delete="handleBatchDelete('批量删除黑名单', handleBatchDel)" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-for="(col, index) in tableHeadFields"
        :key="index"
        :label="col.label"
        :width="col.width"
        :align="col.align"
        :prop="col.prop">
        <template slot-scope="scope">
          <template v-if="index == 0">
            <span class="carNumber">
              <span class="no">{{ scope.row[col.prop] ? scope.row[col.prop] : '--' }}</span>
              <span class="no2"><span class="no3"></span></span>
            </span>
          </template>
          <template v-else-if="index == 1">
            {{ parseTime(scope.row.dateline) }}
          </template>
          <template v-else>
            {{ scope.row[col.prop] ? scope.row[col.prop] : '--' }}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '修改黑名单')">
              <i class="el-icon-edit"></i>
              编辑
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除黑名单', handleDel)">
              <i class="el-icon-delete"></i>
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="160px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="车牌号码:" prop="carNumber">
              <el-input v-model="actionDataItem.carNumber" placeholder="请输入车牌号码" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="车主姓名（选填）:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请输入车主姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="联系方式（选填）:" prop="phone">
              <el-input v-model="actionDataItem.phone" placeholder="请输入车主电话号码" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="添加原因：" prop="remark">
              <el-input v-model="actionDataItem.remark" type="textarea" placeholder="请输入添加原因" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { list, add, info, batchDelete, deleteItem, exportRecord, update, upload } from '@/api/blackList' // eslint-disable-line
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'BlackList',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          name: '',
          phone: '',
          carNumber: '',
          remark: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          carNumber: [{ required: true, message: '请输入车牌号码', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: false,
            tableHeadToggleShow: false,
            searchPlaceholder: '车牌号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            importDataShow: true,
            downloadTemplateShow: true,
            more: [
              {
                label: '批量删除',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: []
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '车牌号',
            isShow: true,
            width: '160',
            align: 'center',
            prop: 'carNumber'
          },
          {
            label: '创建时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'dateline'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'operId'
          },
          { label: '添加原因', isShow: true, align: 'left', prop: 'remark' }
        ],

        /**
         * 权限信息
         */
        permissions: {
          create: 'blacklist:create',
          info: 'blacklist:info',
          delete: 'blacklist:delete',
          update: 'blacklist:update',
          export: 'blacklist:export',
          import: 'blacklist:import'
        }
      }
    },

    computed: {
      importActions() {
        return process.env.VUE_APP_BASE_API + '/pmBlackList/uploadExcel?pId=' + this.pid + '&operId=' + this.uid
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 获取列表数据
       */
      getList() {
        if (this.pid) {
          this.loading = true

          // 组装查询条件
          const queryData = {}
          queryData.pId = this.pid
          queryData.current = this.currentPage
          queryData.size = this.pageSize
          this.filterData.searchStr && (queryData.carNumber = this.filterData.searchStr)

          list(queryData)
            .then((res) => {
              this.dataList = res.data.records
              this.total = res.data.total
            })
            .catch((err) => {
              this.noticeError(err.message)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.modalObject.currentAction === 'edit') {
              _this.actionDataItem.pId = _this.pid
              update(_this.actionDataItem)
                .then((response) => {
                  _this.modalObject.status = false
                  _this.handleRefresh()
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
            } else {
              _this.actionDataItem.pId = _this.pid
              add(_this.actionDataItem)
                .then((res) => {
                  _this.modalObject.status = false
                  _this.handleRefresh()
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 批量删除记录
       */
      handleBatchDel(ids) {
        const _this = this
        const idArr = []
        ids.forEach((item) => {
          idArr.push(item.id)
        })
        const postData = {}
        postData.ids = idArr.join(',')
        batchDelete(postData)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出黑名单列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.pId = this.pid
            queryData.current = this.currentPage
            queryData.size = this.pageSize
            this.filterData.searchStr && (queryData.carNumber = this.filterData.searchStr)
            exportRecord(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'dateline') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '黑名单列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 下载模板
       */
      handleDownload() {
        window.open('http://matrixoss.oss-cn-zhangjiakou.aliyuncs.com/OPO/data/template/blackList.xls')
      },

      /**
       * 上传成功
       */
      handleImportSuccess() {
        this.noticeSuccess('上传成功')
        this.handleRefresh()
      }
    }
  }
</script>
<style>
  .carNumber {
    background: rgb(0, 41, 206);
    padding: 4px;
    display: inline-block;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 3px #b5b4b4;
    margin: 4px;
  }
  .carNumber .no {
    color: #fff;
    background: rgb(0, 41, 206);
    padding: 4px;
    display: inline-block;
    letter-spacing: 4px;
    border: 2px solid #fff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
  }
  .carNumber .no2 {
    position: absolute;
    left: 6px;
    top: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid rgb(135, 12, 12);
    overflow: hidden;
    margin: 0 auto;
  }
  .carNumber .no2 .no3 {
    background: rgb(135, 12, 12);
    display: block;
    width: 40px;
    height: 4px;
    transform: translateX(-6px) translateY(16px) rotate(-45deg);
  }
</style>
