/*
#***********************************************
#
#      Filename: src/api/ParkingSpaceSales/index.js
#
#        Author: xiaowang 1839648563@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 车位销售统计
#        Create: 2023-06-13 10:09:28
# Last Modified: 2023-06-13 10:09:28
#***********************************************
*/

import request from '@/utils/request'
import qs from 'qs'

// 主列表接口
export function list(data) {
  return request({
    url: '/sellinfo/sellInfo/list',
    method: 'get',
    params: data
  })
}

// 获取操作人员
export function getReport(data) {
  return request({
    url: '/sellinfo/oper',
    method: 'get',
    params: data
  })
}

// 添加
export function add(data) {
  return request({
    url: '/sellinfo/sellInfo',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    dataType: 'JSON',
    data: data
  })
}

// 保存表单
export function update(data) {
  return request({
    url: '/sellinfo/sellInfo',
    method: 'post',
    data: data,
    dataType: 'JSON',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 单条删除
export function deleteItem(id) {
  return request({
    url: '/sellinfo/sellInfo/' + id,
    method: 'delete'
  })
}

export function getParking(data) {
  return request({
    url: '/parking/place/list',
    method: 'get',
    params: data
  })
}

export function getCommunityInfo(id) {
  return request({
    url: `parking/place/info/${id}`,
    method: 'get'
  })
}

export function getSaleInfo(id) {
  return request({
    url: `/sellinfo/sellInfo/${id}`,
    method: 'get'
  })
}

export function getParkingNotSell(pid) {
  return request({
    url: '/sellinfo/plate/list/notsell',
    method: 'get',
    params: {
      parkingId: pid
    }
  })
}
