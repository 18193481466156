<!--
#***********************************************
#
#      Filename: src/views/Setting/EntranceAndExit.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 进出口设置
#        Create: 2021-10-28 16:54:52
# Last Modified: 2021-11-30 09:44:01
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增进出口')"
      @export="handleExport"
      @refresh="handleRefresh"
      @batch-delete="handleBatchDelete('批量删除进出口数据', cbBatchDel)" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === 1" effect="dark" type="success">入口</el-tag>
          <el-tag v-if="scope.row.type === 2" effect="dark" type="danger">出口</el-tag>
          <el-tag v-if="scope.row.type === 3" effect="dark" type="info">云相机进口</el-tag>
          <el-tag v-if="scope.row.type === 4" effect="dark" type="info">云相机出口</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.deviceType === 0">未知</span>
          <span v-if="scope.row.deviceType === 1">机器人</span>
          <span v-if="scope.row.deviceType === 2">车牌识别相机</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />

      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.dateline) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop" />
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '修改进出口设置', beforeEdit)">
              编辑
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除进出口设置', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="通道类型:" prop="type">
              <el-select v-model="actionDataItem.type" placeholder="请选择通道类型">
                <el-option :value="1" label="入口" />
                <el-option :value="2" label="出口" />
                <el-option :value="3" label="云相机进口" />
                <el-option :value="4" label="云相机出口" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通道名称:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请输入通道名称" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="位置标识:" prop="pos">
              <el-input v-model="actionDataItem.pos" placeholder="请输入位置标识" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备类型:" prop="deviceType">
              <el-select v-model="actionDataItem.deviceType" placeholder="请选择设备类型">
                <el-option :value="0" label="未选择" />
                <el-option :value="1" label="机器人" />
                <el-option :value="2" label="车牌识别相机" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备ip:" prop="ip">
              <el-input v-model="actionDataItem.ip" placeholder="请输入设备ip" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通话账号" prop="robotSip">
              <el-input v-model="actionDataItem.robotSip" placeholder="请输入通话账号" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="本地端编号:" prop="deviceNo">
              <el-select v-model="actionDataItem.deviceNo" placeholder="请输入设备ip">
                <el-option
                  v-for="(item, index) in parkingGateList"
                  :key="index"
                  :label="item.name + ':' + item.deviceNo"
                  :value="item.deviceNo"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { list, add, batchDelete, update, deleteItem, info, gatelist } from '@/api/parkingPassages' // eslint-disable-line
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'EntranceAndExit',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          type: '',
          name: '',
          pos: '',
          ip: '',
          robotSip: '',
          deviceNo: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          type: [{ required: true, message: '通道类型不能为空', trigger: 'blur' }],
          deviceType: [{ required: true, message: '设备类型不能为空', trigger: 'blur' }],
          ip: [{ required: true, message: '设备IP不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: false,
            searchShow: true,
            filterToggleShow: false,
            tableHeadToggleShow: false,
            searchPlaceholder: '名称/设备IP'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            more: [
              {
                label: '批量删除',
                emitEvent: 'batch-delete'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '通道类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'type'
          },
          {
            label: '通道名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '位置标识',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'pos'
          },
          {
            label: '设备类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'deviceType'
          },
          {
            label: '设备IP',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'ip'
          },
          {
            label: '通话账号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'robotSip'
          },
          {
            label: '本地端设备',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'deviceNo'
          },
          {
            label: '添加时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'dateline'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'xxx'
          }
        ],
        parkingGateList: [],
        /**
         * 权限信息
         */
        permissions: {
          create: 'passages:create',
          info: 'passages:info',
          delete: 'passages:delete',
          update: 'passages:update',
          export: 'passages:export',
          import: 'passages:import'
        }
      }
    },

    mounted() {
      this.getList()
      this.getParkingGateList()
    },

    methods: {
      /**
       * 编辑账号预处理
       */
      beforeEdit(data) {
        this.actionDataItem.deviceType = Number(data.deviceType)
      },

      /**
       * 获取列表数据
       */
      getList() {
        if (!this.pid) {
          this.noticeError('未选定停车场')
          return
        }
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.PId = this.pid
        this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)

        list(queryData)
          .then((res) => {
            _this.dataList = res.data.records
            _this.total = res.data.total
          })
          .catch((e) => {
            _this.noticeError(e.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },
      getParkingGateList() {
        if (!this.pid) {
          this.noticeError('未选定停车场')
          return
        }
        const _this = this
        this.loading = true
        // 组装查询条件
        gatelist(this.pid)
          .then((res) => {
            _this.parkingGateList = res.data
          })
          .catch((e) => {
            _this.noticeError(e.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.modalObject.currentAction === 'edit') {
              _this.actionDataItem.id
              update(_this.actionDataItem)
                .then((res) => {
                  _this.noticeSuccess('编辑成功')
                  _this.handleRefresh()
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
                .finally(() => {
                  _this.modalObject.status = false
                })
            } else {
              delete _this.actionDataItem.id
              _this.actionDataItem.pId = _this.pid
              add(_this.actionDataItem)
                .then((res) => {
                  _this.noticeSuccess('添加成功')
                  _this.handleRefresh()
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
                .finally(() => {
                  _this.modalObject.status = false
                })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出进出口列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const queryData = {}
            queryData.PId = this.pid
            this.filterData.searchKey && (queryData.kw = this.filterData.searchKey)
            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'type') {
                        return ['入口', '出口', '云相机进口', '云相机出口'][v[j] - 1]
                      } else if (j === 'deviceType') {
                        return ['未知', '机器人', '车牌世界相机'][v[j]]
                      } else if (j === 'dateline') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '进出口列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
                console.log('导出进出口列表请求失败:', err.message)
              })
          })
          .catch((err) => {
            console.log('导出进出口列表失败:', err.message)
          })
      },

      /**
       * 批量删除
       */
      cbBatchDel(sel) {
        const _this = this
        const postData = {}
        const ids = []
        sel.forEach((item) => {
          ids.push(item.id)
        })
        postData.ids = ids
        batchDelete(postData)
          .then((res) => {
            _this.noticeSuccess('批量删除成功')
            _this.handleRefresh()
          })
          .catch((e) => {
            _this.noticeError(e.message)
          })
      }
    }
  }
</script>
