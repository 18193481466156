<!--
#***********************************************
#
#      Filename: src/views/index.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 首页
#        Create: 2021-08-18 22:12:58
# Last Modified: 2021-11-15 16:38:38
#***********************************************
-->
<template>
  <div class="app-container vacancy">
    <!-- 更新时间 -->
    <div class="top">
      <div class="top-time">
        <span>上次更新时间：</span>
        <span class="top-lasttime">{{ vacancyInfo.lastUpdateTime || '未知' }}</span>
        <span>{{ remainTime }}秒后自动刷新</span>
      </div>
      <el-button class="top-refresh" size="mini" @click="getInfo">刷新{{ loading ? '中' : '' }}</el-button>
    </div>
    <!-- 信息展示 -->
    <el-row :gutter="10" class="info">
      <el-col :span="8">
        <div class="grid-content">
          <div class="tit">停车场名称</div>
          <div class="con">{{ vacancyInfo.parkName || '暂无' }}</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content">
          <div class="tit">车位总量</div>
          <div class="con">
            <span>{{ vacancyInfo.totalPlateCount || 0 }}</span>
            <el-button class="edit" type="warning plain" size="mini" @click="openEditDialog(1)">修改</el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content">
          <div class="tit">剩余空闲车位</div>
          <div class="con">
            <span>{{ vacancyInfo.freePlateCount || 0 }}</span>
            <el-button class="edit" type="warning plain" size="mini" @click="openEditDialog(2)">修改</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 修改弹窗 -->
    <el-dialog
      :title="editType == 2 ? '修改剩余空闲车位' : '修改车位总量'"
      :visible.sync="editDialogVisible"
      width="30%"
      :before-close="closeEditDialog">
      <div class="dialogInfo">
        <el-form
          ref="editForm"
          :model="editForm"
          :rules="rules"
          :label-width="editType == 2 ? '120px' : '80px'"
          class="demo-editForm">
          <el-form-item v-if="editType == 1" label="车位总量" prop="totalPlateCount">
            <el-input v-model.number="editForm.totalPlateCount" type="number"></el-input>
          </el-form-item>
          <el-form-item v-if="editType == 2" label="剩余空闲车位" prop="freePlateCount">
            <el-input v-model.number="editForm.freePlateCount" type="number"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEditDialog">取 消</el-button>
        <el-button type="primary" @click="editInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getInfo, updateVacancy } from '@/api/vacancy'
  import { mapState } from 'vuex'
  export default {
    name: 'Index',
    data() {
      var checkInt = (rule, value, callback) => {
        if (!value && value !== 0) {
          return callback(new Error('不能为空！'))
        } else if (!Number.isInteger(value)) {
          callback(new Error('请输入数字'))
        } else if (value < 0) {
          callback(new Error('不能为负数'))
        } else if (rule.field === 'freePlateCount' && this.editForm.totalPlateCount < value) {
          callback(new Error('剩余空闲车位不能超过车位总量'))
        } else if (rule.field === 'totalPlateCount' && this.editForm.freePlateCount > value) {
          callback(new Error('车位总量不能小于剩余空闲车位'))
        } else {
          callback()
        }
      }
      return {
        vacancyInfo: {
          parkName: '',
          totalPlateCount: '', // 车位总量
          freePlateCount: '', // 车位余量
          lastUpdateTime: '' // 最后更新时间
        },
        remainTime: 30,
        timer: null,
        editType: 1, // 总量1 ，剩余2
        loading: false,
        editDialogVisible: false,
        editForm: {
          totalPlateCount: '', // 车位总量
          freePlateCount: '' // 车位余量
        },
        rules: {
          totalPlateCount: [{ validator: checkInt, trigger: 'blur' }],
          freePlateCount: [{ validator: checkInt, trigger: 'blur' }]
        }
      }
    },
    computed: {
      ...mapState({
        currentParking: (state) => state.user.currentParking
      })
    },
    created() {
      this.getInfo()
    },
    mounted() {},
    beforeDestroy() {
      // 清除定时器
      this.clearTimer()
    },
    methods: {
      // 倒计时
      countdown() {
        const _this = this
        this.remainTime = 30
        _this.timer = setInterval(() => {
          _this.remainTime = _this.remainTime - 1
          if (_this.remainTime <= 0) {
            this.getInfo()
          }
        }, 1000)
      },
      // 清除定时器
      clearTimer() {
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
          this.remainTime = 0
        }
      },
      // 获取信息
      getInfo() {
        if (this.loading) {
          return
        }
        const _this = this
        console.log('getInfo!!')
        this.loading = true
        this.clearTimer()
        getInfo({ pId: this.currentParking.id })
          .then((res) => {
            const data = res.data
            _this.vacancyInfo = {
              id: data.id,
              parkName: data.parkingName,
              totalPlateCount: data.totalPlateCount, // 车位总量
              freePlateCount: data.freePlateCount, // 车位余量
              lastUpdateTime: data.updateTime || data.createTime // 最后更新时间
            }
          })
          .catch((err) => {
            this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
            _this.countdown()
          })
      },
      // 打开弹窗
      openEditDialog(type) {
        this.editType = type
        this.editDialogVisible = true
        this.editForm.freePlateCount = this.vacancyInfo.freePlateCount
        this.editForm.totalPlateCount = this.vacancyInfo.totalPlateCount
      },
      // 关闭弹窗
      closeEditDialog() {
        this.$refs.editForm.resetFields()
        this.editDialogVisible = false
      },
      // 编辑信息
      editInfo() {
        const _this = this
        this.$refs.editForm.validate((valid) => {
          if (valid) {
            const obj = {
              id: this.vacancyInfo.id
            }
            if (_this.editType === 2) {
              obj.freePlateCount = _this.editForm.freePlateCount
            } else {
              obj.totalPlateCount = _this.editForm.totalPlateCount
            }
            updateVacancy(obj)
              .then((res) => {
                this.getInfo()
                this.noticeSuccess()
                this.closeEditDialog()
              })
              .catch((err) => {
                this.noticeError(err.message)
              })
          }
        })
      }
    }
  }
</script>
<style lang="scss" scope>
  .app-container {
    &.vacancy {
      .top {
        padding-top: 20px;
        text-align: right;
        color: #707070;
        span {
          font-weight: 100;
        }
        .top-time {
          margin-right: 20px;
          display: inline-block;
          .top-lasttime {
            margin-right: 10px;
          }
        }
      }
      .info {
        margin-top: 60px;
        text-align: center;
        .tit {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 35px;
        }
        .con {
          position: relative;
          line-height: 28px;
          .edit {
            position: absolute;
            margin-left: 40px;
          }
        }
      }
      .dialogInfo {
        padding-right: 20px;
      }
    }
  }
</style>
