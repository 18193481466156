/*
#***********************************************
#
#      Filename: src/utils/constants/regex.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 正则表达式常量
#        Create: 2021-08-23 23:38:36
# Last Modified: 2021-10-19 17:08:46
#***********************************************
*/
export const RX_ARRAY_NOTATION = /\[(\d+)]/g
export const RX_BV_PREFIX = /^(BV?)/
export const RX_DIGITS = /^\d+$/
export const RX_DIGITS_AND_SPACE = /^[\d|\s]+$/
export const RX_CHINESE = /^[\u4e00-\u9fa5]+$/
export const RX_ALPHA = /^[A-Za-z]+$/
export const RX_UPPERCASE = /^[A-Z]+$/
export const RX_LOWERCASE = /^[a-z]+$/
export const RX_WORD = /^\w+$/
export const RX_WORD_CH = /^[\u4e00-\u9fa5A-Za-z0-9_]+$/
export const RX_ALPHA_AND_DIGITS = /^[A-Za-z0-9]+$/
export const RX_EXTENSION = /^\..+/
export const RX_HASH = /^#/
export const RX_HASH_ID = /^#[A-Za-z]+[\w\-:.]*$/
export const RX_HTML_TAGS = /(<([^>]+)>)/gi
export const RX_HYPHENATE = /\B([A-Z])/g
export const RX_LOWER_UPPER = /([a-z])([A-Z])/g
export const RX_NUMBER = /^[0-9]*\.?[0-9]+$/
export const RX_MONEY = /^[0-9]*\.?[0-9]{2}$/
export const RX_PLUS = /\+/g
export const RX_REGEXP_REPLACE = /[-/\\^$*+?.()|[\]{}]/g
export const RX_SPACES = /[\s\uFEFF\xA0]+/g
export const RX_SPACE_SPLIT = /\s+/
export const RX_STAR = /\/\*$/
export const RX_START_SPACE_WORD = /(\s|^)(\w)/g
export const RX_TRIM_LEFT = /^\s+/
export const RX_TRIM_RIGHT = /\s+$/
export const RX_UNDERSCORE = /_/g
export const RX_UN_KEBAB = /-(\w)/g
export const RX_EMAIL = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
// export const RX_MOBILE = /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/
export const RX_MOBILE = /^1\d{10}$/
export const RX_MOBILE_WITH_SPACE = /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\s\d{4}\s\d{4}$/
export const RX_CAPTCHA = /^\d{6}$/
export const RX_PASSWORD = /^[a-zA-Z0-9-*/+.~!@#$%^&*()]{8,18}$/
export const RX_ACCOUNT = /^[a-zA-Z0-9-*/+.~!@#$%^&*()]{0,20}$/

/**
 * 时间日期
 */

// 匹配日期 "1999-12-20", "1999-1-1", "1999-01-20T22:51:49.118Z", "1999-01-02 13:00:00"
export const RX_DATE = /^\d+-\d\d?-\d\d?(?:\s|T|$)/

// 用于分割 YYYY-MM-DD 时间字符串
export const RX_DATE_SPLIT = /-|\s|T/

// 匹配时间
export const RX_TIME = /^([0-1]?[0-9]|2[0-3]):[0-5]?[0-9](:[0-5]?[0-9])?$/
