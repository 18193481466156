<!--
#***********************************************
#
#      Filename: src/views/List/Parking.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 车位管理
#        Create: 2021-11-11 08:52:34
# Last Modified: 2021-11-28 17:01:56
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      :import-actions="importActions"
      @filter-change="filterChange"
      @add="add('新增车位')"
      @refresh="handleRefresh"
      @export="handleExport"
      @download="handleDownload"
      @import-success="handleImportSuccess"
      @batch-delete="handleBatchDelete('批量删除车位', handleBatchDel)">
      <div slot="custom-left">
        <el-button type="primary" size="mini" plain @click="handleImportRecord">导入记录</el-button>
      </div>
    </ContentHeader>
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" size="mini" stripe border @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />

      <el-table-column
        v-for="(headerFeild, index) in tableHeadFields"
        :key="index"
        v-show="headerFeild.show"
        :label="headerFeild.label"
        :width="headerFeild.width"
        :align="headerFeild.align"
        :prop="headerFeild.prop">
        <template slot-scope="scope">
          <template v-if="headerFeild.prop == 'status'">
            <span v-if="scope.row.status === 0" class="text-light-blue">&#8226;&nbsp;未使用</span>
            <span v-if="scope.row.status === 1" class="text-orange">&#8226;&nbsp;已占用</span>
          </template>
          <template v-else-if="headerFeild.prop == 'cqType'">
            <span v-if="scope.row.cqType === 0" class="text-light-blue">&#8226;&nbsp;未知</span>
            <span v-if="scope.row.cqType === 1" class="text-orange">&#8226;&nbsp;人防</span>
            <span v-if="scope.row.cqType === 3" class="text-orange">&#8226;&nbsp;个人</span>
            <span v-if="scope.row.cqType === 2" class="text-orange">&#8226;&nbsp;公司</span>
          </template>
          <template v-else>{{ scope.row[headerFeild.prop] }}</template>
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-yellow" @click="edit(scope.row, '修改车位', beforeEdit)">
              <i class="el-icon-edit"></i>
              编辑
            </div>
            <div class="gz-text-btn gz-text-btn-red" @click="handleDelete(scope.row.id, '删除车位', handleDel)">
              <i class="el-icon-delete"></i>
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="车位号:" prop="placeNo">
              <el-input v-model="actionDataItem.placeNo" placeholder="请输入车位号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产权室号:" prop="cqsNo">
              <el-input v-model="actionDataItem.cqsNo" placeholder="请输入产权室号" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="小区区域:" prop="apartment">
              <el-input v-model="actionDataItem.apartment" placeholder="请输入小区区域划分" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="停车场区域:" prop="areaCode">
              <el-input v-model="actionDataItem.areaCode" placeholder="请输入停车场区域划分" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所在楼层:" prop="floor">
              <el-input v-model="actionDataItem.floor" placeholder="请输入所在楼层" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产权所属:" prop="cqType">
              <el-select v-model="actionDataItem.cqType" placeholder="请选择产权所属">
                <el-option v-for="item in optionsCqType" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="actionDataItem.remark" type="textarea" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->
    <!-- 导入记录dialog -->
    <el-dialog
      title="导入记录"
      :visible.sync="importInfoDialog.show"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-table :data="importInfoDialog.data" size="mini" stripe border>
        <el-table-column label="批次号" :width="200" align="center" prop="id" />
        <el-table-column label="开始时间" :width="200" align="center" prop="createTime" />
        <el-table-column label="状态" :width="200" align="center" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">
              <i class="el-icon-time" />
            </span>
            <span v-if="scope.row.status === 1">
              <i class="el-icon-loading" />
            </span>
            <span v-if="scope.row.status === 2" style="color: green">
              <i class="el-icon-circle-check" />
            </span>
          </template>
        </el-table-column>
        >
        <el-table-column label="完成时间" :width="200" align="center" prop="finishTime" />
        <el-table-column label="导入总数(条)" :width="200" align="center" prop="total" />
        <el-table-column label="成功数(条)" :width="200" align="center" prop="success" />
        <el-table-column label="失败数(条)" :width="200" align="center" prop="success">
          // eslint-disable-next-line vue/no-unused-vars
          <template slot-scope="scope">
            <span>
              {{ scope.row.total - scope.row.success > 0 ? scope.row.total - scope.row.success : '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :width="200" align="center" prop="success">
          <template slot-scope="scope">
            <span v-if="scope.row.total - scope.row.success <= 0 || scope.row.status !== 2">--</span>
            <span v-else class="gz-fail-export" @click="handleFailExport(scope.row.id)">导出失败数据</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页模块 -->
      <pagination
        v-show="importInfoDialog.total > 0"
        :total="importInfoDialog.total"
        :page.sync="importInfoDialog.current"
        :limit.sync="importInfoDialog.size"
        @pagination="chanageImportPage" />
      <!-- end 分页模块 -->
    </el-dialog>

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { list, deleteItem, add, update, batchDelete, importBatchList } from '@/api/parking'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'Parking',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        dialog_loading: false,
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          placeNo: '',
          cqsNo: '',
          remark: '',
          cqType: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          placeNo: [{ required: true, message: '车位号不能为空', trigger: 'blur' }],
          // cqsNo: [{ required: true, message: '产权室号不能为空', trigger: 'blur' }],
          remark: [{ required: true, message: '备注不能为空', trigger: 'blur' }]
          // cqType: [{ required: true, message: '产权类型不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '车位号/产权室号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            downloadTemplateShow: true,
            importDataShow: true,
            more: [
              // {
              //   label: '批量删除',
              //   emitEvent: 'batch-delete'
              // }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'select',
                name: 'status',
                placeholder: '请选择车位状态',
                options: [
                  { label: '未使用', value: '0' },
                  { label: '已占用', value: '1' }
                ]
              }
            ]
          }
        },
        /**
         * 查看导入对话框控制对象
         */
        importInfoDialog: {
          show: false,
          current: 1,
          size: 10,
          total: 0,
          data: []
        },
        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '车位号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'placeNo'
          },
          {
            label: '产权室号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'cqsNo'
          },
          {
            label: '车位状态',
            isShow: true,
            width: '100',
            align: 'center',
            prop: 'status'
          },
          {
            label: '产权类型',
            isShow: true,
            width: '100',
            align: 'center',
            prop: 'cqType'
          },
          // {
          //   label: '所属小区',
          //   isShow: true,
          //   width: '140',
          //   align: 'center',
          //   prop: 'communityName'
          // },
          // {
          //   label: '停车场',
          //   isShow: true,
          //   width: '200',
          //   align: 'center',
          //   prop: 'parkingName'
          // },
          {
            label: '小区区域',
            isShow: true,
            width: '100',
            align: 'center',
            prop: 'apartment'
          },
          {
            label: '停车场区域',
            isShow: true,
            width: '100',
            align: 'center',
            prop: 'areaCode'
          },
          {
            label: '所在楼层',
            isShow: true,
            width: '100',
            align: 'center',
            prop: 'floor'
          },

          {
            label: '备注',
            isShow: true,
            align: 'left',
            prop: 'remark'
          }
        ],
        optionsCqType: [
          { id: 0, name: '加划' },
          { id: 1, name: '人防' },
          { id: 2, name: '公司' },
          { id: 3, name: '个人' }
        ],
        permissions: {
          create: 'parking:place:create',
          info: 'parking:place:info',
          delete: 'parking:place:delete',
          update: 'parking:place:update',
          export: 'parking:place:export',
          import: 'parking:place:import'
        }
      }
    },

    computed: {
      // 导入地址
      importActions() {
        // return process.env.VUE_APP_BASE_API +
        //   '/parking/place/importPlace/' + this.pid
        return process.env.VUE_APP_BASE_API + '/parking/place/importBatch/submit/' + this.pid
      }
    },

    mounted() {
      this.getList()
      // this.getcqTypeList()
    },

    methods: {
      /**
       * 获取停车场的类型
       */
      // getcqTypeList() {
      //   var datacqType = {}
      //   datacqType.enable = true
      //   datacqType.parkingId = this.pid
      //   cqType(datacqType).then((res) => {
      //     console.log('车位号', res)
      //     this.optionsCqType = res.data.records
      //   })
      // },
      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.parkingId = this.pid
        this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
        this.filterData.status && (queryData.status = this.filterData.status)

        list(queryData)
          .then((res) => {
            if (res.result) {
              _this.dataList = res.data.records
              console.log(_this.dataList)
              _this.total = res.data.total
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.error('车位列表查询出错', err)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },
      beforeEdit(data) {
        this.actionDataItem = data
      },

      /**
       * 提交信息
       */
      submitForm() {
        this.dialog_loading = true
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            _this.actionDataItem.parkingId = _this.pid
            if (_this.modalObject.currentAction === 'edit') {
              const id = _this.actionDataItem.id
              delete _this.actionDataItem.id
              delete _this.actionDataItem.createTime
              delete _this.actionDataItem.updateTime
              update(id, _this.actionDataItem)
                .then((response) => {
                  if (response.result) {
                    _this.noticeSuccess(response.message)
                    _this.handleRefresh()
                    _this.modalObject.status = false
                  } else {
                    _this.noticeError('请求失败')
                  }
                  _this.dialog_loading = false
                })
                .catch((err) => {
                  console.error(err)
                  _this.dialog_loading = false
                })
            } else {
              delete _this.actionDataItem.id
              delete _this.actionDataItem.createTime
              delete _this.actionDataItem.updateTime
              add(_this.actionDataItem)
                .then((res) => {
                  _this.actionDataItem.cxsNo = _this.actionDataItem.cqsNo
                  if (res.result) {
                    _this.noticeSuccess(res.message)
                    _this.handleRefresh()
                    _this.modalObject.status = false
                  } else {
                    _this.noticeError('请求失败')
                  }
                  _this.dialog_loading = false
                })
                .catch((err) => {
                  console.error(err)
                  _this.dialog_loading = false
                })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id).then((res) => {
          if (res.result) {
            _this.noticeSuccess('删除成功')
            _this.handleRefresh()
          } else {
            _this.noticeError(res.message)
          }
        })
      },

      /**
       * 批量删除记录
       */
      handleBatchDel(ids) {
        // this.noticeError('暂时没有实现批量删除接口')
        const _this = this
        const idArr = []
        ids.forEach((item) => {
          idArr.push(item.id)
        })
        const postData = {}
        postData.placeIds = idArr
        batchDelete(postData)
          .then((res) => {
            if (res.result) {
              _this.noticeSuccess('批量删除成功')
              _this.handleRefresh()
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            console.error('车位批量删除失败', err)
          })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出车位列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.current = 1
            queryData.size = 9999
            queryData.parkingId = this.pid
            this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
            this.filterData.status && (queryData.status = this.filterData.status)

            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.records.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'status') {
                        return ['已占用', '未使用'][v[j]]
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '车位列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.megError('导出失败')
                console.error(err)
              })
          })
          .catch((err) => {
            console.log('取消导出', err)
          })
      },
      chanageImportPage(page) {
        this.importInfoDialog.current = page.page
        this.importInfoDialog.size = page.limit
        this.handleImportRecord()
      },
      /**
       * 查看导入记录按键点击
       */
      handleImportRecord() {
        const parkingId = this.pid
        const queryData = {}
        // queryData.status = ''
        queryData.current = this.importInfoDialog.current
        queryData.size = this.importInfoDialog.size
        importBatchList(queryData, parkingId)
          .then((res) => {
            console.log(res, '+++=')
            if (res.result) {
              this.importInfoDialog.show = true
              this.importInfoDialog.data = res.data.records
              this.importInfoDialog.total = res.data.total
            } else {
              this.noticeError('获取失败', res.message)
            }
          })
          .catch((e) => {
            console.log(e)
            this.noticeError('获取失败', e.message)
          })
      },

      /**
       * 下载模板
       */
      handleDownload() {
        window.open(
          'http://matrixoss.gsgzwlw.com/upload/files/20220518/%E8%BD%A6%E4%BD%8D%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF-v1.1.xlsx'
        )
      },

      /**
       * 上传成功
       */
      handleImportSuccess() {
        this.noticeSuccess('上传成功')
        this.handleRefresh()
      }
    }
  }
</script>
