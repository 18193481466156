/*
#***********************************************
#
#      Filename: src/api/blackList/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 物业费设置
#        Create: 2021-11-23 00:02:38
# Last Modified: 2021-11-26 13:56:29
#***********************************************
*/
import request from '@/utils/request'

// 编辑
export function update(id, data) {
  return request({
    url: '/property/fee/setting/put/' + id,
    method: 'post',
    data: data
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/property/fee/setting/list/' + id,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}
