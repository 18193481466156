/*
#***********************************************
#
#      Filename: src/views/mixins/drawer.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: Drawer框相关混入
#        Create: 2021-11-10 11:21:08
# Last Modified: 2021-11-27 20:28:40
#***********************************************
*/

export default {
  data() {
    return {
      /**
       * modal控制对象
       */
      drawerObject: {
        currentAction: 'house',
        status: false,
        loading: false
      },

      /**
       * modal对话框表单数据绑定
       */
      actionDatasItem: {}
    }
  },
  methods: {
    openDrawer(dataObj, title, cb = () => {}) {
      this.drawerObject.currentAction = 'house'
      this.drawerObject.title = title
      this.drawerObject.status = true
      cb(dataObj)
    },
    closeDrawer() {
      this.drawerObject.status = false
      this.drawerObject.loading = false
    }
  }
}
