<!-- @format -->

<!--
#***********************************************
#
#      Filename: gz-adserver-front/src/views/login.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 登录页面
#        Create: 2021-08-18 11:23:51
# Last Modified: 2021-11-11 21:56:28
#***********************************************
-->
<template>
  <div class="login">
    <div class="login-box">
      <img class="gz-login-logo" src="~@/assets/logo/logo.png" />
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
        <h3 class="title">国臻报表管理系统</h3>
        <el-form-item prop="account">
          <el-input
            v-model="loginForm.account"
            type="text"
            auto-complete="off"
            placeholder="账号"
            @keyup.enter.native="handleLogin">
            <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            type="password"
            auto-complete="off"
            placeholder="密码"
            @keyup.enter.native="handleLogin">
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
          </el-input>
          <!-- <el-input v-model="loginForm.applyId" type="text" /> -->
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="captcha">
              <el-input
                v-model="loginForm.captcha"
                type="text"
                auto-complete="off"
                placeholder="验证码"
                @keyup.enter.native="handleLogin">
                <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="cursor: pointer" title="点击更换验证码">
            <el-image v-no-more-click class="captcha-img" :src="captchaImg" @click="refreshCaptcha" />
          </el-col>
        </el-row>
        <el-form-item>
          <el-checkbox v-model="loginForm.rememberMe">记住密码</el-checkbox>
        </el-form-item>
        <el-form-item style="width: 100%">
          <el-button
            :loading="loading"
            size="medium"
            type="primary"
            style="width: 100%"
            @click.native.prevent="handleLogin">
            <span v-if="!loading">登 录</span>
            <span v-else>登录中...</span>
          </el-button>
        </el-form-item>
      </el-form>
      <!--  底部  -->
      <div class="el-login-footer">
        <span>Copyright © 2018-2023 gz All Rights Reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { encrypt, decrypt } from '@/utils/jsencrypt'
  import { captchaUrl } from '@/config'
  import { platformOneRoutes } from '@/router'
  import Axios from 'axios'
  import { getUUID } from '@/utils/aliOss'
  import Cookies from 'js-cookie'
  export default {
    name: 'Login',
    components: {},
    data() {
      return {
        // 验证码图片
        captchaImg: '',
        applyId: 'captcha_applyId',
        // 表单数据
        loginForm: {
          account: '',
          password: '',
          captcha: '',
          rememberMe: false,
          applyId: ''
        },
        // 表单验证规则
        loginRules: {
          account: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
          password: [{ required: true, trigger: 'blur', message: '密码不能为空' }],
          captcha: [{ required: true, trigger: 'blur', message: '验证码不能为空' }]
        },
        loading: false,
        redirect: undefined
      }
    },

    watch: {
      $route: {
        handler: function (route) {
          this.redirect = route.query && route.query.redirect
        },
        immediate: true
      }
    },

    created() {
      this.getCookie()
      this.refreshCaptcha()
    },

    methods: {
      /**
       * 获取cookie数据
       */
      getCookie() {
        const account = Cookies.get('parking-admin-account')
        const password = Cookies.get('parking-admin-password')
        const rememberMe = Cookies.get('parking-admin-rememberMe')
        this.loginForm = {
          account: account === undefined ? this.loginForm.account : account,
          password: password === undefined ? this.loginForm.password : decrypt(password),
          rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
        }
      },

      /**
       * 登录
       */
      handleLogin() {
        const _this = this
        _this.$store.dispatch('tagsView/delAllViews')
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            _this.loading = true
            if (_this.loginForm.rememberMe) {
              Cookies.set('parking-admin-account', _this.loginForm.account, { expires: 30 })
              Cookies.set('parking-admin-password', encrypt(_this.loginForm.password), { expires: 30 })
              Cookies.set('parking-admin-captchaCode', _this.loginForm.captcha, { expires: 30 })
              Cookies.set('parking-admin-rememberMe', _this.loginForm.rememberMe, { expires: 30 })
            } else {
              Cookies.remove('username')
              Cookies.remove('password')
              Cookies.remove('captchaCode')
              Cookies.remove('rememberMe')
            }
            console.log('提交的数据', _this.loginForm)
            _this.$store
              .dispatch('user/Login', _this.loginForm)
              .then(() => {
                // 添加动态路由到vuex中,路由守卫判断vuex中的动态路由数组是否为空,加载动态路由
                _this.$router.addRoutes(platformOneRoutes)
                console.log('platformOne', platformOneRoutes)
                // _this.$store.commit('permission/SET_ROUTES', platformOneRoutes)
                _this.$store.commit('permission/SET_SIDEBAR_ROUTERS', platformOneRoutes)
                _this.$store.commit('permission/SET_PLATFORM', 'platformOne')
                _this.$router.push({ path: '/workplate/plate' })
              })
              .catch((e) => {
                console.log('发生错误~', e)
                _this.loading = false
                _this.refreshCaptcha()
              })
          }
        })
      },
      /**
       * 刷新验证码
       */
      refreshCaptcha() {
        this.applyId = getUUID()
        this.loginForm.applyId = this.applyId
        Axios.get(captchaUrl + '?t=' + Date.parse(new Date()), {
          params: {
            applyId: this.applyId
          },
          responseType: 'blob'
        }).then((response) => {
          this.captchaImg = window.URL.createObjectURL(response.data)
        })
      }
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss">
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url('../../../assets/images/login-background.jpg');
    background-size: cover;

    .login-box {
      position: relative;
      width: 1060px;
      height: 630px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-image: url('../../../assets/images/login_box_bg.png');
      background-size: 100% 100%;
      .gz-login-logo {
        position: absolute;
        top: 40px;
        right: 250px;
      }

      .login-form {
        border-radius: 6px;
        background: #ffffff;
        width: 370px;
        margin-right: 20px;
        padding: 25px 25px 5px 25px;

        .title {
          margin: 0px auto 30px auto;
          text-align: center;
          color: #707070;
        }

        .input-icon {
          height: 39px;
          width: 14px;
          margin-left: 2px;
        }
        .captcha-img {
          height: 36px;
        }
      }
    }
  }

  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }
</style>
