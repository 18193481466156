/*
#***********************************************
#
#      Filename: src/api/blackList/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 黑名单列表
#        Create: 2021-11-23 00:02:38
# Last Modified: 2021-11-26 13:56:29
#***********************************************
*/
import request from '@/utils/request'

// 添加
export function add(data) {
  return request({
    url: '/pmBlackList/add',
    method: 'post',
    params: data
  })
}

// 批量删除
export function batchDelete(data) {
  return request({
    url: '/pmBlackList/batchdel',
    method: 'post',
    params: data
  })
}

// 删除
export function deleteItem(id, data) {
  return request({
    url: '/pmBlackList/delete/' + id,
    method: 'post',
    params: data
  })
}

// 导出
export function exportRecord(data) {
  return request({
    url: '/pmBlackList/export',
    method: 'get',
    params: data
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/pmBlackList/info/' + id,
    method: 'get'
  })
}

// 详细信息
export function list(data) {
  return request({
    url: '/pmBlackList/list',
    method: 'get',
    params: data
  })
}

// 更新数据
export function update(data) {
  return request({
    url: '/pmBlackList/update',
    method: 'post',
    params: data
  })
}

// 白名单导入
export function upload(data) {
  return request({
    url: '/pmBlackList/uploadExcel',
    method: 'post'
  })
}
