<!--
#***********************************************
#
#      Filename: src/views/List/BlackList.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 灰名单
#        Create: 2021-10-28 16:38:32
# Last Modified: 2021-11-29 18:02:17
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :import-actions="importActions"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增灰名单')"
      @refresh="handleRefresh"
      @export="handleExport"
      @download="handleDownload"
      @import-success="handleImportSuccess"
      @batch-delete="handleBatchDelete('批量删除灰名单', handleBatchDel)" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" border size="mini" :span-method="objectSpanMethod" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.startTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.endTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.status === '1'">使用期限内</span>
          <span v-if="scope.row.status === '2'">已过期</span>
          <span v-if="scope.row.status === '3'">已冻结</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop" />
      <el-table-column
        v-if="tableHeadFields[9].isShow"
        :label="tableHeadFields[9].label"
        :width="tableHeadFields[9].width"
        :align="tableHeadFields[9].align"
        :prop="tableHeadFields[9].prop" />
      <el-table-column
        v-if="tableHeadFields[10].isShow"
        :label="tableHeadFields[10].label"
        :width="tableHeadFields[10].width"
        :align="tableHeadFields[10].align"
        :prop="tableHeadFields[10].prop" />
      <el-table-column
        v-if="tableHeadFields[11].isShow"
        :label="tableHeadFields[11].label"
        :width="tableHeadFields[11].width"
        :align="tableHeadFields[11].align"
        :prop="tableHeadFields[11].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.dateline) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '修改灰名单', beforeEdit)">
              编辑
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除灰名单', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="姓名:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请输入姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话:" prop="phone">
              <el-input v-model="actionDataItem.phone" placeholder="请输入电话" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="车牌号:" required>
              <div
                class="gz-dynamtic-input-wrapper"
                :style="{
                  'grid-template-columns':
                    'repeat(' + (actionDataItem.carNumberArr ? actionDataItem.carNumberArr.length + 1 : 1) + ', 1fr)'
                }">
                <el-form-item
                  v-for="(item, index) in actionDataItem.carNumberArr"
                  :key="index"
                  label=""
                  :prop="'carNumberArr.' + index + '.carNumber'">
                  <el-input v-model="item.carNumber" placeholder="请输入车牌号码" />
                </el-form-item>
                <div class="gz-add-and-sub-btn-group">
                  <el-button size="mini" type="primary" icon="el-icon-plus" circle @click="handleAddItem()" />
                  <el-button size="mini" type="primary" icon="el-icon-minus" circle @click="handleDeleteItem(index)" />
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="生效时间" prop="startTime">
              <el-date-picker
                v-model="actionDataItem.startTime"
                type="date"
                value-format="timestamp"
                placeholder="请选择生效时间" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="失效时间" prop="endTime">
              <el-date-picker
                v-model="actionDataItem.endTime"
                type="date"
                value-format="timestamp"
                placeholder="请选择生效时间" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="车位号" prop="roomNum">
              <el-input v-model="actionDataItem.roomNum" placeholder="请输入车位号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="费用" prop="price">
              <el-input v-model="actionDataItem.price" placeholder="请输入费用" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="场内月租数" prop="multVip">
              <el-select v-model="actionDataItem.multVip" placeholder="请选择场内月租金数">
                <el-option :value="1" />
                <el-option :value="2" />
                <el-option :value="3" />
                <el-option :value="4" />
                <el-option :value="5" />
                <el-option :value="6" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车辆类型" prop="carType">
              <el-select v-model="actionDataItem.carType" placeholder="请选择车辆类型">
                <el-option label="其他" :value="1" />
                <el-option label="地面包月用户" :value="2" />
                <el-option label="地下包月用户" :value="3" />
                <el-option label="地面包月商户" :value="4" />
                <el-option label="服务费" :value="5" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="actionDataItem.remark" type="textarea" placeholder="请输入备注" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import moment from 'moment'

  import { list, add, batchDelete, deleteItem, exportRecord, update, info } from '@/api/grayList'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'GrayList',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 灰名单添加修改表单数据绑定
         */
        formData: {
          id: '',
          name: '',
          phone: '',
          carNumber: '',
          carNumberArr: [
            {
              carNumber: ''
            }
          ],
          startTime: '',
          endTime: '',
          roomNum: '',
          price: '',
          multVip: '',
          carType: '',
          remark: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          name: [{ required: true, message: '业主姓名不能为空', trigger: 'blur' }],
          phone: [{ required: true, message: '电话号码不能为空', trigger: 'blur' }],
          carNumberArr: [
            {
              carNumber: [{ required: true, message: '请输入车牌号码', trigger: 'blur' }]
            }
          ],
          startTime: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
          endTime: [{ required: true, message: '请选择结束时间', trigger: 'blur' }],
          roomNum: [{ required: true, message: '请填写车位号', trigger: 'blur' }],
          price: [{ required: true, message: '请填写费用', trigger: 'blur' }],
          multVip: [{ required: true, message: '请选择场内月租数', trigger: 'blur' }],
          carType: [{ required: true, message: '请选择车辆类型', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: false,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '姓名'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            importDataShow: true,
            downloadTemplateShow: true,
            more: [
              {
                label: '批量删除',
                permission: 'delete',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'input',
                name: 'name',
                placeholder: '请输入姓名'
              },
              {
                type: 'input',
                name: 'carNumber',
                placeholder: '请输入车牌号'
              },
              {
                type: 'input',
                name: 'phone',
                placeholder: '请输入联系电话'
              },
              {
                type: 'input',
                name: 'roomNum',
                placeholder: '请输入车位号'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '姓名',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '车牌号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carNumber'
          },
          {
            label: '电话',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'phone'
          },
          {
            label: '开始时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'startTime'
          },
          {
            label: '结束时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'endTime'
          },
          {
            label: '车辆类型',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carTypeCN'
          },
          {
            label: '状态',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'status'
          },
          {
            label: '场内月租数',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'multVip'
          },
          {
            label: '是否在场内',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'inside'
          },
          {
            label: '备注',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'remark'
          },
          {
            label: '车位号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'roomNum'
          },
          {
            label: '创建时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'dateline'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          create: 'graylist:create',
          info: 'graylist:info',
          delete: 'graylist:delete',
          update: 'graylist:update',
          export: 'graylist:export',
          import: 'graylist:import'
        },

        /**
         * 需要合并的table字段
         */
        needMergeArr: [
          'name',
          'phone',
          'startTime',
          'endTime',
          'carTypeCN',
          'remark',
          'status',
          'multVip',
          'inside',
          'roomNum',
          'dateline'
        ],

        /**
         * 预合并的数组
         */
        rowMergeArr: []
      }
    },

    computed: {
      importActions() {
        return process.env.VUE_APP_BASE_API + '/pmVipGray/uploadExcel?pId=' + this.pid + '&operId=' + this.uid
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 进行预合并
       * @param {Array} arr 需要合并的字段数组
       * @param {Array} data 列表数组
       * @return {Array} 合并数组
       */
      rowMergeHandle(arr, data) {
        if (!Array.isArray(arr) && !arr.length) return false
        if (!Array.isArray(data) && !data.length) return false
        const needMerge = {}
        arr.forEach((i, idx) => {
          needMerge[i] = {
            rowArr: [],
            rowMergeNum: 0
          }
          if (idx === 0) {
            data.forEach((item, index) => {
              // 表格首个数据单独处理
              if (index === 0) {
                needMerge[i].rowArr.push(1)
                needMerge[i].rowMergeNum = 0
              } else {
                if (item[i] === data[index - 1][i]) {
                  needMerge[i].rowArr[needMerge[i].rowMergeNum] += 1
                  needMerge[i].rowArr.push(0)
                } else {
                  needMerge[i].rowArr.push(1)
                  needMerge[i].rowMergeNum = index
                }
              }
            })
          } else {
            const firstRowArr = needMerge[arr[0]].rowArr
            const firstRowArrDeal = []
            firstRowArr.forEach((item, index) => {
              if (item > 0) {
                firstRowArrDeal.push(index)
              }
            })
            data.forEach((item, index) => {
              let sign = false
              if (firstRowArrDeal.indexOf(index) > 0) {
                needMerge[i].rowMergeNum = index
                sign = true
              }
              // 表格首个数据单独处理
              if (index === 0) {
                needMerge[i].rowArr.push(1)
                needMerge[i].rowMergeNum = 0
              } else {
                if (item[i] === data[index - 1][i]) {
                  if (sign) {
                    needMerge[i].rowArr.push(1)
                  } else {
                    needMerge[i].rowArr[needMerge[i].rowMergeNum] += 1
                    needMerge[i].rowArr.push(0)
                  }
                } else {
                  needMerge[i].rowArr.push(1)
                  needMerge[i].rowMergeNum = index
                }
              }
            })
          }
        })
        return needMerge
      },

      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        for (const res in this.needMergeArr) {
          if (this.needMergeArr[res] === column.property) {
            return this.mergeAction(column.property, rowIndex, columnIndex)
          }
        }
      },

      mergeAction(val, rowIndex) {
        const _row = this.rowMergeArr[val].rowArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return [_row, _col]
      },

      /**
       * 编辑前预处理
       */
      beforeEdit(data) {
        info(data.id).then((res) => {
          this.actionDataItem.startTime = res.data.startTime * 1000
          this.actionDataItem.endTime = res.data.endTime * 1000
          this.actionDataItem.carType = Number(res.data.carType)
          this.actionDataItem.carNumberArr = []
          this.actionDataItem.carNumberArr.push({
            carNumber: res.data.carNumber
          })
        })
      },

      /**
       * 获取列表数据
       */
      getList() {
        if (this.pid) {
          const _this = this

          // 组装查询条件
          const queryData = {}
          queryData.pId = this.pid
          queryData.current = this.currentPage
          queryData.size = this.pageSize
          this.filterData.name && (queryData.name = this.filterData.name)
          this.filterData.carNumber && (queryData.carNumber = this.filterData.carNumber)
          this.filterData.phone && (queryData.phone = this.filterData.phone)
          this.filterData.roomNum && (queryData.roomNum = this.filterData.roomNum)

          list(queryData)
            .then((res) => {
              this.dataList = res.data.records
              this.total = res.data.total
              _this.rowMergeArr = _this.rowMergeHandle(_this.needMergeArr, _this.dataList)
            })
            .catch((err) => {
              _this.noticeError(err.message)
            })
            .finally(() => {})
        }
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            let formData = {}
            formData = _this.actionDataItem
            formData.pId = _this.pid
            formData.operId = _this.uid
            formData.startTime = moment(_this.actionDataItem.startTime).format('YYYY-MM-DD')
            formData.endTime = moment(_this.actionDataItem.endTime).format('YYYY-MM-DD')

            if (_this.modalObject.currentAction === 'edit') {
              // formData.newSecondCategory = '[]'
              formData.carNumber = _this.actionDataItem.carNumberArr[0].carNumber
              const newSecondCategory = _this.actionDataItem.carNumberArr.slice(1)
              let newSecondCategoryStr = '['
              newSecondCategory.forEach((item) => {
                newSecondCategoryStr += '"' + item.carNumber + '"'
              })
              newSecondCategoryStr += ']'
              formData.newSecondCategory = newSecondCategoryStr
              decodeURIComponent(newSecondCategoryStr)

              update(formData.id, formData)
                .then((res) => {
                  _this.handleRefresh()
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
                .finally(() => {
                  _this.modalObject.status = false
                })
            } else {
              formData.carNumber = _this.actionDataItem.carNumberArr[0].carNumber
              const newSecondCategory = _this.actionDataItem.carNumberArr.slice(1)
              let newSecondCategoryStr = '['
              newSecondCategory.forEach((item) => {
                newSecondCategoryStr += '"' + item.carNumber + '"'
              })
              newSecondCategoryStr += ']'
              formData.newSecondCategory = newSecondCategoryStr
              decodeURIComponent(newSecondCategoryStr)
              add(formData)
                .then((res) => {
                  _this.handleRefresh()
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
                .finally((_this.modalObject.status = false))
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      },

      /**
       * 批量删除记录
       */
      handleBatchDel(ids) {
        const _this = this
        const idArr = []
        ids.forEach((item) => {
          idArr.push(item.id)
        })
        const postData = {}
        postData.ids = idArr.join(',')
        batchDelete(postData)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出灰名单列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.pId = this.pid
            queryData.current = this.currentPage
            queryData.size = this.pageSize
            this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)
            this.filterData.name && (queryData.name = this.filterData.name)
            this.filterData.carNumber && (queryData.carNumber = this.filterData.carNumber)
            this.filterData.phone && (queryData.phone = this.filterData.phone)
            exportRecord(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'startTime') {
                        return _this.parseTime(v[j])
                      } else if (j === 'endTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '灰名单列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                console.log(err)
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 下载模板
       */
      handleDownload() {
        window.open('http://matrixoss.oss-cn-zhangjiakou.aliyuncs.com/OPO/data/template/gray.xls')
      },

      /**
       * 上传成功
       */
      handleImportSuccess() {
        this.noticeSuccess('上传成功')
        this.handleRefresh()
      },

      /**
       * 添加车牌按钮点击事件
       */
      handleAddItem() {
        // 在actionDataItem中添加成员
        this.actionDataItem.carNumberArr.push({
          carNumber: ''
        })
        // 在表单验证规则中添加成员
        this.rules.carNumberArr.push({
          carNumber: [{ required: true, message: '请输入车牌号码', trigger: 'blur' }]
        })
      },

      /**
       * 删除车牌按钮点击事件
       */
      handleDeleteItem(index) {
        if (this.actionDataItem.carNumberArr.length > 1) {
          // 在actionDataItem中删除成员
          // this.actionDataItem.carNumberArr.splice(index, 1)
          this.actionDataItem.carNumberArr.pop()
          // 在表单验证规则中删除成员
          // this.rules.carNumberArr.splice(index, 1)
          this.rules.carNumberArr.pop()
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .gz-dynamtic-input-wrapper {
    display: grid;
    grid-column-gap: 20px;
  }
  ::v-deep .el-date-editor {
    width: 100%;
  }
</style>
