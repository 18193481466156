<!--
#***********************************************
#
#      Filename: src/views/List/WhiteList.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 白名单
#        Create: 2021-10-28 16:36:50
# Last Modified: 2021-11-29 18:00:34
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :import-actions="importActions"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增白名单')"
      @refresh="handleRefresh"
      @export="handleExport"
      @download="handleDownload"
      @import-success="handleImportSuccess"
      @import-error="handleImportError"
      @batch-delete="handleBatchDelete('批量删除白名单', handleBatchDel)" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe size="mini" border @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          {{ parseTime(scope.row.dateline) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop">
        <template slot-scope="scope">
          {{ formOperName(scope.row.operId) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :min-width="tableHeadFields[5].minWidth"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '修改白名单')">
              编辑
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除白名单', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="业主姓名:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请输入业主姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话号码:" prop="phone">
              <el-input v-model="actionDataItem.phone" placeholder="请输入业主电话号码" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="车牌号:" required>
              <div
                class="gz-dynamtic-input-wrapper"
                :style="{
                  'grid-template-columns':
                    'repeat(' + (actionDataItem.carNumberArr ? actionDataItem.carNumberArr.length + 1 : 1) + ', 1fr)'
                }">
                <el-form-item
                  v-for="(item, index) in actionDataItem.carNumberArr"
                  :key="index"
                  label=""
                  :prop="'carNumberArr.' + index + '.carNumber'">
                  <el-input v-model="item.carNumber" placeholder="请输入车牌号码" />
                </el-form-item>
                <!-- <div class="gz-add-and-sub-btn-group">
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-plus"
                    circle
                    @click="handleAddItem()"
                  />
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-minus"
                    circle
                    @click="handleDeleteItem(index)"
                  />
                </div> -->
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="actionDataItem.remark" type="textarea" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { list, add, info, batchDelete, deleteItem, exportRecord, update, upload } from '@/api/whiteList' // eslint-disable-line
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')
  export default {
    name: 'WhiteList',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        operData: [],
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          name: '',
          phone: '',
          carNumberArr: [
            {
              carNumber: ''
            }
          ],
          remark: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          name: [{ required: true, message: '业主姓名不能为空', trigger: 'blur' }],
          carNumberArr: [
            {
              carNumber: [{ required: true, message: '请输入车牌号码', trigger: 'blur' }]
            }
          ]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '姓名'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: true,
            importDataShow: true,
            downloadTemplateShow: true,
            more: [
              {
                label: '批量删除',
                permission: 'delete',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'input',
                name: 'carNumber',
                placeholder: '请输入车牌号'
              },
              {
                type: 'input',
                name: 'phone',
                placeholder: '请输入联系电话'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '车主姓名',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '电话号码',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'phone'
          },
          {
            label: '车牌号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carNumber'
          },
          {
            label: '创建时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'dateline'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'operId'
          },
          {
            label: '备注',
            isShow: true,
            minWidth: '200',
            align: 'center',
            prop: 'remark'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          create: 'whitelist:create',
          info: 'whitelist:info',
          delete: 'whitelist:delete',
          update: 'whitelist:update',
          export: 'whitelist:export',
          import: 'whitelist:import'
        }
      }
    },

    computed: {
      importActions() {
        return process.env.VUE_APP_BASE_API + '/whiteList/uploadExcel?pId=' + this.pid + '&operId=' + this.uid
      }
    },

    mounted() {
      console.log()
      const opData = localStorage.getItem('operData')
      this.operData = JSON.parse(opData)
      this.getList()
    },

    methods: {
      /**
       * 获取列表数据
       */
      getList() {
        if (this.pid) {
          // 组装查询条件
          const queryData = this.filterData
          queryData.pId = this.pid
          queryData.current = this.currentPage
          queryData.size = this.pageSize
          queryData.name = this.filterData.searchStr

          list(queryData)
            .then((res) => {
              this.dataList = res.data.records
              this.total = res.data.total
            })
            .catch((err) => {
              console.error('车位列表查询出错', err)
            })
        }
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.modalObject.currentAction === 'edit') {
              _this.actionDataItem.pId = _this.pid
              _this.actionDataItem.operId = _this.uid
              const carNumberArr = []
              _this.actionDataItem.carNumberArr.forEach((item) => {
                if (item.carNumber) {
                  carNumberArr.push(item.carNumber)
                }
              })
              _this.actionDataItem.carNumber = carNumberArr.join(',')
              delete _this.actionDataItem.carNumberArr
              update(_this.actionDataItem)
                .then((response) => {
                  _this.modalObject.status = false
                  _this.handleRefresh()
                })
                .catch((e) => {
                  console.log(e)
                })
            } else {
              _this.actionDataItem.pId = _this.pid
              _this.actionDataItem.operId = _this.uid
              const carNumberArr = []
              _this.actionDataItem.carNumberArr.forEach((item) => {
                if (item.carNumber) {
                  carNumberArr.push(item.carNumber)
                }
              })
              _this.actionDataItem.carNumber = carNumberArr.join(',')
              delete _this.actionDataItem.carNumberArr
              add(_this.actionDataItem)
                .then((res) => {
                  _this.modalObject.status = false
                  _this.handleRefresh()
                })
                .catch((e) => {
                  console.log(e)
                })
            }
          }
        })
      },

      /**
       * 添加成员按钮点击事件
       */
      handleAddItem() {
        // 在actionDataItem中添加成员
        if (this.actionDataItem.carNumberArr.length < 3) {
          this.actionDataItem.carNumberArr.push({
            carNumber: ''
          })
          // 在表单验证规则中添加成员
          this.rules.carNumberArr.push({
            carNumber: [{ required: true, message: '请输入车牌号码', trigger: 'blur' }]
          })
        } else {
          this.noticeError('最多添加三个车牌')
        }
      },

      /**
       * 删除成员按钮点击事件
       */
      handleDeleteItem(index) {
        if (this.actionDataItem.carNumberArr.length > 1) {
          // 在actionDataItem中删除成员
          // this.actionDataItem.carNumberArr.splice(index, 1)
          this.actionDataItem.carNumberArr.pop()
          // 在表单验证规则中删除成员
          // this.rules.carNumberArr.splice(index, 1)
          this.rules.carNumberArr.pop()
        }
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      },

      /**
       * 批量删除记录
       */
      handleBatchDel(ids) {
        const _this = this
        const idArr = []
        ids.forEach((item) => {
          idArr.push(item.id)
        })
        const postData = {}
        postData.ids = idArr.join(',')
        batchDelete(postData)
          .then((res) => {
            _this.noticeSuccess('批量删除成功')
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * overwrite mixins中的edit
       */
      edit(dataObj, title) {
        this.modalObject.currentAction = 'edit'
        this.modalObject.title = title
        this.modalObject.status = true
        const objectKey = Object.keys(this.formData)
        const filteredFormData = {}
        filteredFormData.id = dataObj.id
        objectKey.map((item) => {
          filteredFormData[item] = dataObj[item]
        })
        // 这里要把成员信息更新到actionDataItem中,默认把业主的第一间房的信息放进去
        const carNumberArr = []
        dataObj.carNumber.split(',').forEach((item) => {
          carNumberArr.push({ carNumber: item })
        })
        filteredFormData.carNumberArr = carNumberArr
        this.actionDataItem = filteredFormData
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出白名单列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.pId = this.pid
            queryData.current = this.currentPage
            queryData.size = this.pageSize
            this.filterData.searchKey && (queryData.searchStr = this.filterData.searchKey)
            this.filterData.name && (queryData.name = this.filterData.name)
            this.filterData.carNumber && (queryData.carNumber = this.filterData.carNumber)
            this.filterData.phone && (queryData.phone = this.filterData.phone)
            exportRecord(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'dateline') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '白名单列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 下载模板
       */
      handleDownload() {
        window.open('http://matrixoss.oss-cn-zhangjiakou.aliyuncs.com/OPO/data/template/specialVehicle.xls')
      },

      /**
       * 上传成功
       */
      handleImportSuccess() {
        this.noticeSuccess('上传成功')
        this.handleRefresh()
      },

      /**
       * 上传失败
       */
      handleImportError(res) {
        this.noticeError('上传失败:' + res.message || res.msg)
      }
    }
  }
</script>
<style scoped lang="scss">
  .gz-dynamtic-input-wrapper {
    display: grid;
    grid-column-gap: 20px;
  }
</style>
