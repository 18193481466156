<!--
#***********************************************
#
#      Filename: src/views/Setting/OnlineInvoice.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 线上发票设置
#        Create: 2021-10-28 16:57:25
# Last Modified: 2021-11-15 10:14:19
#***********************************************
-->
<template>
  <div class="app-container">
    <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
      <el-row :gutter="20">
        <el-col :span="16">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="选择机构:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="发票税号:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="企业名称:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="企业电话:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="地址:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="银行账号:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开户行:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="税率:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="银行账号:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开户行:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="税率:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="开票人:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="复核人:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收款人:" prop="areaNumber">
                <el-input v-model="actionDataItem.areaNumber" placeholder="请输入区号" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import { list } from '@/api/owner' // eslint-disable-line
  export default {
    name: 'Owner',

    mixins: [Pagination, Filter, ModalMixins, CommonInfo]
  }
</script>
