/*
#***********************************************
#
#      Filename: src/api/blackList/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 灰名单列表
#        Create: 2021-11-23 00:02:38
# Last Modified: 2021-11-26 13:56:29
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 添加
export function add(data) {
  const query = qs.stringify(data)
  return request({
    url: '/pmVipGray/add',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 批量删除
export function batchDelete(data) {
  return request({
    url: '/pmVipGray/batchdel',
    method: 'post',
    params: data
  })
}

// 删除
export function deleteItem(id, data) {
  return request({
    url: '/pmVipGray/delete/' + id,
    method: 'post',
    params: data
  })
}

// 导出
export function exportRecord(data) {
  return request({
    url: '/pmVipGray/export',
    method: 'get',
    params: data
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/pmVipGray/info/' + id,
    method: 'get'
  })
}

// 详细信息
export function list(data) {
  return request({
    url: '/pmVipGray/list',
    method: 'get',
    params: data
  })
}

// 更新数据
export function update(id, data) {
  const query = qs.stringify(data)
  return request({
    url: '/pmVipGray/update/' + id,
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 白名单导入
export function upload(data) {
  return request({
    url: '/pmVipGray/uploadExcel',
    method: 'post'
  })
}
