/*
 *                        .::::.
 *                      .::::::::.
 *                     :::::::::::
 *                  ..:::::::::::'
 *               '::::::::::::'
 *                 .::::::::::
 *            '::::::::::::::..
 *                 ..::::::::::::.
 *               ``::::::::::::::::
 *                ::::``:::::::::'        .:::.
 *               ::::'   ':::::'       .::::::::.
 *             .::::'      ::::     .:::::::'::::.
 *            .:::'       :::::  .:::::::::' ':::::.
 *           .::'        :::::.:::::::::'      ':::::.
 *          .::'         ::::::::::::::'         ``::::.
 *      ...:::           ::::::::::::'              ``::.
 *     ````':.          ':::::::::'                  ::::..
 *                        '.:::::'                    ':'````..
 *
 * @Author       : wwj 318348750@qq.com
 * @Date         : 2022-03-28 19:33:28
 * @LastEditors  : wwj 318348750@qq.com
 * @LastEditTime : 2022-07-16 11:01:20
 * @FilePath     : \gz-report-front\src\api\room\index.js
 * @Description  : des
 */

/*
#***********************************************
#
#      Filename: src/api/room/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 房间相关api接口
#        Create: 2021-11-10 15:27:39
# Last Modified: 2021-11-27 21:16:36
#***********************************************
*/
import request from '@/utils/request'

// 创建房屋
export function add(data) {
  return request({
    url: '/housing/house/create',
    method: 'post',
    data: data
  })
}

// 房屋删除
export function deleteItem(id) {
  return request({
    url: '/housing/house/delete/' + id,
    method: 'post'
  })
}

// 房屋信息
export function info(id) {
  return request({
    url: '/housing/house/info/' + id,
    method: 'get'
  })
}

// 数据列表
export function list(data) {
  return request({
    url: '/housing/house/list',
    method: 'get',
    params: data
  })
}

// 房屋基本信息更新
export function update(id, data) {
  // const formData = qs.stringify(data)
  return request({
    url: '/housing/house/update/' + id,
    method: 'post',
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    // },
    // data: formData
    data: data
  })
}

// 树状列表
export function tree(id) {
  return request({
    url: '/housing/house/tree/' + id,
    method: 'get'
  })
}

// 导入批次列表
export function importBatchList(data) {
  return request({
    url: '/housing/house/importBatch/list',
    method: 'get',
    params: data
  })
}

// 导入批次列表
export function exportErrorRecords(id) {
  return request({
    url: '/housing/house/importBatch/' + id + '/failRecords/export',
    method: 'get',
    responseType: 'blob'
  })
}
